(function () {
    const loginForms = document.querySelectorAll('.login-form form');
    loginForms.forEach(form => {
        const userName = form.querySelector('input[name="login"]');
        const password = form.querySelector('input[type="password"]');
        const isEmpty = el => el.value.replace(/^\s+|\s+$/g, '').length === 0;
        const toggleError = (el, status) => {
            const parent = el.closest('.login-input--wrapper');
            const hintEl = parent?.querySelector('.error-hint');
            if (!parent && !hintEl) return;
            switch (status) {
                case 'show':
                    parent.classList.add('js--error');
                    hintEl.classList.remove('js--hidden');
                    break;
                case 'hide':
                    parent.classList.remove('js--error');
                    hintEl.classList.add('js--hidden');
                    break;
            }
        };
        const formValidation = e => {
            if (!isEmpty(userName) && !isEmpty(password)) return;
            e.preventDefault();
            const errorInputs = form.querySelectorAll('.login-input--wrapper input');
            let isFocused = false;
            errorInputs.forEach(input => {
                if (isEmpty(input)) {
                    toggleError(input, 'show');
                    if (!isFocused) {
                        input.focus();
                        isFocused = true;
                    }
                }
            });
        };
        const inputHandler = e => {
            const {target} = e;
            if (isEmpty(target)) return;
            toggleError(e.target, 'hide');
        };

        form.addEventListener('submit', formValidation);
        [userName, password].forEach(item => item.addEventListener('input', inputHandler));
    });
})();
