(function () {
    // if Pb is open no content truncation should take effect
    let colorOverlay = {
        init: function () {
            let isNotPb = $('#pb-editor [data-pb-dropzone]').length === 0,
                isMobile = false,
                vPort = 'screen-md',
                $footnotesLinks = $('.article-header__info__footnotes'),
                $footnotesCtrl = $('.article-header__footnotes');

            $footnotesLinks.on('click', function () {
                $footnotesCtrl.click();
            });

            // transformTruncate(toTrunk8);
            function transformTruncate(element) {
                if ($(element).length > 0 && typeof $(element).truncate !== 'undefined') {
                    $(element).truncate('destroy');
                    let lines = 5;
                    if (!isMobile) {
                        lines = 2;
                    }

                    $(element).truncate({
                        truncateType: 'number',
                        lines: lines,
                        type: 'list',
                        seeMoreLink: true,
                        seeMoreText: 'Show all authors',
                        seeLessText: 'Show less',
                        position: 'beforeLast',
                    });
                }
            }

            if (isNotPb) {
                let toTrunk8 = '.loa';
                if ($(window).width() < UX.grid.screenMd) {
                    isMobile = true;
                }

                $(document).on(vPort + '-on', function () {
                    // Waiting for custom event that will be triggered by controller.js to activate responsive effects
                    isMobile = true;
                    transformTruncate(toTrunk8);
                });

                $(document).on(vPort + '-off', function () {
                    // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
                    isMobile = false;
                    transformTruncate(toTrunk8);
                });

                transformTruncate(toTrunk8);
            }
            colorOverlay.checkTitle(); // chech article header region height to not exceed 690
        },
        checkTitle: function () {
            if ($('.article-header__overlay').length) {
                let headerLength = $('.article-header__title').html().length;
                if (headerLength > 100) {
                    $('.article-header__title').addClass('smaller'); // make title font size smaller
                } else {
                    $('.article-header').addClass('article-header__decrease-padding');
                }
                if ($('.article-header__middle').outerHeight() <= 400) {
                    $('.article__header').addClass('low-height');
                }
            }
        },
    };
    UX.colorOverlay = colorOverlay;
})();
