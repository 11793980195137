(function () {
    // TODO: Keeping this just in case it has been used by literatum JS code related to ajax response, should be removed later on.
    var article = {
        init: function () {
            $('.MathJax').each(function () {
                var width = $(this).width();
                var parentWidth = $(this).parent().width();
                if (parentWidth < width) {
                    $(this).css({'overflow-y': 'auto', 'overflow-x': 'hidden', 'max-width': '100%', display: 'block'});
                }
            });
        },
    };
    UX.article = article; // add to global namespace
})();
