(function () {
    var ecHelp = {
        $closeBtn: $('.ec-help_db-close-btn'),
        $dropBlockBtn: $('.ec-help_db-btn'),
        $isMobile: false,
        init: function () {
            ecHelp.control();
        },
        control: function () {
            // is mobile
            $(document).on('screen-sm-on', function () {
                ecHelp.$isMobile = true;
            });
            $(document).on('screen-sm-off', function () {
                ecHelp.$isMobile = false;
            });

            ecHelp.$dropBlockBtn.click(function () {
                let $offset = $(this).offset();
                if (ecHelp.$isMobile) {
                    $(this)
                        .next('.ec-help_db')
                        .css('left', -$offset.left + 15);
                } else {
                    $(this).next('.ec-help_db').removeAttr('style');
                }
            });
            ecHelp.$closeBtn.click(function (e) {
                e.preventDefault();
                $(this).closest('.ec-help').find('.ec-help_db-btn').trigger('click');
            });
        },
    };
    UX.ecHelp = ecHelp; // add to global namespace
})();
