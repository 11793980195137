import SimpleViewer from '../../../plugins/simpleViewer/js/simpleViewer';

(function () {
    const $body = document.querySelector('body');
    const searchResult = UX.searchResult;
    const SPACE_KEY = 32;
    let prevMultimediaRowLength = 0;

    searchResult.additionalInit = () => {
        let $qsSearchFields = Array.prototype.slice.call(document.querySelectorAll('.searchText'));
        let $qsSearchDropdownFields = Array.prototype.slice.call(document.querySelectorAll('.quick-search__dropdown'));
        let $qsContainer = document.querySelector('.search-result__quick-search');
        let $qsResultCount;
        let $qsScopeOptions = Array.prototype.slice.call(document.querySelectorAll('.quick-search__scope-options'));
        let $resultCount = document.querySelector('.search-result__count');

        if ($qsContainer) {
            let currentSearchTerm = $qsContainer.dataset.searchterm;
            let currentSearchField = $qsContainer.dataset.searchfield;
            let currentJournals = $qsContainer.dataset.selectedjournals.split(',');
            $qsResultCount = $qsContainer.querySelector('.quick-search__results-count');

            if (currentSearchTerm) {
                $qsSearchFields.forEach($qsSearchField => {
                    $qsSearchField.value = currentSearchTerm;
                });
            }

            if (currentSearchField) {
                $qsSearchDropdownFields.forEach($qsSearchDropdownField => {
                    $qsSearchDropdownField.value = currentSearchField;
                });
            }

            $qsScopeOptions.forEach($scopeOption => {
                let $journalCodeField = $scopeOption.querySelector('[name="journalCode"]');
                let $fullSiteField = $scopeOption.querySelector('[name="SeriesKey"][value=""]');
                if ($journalCodeField && $fullSiteField) {
                    let journalCode = $journalCodeField.value;
                    if (!currentJournals || !currentJournals.includes(journalCode)) {
                        $fullSiteField.checked = true;
                    }
                }
            });
        }

        if ($qsResultCount && $resultCount) {
            $qsResultCount.textContent = $resultCount.textContent;
        }
    };

    searchResult.additionalControl = () => {
        let $advancedSearchLink = document.querySelector('.search-result .quick-search__advanced-link');
        let $advancedSearchModal = document.querySelector('.advanced-search-modal');
        let $selectAllArticles = document.querySelector('.search-result #selectall');
        let $selectArticleCheckboxes = Array.prototype.slice.call(
            document.querySelectorAll('.search__item .search__item__select')
        );
        let $advancedSearchModalClose;
        let $exportAlert = document.querySelector('.search-result__alert');
        let $exportAlertClose = document.querySelector('.search-result__alert--close');
        let $exportCitationsLink = document.querySelector('.search-result__export-citations');
        let $exportMultiPdfLink = document.querySelector('.search-result__download-multi-pdf');
        let $exportOptionsHolder = document.querySelector('.export-options__holder');
        let $advSearchModalEnd = document.querySelector('.advanced-search-modal_end');
        let $viewLargeLinks = Array.prototype.slice.call(document.querySelectorAll('.search__item__links__view-large'));
        let $previewLinks = Array.prototype.slice.call(document.querySelectorAll('.search-result .preview_link'));
        let $closeFilters = document.querySelector('.search-result__close-filters-toggle');
        let $closeFiltersLink;

        let $exportOptionsHolderClose;

        if ($closeFilters) {
            $closeFiltersLink = $closeFilters.querySelector('a');

            $closeFiltersLink.addEventListener('click', e => {
                e.preventDefault();
                document.querySelector('.facets-slide .close-facets').click();
                $closeFilters.style.display = 'none';
            });
        }

        if ($advancedSearchModal) {
            $advancedSearchModalClose = $advancedSearchModal.querySelector('.close');
        }

        if ($exportOptionsHolder) {
            $exportOptionsHolderClose = $exportOptionsHolder.querySelector('.dropBlock__ctrl--close');
        }

        if ($advancedSearchLink) {
            $advancedSearchLink.addEventListener('click', e => {
                e.preventDefault();
                $body.classList.add('noscroll');
                $advancedSearchModal.classList.remove('hidden');
                $advancedSearchModalClose.focus();
            });
        }

        if ($advancedSearchModalClose) {
            $advancedSearchModalClose.addEventListener('click', e => {
                e.preventDefault();
                $advancedSearchModal.classList.add('hidden');
                $body.classList.remove('noscroll');
                $advancedSearchLink.focus();
            });

            $advancedSearchModalClose.addEventListener('keypress', e => {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if (keycode === SPACE_KEY || keycode === 'SPACE_KEY') {
                    e.preventDefault();
                    $advancedSearchModalClose.click();
                }
            });
        }

        if ($exportOptionsHolderClose) {
            $exportOptionsHolderClose.addEventListener('keypress', e => {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if (keycode === SPACE_KEY || keycode === 'SPACE_KEY') {
                    e.preventDefault();
                    $exportOptionsHolderClose.click();
                }
            });
        }

        if ($selectAllArticles) {
            $selectAllArticles.addEventListener('click', e => {
                $selectArticleCheckboxes.forEach($articleCheckbox => {
                    $articleCheckbox.checked = $selectAllArticles.checked;
                });
            });
        }

        if ($selectArticleCheckboxes.length) {
            $selectArticleCheckboxes.forEach($articleCheckbox => {
                $articleCheckbox.addEventListener('click', e => {
                    if (!$articleCheckbox.checked) {
                        $selectAllArticles.checked = false;
                    }
                });
            });
        }

        if ($exportCitationsLink) {
            $exportCitationsLink.addEventListener('click', e => {
                e.preventDefault();
                let $selectedArticleCheckboxes = Array.prototype.slice.call(
                    document.querySelectorAll('.search__item .search__item__select:checked')
                );
                if ($selectedArticleCheckboxes.length === 0) {
                    $exportAlert.querySelector('p').textContent =
                        'Please select at least one article before clicking on the Export Citations link.';
                    $exportAlert.classList.remove('hidden');
                    UX.dropBlock.on.hide();
                } else {
                    window.location = $exportCitationsLink.href + '?' + $($selectedArticleCheckboxes).serialize();
                }
            });
        }

        if ($exportMultiPdfLink) {
            $exportMultiPdfLink.addEventListener('click', e => {
                e.preventDefault();
                let $selectedArticleCheckboxes = Array.prototype.slice.call(
                    document.querySelectorAll('.search__item .search__item__select:checked')
                );
                if ($selectedArticleCheckboxes.length === 0) {
                    $exportAlert.querySelector('p').textContent =
                        'Please select at least one article before clicking on the Export PDF link.';
                    $exportAlert.classList.remove('hidden', 'loading');
                    UX.dropBlock.on.hide();
                } else {
                    searchResult.exportArticles($exportMultiPdfLink.href, $selectedArticleCheckboxes);
                }
            });
        }

        if ($exportAlertClose) {
            $exportAlertClose.addEventListener('click', e => {
                e.preventDefault();
                $exportAlert.classList.add('hidden');
            });
        }

        $(document).on('slide:open', (e, slide) => {
            if (slide.$target.is('#filter')) {
                slide.$elements.addClass('facets-slide');
                $closeFilters.style.display = 'block';
            }
        });

        $(document).on('slide:close', (e, slide) => {
            if (slide.$target.is('#filter')) {
                $closeFilters.style.display = 'none';
            }
        });

        if ($advSearchModalEnd) {
            $advSearchModalEnd.addEventListener('focusout', event => {
                $advancedSearchModalClose.focus();
            });
        }
        if ($previewLinks.length) {
            $previewLinks.forEach($previewLink => {
                $previewLink.addEventListener('click', e => {
                    e.preventDefault();
                    $($previewLink).toggleClass('js--open').closest('.search__item').find('.preview_content').toggle();
                });
            });
        }

        if ($viewLargeLinks.length) {
            new SimpleViewer('.search__item__links__view-large');
        }

        let $multimediaItems = Array.prototype.slice.call(document.querySelectorAll('.search__item--multimedia'));

        if ($multimediaItems.length > 0) {
            window.addEventListener('load', e => {
                searchResult.fixMultimediaLayout($multimediaItems);
            });

            window.addEventListener('resized', e => {
                searchResult.fixMultimediaLayout($multimediaItems);
            });
        }
    };

    searchResult.getMultimediaRowLength = () => {
        let ret;
        let rowlength = Array.prototype.reduce.call(
            document.querySelectorAll('.search__item--multimedia'),
            function (prev, next) {
                if (!prev[2]) {
                    ret = next.getBoundingClientRect().left;
                    // if increasing, increment counter
                    if (ret > prev[1]) {
                        prev[0]++;
                    } else {
                        prev[2] = 1;
                    } // else stop counting
                }
                return [prev[0], ret, prev[2]]; // [counter, elem, stop-counting]
            },
            [0, null, 0]
        )[0];

        return rowlength;
    };

    searchResult.fixMultimediaLayout = $multimediaItems => {
        // update multimedia caption element's height if there's empty space (LIT-384876)
        const MAX_CAPTION_HEIGHT = 204;
        const MAX_AV_CAPTION_HEIGHT = 290;
        const CAPTION_PADDING = 20;

        function chunkArray(array, size) {
            let result = [];
            for (let i = 0; i < array.length; i += size) {
                let chunk = array.slice(i, i + size);
                result.push(chunk);
            }
            return result;
        }

        let rowlength = searchResult.getMultimediaRowLength();

        if (rowlength !== prevMultimediaRowLength) {
            prevMultimediaRowLength = rowlength;

            let $multimediaRows = chunkArray($multimediaItems, rowlength);

            $multimediaRows.forEach(row => {
                let minEmptySpace = 290;

                row.forEach($item => {
                    let $captionContent = $item.querySelector('.figure__caption__body__content');
                    if ($captionContent) {
                        if ($item.classList.contains('search__item--multimedia-figure')) {
                            minEmptySpace = Math.min(minEmptySpace, MAX_CAPTION_HEIGHT - $captionContent.clientHeight);
                        } else if (
                            $item.classList.contains('search__item--multimedia-video') ||
                            $item.classList.contains('search__item--multimedia-audio')
                        ) {
                            minEmptySpace = Math.min(
                                minEmptySpace,
                                MAX_AV_CAPTION_HEIGHT - $captionContent.clientHeight
                            );
                        }
                    }
                });

                if (minEmptySpace > 0) {
                    row.forEach($item => {
                        let $captionBody = $item.querySelector('.figure__caption__body');

                        if ($captionBody) {
                            if ($item.classList.contains('search__item--multimedia-figure')) {
                                $captionBody.style.height = MAX_CAPTION_HEIGHT - minEmptySpace + CAPTION_PADDING + 'px';
                            } else if (
                                $item.classList.contains('search__item--multimedia-video') ||
                                $item.classList.contains('search__item--multimedia-audio')
                            ) {
                                $captionBody.style.height =
                                    MAX_AV_CAPTION_HEIGHT - minEmptySpace + CAPTION_PADDING + 'px';
                            }
                        }
                    });
                } else {
                    row.forEach($item => {
                        let $captionBody = $item.querySelector('.figure__caption__body');

                        if ($captionBody) {
                            if ($item.classList.contains('search__item--multimedia-figure')) {
                                $captionBody.style.height = MAX_CAPTION_HEIGHT + 'px';
                            } else if (
                                $item.classList.contains('search__item--multimedia-video') ||
                                $item.classList.contains('search__item--multimedia-audio')
                            ) {
                                $captionBody.style.height = MAX_AV_CAPTION_HEIGHT + 'px';
                            }
                        }
                    });
                }
            });
        }
    };

    searchResult.saveSearchSuccessHandler = () => {
        const SAVED_SEARCHES_URL = '/action/doSaveSearch';
        $('.success.saveSearchMsg').show('fade').removeClass('hidden');
        $('#newSearchSaveButton').show();
        $('.saveSearchProgress').hide();
        $('#saveSearchDialog').modal('toggle');
        $('#saveSearchDialog').click();
        UX.modal.on.hide();
        window.location.href = SAVED_SEARCHES_URL;
    };

    searchResult.exportArticles = (url, $selectedArticleCheckboxes) => {
        let $exportAlert = $('.search-result__alert');
        let data = $($selectedArticleCheckboxes).serialize();

        $exportAlert.find('p').text('');
        $exportAlert.removeClass('hidden').addClass('loading');
        UX.dropBlock.on.hide();

        if (typeof window.pageDataTracker !== 'undefined') {
            window.pageDataTracker.trackEvent('widgetClick', {
                link: {widgetName: 'Search Result UX3'},
            });
        }

        $.ajax({
            url: url,
            method: 'GET',
            data: data,
            success: function (data, status, xhr) {
                UX.UX3AdobeAnalytics.downloadFullIssueEventTrack.success(data, status, xhr);
                let downloadStatus = xhr.getResponseHeader('Download-Status');
                let message = '';

                if (downloadStatus === 'partial_access') {
                    message =
                        'We are unable to download all the articles unless you log in with your subscription credentials or make a purchase.';
                } else if (downloadStatus === 'no_content') {
                    message = 'There is no PDF for the selected articles.';
                } else {
                    message = 'Download started.';
                }
                $exportAlert.find('p').text(message);
                $exportAlert.removeClass('hidden loading');
                UX.dropBlock.on.hide();

                let downloadLink = document.createElement('a'),
                    url = data.link;
                downloadLink.href = url;
                downloadLink.className = 'xhr-issue-download-link';
                document.body.append(downloadLink);
                downloadLink.click();

                setTimeout(() => {
                    downloadLink.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            },
            error: function (xhr, status, error) {
                let downloadStatus = xhr.getResponseHeader('Download-Status');
                let message = '';

                switch (downloadStatus) {
                    case 'no_access':
                        message =
                            'We are unable to download any articles unless you log in with your subscription credentials or make a purchase.';
                        break;
                    case 'no_content':
                        message = 'There is no PDF for the selected articles.';
                        break;
                    default:
                        message = `Due to some issues please try again later. ${error}`;
                }
                UX.UX3AdobeAnalytics.downloadFullIssueEventTrack.error('search-result-page', message);
                $exportAlert.find('p').text(message);
                $exportAlert.removeClass('hidden loading');
                UX.dropBlock.on.hide();
            },
        });
    };

    if (window.PB && window.PB.$) {
        window.PB.$(document.documentElement).on('WidgetReinit.UX3SearchResults', () => {
            UX.searchResult.init();
        });
    }
})();
