let Html5Player = {
    videoType: ['video/webm', 'video/mpeg', 'video/mp4', 'video/ogg'],
    videoExt: ['webm', 'mpg', 'mpeg', 'mp4', 'm4p', 'm4v', 'ogv', 'ogg'],
    audioType: ['audio/wav', 'audio/mpeg', 'audio/ogg'],
    sharelinks: {
        tw: 'https://twitter.com/share?url=', //+ $title + '&url=' + share_url, // twitter
        fb: 'https://www.facebook.com/sharer/sharer.php?u=', // + share_url, //facebook
        em: 'mailto:?body=', // + share_url, //email
    },
    setup: function ($id, config) {
        let wrapperContent = $('<div>', {class: 'player-wrapper', width: '100%'});
        let mediaContent = null;
        if (!String.prototype.endsWith) {
            String.prototype.endsWith = function (search, this_len) {
                if (this_len === undefined || this_len > this.length) {
                    this_len = this.length;
                }
                return this.substring(this_len - search.length, this_len) === search;
            };
        }

        if (!String.prototype.startsWith) {
            String.prototype.startsWith = function (searchString, position) {
                position = position || 0;
                return this.indexOf(searchString, position) === position;
            };
        }

        if (config.src.endsWith('.mp3')) {
            mediaContent = this.buildAudio(config);
        } else {
            if (this.isYouTubeVideo(config.src)) {
                wrapperContent.addClass('yt-player-wrapper');
                mediaContent = this.buildYouTubeVideo(config);
            } else if (this.isHtml5Video(config.src)) {
                mediaContent = this.buildVideo(config);
            }
        }
        $(mediaContent).addClass('playerReady html5-media-player').css({
            'max-width': '100%',
            width: '100%',
        });
        wrapperContent.append(mediaContent);
        if (!config.src.endsWith('.mp3') && !this.isYouTubeVideo(config.src) && this.isHtml5Video(config.src)) {
            this.addShareButton(wrapperContent, config);
        }
        $($id).replaceWith(wrapperContent);

        let wrapperWidth = config.width;
        let playerShareButton = $(wrapperContent).find('.player-share-button');
        if (wrapperWidth < 405) {
            if (playerShareButton !== null && playerShareButton !== undefined && playerShareButton.length > 0) {
                $(playerShareButton).css({
                    width: '22px',
                    height: '22px',
                });
            }
        }

        $(window).on('resize', function () {
            let wrapperWidth = config.width;
            let playerShareButton = $(wrapperContent).find('.player-share-button');
            if (wrapperWidth < 405) {
                if (playerShareButton !== null && playerShareButton !== undefined && playerShareButton.length > 0) {
                    $(playerShareButton).css({
                        width: '22px',
                        height: '22px',
                    });
                }
            } else {
                if (playerShareButton !== null && playerShareButton !== undefined && playerShareButton.length > 0) {
                    $(playerShareButton).css({
                        width: '40px',
                        height: '40px',
                    });
                }
            }
        });

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            if (isMobile)
                $(playerShareButton).css({
                    right: '4%',
                    top: '11%',
                });
        }

        $.event.trigger('mediaPlayerReady' + $id);
    },
    buildVideo: function (config) {
        if (config.autoplay !== null && config.autoplay !== undefined && config.autoplay.length > 0) {
            if (config.autoplay === false) {
                delete config.autoplay;
            }
        }
        let videoBuilder = $('<video>', config);
        for (let type in this.videoType) {
            $(videoBuilder).append($('<source>', {type: this.videoType[type], src: config.src}));
        }
        return videoBuilder;
    },
    buildYouTubeVideo: function (config) {
        config.frameborder = '0';
        config.allowfullscreen = 'true';
        if (config.src.indexOf('watch?v=') !== -1) {
            config.src = config.src.replace('watch?v=', 'embed/');
        }
        if (config.src.startsWith('http://')) {
            config.src = config.src.replace('http', 'https');
        }
        config.src = config.src + '?enablejsapi=1';

        if (config.autoplay !== null && config.autoplay !== undefined && config.autoplay.length > 0) {
            if (config.autoplay === 'true') {
                config.src = config.src + '&autoplay=1&mute=1';
            }
            delete config.autoplay;
            delete config.preload;
        }

        let videoBuilder = $('<iframe>', config);
        /*if (config.poster && config.poster.length > 0) {
         let youtubeContainer = $("<div>",{'class':'youtube-video-holder'});
         $(videoBuilder).addClass('hidden');
         let posterHolder =  $("<button>",{'class': 'videoPoster js-videoPoster','style':'background-image: url('+config.poster+');',height: config.height});
         $(posterHolder).click(function () {
         $(this).addClass('hidden');
         $(videoBuilder).removeClass('hidden');
         let player = new YT.Player(config.id,{
         events: {
         'onReady': function () {
         player.playVideo();
         }
         }
         });
         });
         youtubeContainer.append(videoBuilder).append(posterHolder);
         return youtubeContainer;
         }*/
        return videoBuilder;
    },
    isYouTubeVideo: function (url) {
        if (url !== undefined || url !== '') {
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            let match = url.match(regExp);
            if (match && match[2].length === 11) {
                // Do anything for being valid
                return true;
            } else {
                // Do anything for not being valid
                return false;
            }
        }
        return false;
    },
    isHtml5Video: function (url) {
        if (url !== undefined || url !== '') {
            const ext = url.substring(url.lastIndexOf('.') + 1);
            if (ext && this.videoExt.includes(ext)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    },
    buildAudio: function (config) {
        let audioBuilder = $('<audio>', config);
        for (let type in this.audioType) {
            $(audioBuilder).append($('<source>', {type: this.audioType[type], src: config.src}));
        }
        return audioBuilder;
    },
    onReady: function ($id, callback) {
        if (typeof $id !== 'string' || $id.length === 0) return;
        let selectorId = $id.charAt(0) === '#' ? $id : '#' + $id;
        let mediaPlayer = $(selectorId);

        if ($(mediaPlayer).hasClass('playerReady') || $(mediaPlayer).hasClass('ready')) {
            callback();
        } else {
            $(mediaPlayer).on('loadeddata', callback);
        }
    },
    on: function ($id, $event, callback) {
        if (typeof $id !== 'string' || $id.length === 0) return;
        let selectorId = $id.charAt(0) === '#' ? $id : '#' + $id;
        let mediaPlayer = $(selectorId);
        $(mediaPlayer).on($event, callback);
    },
    off: function ($id, $event) {
        if (typeof $id !== 'string' || $id.length === 0) return;
        let selectorId = $id.charAt(0) === '#' ? $id : '#' + $id;
        let mediaPlayer = $(selectorId);
        $(mediaPlayer).off($event);
    },
    addShareButton: function (wrapperContent, config) {
        let shareButton = $('<div>', {class: 'player-share-button', tabindex: 0, title: 'Share Video'});
        shareButton.append("<div class='share-player-icon'></div>");
        let shareContent = $('<div>', {class: 'player-share-container hidden'});

        if (config.shareUrl === 'undefined' || config.shareUrl === null || config.shareUrl.length < 1) {
            config.shareUrl = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
        }

        let facebookHref = $('<li>', {
            class: 'html5-share-facebook-icon',
            'data-share-url': this.sharelinks.fb + config.shareUrl,
            target: '_blank',
            tabindex: 0,
        });

        let twitterHref = $('<li>', {
            class: 'html5-share-twitter-icon',
            'data-share-url': this.sharelinks.tw + config.shareUrl,
            target: '_blank',
            tabindex: 0,
        });

        let mailkHref = $('<li>', {
            class: 'html5-share-mail-icon',
            'data-share-url': this.sharelinks.em + config.shareUrl,
            target: '_blank',
            tabindex: 0,
        });

        let socialLinkContainer = $('<div>', {class: 'social-links-container'});
        let socialLinklist = $('<ul>', {class: 'social-links-list'});

        let closeShare = $('<span>', {class: 'player-close-share', tabindex: 0});

        shareContent.append(closeShare);
        socialLinklist.append(facebookHref).append(twitterHref).append(mailkHref);
        socialLinkContainer.append(socialLinklist);

        let sharingTextbox = $('<input>', {
            type: 'text',
            value: config.shareUrl,
            readonly: 'readonly',
            class: 'share-input',
        });
        socialLinkContainer.append(sharingTextbox);

        shareContent.append(socialLinkContainer);
        wrapperContent.append(shareButton).append(shareContent);

        $(shareButton).on('click keyup', function (e) {
            if (e.type === 'click' || (e.type === 'keyup' && e.keyCode === 13)) {
                $(shareContent).toggleClass('hidden');
                $(this).toggleClass('hidden');
                $(wrapperContent).find('video').removeAttr('controls');
            }
        });

        $(closeShare).on('click keyup', function (e) {
            if (e.type === 'click' || (e.type === 'keyup' && e.keyCode === 13)) {
                $(shareContent).toggleClass('hidden');
                $(shareButton).toggleClass('hidden');
                $(wrapperContent).find('video').attr('controls', 'controls');
            }
        });

        $(socialLinkContainer)
            .find('li')
            .each(function () {
                $(this).on('click keyup', function (e) {
                    if (e.type === 'click' || (e.type === 'keyup' && e.keyCode === 13)) {
                        let shareLink = $(this).attr('data-share-url');
                        window.open(shareLink);
                    }
                });
            });
    },
};
UX.Html5Player = Html5Player;
