import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    Injectable,
    UIComponent
} from '@atypon/ui-utils';

import { VPortListener } from 'ui-core/plugins/enquireJs/js/helper';

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    hidden = 'js--hidden';
}

@Injectable()
class Flags {
}

class Selectors extends ComponentSelectors {
    accordionControl: string = '.accordion__control';
    viewAllBiographies = new ComponentSelector('.viewAllBiographies');
}

class Elements extends ComponentElements<Selectors> {
    viewAllBiographies: HTMLElement;
}

interface EditorialBoard extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class EditorialBoard implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    @Inject()
    protected flags: Flags;

    @VPortListener('sm')
    isMobile: boolean = false;

    constructor(readonly wrapper: HTMLElement) {
    }

    protected isMobileOn(): void {
        this.isMobile = true;
    }

    protected isMobileOff(): void {
        this.isMobile = false;
    }

    protected viewAllBiographies(): void {
        const accordionControl = this.domUtils.getElement(this.selectors.accordionControl);
        if (accordionControl) this.elements.viewAllBiographies?.classList.remove(this.classList.hidden);
    }

    protected handleAccordionKeyDown(e: KeyboardEvent): void {
        const target = e.target as HTMLElement;
        const { key } = e;
        if (key === 'Enter' || key === ' ') {
            e.preventDefault();
            target.click();
        }
    }

    addEventListeners(): void {
        const accordionControlList = this.domUtils.getElements(this.selectors.accordionControl);
        accordionControlList.forEach(item => this.domUtils.addEventListener(item, 'keydown', this.handleAccordionKeyDown.bind(this)));
    }

    additionalInit(): void {
        this.viewAllBiographies();
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
        this.additionalInit();
    }
}

export default EditorialBoard;

export {
    Elements as EditorialBoardElements,
    Selectors as EditorialBoardSelectors,
    ClassList as EditorialBoardClassList,
    Texts as EditorialBoardTexts
};