// @TODO: this is old quick search (core) from core-1.49.0 and will should be removed and replaced by the quick-search form core-1.50.0 file (LIT-)
(function () {
    var $body = $('body');

    var quickSearch = {
        autoCompeteSearchUrl: '',
        suggestedData: [],
        enteredTerm: '',
        inputElem: '',
        maxWords: '',
        maxChars: '',
        confNumOfContributors: '',
        confNumOfHistoryItems: '',
        confNumOfPublicationTitles: '',
        confNumOfTopics: '',
        selectedOption: '',
        seriesKey: '',
        vPort: 'screen-md',
        isMobile: false,

        init: function () {
            quickSearch.inputElem = $('.quick-search .magicsuggest');
            quickSearch.seriesKey = $('input[name=SeriesKey]', '[name=thisJournalQuickSearch]').val();
            quickSearch.controller();
            quickSearch.additionalController();
        },
        controller: function () {
            $('.quick-search')
                .find('.magicsuggest')
                .each(function () {
                    quickSearch.inputElem = $(this);
                    quickSearch.maxWords = $(this).data('auto-complete-max-words');
                    quickSearch.maxChars = $(this).data('auto-complete-max-chars');
                    const searchType = $(this).parent();
                    quickSearch.enteredTerm = $(this).val();

                    if (searchType.hasClass('option-citation')) {
                        quickSearch.citationSuggestions();
                    } else if (searchType.hasClass('option-journal')) {
                        quickSearch.journalSearchSuggestions();
                    } else {
                        quickSearch.searchSuggistions();
                    }
                });

            $(document).on(
                'submit',
                '[name="defaultQuickSearch"],[name="citationQuickSearch"],[name="thisJournalQuickSearch"]',
                function (e) {
                    var empty = true;

                    $(this)
                        .find('input[type="text"],input[type="search"]')
                        .each(function () {
                            if ($(this).val() && $(this).val() !== '') {
                                empty = false;
                            }
                        });
                    if ($(this).find('.ms-sel-item').length > 0) {
                        empty = false;
                    }
                    if (empty) {
                        window.location = '/search/advanced';
                        e.preventDefault();
                    }
                }
            );

            if (config.enableQuicksearchFocusTrap) {
                // FOCUS TRAP for QuickSearch in Dropdown
                $(function () {
                    $(document).on('keydown', '.quick-search :tabbable:not([readonly])', function (e) {
                        var loop = $(this).closest('.quick-search');
                        if (
                            $body.attr('data-active') !== 'dropBlock' &&
                            !loop.closest('.dropBlock__holder.js--open').length
                        ) {
                            return;
                        }

                        const firstTabbable = loop.find(':tabbable:not([readonly])').first();
                        const lastTabbable = loop.find(':tabbable:not([readonly])').last();
                        // Leaving the first element with Tab : focus the last one
                        if (
                            firstTabbable.is(e.target) &&
                            e.shiftKey === true &&
                            window.innerWidth >= UX.grid.screenMd
                        ) {
                            e.preventDefault();
                            lastTabbable.focus();
                        }
                        // Leaving the last element with Tab : focus the first one
                        if (lastTabbable.is(e.target) && e.shiftKey === false) {
                            if (e.which === 13) {
                                UX.dropBlock.on.hide();
                            }
                            e.preventDefault();
                            firstTabbable.focus();
                        }
                    });
                });
            }
        },
        additionalController: function () {},

        doSuggest: function (sug) {
            quickSearch.inputElem.magicSuggest({
                data: sug,
                method: 'GET',
                valueField: 'value',
                autoSelect: false,
                displayField: 'label',
                cls: 'search-term',
                hideTrigger: 'true',
                beforeSend: function (xhr, settings) {
                    var enteredTerm = settings.url.substr(settings.url.indexOf('query=') + 6).replace(/[​​+]/g, ' ');
                    if (
                        enteredTerm.split(' ').length > quickSearch.maxWords ||
                        enteredTerm.length > quickSearch.maxChars ||
                        !enteredTerm.replace(/\s/g, '').length
                    ) {
                        return false;
                    }
                },
            });
        },
        citationSuggestions: function () {
            quickSearch.confNumOfPublicationTitles = quickSearch.inputElem.data('publication-titles-conf');
            quickSearch.autoCompeteSearchUrl =
                '/action/doSuggest?target=title-auto-complete&pts=' +
                quickSearch.confNumOfPublicationTitles +
                '&fl=PubID';
            quickSearch.selectedOption = 'citation';
            quickSearch.doSuggest(quickSearch.autoCompeteSearchUrl);
        },
        searchSuggistions: function () {
            quickSearch.confNumOfPublicationTitles = quickSearch.inputElem.data('publication-titles-conf');
            quickSearch.confNumOfContributors = quickSearch.inputElem.data('contributors-conf');
            quickSearch.confNumOfTopics = quickSearch.inputElem.data('topics-conf');
            quickSearch.confNumOfHistoryItems = quickSearch.inputElem.data('history-items-conf');
            quickSearch.autoCompeteSearchUrl =
                '/action/doSuggest?target=auto-complete&hs=' +
                quickSearch.confNumOfHistoryItems +
                '&pts=' +
                quickSearch.confNumOfPublicationTitles +
                '&ts=' +
                quickSearch.confNumOfTopics +
                '&cs=' +
                quickSearch.confNumOfContributors +
                '&fl=PubID';
            quickSearch.doSuggest(quickSearch.autoCompeteSearchUrl);
        },
        journalSearchSuggestions: function () {
            quickSearch.confNumOfPublicationTitles = quickSearch.inputElem.data('publication-titles-conf');
            quickSearch.confNumOfContributors = quickSearch.inputElem.data('contributors-conf');
            quickSearch.confNumOfTopics = quickSearch.inputElem.data('topics-conf');
            quickSearch.confNumOfHistoryItems = quickSearch.inputElem.data('history-items-conf');
            quickSearch.autoCompeteSearchUrl =
                '/action/doSuggest?target=auto-complete&hs=' +
                quickSearch.confNumOfHistoryItems +
                '&pts=' +
                quickSearch.confNumOfPublicationTitles +
                '&ts=' +
                quickSearch.confNumOfTopics +
                '&cs=' +
                quickSearch.confNumOfContributors +
                '&fl=PubID&within=' +
                quickSearch.seriesKey;
            quickSearch.doSuggest(quickSearch.autoCompeteSearchUrl);
        },
    };

    UX.quickSearch = quickSearch; // add to global namespace
})();
