export default class SimpleViewer {
    $links;
    $viewer;

    SPACE_KEY = 32;
    TAB_KEY = 9;
    BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

    constructor(selector) {
        this.init(selector);
    }

    init(selector) {
        this.$links = Array.prototype.slice.call(document.querySelectorAll(selector));
        if (this.$links.length) {
            this.setup();
            this.addListeners();
        }
    }

    addListeners() {
        this.$links.forEach($imageLink => {
            $imageLink.addEventListener('click', e => {
                e.preventDefault();
                this.$currentImageLink = $imageLink;
                this.show($imageLink.href, $imageLink.dataset);
            });
        });

        this.$closeLink.addEventListener('click', e => {
            e.preventDefault();
            this.hide();
        });

        this.$closeLink.addEventListener('keypress', e => {
            var keycode = e.keyCode ? e.keyCode : e.which;
            if (keycode === this.SPACE_KEY) {
                e.preventDefault();
                this.$closeLink.click();
            }
        });

        this.$closeLink.addEventListener('keydown', e => {
            var keycode = e.keyCode ? e.keyCode : e.which;

            if (keycode === this.TAB_KEY) {
                e.preventDefault();
            }
        });

        this.$viewer.addEventListener('click', e => {
            e.preventDefault();
            if (e.target === this.$viewer) {
                this.hide();
            }
        });
    }

    setup() {
        this.$pageContent = document.querySelector('#pb-page-content, .source_clarify_content');
        this.$viewer = document.createElement('div');
        this.$viewer.className = 'simple-viewer hidden';
        this.$viewer.setAttribute('role', 'dialog');
        this.$viewer.setAttribute('aria-modal', 'true');
        this.$viewer.setAttribute('aria-label', 'Large Image Viewer');

        this.$image = document.createElement('img');
        this.$image.src = this.BLANK_IMAGE;
        this.$viewer.appendChild(this.$image);

        this.$closeLink = document.createElement('a');
        this.$closeLink.href = '#closeSimpleViewer';
        this.$closeLink.className = 'simple-viewer__close';
        this.$closeLink.setAttribute('role', 'button');
        this.$closeLink.innerHTML =
            '<i aria-hidden="true" class="icon-tools_close"></i><span class="simple-viewer__close__text">Close</span>';
        this.$viewer.appendChild(this.$closeLink);

        document.body.appendChild(this.$viewer);
    }

    show(imageUrl, data) {
        this.$image.src = this.BLANK_IMAGE;
        this.$image.src = imageUrl;
        this.$image.alt = data.title || '';
        this.$viewer.classList.remove('hidden');
        document.body.classList.add('noscroll');
        this.$pageContent.setAttribute('aria-hidden', 'true');
        this.$closeLink.focus();
    }

    hide() {
        this.$viewer.classList.add('hidden');
        document.body.classList.remove('noscroll');
        this.$pageContent.setAttribute('aria-hidden', 'false');
        this.$image.src = this.BLANK_IMAGE;
        this.$image.alt = '';
        if (this.$currentImageLink) {
            this.$currentImageLink.focus();
        }
    }
}
