$(function () {
    let $popups = $('.popup');
    let $popup = $('.login-popup');

    let $login = $popup.find('.login');
    let $password = $popup.find('.password');
    let $eye = $popup.find('.password-eye-icon');
    let $remember = $popup.find('.remember .cmn-toggle');
    let $message = $popup.find('.message');
    let $reauthenticate = $popup.find('#reauthenticate');
    let $submit = $popup.find('.submit');
    // let $form = $popup.find('form');
    let $showPasswordLink = $('.login-form .js-show-password');
    // let $redirectUri = $popup.find('input[name="redirectUri"]');
    let $showLogin = $('.show-login, .profile-links__user-list__holder a[href^="/action/showLogin"]');
    let $loginLinks = $('[data-db-target-for="loginmenu"]');
    let $loginLinksAll = $showLogin.add($loginLinks);
    let isMobile = false;
    let vPort = 'screen-sm';

    let items = $popup.find('a, button, input');
    let lastItem,
        revers = false,
        tabKey = 9,
        shift = 16,
        spaceKey = 32,
        $close = $popup.find('.close');

    function tabEvent(e) {
        $close.off('focusout');
        if ($(e.target).is(lastItem)) {
            e.preventDefault();
            $close.focus();
        }
    }

    function tabRevers() {
        $close.off('focusout');
        lastItem.off('focusout');
        $close.on('focusout', function (e) {
            lastItem.focus();
        });
    }

    items.each(function (index) {
        if (index === items.length - 1) {
            lastItem = $(this);
        }
    });

    if ($(window).width() < UX.grid.screenSm) {
        isMobile = true;
    }

    $(document).on(vPort + '-on', function () {
        // Waiting for custom event that will be triggered by controller.js to activate responsive effects
        isMobile = true;
    });

    $(document).on(vPort + '-off', function () {
        // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
        isMobile = false;
    });

    $popup.on('keydown', function (e) {
        if (e.keyCode === shift) {
            revers = true;
        }

        if ((e.keyCode || e.which) === tabKey) {
            if (!revers) {
                tabEvent(e);
            } else {
                tabRevers();
            }
        }

        if ((e.keyCode || e.which) === spaceKey && $(e.target).is($close)) {
            e.preventDefault();
        }
    });

    $close.on('keyup', function (e) {
        if ((e.keyCode || e.which) === spaceKey) {
            $close.trigger('click');
        }
    });

    $showLogin.click(function (event) {
        if ($popup && $popup.length > 0) {
            $remember.prop('checked', true);
            $popups.addClass('hidden');
            $popup.removeClass('hidden');
            $('body').addClass('noscroll');
            event.preventDefault();
            setTimeout(function () {
                if ($reauthenticate.text() !== '') {
                    $reauthenticate.focus();
                } else if ($message.text() !== '') {
                    $message.focus();
                } else {
                    $close.focus();
                }
            }, 250);
        }
    });

    $loginLinksAll.on('keyup', function (e) {
        if ((e.keyCode || e.which) === spaceKey) {
            $(this).trigger('click');
        }
    });

    $loginLinksAll.on('keydown', function (e) {
        if ((e.keyCode || e.which) === spaceKey) {
            e.preventDefault();
        }
    });

    $popup.on('click', function (e) {
        if (($(e.target).is($popup) && !isMobile) || $(e.target).is($close) || $(e.target).parent().is($close)) {
            e.preventDefault();
            $('body').removeClass('noscroll');
            $popup.addClass('hidden');
            $eye.addClass('hidden icon-eye').removeClass('icon-eye-blocked');
            $submit.prop('disabled', true);
            $remember.prop('checked', false);
            $login.val('');
            $password.val('');
            $message.html('');
            if ($loginLinks.length) {
                $loginLinks.focus();
            } else {
                $showLogin.focus();
            }
        }
    });

    $popup.on('keyup', function (e) {
        if (e.keyCode === 27) {
            // Escape is pressed
            $popup.find('.close').trigger('click');
        }
        if (e.keyCode === shift) {
            revers = false;
        }
    });

    $showPasswordLink.on('click', function (e) {
        e.preventDefault();
        let $passwordEl = $(this).prev('.password');

        if ($passwordEl.attr('type') === 'password') {
            $passwordEl.attr('type', 'text');
            $(this).text('Hide');
        } else {
            $passwordEl.attr('type', 'password');
            $(this).text('Show');
        }
    });

    if ($popup.hasClass('js--open')) {
        $showLogin.trigger('click');
    }

    // $form.submit(function (event) {
    //     event.preventDefault();
    //     if (!$login.val() || !$password.val()) {
    //         return;
    //     }
    //     var url = $form.attr('action');
    //     var data = $form.serializeArray();

    //     /*format variable indicates the type of response that the server will give. If not indicated, then the server
    //     give a regular response.*/
    //     data.push({ name: 'ajaxRequest', value: 'true' });

    //     $.ajax({ method: 'post', url: url, data: data, dataType: 'json', xhrFields: { withCredentials: true } }).then(function (data) {
    //         if (data.success) {
    //             window.location = $redirectUri.val();
    //         } else {
    //             $message.html(data.errorMessage);
    //         }
    //     }).fail(function () {
    //         $message.html('Unknown error');
    //     });
    // });
});
