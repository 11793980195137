export default class CollectionAlerts {
    selectors = {
        collectionAlertsLink: '.collection-alerts-link',
        collectionAlertsModal: '.collection-alerts',
        collectionAlertsModalClose: '.collection-alerts .close',
        collectionAlertsModalContent: '.collection-alerts .search-modal__content',
        collectionAlertsList: '.collection-alerts__list',
        collectionAlertsError: '.error',
        collectionAlertsForm: 'form',
        collectionAlertsCheckboxes: 'input[type="checkbox"]:checked',
        lastFocusable: 'button, input, [href], [tabindex]:not([tabindex="-1"])',
    };

    $elements = {};

    SPACE_KEY = 32;
    TAB_KEY = 9;
    SHIFT_KEY = 16;
    reversTab = false;

    dataUrl = '/widgets/ux3/ux3-lancetCollection/alert';

    // dataUrl = './templates/data/collection-alerts-ajax.json'; // for testing

    constructor() {
        if (document.querySelector(this.selectors.collectionAlertsLink)) {
            this.init();
        }
    }

    init() {
        this.getElements();
        this.addListeners();
    }

    getElements() {
        this.$elements.collectionAlertsLink = document.querySelector(this.selectors.collectionAlertsLink);
        this.$elements.collectionAlertsModal = document.querySelector(this.selectors.collectionAlertsModal);
        this.$elements.collectionAlertsModalClose = document.querySelector(this.selectors.collectionAlertsModalClose);
        this.$elements.collectionAlertsList = document.querySelector(this.selectors.collectionAlertsList);
        this.$elements.collectionAlertsError = this.$elements.collectionAlertsModal.querySelector(
            this.selectors.collectionAlertsError
        );
        this.$elements.collectionAlertsForm = this.$elements.collectionAlertsModal.querySelector(
            this.selectors.collectionAlertsForm
        );
        this.$elements.lastFocusable = Array.prototype.slice
            .call(this.$elements.collectionAlertsModal.querySelectorAll(this.selectors.lastFocusable))
            .pop();
    }

    addListeners() {
        this.$elements.collectionAlertsLink.addEventListener('click', e => {
            e.preventDefault();
            // this.fetchData();
            this.$elements.collectionAlertsError.classList.add('hidden');
            this.openModal();
        });

        this.$elements.collectionAlertsModalClose.addEventListener('click', e => {
            e.preventDefault();
            this.closeModal();
        });

        this.$elements.collectionAlertsModalClose.addEventListener('keypress', e => {
            var keycode = e.keyCode ? e.keyCode : e.which;
            if (keycode === this.SPACE_KEY || keycode === 'SPACE_KEY') {
                e.preventDefault();
                this.$elements.collectionAlertsModalClose.click();
            }
        });

        document.addEventListener('click', e => {
            if (
                !e.target.closest(this.selectors.collectionAlertsModalContent) &&
                !e.target.matches(this.selectors.collectionAlertsLink) &&
                !this.$elements.collectionAlertsModal.classList.contains('hidden')
            ) {
                this.closeModal();
            }
        });

        this.$elements.collectionAlertsForm.addEventListener('submit', e => {
            let selectedCollections = Array.prototype.slice.call(
                this.$elements.collectionAlertsModal.querySelectorAll(this.selectors.collectionAlertsCheckboxes)
            );
            if (selectedCollections.length === 0) {
                e.preventDefault();
                this.$elements.collectionAlertsError.classList.remove('hidden');
            }
        });

        this.$elements.collectionAlertsModal.addEventListener('keyup', e => {
            if (e.keyCode === this.SHIFT_KEY) {
                this.reversTab = false;
            }
        });

        this.$elements.collectionAlertsModal.addEventListener('keydown', e => {
            if (e.keyCode === this.SHIFT_KEY) {
                this.reversTab = true;
            }
        });

        this.$elements.collectionAlertsModalClose.addEventListener('keydown', e => {
            var keycode = e.keyCode ? e.keyCode : e.which;

            if (keycode === this.TAB_KEY && this.reversTab) {
                e.preventDefault();
                this.$elements.lastFocusable.focus();
            }
        });

        this.$elements.lastFocusable.addEventListener('keydown', e => {
            var keycode = e.keyCode ? e.keyCode : e.which;

            if (keycode === this.TAB_KEY && !this.reversTab) {
                e.preventDefault();
                this.$elements.collectionAlertsModalClose.focus();
            }
        });
    }

    openModal() {
        document.body.classList.add('noscroll');
        this.$elements.collectionAlertsModal.classList.remove('hidden');
        this.$elements.collectionAlertsModalClose.focus();
    }

    closeModal() {
        document.body.classList.remove('noscroll');
        this.$elements.collectionAlertsModal.classList.add('hidden');
        this.$elements.collectionAlertsLink.focus();
    }

    fetchData() {
        fetch(this.dataUrl)
            .then(response => response.json())
            .then(data => this.renderCollections(data))
            .catch(error => {
                console.log('Error:', error); // eslint-disable-line no-console
            });
    }

    renderCollections(data) {
        let halfIndex = Math.floor(data.collections.length / 2);
        let firstColumnItems = data.collections.slice(0, halfIndex);
        let secondColumnItems = data.collections.slice(halfIndex);

        let $firstColumn = this.columnTemplate();
        let $secondColumn = this.columnTemplate();

        firstColumnItems.forEach((item, index) => {
            $firstColumn.append(this.collectionTemplate(item));
        });

        secondColumnItems.forEach((item, index) => {
            $secondColumn.append(this.collectionTemplate(item));
        });

        this.$elements.collectionAlertsList.innerHTML = '';
        this.$elements.collectionAlertsList.append($firstColumn, $secondColumn);
    }

    collectionTemplate(item) {
        let $itemElement = document.createElement('div');
        $itemElement.classList.add('collection-alerts__list__item');

        $itemElement.innerHTML = `<input type="checkbox" name="collectionCode" id="collection-${
            item.collection_code
        }" value="${item.collection_code}" ${item.selected ? 'checked' : ''}><label for="collection-${
            item.collection_code
        }">${item.label}</label>`;

        return $itemElement;
    }

    columnTemplate() {
        let $columnElement = document.createElement('div');
        $columnElement.classList.add('collection-alerts__list__column');

        return $columnElement;
    }
}
