// @todo: this need to be refactored after updating UX.enquireIt to accept pure js on/off events (LIT-472784).
(function () {
    const $doText = $('.do-text');
    if ($doText.length) {
        const doTruncate = () => {
            $doText.truncate({
                lines: 2,
            });
        };
        $(document).on('smartResize', doTruncate);
        doTruncate();
    }
})();
