import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    Injectable,
    UIComponent,
} from '@atypon/ui-utils';

import {VPortListener} from 'ui-core/plugins/enquireJs/js/helper';

interface Screen {
    id: number,
    image: string,
    name: string,
    description: string,
}

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    hidden = 'hidden';
    divided = 'js--divided';
    selected = 'js--selected';
    noScroll = 'js--no-scroll';
    disabled = 'js--disabled';
}

@Injectable()
class Flags {
    canvasMaxHeight = 768;
    spinner = '<div class="threeDModal__spinner" aria-label="loading 3D modal" role="alert"><span></span></div>';
    screenId = 0;
    screenImage = '';
    screenName = '';
    screenDescription = '';
    currentScreenId = 0;
    somethingWentWrong = '<div class="three-d-error error" role="alert">Something went wrong, Please try again later!</div>';
}

class Selectors extends ComponentSelectors {
    modal: string = '.threeDModal';
    viewerInfoBtn: string = '.viewer-info-btn';
    viewerOptionsList: string = '.viewer-options';
    optionSlider: string = '.options-slider';
    currentOptionSlider: string = '.options-slider .current';
    optionsSliderButton: string = '.options-slider__btn';
    current: string = '.current';
    numOfItems: string = '.numOfItems';
    viewerOptionButton: string = '.viewer-options__btn';
    viewerLabelButtons: string = '.viewer-button';
    viewerLabelBtn: string = '.viewer-label-btn';
    viewerRefreshBtn: string = '.viewer-refresh-btn';
    elsCanvasContainer: string = 'els-main-canvas';
    modalSvgContainer: string = 'svg-container-els-main-canvas';
    modalLabelContainer: string = 'label-container-els-main-canvas';
    infoModalBox: string = '.info-modal__dialog';
    focusableItem: string = '[data-focusable-item]';
    infoFocusFlag: string = '[data-info-focus-flag]';
    modalFocusFlag: string = '[data-modal-focus-flag]';
    modalSpinner: string = '.threeDModal__spinner';
    threeDItem: string = '.three-d-item, .three-d-icon';
    threeDItemSelected: string = '.three-d-item.js--selected, .three-d-icon.js--selected';
    errorSection: string = '.three-d-error';
    imagesSection = new ComponentSelector('.three-d-images-section', 'document');
    modalEl = new ComponentSelector('.threeDModal', 'document');
    modalHeader = new ComponentSelector('.threeDModal__viewer-header', 'document');
    modalHeaderTitle = new ComponentSelector('.header-title', 'document');
    modalCloseBtn = new ComponentSelector('.close-viewer--btn', 'document');
    buttonListWrapper = new ComponentSelector('.button-list-wrapper', 'document');
    modalBody = new ComponentSelector('.threeDModal__viewer-body', 'document');
    modalBodyInner = new ComponentSelector('.threeDModal__viewer-body-inner', 'document');
    twoDImageSection = new ComponentSelector('.twoDImageSection', 'document');
    threeDImageSection = new ComponentSelector('.threeDImageSection', 'document');
    modalFooter = new ComponentSelector('.threeDModal__viewer-footer', 'document');
    modalFooterContent = new ComponentSelector('.threeDModal__viewer-footer-content', 'document');
    infoModalEl = new ComponentSelector('.info-modal', 'document');
    infoModalOverlayEl = new ComponentSelector('.info-modal__overlay', 'document');
    infoModalCloseBtn = new ComponentSelector('.info-modal__close', 'document');
    infoModalBody = new ComponentSelector('.dialog-body', 'document');
    modelViewerEl = new ComponentSelector('.model-viewer-3d4medical', 'document');
}

class Elements extends ComponentElements<Selectors> {
    imagesSection: HTMLElement;
    modalEl: HTMLElement;
    modalHeader: HTMLElement;
    modalHeaderTitle: HTMLElement;
    modalCloseBtn: HTMLElement;
    buttonListWrapper: HTMLElement;
    modalBody: HTMLElement;
    modalBodyInner: HTMLElement;
    twoDImageSection: HTMLElement;
    threeDImageSection: HTMLElement;
    modalFooter: HTMLElement;
    modalFooterContent: HTMLElement;
    infoModalEl: HTMLElement;
    infoModalOverlayEl: HTMLElement;
    infoModalCloseBtn: HTMLElement;
    infoModalBody: HTMLElement;
    modelViewerEl: HTMLElement;
}

interface ThreeDModal extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class ThreeDModal implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    @Inject()
    protected flags: Flags;

    @VPortListener('sm')
    isMobile: boolean = false;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
        this.additionalInit();
    }

    protected isMobileOn(): void {
        this.isMobile = true;
    }

    protected isMobileOff(): void {
        this.isMobile = false;
    }

    protected isMobileDevice(): boolean {
        return /iPhone|Android/i.test(navigator.userAgent.toLowerCase());
    }

    additionalInit(): void {
    }

    addEventListeners(): void {
        const threeDItems = this.domUtils.getElements(this.selectors.threeDItem, this.wrapper);
        threeDItems.forEach((item: HTMLElement) => {
            this.domUtils.addEventListener(item, 'click', this.handleImageClick.bind(this))
        });
        this.domUtils.addEventListener(document, 'keydown', this.handleCloseModal.bind(this));
        this.domUtils.addEventListener(this.elements.modalCloseBtn, 'click', this.handleCloseModal.bind(this));
        this.domUtils.addEventListener(this.elements.infoModalCloseBtn, 'click', this.handleCloseInfoModal.bind(this));
        this.domUtils.addEventListener(this.elements.infoModalOverlayEl, 'click', this.handleInfoModalOverlayClick.bind(this));
        this.domUtils.addEventListener(this.elements.infoModalCloseBtn, 'focusout', this.infoModalFocusCycle.bind(this));
        this.domUtils.addEventListener(this.elements.modelViewerEl, 'screenLoadComplete', this.screenLoadComplete.bind(this));
        this.domUtils.addEventListener(this.elements.modelViewerEl, 'screenLoadError', this.screenLoadError.bind(this));
        this.domUtils.addEventListener(document, 'keydown', this.handleCloseInfoModal.bind(this));
        this.domUtils.addEventListener(document, 'click', this.handleInfoButton.bind(this));
        this.domUtils.addEventListener(document, 'click', this.handleViewerOptionsTypes.bind(this));
        this.domUtils.addEventListener(document, 'click', this.handleOptionsSlider.bind(this));
        this.domUtils.addEventListener(document, 'click', this.handleRefresh.bind(this));
        this.domUtils.addEventListener(document, 'click', this.handleLabel.bind(this));
        this.domUtils.addEventListener(document, 'keydown', this.handleModalFocusCycle.bind(this));
        window.addEventListener('resize', this.handleScreenResize.bind(this));
        window.addEventListener('orientationchange', this.handleOrientationChange.bind(this));
    }

    protected get a3D4MApplication(): any {
        return (window as any).a3D4MApplication;
    }

    protected get pageDataTracker(): any {
        return (window as any).pageDataTracker;
    }

    protected stringEncoder(string: string): string {
        return string.replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\s/g, "%20");
    }

    protected handleModalFocusCycle(e: any): void {
        const {target, key, shiftKey} = e;
        if (target.hasAttribute('data-focusable-item') && key === 'Tab') {
            e.preventDefault();
            const focusableList = this.domUtils.getElements(this.selectors.focusableItem)
                .filter(el => el.offsetParent);
            const index = focusableList.indexOf(target);
            let targetIndex = shiftKey
                ? (index - 1 < 0 ? focusableList.length - 1 : index - 1)
                : (index + 1 > focusableList.length - 1 ? 0 : index + 1);
            focusableList[targetIndex].focus();
        }
    }

    protected handleScreenResize(): void {
        const currentView = this.elements.modalBody.dataset.selected || '3d';
        this.getCanvasSize(currentView);
    }

    protected handleOrientationChange(): void {
        const currentView = this.elements.modalBody.dataset.selected || '3d';
        setTimeout(() => {
            const availableHeight: number = window.innerHeight - (this.elements.modalHeader.offsetHeight + this.elements.modalFooter.offsetHeight);
            if (window.innerWidth < this.flags.canvasMaxHeight) {
                this.a3D4MApplication.getApp().canvas.setSize(window.innerWidth, availableHeight);
            } else {
                const windowWidth = currentView === '2d-3d'
                    ? Math.round(window.innerWidth) / 2
                    : Math.round(window.innerWidth);
                const canvasHeight = availableHeight >= this.flags.canvasMaxHeight ? this.flags.canvasMaxHeight : availableHeight;
                const sizeBase = canvasHeight / 3;
                const size = Math.round(sizeBase) * 4 <= windowWidth ? Math.round(sizeBase) * 4 : windowWidth;
                this.a3D4MApplication.getApp().canvas.setSize(size);
            }
        }, 50);
    }

    protected showModalLoading(): void {
        this.elements.modalEl.classList.remove(this.classList.hidden);
        document.body.classList.add(this.classList.noScroll);
        this.toggleSpinner('show');
        this.elements.modalFooterContent.innerHTML = '';
        this.elements.buttonListWrapper.innerHTML = '';
        this.elements.modalHeaderTitle.innerHTML = '';
        this.elements.modalBodyInner.classList.add(this.classList.disabled);
    }

    protected screenLoadComplete(object: any): void {
        if (this.elements.modalBody.dataset.canvasLoaded) return;
        const {detail} = object;
        const item = this.domUtils.getElement(this.selectors.threeDItemSelected);
        const {alt, altTitle, twoDImageSrc} = item.dataset;
        const {id, description, image, name} = detail;
        this.toggleSpinner('hide');
        this.modalLoadingSuccess(item, id, twoDImageSrc, alt, altTitle, description, image, name);
    }

    protected screenLoadError(object: any): void {
        if (this.elements.modalBody.dataset.canvasLoaded) return;
        this.elements.modalEl.classList.add(this.classList.hidden);
        document.body.classList.remove(this.classList.noScroll);
        this.toggleSpinner('hide');
        this.elements.imagesSection.insertAdjacentHTML('beforeend', this.flags.somethingWentWrong);
    }

    protected handleImageClick(e: any) {
        const {currentTarget: item} = e;
        const {screenId} = item.dataset;
        const errorSection = this.domUtils.getElement(this.selectors.errorSection);
        errorSection?.remove();
        this.showModalLoading();
        item.classList.add(this.classList.selected);
        this.elements.modelViewerEl.setAttribute('screen_id', screenId);
    }

    protected modalLoadingSuccess(item: HTMLElement, id: number, twoDImageSrc: string, alt: string, altTitle: string, description: string, image: string, name: string): void {
        this.flags.screenName = altTitle || name;
        this.flags.screenId = id;
        this.flags.currentScreenId = id;
        this.flags.screenImage = image;
        this.flags.screenDescription = description;
        this.elements.modalBodyInner.classList.remove(this.classList.disabled);
        this.toggleSpinner('hide');
        this.handleWidgetClickAnalytics();
        this.showModal(item, this.flags.screenId, twoDImageSrc, this.flags.screenImage, alt, this.flags.screenName, this.flags.screenDescription);
    }

    protected showModal(item: HTMLElement, screenId: number, twoDImageSrc: string, threeDImageSrc: string, alt: string, altTitle: string, screenDescription: string): void {
        const altValue = alt ? alt : altTitle;
        this.elements.buttonListWrapper.innerHTML = this.viewerButtonsRender(screenId, !this.isMobileDevice(), altValue, screenDescription);
        this.elements.modalHeaderTitle.textContent = altTitle;
        this.elements.modalFooterContent.innerHTML = this.modalFooterContent(screenId, twoDImageSrc, threeDImageSrc, altValue, altTitle, screenDescription);
        const mainCanvas = this.domUtils.getElementById(this.selectors.elsCanvasContainer);
        if (!mainCanvas?.dataset.focusableItem) mainCanvas.setAttribute('data-focusable-item', '0');
        this.createModalBody('3d', threeDImageSrc, altValue);
        const focusableTarget = !this.isMobile
            ? this.domUtils.getElement('.viewer-label-btn', this.elements.modalEl)
            : this.elements.modalCloseBtn;
        this.toggleFocus(focusableTarget, item, 'open', 'data-modal-focus-flag');
        this.elements.modalBody.dataset.canvasLoaded = 'true';
    }

    protected infoModalFocusCycle(e: { target: HTMLElement }): void {
        e.target.focus();
    }

    protected async screenRefresh(id: number): Promise<void> {
        try {
            this.toggleSpinner('show');
            await this.a3D4MApplication.getApp().deactivateModel();
            await this.a3D4MApplication.getApp().activateModelWithScreen(id);
            this.getCanvasSize(this.elements.modalBody.dataset.selected);
            this.toggleSpinner('hide');
        } catch (error) {
            this.toggleSpinner('hide');
            console.error("Error during screen refresh:", error);
            throw error;
        }
    }

    protected handleRefresh(e: any): void {
        if (!this.domUtils.closest(this.selectors.viewerRefreshBtn, e.target)) return;
        const item = this.domUtils.closest(this.selectors.viewerRefreshBtn, e.target);
        const {screenId} = item.dataset;
        this.screenRefresh(parseInt(screenId));
    }

    protected handleLabel(e: any): void {
        if (!this.domUtils.closest(this.selectors.viewerLabelBtn, e.target)) return;
        const item = this.domUtils.closest(this.selectors.viewerLabelBtn, e.target);
        this.handleButtonClickAnalytics(item.dataset.buttonName);
        const elements = [this.domUtils.getElementById(this.selectors.modalSvgContainer), this.domUtils.getElementById(this.selectors.modalLabelContainer)];
        elements.map(el => {
            if (el.classList.contains(this.classList.disabled)) {
                el.classList.remove(this.classList.disabled);
            } else {
                el.classList.add(this.classList.disabled);
            }
        });
    }

    protected handleInfoModalOverlayClick(e: any): void {
        if (!this.domUtils.closest(this.selectors.infoModalBox, e.target)) this.toggleInfoModal(e.target, 'hide');
    }

    protected handleWidgetClickAnalytics(): void {
        try {
            if (typeof this.pageDataTracker !== 'undefined') {
                this.pageDataTracker.trackEvent('widgetClick', {
                    link: {'widgetName': '3d4medical'}
                });
                console.log(`widgetClick triggered successfully [ widgetName: 3d4medical ]`); // eslint-disable-line no-console
            }
        } catch (error) {
            console.error(error);
        }
    }

    protected handleButtonClickAnalytics(buttonName: string): void {
        try {
            if (typeof this.pageDataTracker !== 'undefined') {
                this.pageDataTracker.trackEvent('buttonClick', {
                    buttonType: {'jb:3d4med': `jb:${buttonName}`}
                });
                console.log(`buttonClick triggered successfully [ widgetName: 3d4medical ]`); // eslint-disable-line no-console
            }
        } catch (error) {
            console.error(error);
        }
    }

    protected getCanvasSize(view: string): void {
        const availableHeight: number = window.innerHeight - (this.elements.modalHeader.offsetHeight + this.elements.modalFooter.offsetHeight);
        if (window.innerWidth < this.flags.canvasMaxHeight) {
            this.a3D4MApplication.getApp().canvas.setSize(window.innerWidth, availableHeight);
        } else {
            const windowWidth = view === '2d-3d'
                ? window.innerWidth / 2
                : window.innerWidth;
            const canvasHeight = availableHeight >= this.flags.canvasMaxHeight ? this.flags.canvasMaxHeight : availableHeight;
            const sizeBase = canvasHeight / 3;
            const size = sizeBase * 4 <= windowWidth ? sizeBase * 4 : windowWidth;
            this.a3D4MApplication.getApp().canvas.setSize(size);
        }
    }

    protected modalFooterContent(screenId: number, twoDImageSrc: string, threeDImageSrc: string, altValue: string, altTitleValue: string, screenDescription: string): string {
        twoDImageSrc = this.stringEncoder(twoDImageSrc);
        threeDImageSrc = this.stringEncoder(threeDImageSrc);
        return `
            <ul class="rlist viewer-options">
                <li>
                    <a 
                        href="javascript:void(0)" 
                        class="viewer-options__btn" 
                        role="button" aria-label="2D view" 
                        data-view="2d" data-img-src="${twoDImageSrc}"${!this.isMobileDevice() && ' data-focusable-item="0"'} 
                        data-alt="${altValue}" 
                        data-alt-title="${screenDescription}"
                        data-button-name="2d-view"
                    >
                        <span class="viewer-options__btn-inner" style="background-image: url(${twoDImageSrc})"></span>
                    </a>
                </li>  
                <li>
                    <a 
                        href="javascript:void(0)" 
                        class="viewer-options__btn js--selected" 
                        role="button" 
                        aria-label="3D view" 
                        data-view="3d"${!this.isMobileDevice() && ' data-focusable-item="0"'} 
                        data-alt="${altValue}" data-alt-title="${screenDescription}"
                        data-button-name="3d-view"
                    >
                        <div class="view-name__wrapper">
                            <span class="viewer-options__btn-inner" style="background-image: url(${threeDImageSrc})"></span>
                        </div>
                        <span class="view-name">3D</span>
                    </a>
                </li>
                ${!this.isMobileDevice() ? this.renderBothTypes(twoDImageSrc, threeDImageSrc, altValue, screenDescription) : ''}
            </ul>
            ${this.isMobileDevice() ? this.optionSliderRender(twoDImageSrc, altValue, screenDescription) : ''}
            ${this.viewerButtonsRender(screenId, this.isMobileDevice(), altValue, screenDescription)}
        `;
    }

    protected hideModal(el: HTMLElement): void {
        if (!this.elements.infoModalEl.classList.contains(this.classList.hidden)) return;
        const modalFocusFlag = this.domUtils.getElement(this.selectors.modalFocusFlag);
        this.toggleFocus(el, modalFocusFlag, 'close', 'data-modal-focus-flag');
        this.a3D4MApplication.getApp().deactivateModel();
        this.elements.modalEl.classList.add(this.classList.hidden);
        document.body.classList.remove(this.classList.noScroll);
        this.elements.modalBody.removeAttribute('data-canvas-loaded');
    }

    protected renderBothTypes(twoDImage: string, threeDImage: string, altValue: string, screenDescription: string): string {
        return `
            <li>
                <a 
                    href="javascript:void(0)" class="viewer-options__btn" 
                    role="button" aria-label="2D/3D view" 
                    data-view="2d-3d" data-img-src="${twoDImage}"${!this.isMobileDevice() && ' data-focusable-item="0"'} 
                    data-alt="${altValue}" data-alt-title="${screenDescription}"
                    data-button-name="2d-3d-comparison"
                >   
                    <div class="view-name__wrapper">
                        <span class="viewer-options__btn-inner js--divided" style="background-image: url(${twoDImage})"></span>
                        <span class="viewer-options__btn-inner js--divided" style="background-image: url(${threeDImage})"></span>
                    </div>
                    <span class="view-name">2D|3D</span>
                </a>
            </li>
        `;
    }

    protected optionSliderRender(twoDImageSrc: string, altValue: string, screenDescription: string): string {
        return `
            <div class="options-slider" data-two-d-image-src="${twoDImageSrc}">
                <a 
                    href="javascript:void(0)" 
                    class="options-slider__btn options-slider__prev" 
                    role="button" aria-label="Previous option" 
                    data-target="prev" data-alt="${altValue}" 
                    data-alt-title="${screenDescription}"
                    data-focusable-item="0"
                >
                    <i class="icon-chevron-left" aria-hidden="true"></i>
                </a>
                <div class="options-slider__selected-option">
                    <span class="current">2</span>
                    <span>/</span>
                    <span class="numOfItems">2</span>
                </div>
                <a 
                    href="javascript:void(0)" 
                    class="options-slider__btn options-slider__next" 
                    role="button" aria-label="Next option" 
                    data-target="next" data-alt="${altValue}" 
                    data-alt-title="${screenDescription}"
                    data-focusable-item="0"
                >
                    <i class="icon-chevron-right" aria-hidden="true"></i>
                </a>
            </div>
        `;
    }

    protected handleOptionsSlider(e: any): void {
        if (!this.domUtils.closest(this.selectors.optionsSliderButton, e.target)) return;
        const item = this.domUtils.closest(this.selectors.optionsSliderButton, e.target);
        const parent = this.domUtils.closest(this.selectors.optionSlider, item);
        const {twoDImageSrc} = parent.dataset;
        const current = this.domUtils.getElement(this.selectors.current, parent);
        let currentValue = parseInt(current.textContent);
        const {target, altValue, altTitleValue} = item.dataset;
        const typesList = {
            1: '2d',
            2: '3d'
        }
        const numOfItems = parseInt(this.domUtils.getElement(this.selectors.numOfItems, parent).textContent);
        switch (target) {
            case 'prev':
                currentValue--;
                if (currentValue <= 0) currentValue = numOfItems;
                break;
            case 'next':
                currentValue++;
                if (currentValue > numOfItems) currentValue = 1;
                break;
        }
        current.textContent = currentValue.toString();
        const viewerOptionsList = this.domUtils.getElement(this.selectors.viewerOptionsList, document.body);
        const viewerOptionsButtons = this.domUtils.getElements(this.selectors.viewerOptionButton, viewerOptionsList);
        viewerOptionsButtons.find(btn => btn.classList.contains(this.classList.selected) && btn.classList.remove(this.classList.selected));
        viewerOptionsButtons[currentValue - 1].classList.add(this.classList.selected);
        this.createModalBody(typesList[currentValue], twoDImageSrc, altValue);
    }

    protected updateMobileSliderOption(view: string): void {
        if (this.isMobileDevice()) {
            const currentOptionSlider = this.domUtils.getElement(this.selectors.currentOptionSlider);
            if (currentOptionSlider)
                currentOptionSlider.textContent = view === '2d' ? '1' : '2';
        }
    }

    protected handleViewerOptionsTypes(e: any): void {
        if (!this.domUtils.closest(this.selectors.viewerOptionButton, e.target)) return;
        const item = this.domUtils.closest(this.selectors.viewerOptionButton, e.target);
        const {view, imgSrc, alt, buttonName} = item.dataset;
        if (this.elements.modalBody.dataset.selected === view) return;
        this.handleButtonClickAnalytics(buttonName);
        const parent = this.domUtils.closest(this.selectors.viewerOptionsList, item);
        const current = this.domUtils.getElement(`.${this.classList.selected}`, parent);
        const canvasLabelEl = [
            this.domUtils.getElementById(this.selectors.modalSvgContainer),
            this.domUtils.getElementById(this.selectors.modalLabelContainer)
        ];
        canvasLabelEl.map(el => el.classList.remove(this.classList.disabled));
        current.classList.remove(this.classList.selected);
        item.classList.add(this.classList.selected);
        this.updateMobileSliderOption(view);
        this.createModalBody(view, imgSrc, alt);
    }

    protected toggleViewerButtons(status: string): void {
        const buttons = [
            ...this.domUtils.getElements(this.selectors.viewerLabelBtn, document.body),
            ...this.domUtils.getElements(this.selectors.viewerRefreshBtn, document.body)
        ];
        switch (status) {
            case 'show':
                buttons.map(btn => this.domUtils.closest(this.selectors.viewerLabelButtons, btn)
                    .classList.remove(this.classList.hidden));
                break;
            case 'hide':
                buttons.map(btn => this.domUtils.closest(this.selectors.viewerLabelButtons, btn)
                    .classList.add(this.classList.hidden));
                break
        }
    }

    protected toggleInfoButtons(status: string, view: string = ''): void {
        const buttons = this.domUtils.getElements(this.selectors.viewerInfoBtn, document.body);
        switch (status) {
            case 'show':
                buttons.map(btn =>
                    this.domUtils.closest(this.selectors.viewerLabelButtons, btn)
                        .classList.remove(this.classList.hidden));
                break;
            case 'hide':
                buttons.map(btn =>
                    this.domUtils.closest(this.selectors.viewerLabelButtons, btn)
                        .classList.add(this.classList.hidden));
                break
        }
    }

    protected createModalBody(view: string, imgSrc: string, altValue: string): void {
        const infoButtons = this.domUtils.getElements(this.selectors.viewerInfoBtn);
        infoButtons.forEach(item => item.dataset.target = view);
        switch (view) {
            case '2d':
                this.elements.threeDImageSection.classList.add(this.classList.hidden);
                this.elements.twoDImageSection.innerHTML = `<img class="twoDImageSection__image" src="${imgSrc}" alt="${altValue}" data-focusable-item="0" tabindex="-1">`;
                this.elements.twoDImageSection.classList.remove(this.classList.hidden);
                this.elements.twoDImageSection.classList.remove(this.classList.divided);
                this.elements.threeDImageSection.classList.remove(this.classList.divided);
                this.toggleViewerButtons('hide');
                this.toggleInfoButtons('show', view);
                break;
            case '3d':
                this.elements.twoDImageSection.classList.add(this.classList.hidden);
                this.elements.threeDImageSection.classList.remove(this.classList.hidden);
                this.elements.threeDImageSection.classList.remove(this.classList.divided);
                this.elements.twoDImageSection.classList.remove(this.classList.divided);
                this.toggleViewerButtons('show');
                this.toggleInfoButtons('show', view);
                setTimeout(() => this.getCanvasSize(view), 250);
                break;
            case '2d-3d':
                this.elements.twoDImageSection.classList.add(this.classList.divided);
                this.elements.threeDImageSection.classList.add(this.classList.divided);
                this.elements.twoDImageSection.innerHTML = `<img class="twoDImageSection__image" src="${imgSrc}" alt="${altValue}" data-focusable-item="0" tabindex="-1">`;
                this.elements.twoDImageSection.classList.remove(this.classList.hidden);
                this.elements.threeDImageSection.classList.remove(this.classList.hidden);
                this.toggleViewerButtons('show');
                this.toggleInfoButtons('hide');
                setTimeout(() => this.getCanvasSize(view), 250);
                break;
        }
        this.elements.modalBody.dataset.selected = view;
    }

    protected handleInfoButton(e: any): void {
        if (!this.domUtils.closest(this.selectors.viewerInfoBtn, e.target)) return;
        const item = this.domUtils.closest(this.selectors.viewerInfoBtn, e.target);
        this.handleButtonClickAnalytics(item.dataset.buttonName);
        item.dataset.infoFocusFlag = 'true';
        this.toggleInfoModal(item, 'show');
    }

    protected toggleInfoModal(el: HTMLElement = null, status: string): void {
        const {target, twoDInfo, threeDInfo} = el.dataset;
        if (target === '2d-3d') return;
        const bodyContent = target === '2d' ? twoDInfo : threeDInfo;

        switch (status) {
            case 'show':
                this.elements.infoModalBody.innerHTML = bodyContent;
                this.elements.infoModalEl.classList.remove(this.classList.hidden);
                this.toggleFocus(this.elements.infoModalCloseBtn, el, 'open', 'data-info-focus-flag');
                break;
            case 'hide':
                this.elements.infoModalEl.classList.add(this.classList.hidden);
                const infoFocusFlag = this.domUtils.getElement(this.selectors.infoFocusFlag);
                this.toggleFocus(el, infoFocusFlag, 'close', 'data-info-focus-flag');
                break;
        }
    }

    protected handleCloseInfoModal(e: any): void {
        switch (e.type) {
            case 'click':
                this.toggleInfoModal(e.target, 'hide');
                break;
            case 'keydown':
                if (e.code !== 'Escape') return;
                if (!this.elements.infoModalEl.classList.contains(this.classList.hidden)) this.toggleInfoModal(e.target, 'hide');
        }
    }

    protected handleCloseModal(e: any): void {
        switch (e.type) {
            case 'click':
                this.hideModal(e.target);
                break;
            case 'keydown':
                if (e.code !== 'Escape') return;
                const modalSpinner = this.domUtils.getElement(this.selectors.modalSpinner);
                if (!this.elements.modalEl.classList.contains(this.classList.hidden) && !modalSpinner)
                    this.hideModal(e.target);
        }
    }

    protected viewerButtonsRender(screenId: number, isFocusableItem: boolean, altValue: string, screenDescription: string): string {
        const buttonsList = [
            {
                text: 'Labels',
                icon: 'icon-label',
                class: 'viewer-label-btn',
                buttonName: 'info-labels'
            },
            {
                text: 'Refresh',
                icon: 'icon-refresh',
                class: 'viewer-refresh-btn'
            },
            {
                text: 'Info',
                icon: 'icon-info-3',
                class: 'viewer-info-btn',
                buttonName: 'copyrightinfo'
            }
        ]

        let content = ''

        buttonsList.forEach(item => {
            content += `
                <li class="viewer-button">
                    <a 
                        href="javascript:void(0)" 
                        role="button" aria-label="${item.text}" 
                        class="${item.class}" 
                        data-screen-id="${screenId}"
                        ${isFocusableItem && ' data-focusable-item="0"'}
                        data-button-name="${item.buttonName}"
                        ${item.text === 'Info' && ` data-target="3d" data-two-d-info="${altValue}" data-three-d-info="${screenDescription}"`}
                        
                    >
                        <i class="${item.icon}" aria-hidden="true"></i>
                        <span>${item.text}</span>
                    </a>
                </li>
            `;
        });

        return `<ul class="rlist viewer-buttons">${content}</ul>`;
    }

    protected toggleFocus(el: HTMLElement, target: HTMLElement, status: string, attr: string = ''): void {
        setTimeout(() => {
            switch (status) {
                case 'open':
                    el.focus();
                    target.setAttribute(attr, 'true');
                    break;
                case 'close':
                    target.focus();
                    target.removeAttribute(attr);
                    target.classList.remove(this.classList.selected);
                    break;
            }
        }, 250);
    }

    toggleSpinner(status: string): void {
        switch (status) {
            case 'show':
                document.body.insertAdjacentHTML('beforeend', this.flags.spinner);
                break;
            case 'hide':
                const spinnerEl = this.domUtils.getElement(this.selectors.modalSpinner);
                spinnerEl?.remove();
                break;
        }
    }
}

export default ThreeDModal;

export {
    Elements as ThreeDModalElements,
    Selectors as ThreeDModalSelectors,
    ClassList as ThreeDModalClassList,
    Texts as ThreeDModalTexts,
}