(function () {
    const searchInCollection = {
        $thisWidget: $('.search-in'),
        init: function () {
            searchInCollection.controller();
            searchInCollection.runAutoComplete();
        },
        controller: function () {},
        runAutoComplete: function () {
            let $searchInput = searchInCollection.$thisWidget.find('.search-in_input');
            let $searchbar = searchInCollection.$thisWidget.find('.search-in_left');
            let $numOfGroupTitles = $searchInput.data('group-titles-conf');
            let $numOfPublicationItems = $searchInput.data('publication-items-conf');
            let $confNumOfPublicationTitles = $searchInput.data('publication-titles-conf');
            let $confNumOfContributors = $searchInput.data('contributors-conf');
            let $confNumOfTopics = $searchInput.data('topics-conf');
            let $confNumOfHistoryItems = $searchInput.data('history-items-conf');
            let $autoCompeteSearchUrl = `/action/doSuggest?target=auto-complete&hs=${$confNumOfHistoryItems}&pts=${$confNumOfPublicationTitles}&ts=${$confNumOfTopics}&cs=${$confNumOfContributors}&fl=PubID&ptgs=${$numOfGroupTitles}&ptfs=${$numOfPublicationItems}`;

            if ($searchInput.hasClass('search-in_input-autocomplete')) {
                $searchInput.autocomplete({
                    appendTo: $searchbar,
                    source: function (request, response) {
                        $.ajax({
                            url: $autoCompeteSearchUrl,
                            dataType: 'json',
                            data: {
                                query: request.term,
                            },
                            success: function (data) {
                                let uniqueItemsData = {};
                                for (let i in data) {
                                    let itemLabel = data[i]['label'];
                                    itemLabel = itemLabel.toLowerCase();
                                    uniqueItemsData[itemLabel] = data[i];
                                }
                                response(
                                    $.map(uniqueItemsData, function (el) {
                                        return {
                                            label: el.label,
                                            value: el.label,
                                            id: el.value,
                                            type: el.param,
                                            url: el.url ? el.url : el.value,
                                        };
                                    })
                                );
                            },
                        });
                    },
                });
                $searchInput.on('autocompleteselect', function (event, ui) {
                    if (ui.item.type === 'DOI') {
                        $.ajax({
                            url: `/pb/article?articleId=${ui.item.id}`,
                            success: function (data) {
                                let newData = typeof data === 'string' ? JSON.parse(data) : data;
                                window.location.replace(newData.link);
                            },
                        });
                    } else {
                        window.location.replace(ui.item.url);
                    }
                });
            }
        },
    };
    UX.searchInCollection = searchInCollection; // add to global namespace
})();
