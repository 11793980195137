(function () {
    let $body = $('body');
    UX.searchFieldsCtrl.maxNumOfSearchTerms = 7;
    UX.searchFieldsCtrl.searchFieldsContainer = $('[data-numofdefaultsearchterms]');
    UX.searchFieldsCtrl.numOfDefaultSearchTerms = parseInt(
        UX.searchFieldsCtrl.searchFieldsContainer.data('numofdefaultsearchterms'),
        10
    );
    UX.searchFieldsCtrl.clonedInside = '.searchIn--field__to-append';
    UX.searchFieldsCtrl.addCtrlField = $('.advanced-search .add-ctrl-field');

    UX.searchFieldsCtrl.closeJournalSelection = (menu, toggle = null, focus = false) => {
        menu.style.display = 'none';
        menu.ariaHidden = 'true';
        if (focus) toggle.focus();
    };

    UX.searchFieldsCtrl.journalSelectionAdditionalControl = () => {
        const journalSelection = document.querySelector('.journalSelection');
        if (journalSelection) {
            const dropdownMenu = journalSelection.querySelector('.dropdown__menu');
            const dropdownToggle = journalSelection.querySelector('.dropdown__toggle');

            journalSelection.addEventListener('keydown', e => {
                if (e.key !== 'Escape') return;
                if (dropdownMenu.style.display === 'block' && dropdownMenu.ariaHidden === 'false') {
                    UX.searchFieldsCtrl.closeJournalSelection(dropdownMenu, dropdownToggle, true);
                }
            });

            journalSelection.addEventListener('focusout', e => {
                if (
                    dropdownMenu.style.display === 'block' &&
                    dropdownMenu.ariaHidden === 'false' &&
                    e.relatedTarget &&
                    !e.relatedTarget.closest('.published-in__wrapper')
                ) {
                    UX.searchFieldsCtrl.closeJournalSelection(dropdownMenu, dropdownToggle, true);
                }
            });

            document.body.addEventListener('click', e => {
                if (
                    !e.target.closest('.published-in__wrapper') &&
                    dropdownMenu.style.display === 'block' &&
                    dropdownMenu.ariaHidden === 'false'
                ) {
                    UX.searchFieldsCtrl.closeJournalSelection(dropdownMenu);
                }
            });
        }
    };

    UX.searchFieldsCtrl.control = function () {
        $body.on('click', '.advanced-search--searchIn [class*="-ctrl-field"]', function (e) {
            e.preventDefault();
            e.stopPropagation();
            UX.searchFieldsCtrl.$wrapper = $(this).closest('.searchIn--field').find('> .searchIn--field__to-clone');

            if ($(this).is('.add-ctrl-field')) {
                UX.searchFieldsCtrl.$terms = $(this)
                    .closest('.advanced-search--searchIn')
                    .find('.searchIn--field__to-append')[0].childNodes;

                if (UX.searchFieldsCtrl.$terms.length < 6) {
                    UX.searchFieldsCtrl.on.clone($(this));
                }
            } else {
                UX.searchFieldsCtrl.on.remove($(this));
            }
        });

        $body.on('keyup input', '.searchIn--field input', function () {
            UX.searchFieldsCtrl.$form = $(this).closest('form');
            UX.searchFieldsCtrl.check.submitButton();
        });

        UX.searchFieldsCtrl.click.toggleSearchBlock();

        $(document).on(UX.searchFieldsCtrl.vPort + '-on', function () {
            // Waiting for custom event that will be triggered by controller.js to activate responsive effects
            UX.searchFieldsCtrl.isMobile = true;
        });

        $(document).on(UX.searchFieldsCtrl.vPort + '-off', function () {
            // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
            UX.searchFieldsCtrl.isMobile = false;
        });

        $('.advanced-search--advancedFilters .radio--primary input[type="radio"]').change(function () {
            $('.advanced-search--advancedFilters .radio--primary input[type="radio"]').prop('checked', false);
            $(this).prop('checked', true);
        });

        $body.on('change', '#earlyCite', function () {
            // this code added to solve LIT-190918
            let $earlyCite_hidden = $('#earlyCite_hidden');

            if ($earlyCite_hidden.val() === '') {
                $earlyCite_hidden.val('off');
                $earlyCite_hidden.prop('disabled', false);
            } else {
                $earlyCite_hidden.val('');
                $earlyCite_hidden.prop('disabled', true);
            }
        });

        $body.on('change', '[type=radio][name=Ppub]', function () {
            $('#publicationDate .time-frame').find('.inline').prop('disabled', true);
            $(this).closest('.time-frame').find('.inline').prop('disabled', false);
        });

        UX.searchFieldsCtrl.journalSelectionAdditionalControl();
    };

    UX.searchFieldsCtrl.addtionalControls = function () {
        $('.input-group.staticRange .label-txt').append('<span class="sr-only">Static range</span>');

        $('.advanced-search--advancedFilters .accordion__control').attr('role', 'button');

        $('.searchIn--field .dropdown__toggle').on('click', function (e) {
            e.stopPropagation();
            let $dropdownMenu = $('.searchIn--field .dropdown__menu');
            $dropdownMenu.toggle();
            $dropdownMenu.attr('aria-hidden', $dropdownMenu.attr('aria-hidden') === 'true' ? false : true);
        });

        $body.on('keyup', '.searchIn--field .dropdown__toggle', function (e) {
            const ENTER_KEY = 13;
            if ((e.keyCode || e.which) === ENTER_KEY) {
                let $dropdownMenu = $('.searchIn--field .dropdown__menu');
                $dropdownMenu.toggle();
                $dropdownMenu.attr('aria-hidden', $dropdownMenu.attr('aria-hidden') === 'true' ? false : true);
            }
        });

        $body.on('click', '.published-in__item input', function () {
            if ($(this).attr('name') === 'alljournals') {
                if ($(this).prop('checked')) {
                    $('.published-in__item input[name="SeriesKey"]:not(:checked)').each(function () {
                        let $this = $(this);
                        let $innerText = $this[0].dataset.title;
                        let $thisVal = $this.val();

                        $this.prop('checked', true);
                        $('.published-in__selected').append(
                            '<div class="published-in__selected--item" data-value=' +
                                $thisVal +
                                '><a href="#" class="remove-item" aria-label="Remove ' +
                                $innerText +
                                ' journal from selection">X</a> <div>' +
                                $innerText +
                                '</div></div>'
                        );
                    });
                } else {
                    $('.published-in__item input[name="SeriesKey"]:checked').each(function () {
                        let $this = $(this);
                        let $thisVal = $this.val();

                        $this.prop('checked', false);
                        $('.published-in__selected')
                            .find('[data-value=' + $thisVal + ']')
                            .remove();
                    });
                }
            } else {
                let $innerText = $(this)[0].dataset.title,
                    $thisVal = $(this).val();
                if ($(this).closest('.published-in__item input')[0].checked === true) {
                    $('.published-in__selected').append(
                        '<div class="published-in__selected--item" data-value=' +
                            $thisVal +
                            '><a href="#" class="remove-item" aria-label="Remove ' +
                            $innerText +
                            ' journal from selection">X</a> <div>' +
                            $innerText +
                            '</div></div>'
                    );
                } else {
                    $('.published-in__selected')
                        .find('[data-value=' + $thisVal + ']')
                        .remove();
                    $('.published-in__item input[name="alljournals"]').prop('checked', false);
                }
            }
        });

        $body.on('click', '.close-wrapper', function (e) {
            e.preventDefault();
            let $dropdownMenu = $('.searchIn--field .dropdown__menu');
            $dropdownMenu.toggle();
            $dropdownMenu.attr('aria-hidden', true);
        });

        $body.on('click', '.published-in__selected--item .remove-item', function (e) {
            e.preventDefault();
            let journalCode = $(this).closest('.published-in__selected--item').data('value');
            $(this).closest('div').remove();
            $('.published-in__item input[name="SeriesKey"][value="' + journalCode + '"]').prop('checked', false);
            $('.published-in__item input[name="alljournals"]').prop('checked', false);
        });

        $body.on('submit', 'form.advanced-search', function () {
            $('.published-in__item input[name="alljournals"]').prop('disabled', true);
        });

        UX.searchFieldsCtrl.preventSubmissionHandler();
    };

    UX.searchFieldsCtrl.preventSubmissionHandler = () => {
        const formInputs = document.querySelectorAll('form.advanced-search input:not([type="submit"])');
        formInputs.forEach(input => input.addEventListener('keydown', e => e.key === 'Enter' && e.preventDefault()));
    };

    UX.searchFieldsCtrl.on.clone = function ($this) {
        let $newClone = UX.searchFieldsCtrl.$wrapper
            .find('.searchIn--field__row')
            .clone(true, true)
            .find('input')
            .val('')
            .end();
        $newClone.appendTo(UX.searchFieldsCtrl.clonedInside);
        $newClone.find('.remove-ctrl-field').removeClass('hidden');
        $newClone.find('.search-area select').prop('selectedIndex', 0);
        UX.searchFieldsCtrl.check.lastClone($newClone);
    };

    UX.searchFieldsCtrl.on.remove = function ($this) {
        UX.searchFieldsCtrl.$form = $this.closest('form');
        let $elementToRemove;
        if ($this.closest('.searchIn--field__to-clone').length) {
            $elementToRemove = $this.closest('.searchIn--field__to-clone');
        } else {
            $elementToRemove = $this.closest('.searchIn--field__row');
        }
        const prevInputElement = $elementToRemove.prev('.searchIn--field__row').find('[type="text"]');
        if (prevInputElement.length) {
            prevInputElement.focus();
        } else {
            $elementToRemove
                .closest('.searchIn--field__to-append')
                .prev('.searchIn--field__to-clone')
                .find('[type="text"]')
                .focus();
        }

        $elementToRemove.remove();
        UX.searchFieldsCtrl.check.submitButton();

        let $lastClone = $('.searchIn--field__to-append').children('.searchIn--field__to-clone:last-child');
        UX.searchFieldsCtrl.check.lastClone($lastClone);
    };

    UX.searchFieldsCtrl.check.lastClone = function ($this) {
        let prefiledFieldsCount = UX.searchFieldsCtrl.searchFieldsContainer.find('> .searchIn--field__row').length;
        const ROWS_OFFSET = 2; // 1 - zerro index offset, 2 - themplate row to be cloned

        if ($this.index() === UX.searchFieldsCtrl.maxNumOfSearchTerms - prefiledFieldsCount - ROWS_OFFSET) {
            UX.searchFieldsCtrl.addCtrlField.addClass('hidden');
        } else {
            UX.searchFieldsCtrl.addCtrlField.removeClass('hidden');
        }
        $this.find('[type="text"]').focus();
        UX.searchFieldsCtrl.preventSubmissionHandler();
        UX.searchFieldsCtrl.check.terms();
    };

    UX.searchFieldsCtrl.check.terms = function () {
        UX.searchFieldsCtrl.$terms = $('.advanced-search--searchIn .searchIn--field__row');

        UX.searchFieldsCtrl.$terms.each(function (index) {
            let number = index + 1;
            let $this = $(this);

            UX.searchFieldsCtrl.$cloningInput = UX.searchFieldsCtrl.check.defineCloningInput($this);

            if (number === 1) {
                const searchTermLabel = $this.closest('.advanced-search--searchIn').find('.searchTermLabel');
                searchTermLabel.attr('for', `text${number}`);
            }

            $this.find('[for*="searchArea"]').attr({
                for: `searchArea${number}`,
            });

            $this.find('[for*="text"]').attr({
                for: `text${number}`,
            });

            $this.find('select').attr({
                id: 'searchArea' + number,
                name: 'field' + number,
            });

            UX.searchFieldsCtrl.$cloningInput.attr({
                id: `text${number}`,
                name: `text${number}`,
                'aria-label': `Search scope for field ${number}`,
            });
        });
    };

    UX.searchFieldsCtrl.check.emptyInputs = function (form) {
        let textInputs = $(form).find('input[type=search]');
        if (textInputs.length === 0) {
            textInputs = $(form).find('input[type=text]');
        }

        textInputs = $(textInputs).add($(form).find('.checkVal'));

        let $emptyFields = $(textInputs).filter(function () {
            return this.value.trim() === '';
        });
        return $emptyFields.length === textInputs.length;
    };
})(UX);
