(function () {
    var mediaAdobeAnalatics = {
        lastPlayer: '',
        init: function () {
            //@toDo Remove this function and do a clean up if it worked as expected
        },
        eventData: function (id, position, length) {
            var video = new Object();
            video.id = String(id);
            video.position = String(parseInt(position));
            video.length = String(parseInt(length));
            return video;
        },
        logMessage: function (event, data) {
            if (window.console && window.console.log) {
                console.log('calling adobe ' + event + ' action: ' + JSON.stringify(data));
            }
        },
        mediaReload: e => e.target.load(),
        additionalControl: function () {
            const mediaItems = document.querySelectorAll('.html5-media-player');
            mediaItems.forEach(item => item.addEventListener('error', mediaAdobeAnalatics.mediaReload));
        },
        attach: function (element, _this) {
            if (!element.hasClass('tracked')) {
                var avFile = element.attr('data-mediaplayer-file');
                var pii = element.data('pii');
                if (element.find('.html5__media').length) {
                    avFile = element.find('.html5__media').text();
                }
                var avID = '';
                if (avFile != undefined && avFile.indexOf('cms/') !== -1) {
                    avFile = avFile.split('/');
                    avFile = avFile[avFile.length - 1];
                    if ($('article').attr('data-pii') != undefined) {
                        avID =
                            'pii:' +
                            $('article')
                                .attr('data-pii')
                                .replace(/[&\/\\#,+()$~%.'"*?<>{}]/g, '')
                                .replace(/-/g, '') +
                            ':' +
                            avFile.replace('.', ':');
                    }
                    if (pii != undefined) {
                        avID =
                            'pii:' +
                            pii.replace(/[&\/\\#,+()$~%.'"*?<>{}]/g, '').replace(/-/g, '') +
                            ':' +
                            avFile.replace('.', ':');
                    }
                } else if (
                    avFile != undefined &&
                    (avFile.indexOf('pb/assets') !== -1 || avFile.indexOf('pb-assets') !== -1)
                ) {
                    avFile = avFile.split('/');
                    avFile = avFile[avFile.length - 1];
                    avID = avFile.replace('.', ':');
                } else {
                    avID = avFile;
                }
                if (element.is('stream')) {
                    if ($('article').attr('data-pii') != undefined)
                        avID =
                            'pii:' +
                            $('article')
                                .attr('data-pii')
                                .replace(/[&\/\\#,+()$~%.'"*?<>{}]/g, '')
                                .replace(/-/g, '') +
                            ':' +
                            element.parent().attr('id') +
                            ':' +
                            element.parent().data('type');
                }

                var hasStarted = false;
                var $id = '';
                if (element.find('.mediaPlayer__player').length) {
                    $id = element.find('.html5-media-player').attr('id');
                } else if (element.find('.mediaPlayer__player-holder').length) {
                    $id = element.find('.mediaPlayer__player-holder').attr('id');
                } else {
                    $id = element.attr('id');
                }

                if ($('#' + $id).is('frame') || $('#' + $id).is('iframe')) {
                    if (typeof YT === 'undefined') {
                        let onYouTubeIframeAPIReadyOld;
                        if (window.onYouTubeIframeAPIReady) {
                            onYouTubeIframeAPIReadyOld = window.onYouTubeIframeAPIReady;
                        }
                        window.onYouTubeIframeAPIReady = function () {
                            if (onYouTubeIframeAPIReadyOld) {
                                onYouTubeIframeAPIReadyOld();
                            }
                            loadYTVideo();
                        };
                    } else {
                        loadYTVideo();
                    }

                    function loadYTVideo() {
                        YT.ready(function () {
                            let timeupdater;
                            let halfCompleteTracked = false;

                            let player = new YT.Player($id, {
                                events: {
                                    onStateChange: function (event) {
                                        switch (event.data) {
                                            case 0:
                                                var data = mediaAdobeAnalatics.eventData(
                                                    avID,
                                                    player.getCurrentTime(),
                                                    player.getDuration()
                                                );
                                                mediaAdobeAnalatics.logMessage('videoComplete', data);
                                                if (typeof pageDataTracker !== 'undefined') {
                                                    pageDataTracker.trackEvent('videoComplete', {video: data});
                                                }
                                                console.log('end');
                                                stopTimer();
                                                break;
                                            case 1:
                                                var eventName = hasStarted ? 'videoPlay' : 'videoStart';
                                                hasStarted = true;
                                                var data = mediaAdobeAnalatics.eventData(
                                                    avID,
                                                    player.getCurrentTime(),
                                                    player.getDuration()
                                                );
                                                mediaAdobeAnalatics.logMessage(eventName, data);
                                                if (typeof pageDataTracker !== 'undefined') {
                                                    pageDataTracker.trackEvent(eventName, {video: data});
                                                }
                                                console.log('play');
                                                if (!halfCompleteTracked) {
                                                    startTimer();
                                                }
                                                break;
                                            case 2:
                                                var data = mediaAdobeAnalatics.eventData(
                                                    avID,
                                                    player.getCurrentTime(),
                                                    player.getDuration()
                                                );
                                                mediaAdobeAnalatics.logMessage('videoStop', data);
                                                if (typeof pageDataTracker !== 'undefined') {
                                                    pageDataTracker.trackEvent('videoStop', {video: data});
                                                }
                                                console.log('pause');
                                                stopTimer();
                                                break;
                                        }
                                    },
                                },
                            });

                            function startTimer() {
                                function checkProgress() {
                                    if (player.getCurrentTime() > player.getDuration() / 2) {
                                        let data = mediaAdobeAnalatics.eventData(
                                            avID,
                                            player.getCurrentTime(),
                                            player.getDuration()
                                        );
                                        mediaAdobeAnalatics.logMessage('video50PercentComplete', data);
                                        if (typeof pageDataTracker !== 'undefined') {
                                            pageDataTracker.trackEvent('video50PercentComplete', {video: data});
                                        }
                                        console.log('50% complete');
                                        halfCompleteTracked = true;
                                        stopTimer();
                                    }
                                }

                                timeupdater = setInterval(checkProgress, 1000);
                            }

                            function stopTimer() {
                                clearInterval(timeupdater);
                            }
                        });
                    }
                } else {
                    UX.Html5Player.on($id, 'play', function () {
                        var playing = _this.id;
                        if (mediaAdobeAnalatics.lastPlayer && mediaAdobeAnalatics.lastPlayer != playing) {
                            var lastPlayer = document.getElementById(mediaWidget.lastPlayer);
                            lastPlayer.pause();
                        }
                        mediaAdobeAnalatics.lastPlayer = playing;
                        var eventName = hasStarted ? 'videoPlay' : 'videoStart';
                        hasStarted = true;
                        var data = mediaAdobeAnalatics.eventData(avID, this.currentTime, this.duration);
                        mediaAdobeAnalatics.logMessage(eventName, data);
                        if (typeof pageDataTracker !== 'undefined') {
                            pageDataTracker.trackEvent(eventName, {video: data});
                        }
                        console.log('play');
                    });

                    UX.Html5Player.on($id, 'pause', function () {
                        var data = mediaAdobeAnalatics.eventData(avID, this.currentTime, this.duration);
                        if (this.currentTime != this.duration) {
                            //not the end of the video/audio
                            mediaAdobeAnalatics.logMessage('videoStop', data);
                            if (typeof pageDataTracker !== 'undefined') {
                                pageDataTracker.trackEvent('videoStop', {video: data});
                            }
                            console.log('pause');
                        }
                    });

                    UX.Html5Player.on($id, 'ended', function () {
                        var data = mediaAdobeAnalatics.eventData(avID, this.currentTime, this.duration);
                        mediaAdobeAnalatics.logMessage('videoComplete', data);
                        if (typeof pageDataTracker !== 'undefined') {
                            pageDataTracker.trackEvent('videoComplete', {video: data});
                        }
                        console.log('end');
                    });

                    UX.Html5Player.on($id, 'timeupdate', function () {
                        if (this.currentTime >= this.duration / 2) {
                            var data = mediaAdobeAnalatics.eventData(avID, this.currentTime, this.duration);
                            mediaAdobeAnalatics.logMessage('video50PercentComplete', data);
                            if (typeof pageDataTracker !== 'undefined') {
                                pageDataTracker.trackEvent('video50PercentComplete', {video: data});
                            }
                            console.log('50% complete');
                            UX.Html5Player.off($id, 'timeupdate');
                        }
                    });
                }
                element.addClass('tracked');
                mediaAdobeAnalatics.additionalControl();
            }
        },
    };

    UX.mediaAdobeAnalatics = mediaAdobeAnalatics;
})();
