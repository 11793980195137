(function () {
    var subscriptions = {
        $priceLabel: $('.esubscriptions_price-val'),
        $formsContainer: $('.esubscriptions_forms-cont'),
        $ajaxFormInputs: $(".esubscriptions_ajax-form input[type='radio'], .esubscriptions_ajax-form select"),
        $pubCodeInput: $(".esubscriptions_ajax-form input[name='pubCode']"),
        $regionCodeSelect: $(".esubscriptions_ajax-form select[name='regionCode']"),
        $subscriberTypeInput: $(".esubscriptions_ajax-form input[name='subscriberType']"),
        $deliveryFormatInput: $(".esubscriptions_ajax-form input[name='deliveryFormat']"),
        $codeInput: $(".esubscriptions_ajax-form input[name='code']"),
        $uuidInput: $(".esubscriptions_ajax-form input[name='uuid']"),
        $formNotAllowedClass: 'esubscriptions_notAllowed',
        $submitBtn: $('.esubscriptions_submitBtn'),
        $offerIdInput: $('.esubscriptions_offerIdInput'),
        $renewWidget: $('.renewWidget'),
        $renewWidgetClose: $('.ui-icon-close', this.$renewWidget),
        init: function () {
            subscriptions.control();
        },
        control: function () {
            subscriptions.removeNotAllowedClass();
            subscriptions.$ajaxFormInputs.change(function () {
                subscriptions.updateSubscriptionOffersForm();
            });

            subscriptions.$renewWidgetClose.on('click', () => subscriptions.$renewWidget.hide());
        },
        addNotAllowedClass: function () {
            subscriptions.$formsContainer.addClass(subscriptions.$formNotAllowedClass);
        },
        removeNotAllowedClass: function () {
            subscriptions.$formsContainer.removeClass(subscriptions.$formNotAllowedClass);
        },
        updateSubscriptionOffersForm: function () {
            // add not allowed class
            subscriptions.addNotAllowedClass();
            // ajax call parameters
            let $pubCode = subscriptions.$pubCodeInput.val();
            let $regionCode = subscriptions.$regionCodeSelect.val();
            let $subscriberType = subscriptions.$subscriberTypeInput.filter(':checked').val();
            let $deliveryFormat = subscriptions.$deliveryFormatInput.filter(':checked').val();
            let $code = subscriptions.$codeInput.val();
            let $uuid = subscriptions.$uuidInput.val();
            // ajax call
            $.ajax({
                method: 'GET',
                url: '/pb/widgets/getUX3SubscriptionOffers',
                data: {
                    pubCode: $pubCode,
                    regionCode: $regionCode,
                    subscriberType: $subscriberType,
                    deliveryFormat: $deliveryFormat,
                    code: $code,
                    uuid: $uuid,
                },
                success: function ($data) {
                    $data = JSON.parse($data);
                    // update subscriber type radio
                    subscriptions.$subscriberTypeInput.prop('disabled', true);
                    $.each($data.ajaxJson.subscriptionTypesList, function (index, value) {
                        subscriptions.$subscriberTypeInput
                            .filter("[value='" + value.subscriberType + "']")
                            .prop('disabled', false);
                    });
                    if (subscriptions.$subscriberTypeInput.filter(':checked:disabled').length > 0) {
                        subscriptions.$subscriberTypeInput.filter(':not(:disabled)').eq(0).prop('checked', true);
                    }
                    // update delivery format radio
                    subscriptions.$deliveryFormatInput.prop('disabled', true);
                    $.each($data.ajaxJson.deliveryFormatsList, function (index, value) {
                        subscriptions.$deliveryFormatInput
                            .filter("[value='" + value.value + "']")
                            .prop('disabled', false);
                    });
                    if (subscriptions.$deliveryFormatInput.filter(':checked:disabled').length > 0) {
                        subscriptions.$deliveryFormatInput.filter(':not(:disabled)').eq(0).prop('checked', true);
                    }
                    // update price label
                    subscriptions.$priceLabel.text($data.ajaxJson.offerPriceAsString);
                    // update offer id input
                    subscriptions.$offerIdInput.val($data.ajaxJson.offerKey);
                    // update submit button data
                    subscriptions.$submitBtn.attr('data-price', $data.ajaxJson.offerData.price);
                    subscriptions.$submitBtn.attr('data-id', $data.ajaxJson.offerData.id);
                    subscriptions.$submitBtn.attr('data-quantity', $data.ajaxJson.offerData.quantity);
                    // remove not allowed class
                    subscriptions.removeNotAllowedClass();
                },
                error: function ($jqXHR) {
                    // remove not allowed class
                    subscriptions.removeNotAllowedClass();
                },
            });
        },
    };
    UX.subscriptions = subscriptions; // add to global namespace
})();
