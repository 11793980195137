UX.tab.additionalControl = function () {
    const tabsWrapper = document.querySelector('[role="tablist"]');
    if (tabsWrapper) {
        const tabLinks = tabsWrapper.querySelectorAll('[role="tab"]'),
            keyDownHandler = e => UX.tab.tabsAccessibilityHandler(tabLinks, e);

        tabLinks.forEach((item, index) => {
            if (item.parentElement.classList.contains('active')) {
                item.tabIndex = 0;
                item.ariaSelected = 'true';
            } else {
                item.tabIndex = -1;
                item.ariaSelected = 'false';
            }
            item.dataset.tab = `${index}`;
            item.addEventListener('keydown', keyDownHandler);
        });
    }
};

UX.tab.tabsAccessibilityHandler = (tabLinks, e) => {
    if (e.code === 'ArrowLeft' || e.code === 'ArrowRight' || e.code === 'Enter') {
        const itemIndex = parseInt(e.target.dataset.tab);
        if (e.code === 'Enter') {
            e.target.ariaSelected = 'false';
            tabLinks[itemIndex].ariaSelected = 'true';
        } else {
            let targetLink = '';
            switch (e.code) {
                case 'ArrowRight':
                    targetLink = tabLinks[itemIndex + 1] ? tabLinks[itemIndex + 1] : tabLinks[0];
                    break;
                case 'ArrowLeft':
                    targetLink = tabLinks[itemIndex - 1] ? tabLinks[itemIndex - 1] : tabLinks[tabLinks.length - 1];
                    break;
            }
            e.target.tabIndex = -1;
            targetLink.tabIndex = 0;
            targetLink.focus();
        }
    }
};
