(function () {
    UX.controller.extra = function () {
        UX.controller.$body.on('click touchstart', function (e) {
            // hide / close opened components when click out side it
            UX.controller.active = UX.controller.$body.attr('data-active');
            if (
                typeof eval('UX.' + UX.controller.active) != 'undefined' &&
                typeof eval('UX.' + UX.controller.active).$section != 'undefined' &&
                typeof eval('UX.' + UX.controller.active).$controller != 'undefined'
            ) {
                let container = eval('UX.' + UX.controller.active).$section;
                let target = eval('UX.' + UX.controller.active).$controller;
                if (
                    !container.is(e.target) && // if the target of the click isn't the container...
                    container.has(e.target).length === 0 &&
                    !target.is(e.target) && // if the target of the click isn't the controller...
                    target.has(e.target).length === 0 &&
                    !$(e.target).closest('.ui-autocomplete').length &&
                    !$(e.target).closest('.dropBlock__holder').length &&
                    !$(e.target).closest('.article-header__info').length
                ) {
                    // ... nor a descendant of the controller
                    eval('UX.' + UX.controller.active).on.hide(e);
                }
            }
        });
    };
})();
