import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    UIComponent,
} from '@atypon/ui-utils';

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    hidden = 'js--hidden';
    expanded = 'js--expanded';
}

class Selectors extends ComponentSelectors {
    extraItemsList: string = '.axel-related-articles__extra-items';
    toggleExtraItems: string = '.toggle-extra-items';
    linkedArticleTitle: string = '.linked-article__title';
}

class Elements extends ComponentElements<Selectors> {
}

interface RelatedArticles extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class RelatedArticles implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
    }

    toggleItems = (el: HTMLElement, extraList: HTMLElement) => {
        const toggleText = `Show ${el.classList.contains(this.classList.expanded) ? 'more' : 'less'}`;
        const toggleIconClass = `icon-arrow_${el.classList.contains(this.classList.expanded) ? 'd_n' : 'u_p'}`;

        el.classList.toggle(this.classList.expanded);
        extraList.classList.toggle(this.classList.hidden);

        el.innerHTML = `<span>${toggleText}</span><i class="${toggleIconClass}" aria-hidden="true"></i>`;
        if (!el.classList.contains(this.classList.expanded)) return;
        const firstItem = this.domUtils.getElement(this.selectors.linkedArticleTitle, extraList);
        firstItem && setTimeout(() => firstItem.focus(), 100);
    }

    toggleExtraItems = (e:any) => {
        const {target} = e;
        const el = this.domUtils.closest(this.selectors.toggleExtraItems, target);
        if (!el) return;
        const parent = el.parentElement;
        const extraList = this.domUtils.getElement(this.selectors.extraItemsList, parent);
        this.toggleItems(el, extraList);
    }

    addEventListeners(): void {
        this.domUtils.addEventListener(document, 'click', this.toggleExtraItems.bind(this));
    }
}

export default RelatedArticles;

export {
    Elements as RelatedArticlesElements,
    Selectors as RelatedArticlesSelectors,
    ClassList as RelatedArticlesClassList,
    Texts as RelatedArticlesTexts,
}