import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    Injectable,
    UIComponent
} from '@atypon/ui-utils';

import { VPortListener } from 'ui-core/plugins/enquireJs/js/helper';
import { focusCycle } from 'ui-core/utility/js/utils.js';

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    hidden = 'js--hidden';
    show = 'js--show';
    active = 'js--active';
    tocLink = 'jifa__toc__link';
    table = 'jifa__table-wrapper';
    header = 'header';
}

class Selectors extends ComponentSelectors {
    fixedElements: string = '.header, .institution-access__banner';
    tocLink: string = '.jifa__toc__link';
    tocActiveLink: string = '.jifa__toc__link.js--active';
    contentBody: string = '.jifa__content-body';
    innerSection: string = '.jifa__section__inner';
    title: string = '.jifa__title';
    focusableTitle: string = '.jifa__title[tabindex="-1"]';
    mainTitles: string = '.jifa__title[data-title-type="main"]';
    leftGrid = new ComponentSelector('.jifa__grid__col-left');
    header = new ComponentSelector('.header', 'document');
    tocToggleBtn = new ComponentSelector('.jifa__toc__ctrl');
    tocListWrapper = new ComponentSelector('.jifa__toc__list-wrapper');
    tocList = new ComponentSelector('.jifa__toc__list');
    contentBodyEl = new ComponentSelector('.jifa__content-body');
    popupClose = new ComponentSelector('.popup-close');
}

class Elements extends ComponentElements<Selectors> {
    leftGrid: HTMLElement;
    header: HTMLElement;
    tocToggleBtn: HTMLElement;
    tocListWrapper: HTMLElement;
    tocList: HTMLElement;
    contentBodyEl: HTMLElement;
    popupClose: HTMLElement;
}

interface Jifa extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class Jifa implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    @VPortListener('sm')
    isMobile: boolean = false;

    constructor(readonly wrapper: HTMLElement) {
    }

    protected isMobileOn(): void {
        this.isMobile = true;
    }

    protected isMobileOff(): void {
        this.isMobile = false;
    }

    protected isElementFixed(el: HTMLElement): number {
        const { position } = window.getComputedStyle(el);
        return position === 'fixed' ? el.offsetHeight : 0;
    }

    protected getFixedElementsHeight(): number {
        let height = 0;
        const fixedElements = this.domUtils.getElements(this.selectors.fixedElements);
        fixedElements.forEach(el => {
            if (el.classList.contains(this.classList.header))
                height += this.isElementFixed(el);
            else
                height += el.offsetHeight;
        });
        return height;
    }

    protected linkMarkup(id: string, text: string): string {
        return `<li><a href="#${id}" class="jifa__toc__link" data-type="sub">${text}</a></li>`;
    }

    protected buildInnerTitleList(item: HTMLElement): string {
        const { titleRef } = item.dataset;
        const childTitles = this.domUtils.getElements(`${this.selectors.title}[data-parent-ref="${titleRef}"]`);
        const innerContent = childTitles
            .map(childTitle => this.linkMarkup(childTitle.id, childTitle.textContent))
            .join('');
        return innerContent.length ? `<ul class="jifa__toc__list-sub">${innerContent}</ul>` : '';
    }

    protected buildTopList(): void {
        const headingList = this.domUtils.getElements(this.selectors.mainTitles);
        headingList.forEach(item => {
            const { id } = item;

            const content = `
                <li role="menuitem">
                    <div class="main-link__wrapper">
                        <a href="#${id}" class="jifa__toc__link" data-type="main">${item.textContent}</a>
                    </div>
                    ${this.buildInnerTitleList(item) || ''}
                </li>  
            `;
            this.elements.tocList.insertAdjacentHTML('beforeend', content);
            focusCycle(this.elements.tocListWrapper);
        });
    }

    protected getHashElementPosition(hash: string): number {
        return this.domUtils.getElement(hash)?.getBoundingClientRect().top + window.scrollY - this.getFixedElementsHeight();
    }

    protected scrollToElement(hash: string, position: number): void {
        const targetTitle = this.domUtils.getElement(hash);
        if (!targetTitle) return;
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
        history.pushState(null, null, hash);
        if (this.isMobile) this.toggleTocList(this.elements.tocToggleBtn, false);
    }

    protected moveFocusToTitle(hash: string): void {
        const currentFocusableTitle = this.domUtils.getElement(this.selectors.focusableTitle);
        currentFocusableTitle?.removeAttribute('tabindex');
        const target = this.domUtils.getElement(hash);
        target.tabIndex = -1;
        target.focus({ preventScroll: true });
    }

    protected handleScrollableLink(e: Event): void {
        const link = e.target as Element;
        if (!link.classList.contains(this.classList.tocLink)) return;
        e.preventDefault();
        const hash = (link as HTMLAnchorElement).hash;
        this.moveFocusToTitle(hash);
        this.scrollToElement(hash, this.getHashElementPosition(hash));
    }

    protected setDynamicStyles(): void {
        setTimeout(() => {
            if (this.elements.leftGrid) {
                this.elements.leftGrid.style.top = !this.isMobile ? `${this.getFixedElementsHeight() + 10}px` : 'auto';
                this.elements.leftGrid.style.maxHeight = !this.isMobile ? `calc(100vh - ${this.getFixedElementsHeight() + 32}px)` : 'none';
            }
            if (this.elements.tocList)
                this.elements.tocList.style.maxHeight = this.isMobile ? 'calc(100vh - 13rem)' : 'none';
        }, 25);
    }

    protected handleScroll(): void {
        if (!this.isTocLinksEnabled()) return;
        const { top, bottom } = this.elements.contentBodyEl.getBoundingClientRect();
        if (
            window.scrollY + this.getFixedElementsHeight() <= top + window.scrollY ||
            window.scrollY + this.getFixedElementsHeight() >= bottom + window.scrollY
        ) {
            const activeLink = this.domUtils.getElements(this.selectors.tocActiveLink);
            activeLink.forEach(link => link.classList.remove(this.classList.active));
        }

        const titleList = this.domUtils.getElements(this.selectors.title);
        titleList.forEach(title => {
            const parent = this.domUtils.closest(this.selectors.contentBody, title);
            if (parent) {
                const { bottom: parentBottom } = parent.getBoundingClientRect();
                const { top: titleTop } = title.getBoundingClientRect();

                if (
                    window.scrollY + this.getFixedElementsHeight() >= titleTop + window.scrollY - 10 &&
                    window.scrollY + this.getFixedElementsHeight() <= parentBottom + window.scrollY
                ) {
                    const activeLink = this.domUtils.getElements(`${this.selectors.tocActiveLink}`);
                    activeLink.forEach(link => link.classList.remove(this.classList.active));

                    const targetLink = this.domUtils.getElement(`${this.selectors.tocLink}[href="#${title.id}"]`);
                    targetLink?.classList.add(this.classList.active);

                    const innerParent = this.domUtils.closest(this.selectors.innerSection, title);
                    if (innerParent) {
                        const { parentRef } = title.dataset;
                        const parentTitle = this.domUtils.getElement(`${this.selectors.title}[data-title-ref="${parentRef}"]`);
                        const targetParentLink = this.domUtils.getElement(`${this.selectors.tocLink}[href="#${parentTitle.id}"]`);
                        targetParentLink.classList.add(this.classList.active);
                    }
                }

            }

        });
    }

    protected toggleTocList(el: HTMLElement, show: boolean): void {
        el.dataset.active = `${show}`;
        el.ariaExpanded = `${show}`;
        this.elements.tocListWrapper.classList.toggle(this.classList.show, show);
        setTimeout(() => {
            if (show)
                this.elements.popupClose.focus();
            else
                this.elements.tocToggleBtn.focus();
        }, 100);
    }

    protected viewPortReset(): void {
        this.elements.tocToggleBtn.dataset.active = 'false';
        this.elements.tocToggleBtn.ariaExpanded = 'false';
        this.elements.tocListWrapper.classList.remove(this.classList.show);
    }

    protected handleToggleClick(e: any): void {
        const { currentTarget: item } = e;
        this.toggleTocList(item, item.dataset.active === 'false');
    }

    protected handleClosePopup(): void {
        this.toggleTocList(this.elements.tocToggleBtn, false);
    }

    addEventListeners(): void {
        this.domUtils.addEventListener(document, 'click', this.handleScrollableLink.bind(this));
        this.domUtils.addEventListener(this.elements.tocToggleBtn, 'click', this.handleToggleClick.bind(this));
        this.domUtils.addEventListener(this.elements.popupClose, 'click', this.handleClosePopup.bind(this));
        window.addEventListener('scroll', this.handleScroll.bind(this));
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    protected scrollToHash(): void {
        if (window.location.hash) this.scrollToElement(window.location.hash, this.getHashElementPosition(window.location.hash));
    }

    protected wrapTables(): void {
        const tables = this.domUtils.getElements('table', this.wrapper);
        tables.forEach((table: HTMLElement) => {
            const wrapper = document.createElement('div');
            wrapper.classList.add(this.classList.table);
            table.parentNode.insertBefore(wrapper, table);
            wrapper.appendChild(table);
        });
    }

    protected isTocLinksEnabled(): boolean {
        if (!this.elements.tocList) return;
        const { listType } = this.elements.tocList.dataset;
        return listType === 'link';
    }

    protected handleResize(): void {
        this.setDynamicStyles();
        if (this.isTocLinksEnabled()) {
            this.viewPortReset();
        }
    }

    additionalInit(): void {
        this.scrollToHash();
        this.wrapTables();
        this.setDynamicStyles();
        if (this.isTocLinksEnabled()) {
            this.buildTopList();
        }
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
        this.additionalInit();
    }
}

export default Jifa;

export {
    Elements as JifaElements,
    Selectors as JifaSelectors,
    ClassList as JifaClassList,
    Texts as JifaTexts
};