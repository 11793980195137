(function () {
    const $body = $('body');
    const tableOfContent = {
        selectAllBox: null,
        NextButton: null,
        prevButton: null,
        isMobile: $(window).width() <= UX.grid.screenSm,
        $controller: null,
        $multiAlert: null,
        $downloadAlert: null,
        checkboxes: null,
        $downloadIssuePdfLink: null,
        selectors: {
            downloadIssuePdfLink: '.download-full-issue',
            downloadFullIssueBtn: '[data-button="download-full-issue"]',
            checkboxes: '.table-of-content__body input[type="checkbox"]',
            itemPrefixCheckboxes: '.toc__item__prefix input:checkbox',
            alert: '.table-of-content__alert',
            alertMulti: '.table-of-content__alert--multi',
            alertDownload: '.table-of-content__alert--download',
            alertClose: '.table-of-content__alert--close',
            jsAlertButton: '.js-alert button',
            selectAllBox: '.table-of-content__links--item input',
            jsNextLink: '.js_nextLink',
            jsPrevLink: '.js_preLink',
            jsLinks: '.js-links',
            freeIssueIcon: '.freeIssueIcon',
            freeIcon: '.freeIcon',
            tocLinksButtons: '.table-of-content__links button',
            articleHeaderDownloadFullIssue: '.article-header__download-full-issue',
            xhrIssueDownloadLink: '.xhr-issue-download-link',
            axelArticle: 'article[data-design',
        },
        init: function () {
            tableOfContent.selectAllBox = $(tableOfContent.selectors.selectAllBox);
            tableOfContent.nextButton = $(tableOfContent.selectors.jsNextLink);
            tableOfContent.prevButton = $(tableOfContent.selectors.jsPrevLink);
            tableOfContent.$controller = $(tableOfContent.selectors.jsLinks);
            tableOfContent.$multiAlert = $(tableOfContent.selectors.alertMulti);
            tableOfContent.$downloadAlert = $(tableOfContent.selectors.alertDownload);
            tableOfContent.checkboxes = $(tableOfContent.selectors.checkboxes);
            tableOfContent.$downloadIssuePdfLink = $(tableOfContent.selectors.downloadIssuePdfLink);
            tableOfContent.control();
            tableOfContent.articleToolsAction();
        },
        control: function () {
            tableOfContent.checkboxes.on('click', function () {
                tableOfContent.selectAllBox.prop('checked', false);
            });

            tableOfContent.selectAllBox.click(function () {
                $(tableOfContent.selectors.checkboxes).prop('checked', this.checked);
            });

            $(tableOfContent.selectors.freeIssueIcon).length ? $(tableOfContent.selectors.freeIcon).hide() : '';

            tableOfContent.$downloadIssuePdfLink.on('click', function (e) {
                e.preventDefault();

                const $self = $(this);
                let widgetSource = '';
                let widgetLocation = '';
                if ($self.closest('.article-header__download-full-issue').length) {
                    widgetSource = 'Article content Axel';
                    widgetLocation = 'article-page';
                } else if ($self.closest('.current-issue').length) {
                    widgetSource = 'Current Issue UX3';
                    widgetLocation = 'current-issue';
                    UX.UX3AdobeAnalytics.conversionDriverClickHandler('jb:jnl-home-full-issue-link:click');
                } else if ($self.closest('.cover-image').length) {
                    widgetSource = 'Cover Image UX3';
                    widgetLocation = 'cover-image';
                    UX.UX3AdobeAnalytics.conversionDriverClickHandler('jb:jnl-home-full-issue-link:click');
                } else {
                    widgetSource = 'Table Of Content UX3';
                    widgetLocation = 'toc-page';
                }

                UX.UX3AdobeAnalytics.widgetClickHandler(widgetSource);

                if (tableOfContent.$multiAlert.is(':visible')) {
                    tableOfContent.$multiAlert.hide();
                }

                tableOfContent.$downloadAlert.find('p').text('Your download will start shortly.');
                tableOfContent.$downloadAlert.show();
                tableOfContent.on.show();

                $.ajax({
                    url: $(this).attr('formaction'),
                    method: 'GET',
                    success: function (data, status, xhr) {
                        UX.UX3AdobeAnalytics.conversionDriverClickHandler('jb:Article-download-Full-Issue-Link:click');
                        if (data.content && data.content.length) {
                            data.content.map(item => {
                                const obj = {
                                    id: `jb:article:${item.id}`,
                                    type: item.type,
                                    format: item.format,
                                    title: item.title,
                                    detail: item.detail,
                                    entitlementType: item.entitlementType,
                                };
                                UX.UX3AdobeAnalytics.downloadMultiplePdfHandler(obj);
                            });
                        }
                        let downloadStatus = xhr.getResponseHeader('Download-Status');
                        let message = '';

                        if (downloadStatus === 'partial_access') {
                            message =
                                'We are unable to download all the articles unless you log in with your subscription credentials or make a purchase.';
                        } else {
                            message = 'Download started.';
                        }
                        tableOfContent.$downloadAlert.find('p').text(message);
                        tableOfContent.$downloadAlert.show();
                        tableOfContent.on.show();

                        let downloadLink = document.createElement('a'),
                            url = data.link;
                        downloadLink.href = url;
                        downloadLink.className = 'xhr-issue-download-link';
                        document.body.append(downloadLink);
                        downloadLink.click();
                        setTimeout(() => {
                            downloadLink.remove();
                            window.URL.revokeObjectURL(url);
                        }, 1000);
                    },
                    error: function (xhr, status, error) {
                        let downloadStatus = xhr.getResponseHeader('Download-Status');
                        let message = '';

                        if (downloadStatus === 'no_access') {
                            message =
                                'We are unable to download any articles unless you log in with your subscription credentials or make a purchase.';

                            let navigationContent = {location: widgetLocation, name: 'dowload-full-issue-pdfs'};
                            UX.UX3AdobeAnalytics.navigationAnalytics(navigationContent);
                        } else {
                            message = 'Due to some issues please try again later.';
                        }
                        UX.UX3AdobeAnalytics.downloadFullIssueEventTrack.error(widgetLocation, message);
                        tableOfContent.$downloadAlert.find('p').text(message);
                        tableOfContent.$downloadAlert.show();
                        tableOfContent.on.show();
                    },
                });
            });

            $(document).on('click', function (e) {
                if (
                    !$(e.target).is(tableOfContent.selectors.alert) &&
                    $(e.target).closest(tableOfContent.selectors.alert).length === 0 &&
                    !$(e.target).is(tableOfContent.selectors.tocLinksButtons) &&
                    $(e.target).closest(tableOfContent.selectors.tocLinksButtons).length === 0 &&
                    !$(e.target).is(tableOfContent.selectors.xhrIssueDownloadLink) &&
                    !$(e.target).is(tableOfContent.selectors.articleHeaderDownloadFullIssue) &&
                    !$(e.target).closest(tableOfContent.selectors.articleHeaderDownloadFullIssue).length &&
                    !$(e.target).is(tableOfContent.selectors.downloadFullIssueBtn) &&
                    !$(e.target).closest(tableOfContent.selectors.downloadFullIssueBtn).length
                ) {
                    tableOfContent.on.hide();
                }
            });
        },
        articleToolsAction: function () {
            $(tableOfContent.selectors.jsAlertButton).on('click', function (event) {
                let checkboxes = $(tableOfContent.selectors.itemPrefixCheckboxes),
                    scrollTopPosition = 0;
                if (tableOfContent.isMobile) {
                    scrollTopPosition = 60;
                }
                if (!checkboxes.is(':checked')) {
                    event.preventDefault();
                    $('html, body').stop().animate(
                        {
                            scrollTop: scrollTopPosition,
                        },
                        500,
                        'swing'
                    );
                    if (tableOfContent.$downloadAlert.is(':visible')) {
                        tableOfContent.$downloadAlert.hide();
                    }
                    tableOfContent.$multiAlert.show();
                    tableOfContent.on.show();
                    return;
                }
            });
            $(tableOfContent.selectors.alertClose).on('click', function (event) {
                event.preventDefault();
                if ($body.attr('data-active') === 'tableOfContent') {
                    $body.removeAttr('data-active');
                }
                $(this).parent().hide();
            });
        },
        on: {
            hide: function () {
                $(tableOfContent.selectors.alert).hide();
                if ($body.attr('data-active') === 'tableOfContent') $body.removeAttr('data-active');
            },
            show: function () {
                if ($body.attr('data-active') !== undefined && $body.attr('data-active') !== 'tableOfContent') {
                    UX.dropBlock.on.hide();
                    UX.drawer.on.hide();
                }
                $body.attr('data-active', 'tableOfContent');
            },
        },
    };
    UX.tableOfContent = tableOfContent;
})();
