UX.adplaceholder.toggleSticky = function (scrollTop, stickypoint, windowBottom, footerTop) {
    if (UX.adplaceholder.isTablet) {
        UX.adplaceholder.adPlaceholdersRight.style.position = 'static';
        UX.adplaceholder.adPlaceholdersRight.style.top = 'auto';
        UX.adplaceholder.adPlaceholdersRight.style.bottom = 'auto';
        UX.adplaceholder.adPlaceholdersRight.classList.remove('js--sticky');
        UX.adplaceholder.adPlaceholdersRight.classList.add('container');
        UX.adplaceholder.adPlaceholdersRight.querySelector('.literatumAd').style.display = 'none';
    } else if (scrollTop > stickypoint) {
        if (UX.adplaceholder.adPlaceholdersRight) {
            UX.adplaceholder.adPlaceholdersRight.classList.add('js--sticky');
            if (windowBottom < footerTop) {
                UX.adplaceholder.adPlaceholdersRight.style.position = 'fixed';
                UX.adplaceholder.adPlaceholdersRight.style.top =
                    $('.header').height() - UX.adplaceholder.articleToolsHeight + 'px';
                UX.adplaceholder.adPlaceholdersRight.style.bottom = 'auto';
            } else {
                UX.adplaceholder.adPlaceholdersRight.style.position = 'absolute';
                UX.adplaceholder.adPlaceholdersRight.style.top = 'auto';
                UX.adplaceholder.adPlaceholdersRight.style.bottom = '10px';
            }
        }
    } else {
        if (UX.adplaceholder.adPlaceholdersRight) {
            UX.adplaceholder.adPlaceholdersRight.classList.remove('js--sticky');
            UX.adplaceholder.adPlaceholdersRight.style.position = 'absolute';
            UX.adplaceholder.adPlaceholdersRight.style.top = 0;
            UX.adplaceholder.adPlaceholdersRight.style.bottom = 'auto';
        }
    }
};
