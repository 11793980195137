(function () {
    let $window = $(window),
        $html = $('html'),
        $body = $html.find('body'),
        $clicked_link = null;

    const ENTER_KEY = 13;
    const TAB_KEY = 9;
    const SHIFT_KEY = 16;
    let reversTab = false;
    let $lastFocusable;

    let figureViewer = {
        figures: null,
        $summary: null,
        $summaryList: null,
        $figuresPlaceholder: null,
        $viewer: null,
        $viewerFrames: null,
        $viewerFrameList: null,
        $viewerCaption: null,
        $viewerCaptionText: null,
        $viewerThumbstripList: null,
        $previousFrameButton: null,
        $nextFrameButton: null,
        $previousThumbstripButton: null,
        $nextThumbstripButton: null,
        $download: null,
        frameCount: null,
        frameIndex: null,
        frameHeight: null,
        frameWidth: null,
        thumbnailWidth: null,
        thumbstripOffsets: null,
        thumbstripOffsetIndex: null,
        captionsLinks: null,
        figuresLinks: null,
        allowLazyload: null,
        firstLazyload: null,
        //those variables are needed for zoom in function
        scale: 1,
        counter: 1,
        x: null,
        y: null,
        scaled: false,
        mouseDownVal: false,
        clickCounter: 0,
        captionControllTextShow: 'Show full caption',
        captionControllTextHide: 'Hide caption',
        figureBodyClone: null,
        figureBodyCloneParent: null,
        isAxel: false,
        init: function () {
            figureViewer.isAxel = document.querySelector('article[data-design]');
            let figuresSelector =
                (figureViewer.isAxel ? 'figure.graphic' : 'figure') + ':not([data-figureviewer-ignore="true"])';
            figureViewer.figures = $(figuresSelector);
            if (figureViewer.isAxel) {
                let tmpFigures = [];
                figureViewer.figures.each(function () {
                    let figure = this;
                    let images = figure.querySelectorAll('img');
                    if (images.length > 1) {
                        images.forEach((img, i) => {
                            img.id = figure.id + '-part-' + (i + 1);
                            let figClone = figure.cloneNode(true);
                            figClone
                                .querySelectorAll(`:scope > a:not(:nth-child(${i + 1}))`)
                                .forEach(anchorClone => figClone.removeChild(anchorClone));
                            tmpFigures.push(figClone);
                        });
                    } else {
                        tmpFigures.push(figure);
                    }
                });
                figureViewer.figures = $(tmpFigures);
            }
            figureViewer.$summary = $('<section><h2 id="figures" class="top">Figures</h2></section>');
            figureViewer.$summaryList = $('<ul class="figures rlist"></ul>').appendTo(figureViewer.$summary);
            figureViewer.$figuresPlaceholder = $('div.figures-placeholder');
            figureViewer.$viewer = $('#figure-viewer');
            figureViewer.$viewerFrames = figureViewer.$viewer.find('div.frames');
            figureViewer.$viewerFrameList = figureViewer.$viewerFrames.find('ul');
            figureViewer.$viewerCaption = figureViewer.$viewer.find('div.caption');
            figureViewer.$viewerCaptionText = figureViewer.$viewerCaption.find('div');
            figureViewer.$viewerThumbstripList = figureViewer.$viewer.find('div.thumbstrip > div > ul');
            figureViewer.$previousFrameButton = figureViewer.$viewerFrames.find('a.previousFrameButton');
            figureViewer.$nextFrameButton = figureViewer.$viewerFrames.find('a.nextFrameButton');
            figureViewer.$previousThumbstripButton = figureViewer.$viewer.find(
                'div.thumbstrip > a.previousThumbstripButton'
            );
            figureViewer.$nextThumbstripButton = figureViewer.$viewer.find('div.thumbstrip > a.nextThumbstripButton');
            figureViewer.$download = figureViewer.$viewer.find('div.download');
            figureViewer.frameCount = figureViewer.figures.length;
            figureViewer.captionsLinks = $(
                '.caption__link, article[data-design] .figure__caption__body a[href^="#"]:not([href$="#"])'
            );
            figureViewer.figureViewerframeIndex = 0;
            figureViewer.thumbnailWidth = 134;
            figureViewer.captionControls = $('.figure__caption__body .accordion__control');
            figureViewer.figuresLinks = $('.article__body .figure-link');
            figureViewer.$closeButton = figureViewer.$viewer.find('a.figure-viewer__close');
            figureViewer.allowLazyload = false;
            figureViewer.firstLazyload = true;
            figureViewer.captionControls.on('click', function (e) {
                // caption inline links controllers
                let textContainer = $(this).find('span');
                $(this).attr('aria-expanded') === 'false'
                    ? textContainer.text(figureViewer.captionControllTextHide)
                    : textContainer.text(figureViewer.captionControllTextShow);
            });

            figureViewer.captionsLinks.on('click', function (e) {
                // caption inline links controllers
                if ($(this).closest('.figure-viewer').length)
                    if ($(this).hasClass('figure-link')) {
                        e.preventDefault();
                        figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
                        let locator = $(this).attr('data-locator');
                        $('.thumbstrip__wrapper img[data-locator="' + locator + '"]').trigger('click'); // got to target figure
                    } else {
                        e.preventDefault();
                        figureViewer.closeViewer(); // prevent default scrolling
                    }
            });

            figureViewer.figuresLinks.on('click', function (e) {
                // caption inline links controllers
                e.preventDefault();
                if (figureViewer.figureBodyCloneParent) {
                    figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
                }

                let locator = $(this).attr('data-locator');
                $(`.figures__thumbnail img[data-locator="${locator}"]`).trigger('click'); // got to target figure
            });

            figureViewer.figures.each(function (index) {
                let $image = $(this).find('img:not(.inline-image)'),
                    $imgWrapper = $('<div></div>').append($image.clone(true).removeClass('tall').attr('tabindex', 0)),
                    $frame = $('<div class="thumbnail__image thumbnail__image--img-ratio"></div>').append($imgWrapper),
                    $figureViewerFrame = $('<div class="thumbnail__image"></div>').append(
                        $image.clone(true).removeClass('tall').attr('tabindex', 0)
                    ),
                    $figureTitle = $(this).find('.figure__label'),
                    $label = $('<div class="thumbnail__label"></div>').append(
                        $figureTitle.data('fv-label') ||
                            $figureTitle.text().replace('Supplementary Figure', 'Fig. S') ||
                            'Figure'
                    ),
                    $thumbnail = $('<div class="thumbnail"></div>').append($figureViewerFrame.clone(true)),
                    $openlink = $(this).find('.figure__open__ctrl'),
                    imgAspectRatio = parseFloat(($image.width() / $image.height()).toPrecision(3)),
                    $figureBody = $(this).find('.figure__title__text'),
                    figureCopyright = $(this).find('.figure__copyright').clone(true),
                    figureSource = $(this).find('.figure__source').clone(true),
                    $figureTitleClone =
                        '<span class="' + $figureTitle.attr('class') + '">' + $figureTitle.text() + '</span>',
                    $figureBodyClone =
                        '<span class="' + $figureBody.attr('class') + '">' + $figureBody.html() + '</span>',
                    figureContent = $('<div class = "figure__title" ></div>')
                        .append($figureTitleClone)
                        .append($figureBodyClone);
                if ($('.b-lazy[data-src]').length === 0) {
                    if (imgAspectRatio > 1.45) {
                        $frame.find('img').addClass('wide');
                    } else if (imgAspectRatio <= 1.45) {
                        $frame.find('img').addClass('tall');
                    }
                }

                $('<li></li>').append($figureViewerFrame).appendTo(figureViewer.$viewerFrameList);
                $('<li class="figures__thumbnail"></li>')
                    .append($frame.clone(true))
                    .append(figureContent)
                    .append(figureSource)
                    .append(figureCopyright)
                    .appendTo(figureViewer.$summaryList);
                $('<li></li>')
                    .append($label.clone(true))
                    .append($thumbnail)
                    .appendTo(figureViewer.$viewerThumbstripList);
                if (!figureViewer.isMobile() && !figureViewer.isAxel) {
                    $image.click(function () {
                        figureViewer.openViewer();
                        figureViewer.move(index);
                        figureViewer.fillLinks(index);
                        $clicked_link = $image;
                    });

                    $image.on('keyup', function (e) {
                        if ((e.keyCode || e.which) === ENTER_KEY) {
                            figureViewer.openViewer();
                            figureViewer.move(index);
                            figureViewer.fillLinks(index);
                            $clicked_link = $image;
                        }
                    });
                }
                if (figureViewer.isAxel && $image[0].id)
                    if ($image[0].id.match(/-part-1/)) $openlink = $('#' + this.id).find('.figure__open__ctrl');
                    else $openlink = null;
                $openlink?.on('click', function (e) {
                    e.preventDefault();
                    figureViewer.openViewer();
                    figureViewer.move(index);
                    figureViewer.fillLinks(index);
                    $clicked_link = $openlink;
                });
            });

            //this is needed for zoom functionality
            figureViewer.$viewerFrameList.find('img').each(function () {
                $(this).draggable({
                    start: function (event, ui) {
                        $(this).data('dragging', true);
                        $(this).attr('position', 'relative');
                    },
                    scroll: false,
                    stop: function (event, ui) {
                        setTimeout(function () {
                            $(event.target).data('dragging', false);
                        }, 1);
                    },
                });

                $(this).on('load', function () {
                    $(this).addClass('fw-loaded');
                });
            });

            figureViewer.clickCounter = 0;
            figureViewer.$viewerFrameList.find('img:not(.inline-image)').each(function () {
                let fig = this;
                $(fig)
                    .parent()
                    .click(function (e) {
                        e.preventDefault();
                        figureViewer.activate(e, fig);
                    });
                $(fig)
                    .parent()
                    .on('drag', function (e) {
                        if (!$(fig).hasClass('zoomed')) {
                            e.preventDefault();
                            $(fig).draggable('disable');
                            $(fig).css({top: 0, left: 0});
                        }
                    });
                if (figureViewer.isMobile()) {
                    $(fig)
                        .parent()
                        .swipe({
                            swipeStatus: function (event, phase, direction, distance, duration, fingerCount) {
                                // $(this).find('#swipe_text').text("swiped " + distance + ' px');
                                if (phase === $.fn.swipe.phases.PHASE_END || phase === $.fn.swipe.phases.PHASE_CANCEL) {
                                    // event.preventDefault();
                                    if (direction === 'right') {
                                        if (figureViewer.frameIndex > 0 && !figureViewer.scaled) {
                                            figureViewer.movePrevious();
                                        }
                                    } else if (direction === 'left') {
                                        if (
                                            figureViewer.frameIndex < figureViewer.frameCount - 1 &&
                                            !figureViewer.scaled
                                        ) {
                                            figureViewer.moveNext();
                                        }
                                    }
                                }
                            },
                            fingers: $.fn.swipe.fingers.ALL,
                        });
                }
            });

            $body.mousedown(function () {
                figureViewer.mouseDownVal = true;
            });

            $body.mouseup(function () {
                setTimeout(function () {
                    figureViewer.mouseDownVal = false;
                }, 1);
            });

            if (figureViewer.$figuresPlaceholder.length) {
                figureViewer.$figuresPlaceholder.replaceWith(figureViewer.$summary);
                UX.LSN.fill.top(); // fill top side bar nav
            }
            figureViewer.$viewer.find('.figure-viewer__title').text($('h1').text());
            // figureViewer.resize();

            //Event binding
            $('.js--openFigureViewer').on('click', function (e) {
                e.preventDefault();
                if (figureViewer.isAxel) {
                    const firstFigure = $('#' + figureViewer.figures.first().attr('id'));
                    firstFigure.find('.figure__open__ctrl').trigger('click');
                } else {
                    figureViewer.figures.first().find('img:not(.inline-image)').trigger('click');
                }
            });
            figureViewer.$viewer.find('a.figure-viewer__close').click(function (event) {
                event.preventDefault();
                figureViewer.closeViewer();
            });

            figureViewer.$viewer.find('a.figure-viewer__close').on('keypress', function (event) {
                if ((event.keyCode || event.which) === ENTER_KEY) {
                    event.preventDefault();
                    event.stopPropagation();
                    figureViewer.closeViewer();
                }
            });

            $('.figure-viewer__see-figure__ctrl').on('click', function (e) {
                e.preventDefault();
                figureViewer.closeViewer();
                UX.LSN.scroll($(this), this.hash);
            });

            figureViewer.$summaryList.find('.thumbnail__image').each(function (index) {
                $(this).click(function () {
                    figureViewer.openViewer();
                    figureViewer.move(index);
                    figureViewer.fillLinks(index);
                });

                $('img', this).on('keypress', function (event) {
                    if ((event.keyCode || event.which) === ENTER_KEY) {
                        event.preventDefault();
                        figureViewer.openViewer();
                        figureViewer.move(index);
                        figureViewer.fillLinks(index);
                    }
                });
            });

            figureViewer.$viewerThumbstripList.find('img:not(.inline-image)').each(function (index) {
                $(this).click(function () {
                    figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
                    figureViewer.move(index);
                    figureViewer.fillLinks(index);
                });

                $(this).on('keypress', function (event) {
                    if ((event.keyCode || event.which) === ENTER_KEY) {
                        event.preventDefault();
                        figureViewer.openViewer();
                        figureViewer.move(index);
                        figureViewer.fillLinks(index);
                    }
                });
            });

            /*detect key down event*/
            $window.keydown(function (e) {
                if (figureViewer.$viewer.hasClass('open')) {
                    switch (e.which) {
                        case 27:
                            figureViewer.closeViewer();

                            break;
                        case 37:
                            if (figureViewer.frameIndex > 0) {
                                figureViewer.movePrevious();
                            }
                            break;
                        case 38:
                            figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
                            figureViewer.move(0);
                            figureViewer.fillLinks(0);
                            break;
                        case 39:
                            if (figureViewer.frameIndex !== figureViewer.frameCount - 1) {
                                figureViewer.moveNext();
                            }
                            break;
                        case 40:
                            figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
                            figureViewer.move(figureViewer.frameCount - 1);
                            figureViewer.fillLinks(figureViewer.frameCount - 1);
                            break;

                        default:
                            return; // exit this handler for other keys
                    }
                    if (figureViewer.$viewer.hasClass('open')) {
                        // check if figure viewer is open
                        e.preventDefault(); // prevent the default action (scroll / move caret)
                    }
                }
            });

            figureViewer.$previousFrameButton.click(function (e) {
                e.preventDefault();
                figureViewer.movePrevious();
            });
            figureViewer.$nextFrameButton.click(function (e) {
                e.preventDefault();
                figureViewer.moveNext();
            });
            figureViewer.$previousThumbstripButton.click(function (e) {
                e.preventDefault();
                figureViewer.thumbstripMovePrevious();
            });
            figureViewer.$nextThumbstripButton.click(function (e) {
                e.preventDefault();
                figureViewer.thumbstripMoveNext();
            });
            figureViewer.$viewer.on('click', 'div > div > span.Figcontent a', function () {
                $(this).removeAttr('href');
                figureViewer.closeViewer();
            });
            figureViewer.$viewer.find('div.toolbar > a.toggle-caption-button').click(function (e) {
                e.preventDefault();
                figureViewer.toggleViewerCaption();
            });
            figureViewer.$viewer.find('a.download-options-button').click(function (event) {
                event.stopPropagation();
                event.preventDefault();
                if (!figureViewer.$download.hasClass('open')) {
                    figureViewer.$download.addClass('open');
                } else {
                    figureViewer.$download.removeClass('open');
                }
            });

            figureViewer.$viewer.find('div.thumbstrip > a.toggleButton').click(function (e) {
                e.preventDefault();
                figureViewer.toggleViewerThumbstrip();
            });
            figureViewer.$viewer.click(function () {
                figureViewer.$download.removeClass('open');
            });

            figureViewer.$viewer.on('keyup', function (e) {
                if (e.keyCode === SHIFT_KEY) {
                    reversTab = false;
                }
            });

            figureViewer.$viewer.on('keydown', function (e) {
                if (e.keyCode === SHIFT_KEY) {
                    reversTab = true;
                }

                if ((e.keyCode || e.which) === TAB_KEY) {
                    if (!reversTab) {
                        figureViewer.tabForward(e);
                    } else {
                        figureViewer.tabBackwards();
                    }
                }
            });

            figureViewer.updateDownloadFigureLink(0);

            $window.resize(function () {
                figureViewer.resize();
            });
        },
        tabForward: function (e) {
            figureViewer.$closeButton.off('focusout');
            if ($(e.target).is($lastFocusable)) {
                e.preventDefault();
                figureViewer.$closeButton.focus();
            }
        },
        tabBackwards: function () {
            figureViewer.$closeButton.off('focusout');
            $lastFocusable.off('focusout');
            figureViewer.$closeButton.on('focusout', function (e) {
                $lastFocusable.focus();
            });
        },
        fillLinks: function (index) {
            let figure = figureViewer.figures.eq(index);
            let elementId = figure.attr('id');
            if (figureViewer.isAxel) {
                let imgId = figure[0].querySelector('img').id;
                imgId && (elementId = imgId);
            }
            $('.figure-viewer__see-figure__ctrl').prop('href', '#' + elementId);
            let downLink = figureViewer.figures.eq(index).find('.download-links__download-This').attr('href');
            if (downLink !== undefined) {
                $('.download-options-button').removeClass('hidden-sm hidden-md hidden-lg');
            } else {
                $('.download-options-button').addClass('hidden-sm hidden-md hidden-lg');
            }

            figureViewer.getLastFocusable();
        },
        activate: function (e, fig) {
            figureViewer.clickCounter++;
            if (figureViewer.scaled || figureViewer.clickCounter === 1) {
                $(fig).draggable('enable');
            } else {
                $(fig).draggable('disable');
            }
            if ($(fig).data('dragging') && figureViewer.mouseDownVal) return;
            let offset = $(fig).parent().offset();
            let width = fig.width;
            let height = fig.height;
            let x = e.pageX;
            let y = e.pageY;
            figureViewer.scale += 0.5;
            if (figureViewer.clickCounter < 3) {
                x = ((x - offset.left) / width) * 100;
                y = ((y - offset.top) / height) * 100;
                $(fig).addClass('zoomed');
                figureViewer.zoom.in(fig, x, y, figureViewer.scale);
                figureViewer.counter++;
            } else {
                $(fig).removeClass('zoomed');
                figureViewer.zoom.reset(fig);
                figureViewer.counter = 1;
                figureViewer.clickCounter = 0;
                figureViewer.scale = 1;
            }
        },
        layoutViewer: function () {
            figureViewer.$viewer.removeClass('landscape portrait');
            if (figureViewer.$viewer.height() < figureViewer.$viewer.width() || !figureViewer.isMobile()) {
                figureViewer.$viewer.addClass('landscape');
                figureViewer.frameHeight = figureViewer.$viewer.height() - 65;
                figureViewer.frameWidth = figureViewer.$viewer.hasClass('showCaption')
                    ? figureViewer.$viewer.width() * 0.7
                    : figureViewer.$viewer.width() - 75;
                figureViewer.$viewerCaption.css({left: figureViewer.frameWidth, top: 0});
                // figureViewer.$viewerFrames.find('a.frameButton').css({'top':"50%"});
            } else {
                figureViewer.$viewer.addClass('portrait');
                figureViewer.frameHeight = figureViewer.$viewer.height() * 0.5 - 65;
                figureViewer.frameWidth = figureViewer.$viewer.width();
                figureViewer.$viewerCaption.css({left: 0, top: figureViewer.frameHeight + 65});
                // figureViewer.$viewerFrames.find('a.frameButton').css({'top':"50%"});
            }
            figureViewer.$viewerFrames.css({height: figureViewer.frameHeight, top: 65, width: figureViewer.frameWidth});

            figureViewer.$viewerFrameList
                .css({
                    left: -(figureViewer.frameWidth * figureViewer.frameIndex),
                    width: figureViewer.frameWidth * figureViewer.frameCount,
                })
                .find('li')
                .each(function (index) {
                    // setTimeout(() => {
                    figureViewer.layoutFrame($(this), index);
                    // }, 100);
                });
            figureViewer.firstLazyload = false;

            figureViewer.$previousFrameButton.css({left: 0});
            figureViewer.$nextFrameButton.css({right: 0});

            figureViewer.thumbstripOffsets = figureViewer.getThumbstripOffsets();
            figureViewer.$viewerThumbstripList.css('width', figureViewer.thumbnailWidth * figureViewer.frameCount);
            figureViewer.thumbstripMove(0);

            figureViewer.$download.removeClass('open');
        },
        move: function (index) {
            figureViewer.frameIndex = index;
            let $currentFrame = figureViewer.figures.eq(figureViewer.frameIndex);
            let $currentFrameBody = $currentFrame.find('.figure__caption__body');
            let $currentFrameBodyChildren = $currentFrameBody.find('.accordion__content, .figure__caption__content');
            let $currentFrameBodyTitle = $currentFrameBody.find('.figure__title');

            if ($(window).width() >= UX.grid.screenMd) {
                figureViewer.figureBodyClone = $currentFrameBodyChildren.children().detach();
            }
            let figureImages = $('.thumbnail__image img[data-src]');
            if (figureImages.length > 0 && figureImages.hasClass('b-lazy')) {
                figureImages.each(function () {
                    $(this).attr('src', $(this).attr('data-src'));
                    $(this).removeAttr('data-src');
                });
            }
            if (figureViewer.isAxel) {
                figureImages = $('.thumbnail__image img[data-viewer-src]');
                figureImages.each(function () {
                    $(this).attr('src', $(this).attr('data-viewer-src'));
                    $(this).removeAttr('data-viewer-src');
                });
            }
            figureViewer.figureBodyCloneParent = $currentFrameBodyChildren;
            figureViewer.$viewerFrameList
                .addClass('transition')
                .css('left', -(figureViewer.frameWidth * figureViewer.frameIndex));
            figureViewer.$previousFrameButton.css('visibility', figureViewer.frameIndex === 0 ? 'hidden' : 'visible');
            figureViewer.$nextFrameButton.css(
                'visibility',
                figureViewer.frameIndex === figureViewer.frameCount - 1 ? 'hidden' : 'visible'
            );
            figureViewer.$viewer
                .find('div.toolbar > span')
                .text(figureViewer.frameIndex + 1 + '/' + figureViewer.frameCount);
            figureViewer.$viewerCaptionText.empty().append($currentFrameBodyTitle.clone(true, true));
            figureViewer.$viewerCaptionText.append(figureViewer.figureBodyClone);
            figureViewer.$viewerCaptionText.append($currentFrame.find('.figure__source').clone(true, true));
            figureViewer.$viewerCaptionText.append($currentFrame.find('.figure__copyright').clone(true, true));
            figureViewer.cloned = figureViewer.figures
                .eq(figureViewer.frameIndex)
                .find('.figure__caption__body')
                .find('.figure__title')
                .clone(true);
            figureViewer.$viewerThumbstripList
                .find('li')
                .removeClass('current')
                .eq(figureViewer.frameIndex)
                .addClass('current');
            figureViewer.$viewerFrameList
                .find('li')
                .removeClass('current')
                .eq(figureViewer.frameIndex)
                .addClass('current');
            setTimeout(function () {
                figureViewer.$viewerFrameList.removeClass('transition');
            }, 500);
            figureViewer.updateDownloadFigureLink(index);
            let $currentFrameImage = figureViewer.$viewer.find('img:eq(' + figureViewer.frameIndex + ')');
            if (
                $currentFrameImage.data('displayLargeDetail') &&
                $currentFrameImage.attr('src') !== $currentFrameImage.data('highRes')
            ) {
                $currentFrameImage.attr('src', $currentFrameImage.data('highRes'));
            }
            figureViewer.zoom.reset($currentFrameImage);
        },
        getThumbstripOffsets: function () {
            let availableWidth = figureViewer.$viewer.width() - 62,
                thumbnailsPerPage = Math.floor(availableWidth / figureViewer.thumbnailWidth),
                pageCount = Math.ceil(figureViewer.frameCount / thumbnailsPerPage),
                pageWidth = thumbnailsPerPage * figureViewer.thumbnailWidth,
                offsets = [0];

            for (let i = 1; i < pageCount - 1; i++) {
                offsets[i] = i * pageWidth;
            }
            if (pageCount > 1) {
                offsets[pageCount - 1] = figureViewer.thumbnailWidth * figureViewer.frameCount - availableWidth;
            }

            return offsets;
        },
        layoutFrame: function (frame, index) {
            let availableWidth = 0;
            let availableHeight = 0;
            let matteWidth = 0;
            let matteHeight = 0;
            let aspectRatio = 0;
            let height = 0;
            let width = 0;
            let top = 0;
            let imageFiger = figureViewer.figures.eq(index).find('img:not(.inline-image)');
            let frameFigure = frame.find('img:not(.inline-image)');
            let displayLargeDetail = imageFiger.data('displayLargeDetail');
            if (!$('.b-lazy').length) {
                availableWidth = figureViewer.frameWidth - 120;
                availableHeight = figureViewer.frameHeight - 120;
                matteWidth = figureViewer.figures.eq(index).find('img:not(.inline-image)')[0].width + 10;
                matteHeight = figureViewer.figures.eq(index).find('img:not(.inline-image)')[0].height + 10;
                aspectRatio = matteWidth / matteHeight;
                if (aspectRatio > availableWidth / availableHeight) {
                    width = matteWidth < availableWidth && !displayLargeDetail ? matteWidth : availableWidth;
                    height = width / aspectRatio;
                } else {
                    height = matteHeight < availableHeight && !displayLargeDetail ? matteHeight : availableHeight;
                    width = height * aspectRatio;
                }
                top = (figureViewer.frameHeight - height) / 2;

                frame
                    .css({
                        height: figureViewer.frameHeight,
                        left: figureViewer.frameWidth * index,
                        width: figureViewer.frameWidth,
                    })
                    .find('div')
                    .css({height: height, top: top, width: width});
            } else {
                if (figureViewer.firstLazyload && !frameFigure.hasClass('fw-loaded')) {
                    frame.css({
                        height: figureViewer.frameHeight,
                        left: figureViewer.frameWidth * index,
                        width: figureViewer.frameWidth,
                    });
                    frameFigure.on('load', function () {
                        matteWidth = frameFigure.width() + 10;
                        matteHeight = frameFigure.height() + 10;
                        aspectRatio = matteWidth / matteHeight;
                        availableWidth = figureViewer.frameWidth - 120;
                        availableHeight = figureViewer.frameHeight - 120;

                        if (aspectRatio > availableWidth / availableHeight) {
                            width = matteWidth < availableWidth && !displayLargeDetail ? matteWidth : availableWidth;
                            height = width / aspectRatio;
                        } else {
                            height =
                                matteHeight < availableHeight && !displayLargeDetail ? matteHeight : availableHeight;
                            width = height * aspectRatio;
                        }
                        top = (figureViewer.frameHeight - height) / 2;
                        frame
                            .css({
                                height: figureViewer.frameHeight,
                                left: figureViewer.frameWidth * index,
                                width: figureViewer.frameWidth,
                            })
                            .find('div')
                            .css({height: height, top: top, width: width});

                        top = 0;
                        frameFigure.addClass('fw-loaded');
                    });

                    if (figureViewer.allowLazyload) {
                        imageFiger.attr('src', imageFiger.attr('data-src'));
                    } // to replace data-src with src attribute
                } // this condition to check if the user click in Figures on the nav bar "using load() to solve
                // issues load image "
                else {
                    if (frameFigure.hasClass('fw-loaded')) {
                        matteWidth = frameFigure.width() + 10;
                        matteHeight = frameFigure.height() + 10;
                        availableWidth = figureViewer.frameWidth - 120;
                        availableHeight = figureViewer.frameHeight - 120;
                        aspectRatio = matteWidth / matteHeight;

                        if (aspectRatio > availableWidth / availableHeight) {
                            width = matteWidth < availableWidth ? matteWidth : availableWidth;
                            height = width / aspectRatio;
                        } else {
                            height = matteHeight < availableHeight ? matteHeight : availableHeight;
                            width = height * aspectRatio;
                        }
                        top = (figureViewer.frameHeight - height) / 2;

                        frame
                            .css({
                                height: figureViewer.frameHeight,
                                left: figureViewer.frameWidth * index,
                                width: figureViewer.frameWidth,
                            })
                            .find('div')
                            .css({height: height, top: top, width: width});
                    }
                }
            }
        },
        moveNext: function () {
            figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
            figureViewer.move(figureViewer.frameIndex + 1);
            figureViewer.fillLinks(figureViewer.frameIndex);
        },
        movePrevious: function () {
            figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
            figureViewer.move(figureViewer.frameIndex - 1);
            figureViewer.fillLinks(figureViewer.frameIndex);
        },
        openViewer: function () {
            let viewerOpen = false;
            if ($(window).width() <= 414) {
                $('html, body').toggleClass('no-scroll');
            }
            if ($(window).width() >= UX.grid.screenMd) {
                if (figureViewer.$viewer.hasClass('open')) {
                    viewerOpen = true;
                }
                $body.css('overflow', 'hidden');
                $body.addClass('modal-open');
                figureViewer.$viewer.addClass('open');
                figureViewer.allowLazyload = true;
                figureViewer.layoutViewer();

                if (!viewerOpen) {
                    setTimeout(function () {
                        figureViewer.toggleViewerThumbstrip();
                    }, 2000);
                    figureViewer.$viewer.find('a.figure-viewer__close').focus();
                }
            }
        },
        closeViewer: function () {
            figureViewer.figureBodyCloneParent.append(figureViewer.figureBodyClone);
            $('html, body').removeClass('no-scroll');
            $body.css('overflow', 'auto');
            $body.removeClass('modal-open');
            figureViewer.$viewer.removeClass('open').addClass('showThumbstrip');

            if ($clicked_link && !figureViewer.isAxel) {
                setTimeout(function () {
                    $clicked_link.focus();
                    $clicked_link = null;
                }, 250);
            }
        },
        thumbstripMove: function (index) {
            figureViewer.thumbstripOffsetIndex = index;
            figureViewer.$viewerThumbstripList.css(
                'left',
                -figureViewer.thumbstripOffsets[figureViewer.thumbstripOffsetIndex]
            );
            figureViewer.$previousThumbstripButton.css(
                'visibility',
                figureViewer.thumbstripOffsetIndex === 0 ? 'hidden' : 'visible'
            );
            figureViewer.$nextThumbstripButton.css(
                'visibility',
                figureViewer.thumbstripOffsetIndex === figureViewer.thumbstripOffsets.length - 1 ? 'hidden' : 'visible'
            );
        },
        thumbstripMoveNext: function () {
            figureViewer.thumbstripMove(figureViewer.thumbstripOffsetIndex + 1);
        },
        thumbstripMovePrevious: function () {
            figureViewer.thumbstripMove(figureViewer.thumbstripOffsetIndex - 1);
        },
        resize: function () {
            let contentWidth = $('h1').width();

            figureViewer.figures.each(function () {
                let $figure = $(this),
                    availableHeight = $window.height() - 100,
                    standardWidth = $figure.data('std-width'),
                    standardHeight = $figure.data('std-height'),
                    aspectRatio = standardWidth / standardHeight,
                    width = '100%',
                    height = 'auto';
                if ($figure.closest('.figure-viewer').length) {
                    (width = $figure.data('ds-width') * 1.25), (height = width / aspectRatio);
                }

                if ($window.width() >= 992) {
                    availableHeight = availableHeight > 500 ? availableHeight : 500;
                    width = height > availableHeight ? availableHeight * aspectRatio : width;
                }
                width = width > contentWidth ? contentWidth : width;
            });
            figureViewer.layoutViewer();
        },
        toggleViewerCaption: function () {
            let $label = figureViewer.$viewer.find('div.toolbar > a.toggle-caption-button > span.label');
            figureViewer.$viewerCaption.addClass('transition');
            if (figureViewer.$viewer.hasClass('showCaption')) {
                $label.text('Show Caption');
                figureViewer.$viewerFrameList
                    .find('li')
                    .eq(figureViewer.frameIndex)
                    .css('width', figureViewer.$viewer.width() - 75);
                figureViewer.$viewerCaption.css('left', figureViewer.$viewer.width() - 75);
            } else {
                $label.text('Hide Caption');
                figureViewer.$viewerCaption.css('left', figureViewer.$viewer.width() * 0.7);
            }
            figureViewer.$viewer.toggleClass('showCaption');
            setTimeout(function () {
                figureViewer.$viewerCaption.removeClass('transition');
                figureViewer.layoutViewer();
            }, 200);
        },
        toggleViewerThumbstrip: function () {
            let $thumbstripWrapper = $('.thumbstrip__wrapper');
            let $thumbstripButtons = $('.thumbstripButton');

            figureViewer.$viewer.toggleClass('showThumbstrip');

            if (figureViewer.$viewer.hasClass('showThumbstrip')) {
                $thumbstripWrapper.attr('aria-hidden', 'false');
                $thumbstripWrapper.css('display', 'block');
                $thumbstripButtons.css('display', 'block');
            } else {
                $thumbstripWrapper.attr('aria-hidden', 'true');
                $thumbstripWrapper.css('display', 'none');
                $thumbstripButtons.css('display', 'none');
            }

            figureViewer.getLastFocusable();
        },
        updateDownloadFigureLink: function (index) {
            let $downloadThisButton = figureViewer.$download.find('.downloadThisButton');
            let $viewLargeImage = figureViewer.$download.find('.viewLargeImage');
            let $downloadAllButton = figureViewer.$download.find('.downloadAllButton');
            let hieResLocator = figureViewer.$viewer.find('img:eq(' + index + ')').data('high-res');
            let largeLocator = figureViewer.$viewer.find('img:eq(' + index + ')').data('large-image');
            let downLink = figureViewer.figures.eq(index).find('.download-links__download-This').attr('href');
            if (hieResLocator) {
                //.length > 0) {
                $downloadThisButton.prop('href', hieResLocator);
            } else {
                $downloadThisButton.closest('li').remove();
            }

            if (largeLocator) {
                //.length > 0){
                $viewLargeImage.prop('href', largeLocator);
            } else {
                $viewLargeImage.closest('li').remove();
            }
            $downloadAllButton.prop('href', downLink);
        },
        isMobile: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            }
            return false;
        },
        zoom: {
            in: function (fig, x, y, scale) {
                fig.style.transform = 'scale(' + scale + ')';
                fig.style.position = 'relative';
                $(fig).css('transform-origin', x + '% ' + y + '%');
                figureViewer.scaled = true;
            },
            reset: function (fig) {
                $(fig).removeAttr('style');
                figureViewer.scaled = false;
            },
        },
        getLastFocusable: function () {
            $lastFocusable = $('[href]:visible, [tabindex]:not([tabindex="-1"]):visible', figureViewer.$viewer).last();
        },
    };

    UX.figureViewer = figureViewer; // add to global namespace
})();
