(function($) {
    $.fn.pbAjax = function(args) {
        let widgetId = $(this).attr('widget-id');
        if (!widgetId) {
            widgetId = $(this).data('widget-id');
        }
        if (!widgetId) {
            widgetId = $(this).attr('id');
        }
        const pbContext = $('[name="pbContext"]').attr('content');

        if (widgetId !== null && widgetId !== undefined) {
            const data = args['data'] ? args['data'] : {};
            data['pbContext'] = pbContext;
            data['widgetId'] = widgetId;
            const showJson = searchParams(window.location.href);
            if (showJson.hasOwnProperty('showJson')) data['showJson'] = showJson['showJson'] || '';

            const targetUrl = args['url'];
            const requestMethod = args.hasOwnProperty('type') ? args['type'] : 'GET';
            const requestDataType = args.hasOwnProperty('dataType') ? args['dataType'] : 'html';
            const asyncRequest = args.hasOwnProperty('async') ? args['async'] : true;

            const successFunction = args['success'];
            const failFunction = args['error'];

            return $.ajax({
                type: requestMethod,
                dataType: requestDataType,
                async: asyncRequest,
                url: targetUrl,
                data: data,
                success: successFunction,
                error: failFunction
            });
        } else {
            console.log('widgetId not found');
        }

        // We need to use this function because URL().searchParams is not
        // supported in IE and caused all ajax widget's to fail (LIT-284827).
        function searchParams(url) {
            const objURL = {};
            url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function($0, $1, $2, $3) {
                objURL[$1] = $3;
            });
            return objURL;
        }
    };

    const UX3AdobeAnalytics = {
        init: function() {
            UX3AdobeAnalytics.bindEvents();
            UX3AdobeAnalytics.navAnalytics();
            UX3AdobeAnalytics.conversionDriverMap();
            UX3AdobeAnalytics.calculatePageLoadTime();
        },

        downloadFullIssueEventTrack: {
            success: function(data, status, xhr) {
                if (data) {
                    try {
                        let link = data.link.substring(data.link.indexOf('link') - 1, data.link.indexOf(',') + 1);
                        data.link = data.link.replace(link, '');
                        if (typeof pageDataTracker !== 'undefined') {
                            pageDataTracker.trackEvent('downloadMultiplePdf', data);
                            UX.UX3AdobeAnalytics.logMessage(`calling adobe downloadMultiplePdf: ${JSON.stringify(data)}`);
                            pageDataTracker.trackEvent('contentDownload', data);
                            UX3AdobeAnalytics.logMessage(`calling adobe contentDownload with data: ${data.link.substring(data.link.indexOf('content') - 1, data.link.indexOf('}]') + 2)}`);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            error: function(widgetLocation, error) {
                UX.UX3AdobeAnalytics.logMessage(`calling adobe downloadMultiplePdf with error: ${error}`);
                let ctaData = '{ "cta" : [' + '{ "ids":"entitlement-popup" } ]}';
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('ctaimpression', JSON.parse(ctaData));
                    UX3AdobeAnalytics.logMessage('calling adobe ctaimpression with data: ' + ctaData);
                }

                let navigationContent = { location: widgetLocation, name: 'download-full-issue-pdfs' };
                UX3AdobeAnalytics.navigationAnalytics(navigationContent);
            }
        },

        navAnalytics: function() {
            const issue = {
                previous: { location: 'issue-prev-next', name: 'issue-prev' },
                next: { location: 'issue-prev-next', name: 'issue-next' }
            };
            const article = {
                previous: { location: 'article-prev-next', name: 'article-prev' },
                next: { location: 'article-prev-next', name: 'article-next' }
            };
            $('.menu-parent a, .menu-item a').on('click', function() {
                const navigationContent = {
                    location: 'main-nav',
                    name: $(this).text().replace(/ /g, '-').toLowerCase()
                };
                UX3AdobeAnalytics.navigationAnalytics(navigationContent);
            });
            $('.content-navigation__btn--pre').on('click', function() {
                if ($(this).closest('.toc-header').length > 0) {
                    UX3AdobeAnalytics.navigationAnalytics(issue.previous);
                } else {
                    UX3AdobeAnalytics.navigationAnalytics(article.previous);
                }
            });
            $('.content-navigation__btn--next').on('click', function() {
                if ($(this).closest('.toc-header').length > 0) {
                    UX3AdobeAnalytics.navigationAnalytics(issue.next);
                } else {
                    UX3AdobeAnalytics.navigationAnalytics(article.next);
                }
            });
            $('.literatumIssueInfo .prevIssue').on('click', function() {
                UX3AdobeAnalytics.navigationAnalytics(issue.previous);
            });
            $('.literatumIssueInfo .nextIssue').on('click', function() {
                UX3AdobeAnalytics.navigationAnalytics(issue.next);
            });
        },

        conversionDriverMap: function() {
            //Bind Conversion Driver Event To Track in Adobe Site Catalyst
            $.getJSON('/pb-assets/adobe/conversionDriverMap', function(result) {
                $.each(result, function(key, val) {
                    $(document).on('click', val.selector, function(e) {
                        try {
                            UX3AdobeAnalytics.conversionDriverClickHandler(val.value);
                        } catch (error) {
                            console.error(error);
                        }
                    });
                });
            });
        },

        calculatePageLoadTime: function() {
            const dateNow = Date.now();
            const loadTracker = localStorage.getItem('loadTracker');

            if (loadTracker) {
                pageData.page.loadTime = dateNow - loadTracker;
                pageDataTracker.trackEvent('newPage', pageData);
                localStorage.removeItem('loadTracker');
            }
        },

        PurchaseOfferHandler: e => {
            let navigationContentName;
            switch (e.target.className) {
                case 'article-tools__purchase--firstPurchaseOffer':
                    navigationContentName = 'purchase-pdf-cc-button-academic';
                    break;
                case 'article-tools__purchase--secondPurchaseOffer':
                    navigationContentName = 'purchase-pdf-cc-button-corporate';
                    break;
            }
            let navigationContent = { location: 'jb', name: navigationContentName };
            UX3AdobeAnalytics.navigationAnalytics(navigationContent);
        },

        menuLinkClickHandler: e => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('widgetClick', {
                        link: { 'widgetName': 'new-nav' }
                    });
                    UX3AdobeAnalytics.logMessage('widgetClick triggered successfully' + '[ widgetName: new-nav ]');
                }
            } catch (error) {
                console.error(error);
            }

            const buttonTypeData = e.currentTarget.textContent.replace(/\s+/g, '-').toLowerCase();
            if (e.currentTarget.classList.contains('mega-menu__item-link')) {
                pageDataTracker.trackEvent('buttonClick', {
                    buttonType: `jb:navbar-menu:jb:${buttonTypeData}`
                });
            } else {
                const parentWrapper = e.currentTarget.closest('.mega-menu__item-wrapper');
                const parentButton = parentWrapper.previousSibling;
                const parentButtonTypeData = parentButton.textContent.replace(/\s+/g, '-').toLowerCase();
                pageDataTracker.trackEvent('buttonClick', {
                    buttonType: `jb:navbar-menu-sub:jb:${parentButtonTypeData}:${buttonTypeData}`
                });
            }
        },

        bindEvents: function() {
            $('.article-tools__pdf a, a.pdfLink, a.pdf-link, .article-tools__item__pdf').click(function() {
                const href = $(this).attr('href');
                if (href && href !== '#') {
                    let pii = '';
                    const ArticleWithPii = $(this).closest('[data-pii]');
                    if (ArticleWithPii.length) pii = ArticleWithPii.attr('data-pii');

                    const pageName = $(this).attr('href');
                    const $widget = $(this).closest('[data-widget-name]');
                    $widget.pbAjax({
                        type: 'POST',
                        url: '/pb/widgets/adobeAnalytics/pdfClick',
                        async: 'false',
                        data: { pii: pii, pageName: pageName },
                        error: function(jqXHR, textStatus) {
                            console.error(`Request failed: ${textStatus}`);
                        },
                        success: function(data) {
                            if (data) {
                                const contentData = JSON.parse(data);
                                if (contentData.content && contentData.content.length) {
                                    contentData.content.map(item => {
                                        const analyticsObj = {
                                            accessType: item.accessType,
                                            format: item.format,
                                            id: item.id,
                                            type: item.type,
                                            title: item.title
                                        };
                                        UX3AdobeAnalytics.contentDownloadHandler(analyticsObj);
                                    });
                                }
                            }
                        }
                    });
                }
            });

            $('.upsell-box__action--firstPurchaseOffer').click(function() {
                let navigationContent = { location: 'jb', name: 'purchase-pdf-cc-button-academic' };
                UX3AdobeAnalytics.navigationAnalytics(navigationContent);
            });

            $('.upsell-box__action--secondPurchaseOffer').click(function() {
                let navigationContent = { location: 'jb', name: 'purchase-pdf-cc-button-corporate' };
                UX3AdobeAnalytics.navigationAnalytics(navigationContent);
            });

            $('.continue').click(function() {
                try {
                    const url = window.location.href;
                    const errorMsg = document.querySelector('.registration__field--error');
                    if (!errorMsg) {
                        const step = url.substring(url.indexOf('step'));
                        const stepStr = step.substr(step.indexOf(step.length - 1));
                        const stepNum = parseInt(stepStr) - 1;
                        UX3AdobeAnalytics.conversionDriverClickHandler(`masthead:register-step-${stepNum}:click`);
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.completeReg, .register').click(function() {
                try {
                    let url = window.location.href.replace(/%3D/g, '=').replace(/%2B/g, '+');
                    let ctaData = '';
                    if (url.indexOf('menuTab=Alerts') !== -1 || url.indexOf('doUpdateAlertSettings') !== -1) {
                        ctaData = '{ "cta" : [' + '{ "ids":"jb:journal:journal-alert" } ]}';
                    }
                    if (
                        url.indexOf('checkOut=Proceed+to+Checkout') !== -1 ||
                        url.indexOf('checkEcommFlow=ecommFlow') !== -1 ||
                        url.indexOf('ecommerce?checkOut') !== -1
                    ) {
                        ctaData = '{ "cta" : [' + '{ "ids":"jb:Subscription-Flow" } ]}';
                    }
                    if (url.indexOf('doSearch') !== -1) {
                        ctaData = '{ "cta" : [' + '{ "ids":"jb:Save-Search-Flow" } ]}';
                    }
                    if (url.indexOf('addFavoritePublication') !== -1) {
                        ctaData = '{ "cta" : [' + '{ "ids":"jb:Adding-Article-To-Reading-List" } ]}';
                    }
                    if (url.indexOf('ckCmeExam') !== -1) {
                        ctaData = '{ "cta" : [' + '{ "ids":"jb:CME-Exam-flow" } ]}';
                    }
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('ctaClick', JSON.parse(ctaData));
                        UX3AdobeAnalytics.logMessage('calling adobe Cta Click with data: ' + ctaData);
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('#searchSaveAction .searchInput').click(function() {
                try {
                    const url = window.location.href;
                    const frequency = $(document).find('.modal-dialog #searchAlert option:selected').text();
                    const arr = url.split('&');
                    let journalCode = '';
                    let websiteCode = '';
                    let ISSN = '';
                    let journalTitle = '';
                    let saveAlertData = '';

                    for (i = 0; i < arr.length; i++) {
                        if (arr[i].indexOf('journalCode') !== -1) {
                            journalCode = arr[i].substring(arr[i].indexOf('=') + 1);
                            journalTitle = $(document).find('.citation .journalTitleSp').first().text();
                        } else if (arr[i].indexOf('code') !== -1) {
                            websiteCode = arr[i].substring(arr[i].indexOf('=') + 1);
                        }
                        if (arr[i].indexOf('ISSN') !== -1) {
                            ISSN = arr[i].substring(arr[i].indexOf('=') + 1);
                        }
                    }
                    if (journalCode.length === 0 && journalTitle.length === 0 && ISSN.length === 0) {
                        saveAlertData =
                            '{ "alert" : {' +
                            '"frequency": ' +
                            '"' +
                            frequency +
                            '"' +
                            ',' +
                            '"type": "journal issue alert"' +
                            '},' +
                            '"content"' +
                            ': [{' +
                            '"format": "MIME-XHTML",' +
                            '"type": "jb:journal:scope-full"}]}';
                    } else {
                        saveAlertData =
                            '{ "alert" : {' +
                            '"frequency": ' +
                            '"' +
                            frequency +
                            '"' +
                            ',' +
                            '"type": "journal issue alert"' +
                            '},' +
                            '"content"' +
                            ': [{' +
                            '"format": "MIME-XHTML",' +
                            '"id": "jb:journal:issn:' +
                            ISSN +
                            '",' +
                            '"type": "jb:journal:scope-full",' +
                            '"name": "' +
                            journalTitle +
                            '"' +
                            '}]}';
                    }
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('saveAlertStart', JSON.parse(saveAlertData));
                        UX3AdobeAnalytics.logMessage('calling adobe save Alert Click with data: ' + saveAlertData);
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('#newSearchSaveButton').click(function() {
                try {
                    const url = window.location.href;
                    const frequency = $(document).find('.alert-times input:checked').next().text();
                    const arr = url.split('&');
                    let journalCode = '';
                    let websiteCode = '';
                    let ISSN = '';
                    let journalTitle = '';
                    let saveAlertData = '';

                    for (i = 0; i < arr.length; i++) {
                        if (arr[i].indexOf('journalCode') !== -1) {
                            journalCode = arr[i].substring(arr[i].indexOf('=') + 1);
                            journalTitle = $(document).find('.citation .journalTitleSp').first().text();
                        } else if (arr[i].indexOf('code') !== -1) {
                            websiteCode = arr[i].substring(arr[i].indexOf('=') + 1);
                        }
                        if (arr[i].indexOf('ISSN') !== -1) {
                            ISSN = arr[i].substring(arr[i].indexOf('=') + 1);
                        }
                    }
                    if (journalCode.length === 0 && journalTitle.length === 0 && ISSN.length === 0) {
                        saveAlertData =
                            '{ "alert" : {' +
                            '"frequency": ' +
                            '"' +
                            frequency +
                            '"' +
                            ',' +
                            '"type": "journal issue alert"' +
                            '},' +
                            '"content"' +
                            ': [{' +
                            '"format": "MIME-XHTML",' +
                            '"type": "jb:journal:scope-full"}]}';
                    } else if (journalTitle.length !== 0) {
                        saveAlertData =
                            '{ "alert" : {' +
                            '"frequency": ' +
                            '"' +
                            frequency +
                            '"' +
                            ',' +
                            '"type": "journal issue alert"' +
                            '},' +
                            '"content"' +
                            ': [{' +
                            '"format": "MIME-XHTML",' +
                            '"id": "jb:journal:issn:' +
                            ISSN +
                            '",' +
                            '"type": "jb:journal:scope-full",' +
                            '"name": "' +
                            journalTitle +
                            '"' +
                            '}]}';
                    } else {
                        saveAlertData =
                            '{ "alert" : {' +
                            '"frequency": ' +
                            '"' +
                            frequency +
                            '"' +
                            ',' +
                            '"type": "journal issue alert"' +
                            '},' +
                            '"content"' +
                            ': [{' +
                            '"format": "MIME-XHTML",' +
                            '"id": "jb:journal:issn:' +
                            ISSN +
                            '",' +
                            '"type": "jb:journal:scope-full" }]}';
                    }
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('saveAlertStart', JSON.parse(saveAlertData));
                        UX3AdobeAnalytics.logMessage('calling adobe save Alert Click with data: ' + saveAlertData);
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.facet__title, .facet__label, .facet__counter').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent(
                            'widgetClick',
                            {
                                link: { widgetName: 'search-facets' }
                            },
                            pageData
                        );
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: search-facets ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.article-title-ux3-promotional-list, .promotional-list__image').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('widgetClick', {
                            link: { widgetName: 'Features' }
                        });
                        UX3AdobeAnalytics.logMessage('widgetClick triggered successfully' + '[ widgetName: Features ]');
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $(
                '.full-link-publication-list, #pdf-link-publication-list, .article-title-ux3-publication-list, .supplementaryMaterial-publication-list, .export-citation-publication-list'
            ).click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('widgetClick', {
                            link: { widgetName: 'ux3-publication-list' }
                        });
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: ux3-publication-list ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.mostCited-articleTitle, .mostCitedArticles-scopus, #pdf-mostCitedArticles-scopus').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('widgetClick', {
                            link: { widgetName: 'Most Cited Articles' }
                        });
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: Most Cited Articles ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.search__item .multi-search-links a, .full-title-multi-search, .scopus, .article_abstract_image').click(
                function() {
                    try {
                        if (typeof pageDataTracker !== 'undefined') {
                            pageDataTracker.trackEvent('widgetClick', {
                                link: { widgetName: 'UX3MultiSearchWidget' }
                            });
                            UX3AdobeAnalytics.logMessage(
                                'widgetClick triggered successfully' + '[ widgetName: UX3MultiSearchWidget ]'
                            );
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            );

            $('.collection-alert-link').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent('widgetClick', {
                            link: { widgetName: 'ux3-collection-alert-link' }
                        });
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: ux3-collection-alert-link ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('[data-target=\'#saveSearchDialog\']').click(function() {
                try {
                    UX3AdobeAnalytics.conversionDriverClickHandler('search:save-and-receive-alerts-for-this-search:click');
                } catch (error) {
                    console.error(error);
                }
            });

            $('.quick-search__advanced-link').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent(
                            'widgetClick',
                            {
                                link: { widgetName: 'AtyponUX3QuickSearchWidget' }
                            },
                            pageData
                        );
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: AtyponUX3QuickSearchWidget ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.cover-image__meta__subscribe , .cover-image__thumb').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent(
                            'widgetClick',
                            {
                                link: { widgetName: 'current-cover' }
                            },
                            pageData
                        );
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: current-cover ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('.current-issue__link').click(function() {
                try {
                    if (typeof pageDataTracker !== 'undefined') {
                        pageDataTracker.trackEvent(
                            'widgetClick',
                            {
                                link: { widgetName: 'current-issue-info' }
                            },
                            pageData
                        );
                        UX3AdobeAnalytics.logMessage(
                            'widgetClick triggered successfully' + '[ widgetName: current-issue-info ]'
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            });

            $('#ecommerceForm').submit(function() {
                const offerData = $(this).find('.cmeOfferLink').data();
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $('.ppvOfferLink').click(function() {
                const price = $('[name=\'offerPrice\']');
                const quantity = $('[name=\'offerQuantiy\']');
                const pii = $('[name=\'offerItemId\']');
                const offerData = { price: price.val(), id: pii.val(), quantity: quantity.val() };
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $('.cellPpvOffers a').click(function() {
                const upsellBox = $('.upsellBox');
                const price = upsellBox.attr('data-price');
                const quantity = upsellBox.attr('data-quantity');
                const pii = $('article').attr('data-pii').replace(/[()-]/g, '');
                const offerData = { price: price, id: 'pii:' + pii, quantity: quantity };
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $('.upsell-box__action--firstPurchaseOffer, .article-tools__purchase--firstPurchaseOffer').click(
                function() {
                    const upsellBox = $('.upsellBox');
                    const price = upsellBox.attr('data-price1');
                    const quantity = upsellBox.attr('data-quantity1');
                    const pii = $('article').attr('data-pii').replace(/[()-]/g, '');
                    const offerData = { price: price, id: 'pii:' + pii, quantity: quantity };
                    UX3AdobeAnalytics.cartAddEventHandler(offerData);
                }
            );

            $('.upsell-box__action--secondPurchaseOffer, .article-tools__purchase--secondPurchaseOffer').click(
                function() {
                    const upsellBox = $('.upsellBox');
                    const price = upsellBox.attr('data-price2');
                    const quantity = upsellBox.attr('data-quantity2');
                    const pii = $('article').attr('data-pii').replace(/[()-]/g, '');
                    const offerData = { price: price, id: 'pii:' + pii, quantity: quantity };
                    UX3AdobeAnalytics.cartAddEventHandler(offerData);
                }
            );

            $('.upsell-box__allowance').click(function() {
                const price = $(this).attr('data-price');
                const quantity = $('.upsellBox').attr('data-quantity');
                const offerData = { price: price, id: 'allowances:5articles', quantity: quantity };
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $('.allowanceOfferLink').click(function() {
                const offerData = $(this).data();
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $(document).on('click', '[name=\'addToCart\']', function() {
                const offerData = $(this).data();
                UX3AdobeAnalytics.cartAddEventHandler(offerData);
            });

            $('.removeOffer, .eshopping-cart_item-remove').click(function() {
                const dataAttributes = $(this).data();
                UX3AdobeAnalytics.cartRemove(dataAttributes);
            });

            const firstPurchaseOffer = document.querySelector('.article-tools__purchase--firstPurchaseOffer');
            const secondPurchaseOffer = document.querySelector('.article-tools__purchase--secondPurchaseOffer');

            firstPurchaseOffer && firstPurchaseOffer.addEventListener('click', UX3AdobeAnalytics.PurchaseOfferHandler);
            secondPurchaseOffer && secondPurchaseOffer.addEventListener('click', UX3AdobeAnalytics.PurchaseOfferHandler);

            const megaMenuNav = document.querySelector('.mega-menu-nav');
            if (megaMenuNav) {
                const menuLinks = megaMenuNav.querySelectorAll('.menu-link');
                menuLinks.forEach(link => link.addEventListener('click', UX3AdobeAnalytics.menuLinkClickHandler));
            }

            document.addEventListener('click', e => {
                const { target } = e;

                if (
                    target.tagName === 'A' &&
                    (
                        target.closest('#article_sections_menu') ||
                        target.closest('#article_sections_menu-desktop')
                    )
                ) {
                    const buttonTypeData = target.textContent.replace(/\s+/g, '-').toLowerCase();
                    UX3AdobeAnalytics.widgetClickHandler('Article Content Axel');
                    UX3AdobeAnalytics.buttonClickHandler(`jb:[${buttonTypeData}]`);
                }

                if (
                    target.dataset.id === 'article-toolbar-showhide' ||
                    target.closest('[data-id="article-toolbar-showhide"]')
                ) {
                    const denialBlock = document.querySelector('.denial-block');
                    const buttonType = `jb:showoutline:jb:openoutline-${denialBlock ? 'paywalled' : 'html'}`;
                    UX3AdobeAnalytics.widgetClickHandler('Article Content Axel');
                    UX3AdobeAnalytics.buttonClickHandler(buttonType);
                }

                if (
                    target.matches('.jifa a, .editorial-board a') ||
                    target.closest('.jifa a') ||
                    target.closest('.editorial-board a')
                ) {
                    const widgetName = target.closest('.jifa') ? 'Jifa' : 'Editorial Board';
                    UX3AdobeAnalytics.widgetClickHandler(widgetName);
                }
            });

            const contentNavigationButtons =
                document.querySelectorAll('.content-navigation__prev, .content-navigation__next');
            contentNavigationButtons.forEach(item => item.addEventListener('click', e => {
                const navigationClickData = {
                    location: 'Article Content Axel',
                    name: `article-${e.currentTarget.classList.contains('content-navigation__prev') ? 'prev' : 'next'}`
                };
                UX3AdobeAnalytics.widgetClickHandler('Article Content Axel');
                UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
            }));

            const reprintsButton = document.querySelector('.article-tools__item__reprints');
            if (reprintsButton) {
                reprintsButton.addEventListener('click', () => {
                    const navigationClickData = {
                        location: 'article-tools',
                        name: 'reprints'
                    };
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                });
            }

            const getRightsButton = document.querySelector('.article-tools__item__request');
            if (getRightsButton) {
                getRightsButton.addEventListener('click', () => {
                    const navigationClickData = {
                        location: 'article-tools',
                        name: 'getrights'
                    };
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                });
            }

            const axelRelatedArticlesSection = document.querySelector('section.axel-related-articles');
            if (axelRelatedArticlesSection) {
                axelRelatedArticlesSection.addEventListener('click', e => {
                    const { target } = e;
                    if (
                        target.classList.contains('linked-article__title') &&
                        target.closest('.axel-related-articles__items')
                    ) {
                        UX3AdobeAnalytics.widgetClickHandler('related-content');
                        const navigationClickData = {
                            location: 'jb',
                            name: 'relatedcontent'
                        };
                        UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                    } else if (
                        target.classList.contains('toggle-extra-items') ||
                        target.closest('.toggle-extra-items')
                    ) {
                        const toggleExtraItems = axelRelatedArticlesSection.querySelector('.toggle-extra-items');
                        if (toggleExtraItems.classList.contains('js--expanded')) return;
                        UX3AdobeAnalytics.widgetClickHandler('Article content Axel');
                        UX3AdobeAnalytics.buttonClickHandler('jb:relatedcontent:jb:relatedcontent-expand');
                    }
                });
            }

            const accessDenialLogo = document.querySelector('.denial-block a');
            if (accessDenialLogo) {
                accessDenialLogo.addEventListener('click', () => {
                    UX3AdobeAnalytics.widgetClickHandler('upsell-box');
                    const navigationClickData = {
                        location: 'jb',
                        name: 'journal-logo'
                    };
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                });
            }

            const getAccessButtons =
                document.querySelectorAll('.article-tools__item__purchase, .denial-block__upsell button');
            getAccessButtons.forEach(item => item.addEventListener('click', e => {
                const { currentTarget: target } = e;
                const widgetName = target.closest('.denial-block__upsell') ? 'upsell-box' : 'article-tools';
                UX3AdobeAnalytics.widgetClickHandler(widgetName);
                UX3AdobeAnalytics.buttonClickHandler('jb:upsell-popup:jb:getacces');
            }));

            const loginTabButton = document.querySelector('.get-access--tabs-list [data-type="login"]');
            if (loginTabButton) {
                loginTabButton.addEventListener('click', () => {
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.buttonClickHandler('jb:upsell-popup:jb:login');
                });
            }

            const viewPurchaseLink = document.querySelector('.view-purchase__link');
            if (viewPurchaseLink) {
                viewPurchaseLink.addEventListener('click', () => {
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.buttonClickHandler('jb:upsell-popup:jb:view-options-purchase');
                });
            }

            const purchaseTabButton = document.querySelector('.get-access--tabs-list [data-type="purchase"]');
            if (purchaseTabButton) {
                purchaseTabButton.addEventListener('click', () => {
                    UX3AdobeAnalytics.buttonClickHandler('jb:upsell-popup:jb:purchase');
                    UX3AdobeAnalytics.conversionDriverClickHandler('upsell-popup:purchase-this-article:click');
                    UX3AdobeAnalytics.ctaClickHandler('upsell-popup:purchase-pdf');
                });
            }

            const purchaseItemButtons = document.querySelectorAll('.purchase-item__btn');
            purchaseItemButtons.forEach(item => {
                if (item.classList.contains('lancetChoice__btn')) return;
                item.addEventListener('click', e => {
                    const { target } = e;
                    const { pii: id, price, quantity } = target.dataset;
                    const location = target.textContent.trim().replace(' access', '').toLowerCase();
                    const navigationClickData = {
                        location: 'jb',
                        name: `purchase-pdf-cc-button-${location}`
                    };
                    const cartData = { id, price, quantity };
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.cartAddEventHandler(cartData);
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                    UX3AdobeAnalytics.conversionDriverClickHandler('upsell-popup:purchase-this-article:click');
                });
            });

            const lancetChoiceButton = document.querySelector('.lancetChoice__btn');
            if (lancetChoiceButton) {
                lancetChoiceButton.addEventListener('click', () => {
                    const navigationClickData = {
                        location: 'jb',
                        name: 'purchase-pdf-cc-button-lancetchoice'
                    };
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                });
            }

            const seePurchaseOptions = document.querySelector('.purchase-item__purchase-options');
            if (seePurchaseOptions) {
                seePurchaseOptions.addEventListener('click', () => {
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.buttonClickHandler('b:upsell-popup:jb:purchase-options');
                });
            }

            const subscribeTabButton = document.querySelector('.get-access--tabs-list [data-type="subscribe"]');
            if (subscribeTabButton) {
                subscribeTabButton.addEventListener('click', () => {
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.buttonClickHandler('jb:upsell-popup:jb:subscribe');
                });
            }

            const viewSubscriptionLink = document.querySelector('.view-subscription__link');
            if (viewSubscriptionLink) {
                viewSubscriptionLink.addEventListener('click', (e) => {
                    const navigationClickData = {
                        location: 'jb',
                        name: 'subscription-options'
                    };
                    UX3AdobeAnalytics.widgetClickHandler('upsell-popup');
                    UX3AdobeAnalytics.navigationClickHandler(navigationClickData);
                    UX3AdobeAnalytics.conversionDriverClickHandler('upsell-popup:purchase-a-subscription:click');
                });
            }

            const axelLoginForm = document.querySelector('.get-access .login-content form');
            if (axelLoginForm) {
                axelLoginForm.addEventListener('submit', () => UX3AdobeAnalytics.loginStartHandler());
            }
        },

        loginStartHandler: () => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('loginStart');
                    UX3AdobeAnalytics.logMessage(`loginStart triggered successfully`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        ctaClickHandler: name => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const data = {
                        cta: {
                            ids: [name]
                        }
                    };
                    pageDataTracker.trackEvent('conversionDriverClick', data);

                    UX3AdobeAnalytics.logMessage(`calling adobe navigation action: ${JSON.stringify(data)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        conversionDriverClickHandler: name => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const conversionDriver = {
                        conversionDriver: {
                            name: name
                        }
                    };
                    pageDataTracker.trackEvent('conversionDriverClick', conversionDriver);

                    UX3AdobeAnalytics.logMessage(`calling adobe navigation action: ${JSON.stringify(conversionDriver)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        navigationClickHandler: obj => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const data = {
                        link: {
                            location: obj['location'],
                            name: obj['name']
                        }
                    };
                    pageDataTracker.trackEvent('navigationClick', data);
                    UX3AdobeAnalytics.logMessage(`calling adobe navigation action: ${JSON.stringify(data)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        widgetClickHandler: widgetName => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const data = {
                        link: {
                            widgetName: widgetName
                        }
                    };
                    pageDataTracker.trackEvent('widgetClick', data);
                    UX3AdobeAnalytics.logMessage(`widgetClick triggered successfully [ widgetName: ${widgetName} ]`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        buttonClickHandler: buttonType => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const data = {
                        buttonType: buttonType
                    };
                    pageDataTracker.trackEvent('buttonClick', data);
                    UX3AdobeAnalytics.logMessage(`calling adobe button click action: ${JSON.stringify(data)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        cartAddEventHandler: offerData => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('cartAdd', {
                        content: [
                            {
                                id: offerData['id'],
                                price: offerData['price'],
                                quantity: offerData['quantity']
                            }
                        ]
                    });
                    UX3AdobeAnalytics.logMessage(`calling adobe cart add with data: ${JSON.stringify(offerData)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        contentDownloadHandler: obj => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('contentDownload', obj);
                    UX3AdobeAnalytics.logMessage(`calling adobe content download with data: ${JSON.stringify(obj)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        downloadMultiplePdfHandler: obj => {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('downloadMultiplePdf', obj);
                    UX3AdobeAnalytics.logMessage(`calling adobe download multiple pdf with data: ${JSON.stringify(obj)}`);
                }
            } catch (error) {
                console.error(error);
            }
        },

        cartRemove: function(offerData) {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('cartRemove', {
                        content: [
                            {
                                id: offerData['id'],
                                price: offerData['price'],
                                quantity: offerData['quantity']
                            }
                        ]
                    });
                    UX3AdobeAnalytics.logMessage('calling adobe cartRemove action:' + JSON.stringify(offerData));
                }
            } catch (error) {
                console.error(error);
            }
        },

        navigationAnalytics: function(navigationContent) {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    const clickTime = Date.now().toString();
                    localStorage.setItem('loadTracker', clickTime);
                    pageDataTracker.trackEvent('navigationClick', {
                        link: { location: navigationContent['location'], name: navigationContent['name'] }
                    });
                    UX3AdobeAnalytics.logMessage(
                        'calling adobe navigation action: ' + JSON.stringify(navigationContent)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        logMessage: function(message) {
            if (window.console && window.console.log) {
                console.log(message);
            }
        }
    };

    $(document).ready(UX3AdobeAnalytics.init);

    /* populating page variable with widget names
     *  Case1 A: within a tab widgetNames have the following format [widgetName:title1:title2]
     *  Case1 B: within the map of case one widgetNames have the following format [widgetName:title1]
     *  Case2  : within the map of case two widgetNames have the following format [widgetName]
     * */
    $(document).on('click', '[data-widget-name] a:not([href^="#"]), .toc-header a:not([href="#"])', function() {
        const items =
            '.menu-parent, .menu-item, .content-navigation__btn--prev, .content-navigation__btn--next, .literatumIssueInfo .prevIssue, .literatumIssueInfo .nextIssue';
        const widgetNamesMapCaseOne = {
            UX3HTMLWidget: 'html',
            'general-html-asset': 'html-asset',
            'ux3-general-image': 'image',
            'general-rich-text': 'rich-text',
            slideshowWidget: 'image-slideshow',
            UX3SlideShow: 'image-slideshow',
            genericSlideshow: 'generic-slideshow',
            'layout-tabs': 'tabs',
            'layout-carousel-dropzones': 'carousel-with-drop-zones',
            UX3CoverImage: 'issue-cover',
            latestIssueCoverImage: 'current-cover',
            newsStoryDisplay: 'news-story-display',
            mostRead: 'most-read-articles',
            mostRecent: 'most-recent-articles',
            storyGrid: 'story-grid',
            UX3StoryCardStack: 'story-card-stack',
            UX3storyTeaserView: 'story-teaser-view'
        };
        const widgetNamesMapCaseTwo = {
            pictureQuizStory: 'picture-quiz',
            issueCoverCarousel: 'issue-cover-carousel',
            lancetCollectionBreadcrumb: 'lancet-collection-breadcrumb',
            multipleAbstracts: 'multiple-abstracts',
            literatumNewIssueAlert: 'new-content-alert',
            ExportCitation: 'export-citations',
            EmailAColleague: 'email-a-colleague',
            CollectionAlertLink: 'collection-alert-link',
            CurrentIssueInfo: 'current-issue-info',
            companionJournals: 'companion-journals',
            journalHistory: 'journal-history',
            theIHubLink: 'the-ihub-link',
            UX3ArticleToolsWidget: 'article-tools',
            alsoReadList: 'also-read-list',
            articleListWidget: 'article-list',
            articleCommentWidget: 'article-comments-widget',
            UX3RelatedArticlesWidget: 'related-article-list',
            issueHighlights: 'issue-highlights',
            UX3linkedContentList: 'linked-content-list',
            promis: 'promis-journal-metadata',
            findByCitation: 'find-by-citation',
            searchHistory: 'search-history',
            UX3QuickSearchWidget: 'quick-search',
            withinIssue: 'search-within-issue',
            collectionSearch: 'search-in-collection',
            searchFacets: 'search-facets',
            journalsAlerts: 'journals-alerts',
            collectionsAlerts: 'collections-alerts',
            citationAlerts: 'citation-alerts',
            readingList: 'reading-list',
            virtualMicroscope: 'virtual-microscope',
            productPublications: 'product-publications',
            StoryEditor: 'story-editor',
            StoryManager: 'story-manager'
        };
        if ($(this).parents(items).length < 1) {
            let titleH2 = '';
            let titleH3 = '';
            const currentLink = $(this);
            let currentWidget = currentLink.closest('[data-widget-name]');
            const currentWidgetName = currentWidget.attr('data-widget-name');

            if (currentWidgetName !== undefined) {
                if (
                    currentWidgetName === 'slideshowWidget' ||
                    currentWidgetName === 'UX3SlideShow' ||
                    currentWidgetName === 'genericSlideshow'
                ) {
                    currentWidget = currentLink.closest('.slide');
                }

                if (
                    widgetNamesMapCaseOne[currentWidgetName] !== undefined &&
                    widgetNamesMapCaseOne[currentWidgetName] !== widgetNamesMapCaseOne['layout-tabs']
                ) {
                    titleH2 = checkTitle(currentWidget, 2);
                    titleH3 = checkTitle(currentWidget, 3);
                    tracker(widgetNamesMapCaseOne[currentWidgetName], titleH2, titleH3);
                } else if (widgetNamesMapCaseTwo[currentWidgetName] !== undefined) {
                    tracker(widgetNamesMapCaseTwo[currentWidgetName], '', '');
                } else if (currentWidgetName === 'UX3Toc') {
                    currentWidget = currentLink.closest('.widget');
                    if (currentLink.closest('.widget').attr('class') !== undefined) {
                        const currentWidgetClasses = currentLink.closest('.widget').attr('class').split(' ');
                        titleH2 = checkTitle(currentWidget, 2);
                        titleH3 = checkTitle(currentWidget, 3);
                        tracker(widgetNamesMapCaseOne[currentWidgetClasses[1]], titleH2, titleH3);
                    }
                }
            }
        }

        function tracker(widgetName, titleH2, titleH3) {
            try {
                if (typeof pageDataTracker !== 'undefined') {
                    pageDataTracker.trackEvent('widgetClick', {
                        link: { widgetName: widgetName + titleH2 + titleH3 }
                    });
                    UX3AdobeAnalytics.logMessage(
                        'widgetClick triggered successfully' + '[ widgetName: ' + widgetName + titleH2 + titleH3 + ']'
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }

        function checkTitle(currentNode, position) {
            const parentNode = $(currentNode).closest('.layout-tabs');
            let title;
            if (parentNode.length > 0) currentNode = parentNode;
            if (position === 2) title = $(currentNode).find('h2:first').text().toLowerCase().trim().replace(/ /g, '-');
            else {
                title = $(currentNode).find('h3:first').text().toLowerCase().trim().replace(/ /g, '-');
                if (title === '') title = $(currentNode).find('h3:eq(1)').text().toLowerCase().trim().replace(/ /g, '-');
            }
            if (title !== '') {
                title = ':' + title;
            } else {
                if (position === 2)
                    title = $(currentNode).closest('h2:first').text().toLowerCase().trim().replace(/ /g, '-');
                else title = $(currentNode).closest('h3:first').text().toLowerCase().trim().replace(/ /g, '-');
                if (title === '') title = '';
                else title = ':' + title;
            }
            return title;
        }
    });

    UX.UX3AdobeAnalytics = UX3AdobeAnalytics;
})(jQuery);
