(function () {
    let complexTable = {
        myTables: '',
        $summary: null,
        $summaryList: null,
        $summaryItem: null,
        $summaryLink: null,
        isStickySupported: null,
        init: function () {
            if ($('.complexTable').length) {
                $('table').each(function () {
                    $(this).wrap("<div class='scroallbe-table'></div>");
                    let complex = $(this).closest('.scroallbe-table');
                    let table = $(this);
                    $(this).parent().css({
                        'max-height': '600px',
                        overflow: 'auto',
                    });
                    if (!$(this).closest('.accordion--textbox').length) {
                        complexTable.scrolling.init(this);
                        let temp = 1;
                        let ignore = false;
                        $('tr:first-child th:first-child').addClass('left--stuck js--stuck-top-h');
                        $(this)
                            .find('tr')
                            .each(function (i) {
                                let thisIndex = i + 1;
                                let pass = 1;
                                let that = complex.find('tr:nth-child(' + thisIndex + ') td:first-child');
                                if (!ignore && that != null) {
                                    if ($(that).attr('rowspan')) {
                                        temp = thisIndex + Number($(that).attr('rowspan')) - 1;
                                        pass = thisIndex;
                                        ignore = true;
                                    }
                                    if (thisIndex >= temp || thisIndex === pass) {
                                        that.addClass('left--stuck');
                                        that.addClass('js--stuck-left');
                                    }
                                }
                                if (thisIndex >= temp) {
                                    temp = thisIndex;
                                    ignore = false;
                                }
                            });
                        if (!complexTable.isStickySupported) {
                            complex.on('scroll', function (event) {
                                complexTable.supportScrolling(table);
                            }); // this code to check if the browser doesn't support sticky , if not scroll event will
                            // start
                        }
                    }
                });
            }
        },
        list: function () {
            if ($('article table').length) {
                complexTable.$summary = $(
                    '<section class="table-list"><h2 id="tables" class="top">Tables</h2></section>'
                );
                complexTable.$summaryList = $('<ul class="tabels-list__wrapper rlist"></ul>').appendTo(
                    complexTable.$summary
                );
                $('table').each(function () {
                    if (
                        !$(this).closest('.inline-table').find('.inline-table__head.no-label').length &&
                        $(this).closest('.article__sections').length
                    ) {
                        complexTable.$summaryItem = $('<li class="table-list__item"></li>').appendTo(
                            complexTable.$summaryList
                        );
                        complexTable.$summaryLink = $(
                            `<a target="_blank" href="${$(this)
                                .closest('.inline-table')
                                .find('.table__open__ctrl')
                                .attr('href')}"></a>`
                        )
                            .append(
                                $(this).closest('.inline-table').find('.inline-table__head').children('span').clone()
                            )
                            .appendTo(complexTable.$summaryItem);
                    }
                });
                if (complexTable.$summaryList.children().length) {
                    $('div.tables-placeholder').replaceWith(complexTable.$summary);
                    $(document).trigger('LNS-refill');
                }
            }
        },
        tableRef: function () {
            if ($('table .dropBlock').length) {
                $('table .dropBlock__holder.centered').each(function () {
                    let thisHeight = $(this).outerHeight();
                    $(this).css('margin-top', -(thisHeight / 2));
                });
                $('table').each(function () {
                    if ($(this).find('.dropBlock__holder').length) {
                        $(this).wrap('<div class="table-with-References"> </div>');
                    }
                });
            }
        },
        scrolling: {
            table: null,
            tableWrapper: null,
            thead: null,
            ths: null,
            theadTrs: null,
            multiHeadRow: null,
            rowsWithColSpan: null,
            maxColSpan: null,
            firstTh: null,
            tds: null,
            columnsToFixed: null,
            init: function (table) {
                this.table = table;
                this.tableWrapper = table.parentNode;
                this.thead = table.querySelector('thead');
                this.ths = this.thead.querySelectorAll('th');
                this.theadTrs = this.thead.querySelectorAll('tr');
                this.multiHeadRow = this.theadTrs.length > 1;
                this.rowsWithColSpan = table.querySelectorAll('td[colspan]:first-child');
                this.maxColSpan = Math.max.apply(
                    Math,
                    Array.prototype.map.call(this.rowsWithColSpan, function (o) {
                        return o.colSpan;
                    })
                );
                this.firstTh = Array.prototype.slice.call(table.querySelectorAll('thead tr th:first-of-type'));
                this.tds = Array.prototype.slice.call(table.querySelectorAll('tbody tr td:first-of-type'));
                this.columnsToFixed = this.firstTh.concat(this.tds);
                let _this = this;
                (function () {
                    // This is for giving the NodeList the ability to "forEach" it
                    if (typeof NodeList.prototype.forEach === 'function') return false;
                    NodeList.prototype.forEach = Array.prototype.forEach;
                })();

                if (window.Modernizr.csspositionsticky) {
                    _this.stickySupported(_this);
                    complexTable.isStickySupported = true;
                } else {
                    _this.stickyNotSupported(_this);
                    _this.tableWrapper.classList.add('complex__table--support-sticky-js');
                    complexTable.isStickySupported = false;
                }
            },
            stickySupported: function (_this) {
                _this.ths.forEach(function (th) {
                    th.classList.add('top--stuck');
                });
                if (_this.multiHeadRow) {
                    let prevHeight = 0;
                    _this.theadTrs.forEach(function (headtr) {
                        let ths = headtr.querySelectorAll('th');
                        ths.forEach(function (th) {
                            th.style.top = prevHeight + 'px';
                        });
                        prevHeight += ths[0].getBoundingClientRect().height;
                    });
                }
                if (_this.maxColSpan > 1) {
                    if (_this.maxColSpan > 3) _this.maxColSpan = 1;
                    let tbodyTRs = _this.table.querySelectorAll('tr');
                    tbodyTRs.forEach(function (tr) {
                        let colSpanCounter = 0;
                        let prevWidth = 0;
                        let children = tr.querySelectorAll(
                            'td:nth-child(-n+' + _this.maxColSpan + '), th:nth-child(-n+' + _this.maxColSpan + ')'
                        );
                        children.forEach(function (child) {
                            colSpanCounter += child.colSpan;
                            if (colSpanCounter > _this.maxColSpan) return;
                            child.classList.add('left--stuck');
                            child.style.left = prevWidth + 'px';
                            prevWidth += child.getBoundingClientRect().width;
                        });
                    });
                }
            },
            stickyNotSupported: function (_this) {
                _this.slowScrolling(_this.tableWrapper);
                _this.ths.forEach(function (th) {
                    th.classList.add('js--stuck-top');
                });
                _this.thead.classList.add('js--stuck');
            },
            slowScrolling: function (element) {
                function _handle(delta) {
                    let time = 300;
                    let distance = 300;

                    $(element)
                        .stop()
                        .animate(
                            {
                                scrollTop: $(element).scrollTop() - distance * delta,
                            },
                            time
                        );
                }

                function _wheel(event) {
                    let delta = 0;
                    if (event.wheelDelta) delta = event.wheelDelta / 120;
                    else if (event.detail) delta = -event.detail / 3;

                    _handle(delta);
                    if (event.preventDefault) event.preventDefault();
                    event.returnValue = false;
                }
                element.onmousewheel = _wheel;
                element.addEventListener('scroll', function (e) {
                    if (e.target.scrollLeft === window.prevHorizontalScroll) return;
                    window.prevHorizontalScroll = e.target.scrollLeft;
                    $(element).stop().animate(
                        {
                            scrollLeft: element.scrollLeft,
                        },
                        1000
                    );
                });
            },
        },
        supportScrolling: function (table) {
            let scrollTopWrapper = table.closest('.scroallbe-table');
            table.find('.js--stuck-top').each(function () {
                $(this).css('transform', 'translateY(' + (scrollTopWrapper.scrollTop() - 1) + 'px)');
            });
            table.find('.js--stuck-left').each(function () {
                $(this).css('transform', 'translateX(' + (scrollTopWrapper.scrollLeft() - 1) + 'px)');
            });
            table.find('.js--stuck-top-h').each(function () {
                $(this).css({
                    transform:
                        'translate(' +
                        (scrollTopWrapper.scrollLeft() - 1) +
                        'px, ' +
                        (scrollTopWrapper.scrollTop() - 1) +
                        'px)',
                    'z-index': 8,
                });
            });
        },
    };

    complexTable.tableRef();
    UX.complexTable = complexTable; // add to global namespace
})();
