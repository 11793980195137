export default class Registration {
    selectors = {
        registration: '.registration',
        registrationField: '.registration__field',
        passwordText: '.password-text',
        inputFields: '.registration__field input, .registration__field select',
        selectFields: '.registration__field select',
        inputFieldsActive:
            '[class*="step-"].active .registration__field input, [class*="step-"].active .registration__field select',
        activeStepErrors:
            '[class*="step-"].active .registration__field--error, [class*="step-"].active .registration__field--client-side-validation-error',
        passwordField: '.registration__field #password1',
        passwordVerifyField: '.registration #password2',
        passwordHint: '.registration__field .js-show-password',
        emailField: '.registration__field #email',
        professionField: '.registration__field #profession',
        specializationField: '.registration__field #specialization',
        controllerName: '.registration #controllerName',
        errorMessageChief: '.registration #errorMessageChief',
        errorMessageLiteratum: '.registration #errorMessageLiteratum',
        registrationFieldError: '.registration__field__error',
        registerButton: 'button.continue, button.register, button.completeReg',
        continueButton: 'button.continue',
        registrationError: '[class*="step-"].active .registration__error',
        step: '[data-form-step]',
        stepActive: '.registration [class*="step-"].active',
        stepIndicator: '.registration__navigation__step',
        specializationType: '.registration #specializationType',
        benefits: '.registration .registration__benefits',
        feedbackBadge: '.usabilla_live_button_container',
        recaptcha: '.registration .goggle-recaptcha',
        recaptchaPlaceholder: '.goggle-recaptcha-placeholder',
        systemError: '#registration-system-error',
        showLogin: '.show-login-link',
    };

    $elements = {};

    constructor() {
        if (document.querySelector(this.selectors.registration)) {
            this.init();
        }
    }

    init() {
        this.getElements();
        this.setInitialState();
        this.addListeners();
    }

    getElements() {
        this.$elements.registration = document.querySelectorAll(this.selectors.registration);
        this.$elements.inputFields = Array.prototype.slice.call(document.querySelectorAll(this.selectors.inputFields));
        this.$elements.selectFields = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.selectFields)
        );
        this.$elements.inputFieldsActive = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.inputFieldsActive)
        );
        this.$elements.passwordField = document.querySelector(this.selectors.passwordField);
        this.$elements.passwordVerifyField = document.querySelector(this.selectors.passwordVerifyField);
        this.$elements.passwordHint = document.querySelector(this.selectors.passwordHint);
        this.$elements.emailField = document.querySelector(this.selectors.emailField);
        this.$elements.professionField = document.querySelector(this.selectors.professionField);
        this.$elements.specializationField = document.querySelector(this.selectors.specializationField);
        this.$elements.controllerName = document.querySelector(this.selectors.controllerName);
        this.$elements.errorMessageChief = document.querySelector(this.selectors.errorMessageChief);
        this.$elements.errorMessageLiteratum = document.querySelector(this.selectors.errorMessageLiteratum);
        this.$elements.registerButton = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.registerButton)
        );
        this.$elements.registration = document.querySelector(this.selectors.registration);
        this.$elements.specializationType = document.querySelector(this.selectors.specializationType);
        this.$elements.benefits = document.querySelector(this.selectors.benefits);
        this.$elements.recaptcha = document.querySelector(this.selectors.recaptcha);
        this.$elements.recaptchaPlaceholder = document.querySelector(this.selectors.recaptchaPlaceholder);
        this.$elements.systemError = document.querySelector(this.selectors.systemError);
        this.$elements.showLogin = document.querySelector(this.selectors.showLogin);
        this.$elements.passwordText = this.$elements.registration.querySelector(this.selectors.passwordText);
    }

    setInitialState() {
        this.$elements.registration.reset();
        if (this.$elements.inputFields) {
            this.$elements.inputFields.forEach(inputField => {
                if (inputField.value !== '') {
                    inputField.parentElement.classList.add('has-value');
                }
                if (this.$elements.professionField) {
                    if (
                        this.$elements.professionField.value === '' &&
                        this.$elements.specializationType.value === 'dynamic'
                    ) {
                        this.toggleDisabled(this.$elements.specializationField, true);
                    } else {
                        this.toggleDisabled(this.$elements.specializationField, false);
                    }
                }
            });
        }

        this.loadCaptcha();

        let queryParams = this.getQueryParameters(window.location);
        if (queryParams.isSystemError && queryParams.isSystemError === 'true') {
            $(this.$elements.systemError).modal({backdrop: 'static', keyboard: false}, 'toggle');
        }

        if (queryParams.code && this.$elements.showLogin.href.indexOf('code=') === -1) {
            this.$elements.showLogin.href +=
                (this.$elements.showLogin.href.indexOf('?') > -1 ? '&' : '?') +
                'code=' +
                encodeURIComponent(queryParams.code);
        }

        if (queryParams.redirectUri && this.$elements.showLogin.href.indexOf('redirectUri=') === -1) {
            this.$elements.showLogin.href +=
                (this.$elements.showLogin.href.indexOf('?') > -1 ? '&' : '?') +
                'redirectUri=' +
                encodeURIComponent(queryParams.redirectUri);
        }
    }

    tabs(e, step) {
        let stepNumber,
            nextStep = 1,
            $activeStep;

        $activeStep = document.querySelector(this.selectors.stepActive);

        if (e) {
            stepNumber = $activeStep.dataset.formStep;
        }

        if (step) {
            nextStep = step;
        } else if (stepNumber) {
            nextStep = parseInt(stepNumber, 10) + 1;
        }

        if (nextStep === 1) {
            this.$elements.benefits.classList.remove('hidden');
        } else {
            this.$elements.benefits.classList.add('hidden');
        }

        let stepElements = this.$elements.registration.querySelectorAll(this.selectors.stepIndicator);

        if (!isNaN(nextStep) && stepElements.length > 0) {
            for (let i = 0; i < nextStep - 1; i++) {
                stepElements[i].classList.remove('active');
                stepElements[i].classList.add('done');
                stepElements[i].removeAttribute('aria-current');
            }

            for (let i = nextStep; i < stepElements.length; i++) {
                stepElements[i].classList.remove('done');
                stepElements[i].classList.remove('active');
                stepElements[i].removeAttribute('aria-current');
            }
        }

        if (nextStep <= 4 && !isNaN(nextStep) && stepElements.length >= nextStep) {
            stepElements[nextStep - 1].classList.remove('done');
            stepElements[nextStep - 1].classList.add('active');
            stepElements[nextStep - 1].ariaCurrent = 'step';
            $activeStep.classList.remove('active');
            $activeStep.removeAttribute('aria-current');
            const nextActiveStep = this.$elements.registration.querySelector(`.step-${nextStep}`);
            nextActiveStep.classList.add('active');
        }

        if (!step && nextStep <= 4) {
            this.addState(nextStep);
        }
    }

    addState(step) {
        const state = {step: step};
        const title = 'Registartion - Step ' + step;
        const stepUrl = '#step-' + step;

        history.pushState(state, title, stepUrl);
    }

    passwordToggleHandler = e => {
        e.preventDefault();
        if (this.$elements.passwordField.type === 'password') {
            this.$elements.passwordField.type = 'text';
            this.$elements.passwordHint.textContent = 'Hide';
            this.$elements.passwordHint.ariaChecked = 'true';
            this.$elements.passwordText.textContent = 'Password shown';
        } else {
            this.$elements.passwordField.type = 'password';
            this.$elements.passwordHint.textContent = 'Show';
            this.$elements.passwordHint.ariaChecked = 'false';
            this.$elements.passwordText.textContent = 'Password hidden';
        }
    };

    addListeners() {
        if (this.$elements.registration) {
            this.$elements.registration.addEventListener('load', this.setInitialState());
        }

        if (this.$elements.inputFields) {
            this.$elements.inputFields.forEach(inputField => {
                inputField.addEventListener('focus', e => {
                    inputField.parentElement.classList.add('has-value');
                    this.toggleRegistrationError();
                });

                inputField.addEventListener('keyup', e => {
                    inputField.parentElement.classList.add('has-value');
                });

                inputField.addEventListener('blur', e => {
                    if (inputField.value.trim() === '') {
                        inputField.parentElement.classList.remove('has-value');
                    }
                    this.toggleRegistrationError();
                });

                inputField.addEventListener('keypress', e => {
                    var keycode = e.keyCode ? e.keyCode : e.which;
                    if (keycode === 13 || keycode === '13') {
                        e.preventDefault();
                        let evt;

                        if (typeof Event === 'function') {
                            evt = new Event('blur');
                        } else {
                            evt = document.createEvent('Event');
                            evt.initEvent('blur', true, true);
                        }

                        e.target.dispatchEvent(evt);

                        if (typeof Event === 'function') {
                            evt = new Event('click');
                        } else {
                            evt = document.createEvent('Event');
                            evt.initEvent('click', true, true);
                        }
                        document.querySelector(this.selectors.stepActive).querySelector('button').dispatchEvent(evt);
                    }
                });
            });
        }

        if (this.$elements.passwordHint)
            this.$elements.passwordHint.addEventListener('click', this.passwordToggleHandler);

        if (this.$elements.professionField) {
            this.$elements.professionField.addEventListener('change', e => {
                if (this.$elements.specializationType && this.$elements.specializationType.value === 'dynamic') {
                    this.updateSpecialization();
                } else {
                    this.toggleDisabled(
                        this.$elements.specializationField,
                        this.$elements.professionField.value === ''
                    );
                }
            });
        }

        if (this.$elements.emailField) {
            this.validateEmail();
        }

        if (this.$elements.passwordField) {
            this.validatePassword();
        }

        if (this.$elements.registerButton) {
            let queryParams = location.search;
            this.$elements.registration.action += queryParams;
            this.$elements.registerButton.forEach(button => {
                button.addEventListener('click', e => {
                    let validInput = true;
                    let inputFieldsActive = Array.prototype.slice.call(
                        document.querySelectorAll(this.selectors.inputFieldsActive)
                    );
                    let $activeStep = document.querySelector(this.selectors.stepActive);
                    let stepNumber = parseInt($activeStep.dataset.formStep, 10);

                    if (stepNumber === 4 && grecaptcha) {
                        if (this.$elements.registration.dataset.disableRecaptcha === 'true') return;
                        let rechaptchaValue = grecaptcha.getResponse();
                        if (!rechaptchaValue) {
                            validInput = false;
                            this.$elements.recaptcha.classList.add('registration__field--error');
                        } else {
                            this.$elements.recaptcha.classList.remove('registration__field--error');
                        }
                    }

                    inputFieldsActive.forEach(inputField => {
                        if (
                            (!inputField.parentElement.classList.contains('has-value') ||
                                inputField.parentElement.classList.contains(
                                    'registration__field--client-side-validation-error'
                                ) ||
                                inputField.parentElement.classList.contains('registration__field--validating')) &&
                            !inputField.parentElement.classList.contains('registration__field--ignore')
                        ) {
                            validInput = false;
                            if (!inputField.parentElement.classList.contains('registration__field--validating')) {
                                inputField.parentElement.classList.add('registration__field--error');
                            }
                        } else {
                            inputField.parentElement.classList.remove('registration__field--error');
                        }
                    });
                    if (!validInput) {
                        e.preventDefault();
                        this.toggleRegistrationError();
                    } else {
                        this.toggleRegistrationError();
                        this.tabs(e);
                    }
                });
            });
        }

        window.addEventListener('load', e => {
            if (!history.state || (history.state.step && history.state.step !== 1)) {
                this.addState(1);
            }

            setTimeout(() => {
                let $feedbackBadge = document.querySelector(this.selectors.feedbackBadge);
                if ($feedbackBadge) {
                    $feedbackBadge.classList.add('move-to-right');
                }
            }, 100);
        });

        window.addEventListener('popstate', e => {
            if (e.state && e.state.step) {
                this.tabs(null, e.state.step);
            }
        });
    }

    getErrorMessageChief() {
        let errorMessageChief = this.$elements.errorMessageChief.value;
        return errorMessageChief;
    }

    getErrorMessageLiteratum() {
        let errorMessageLiteratum = this.$elements.errorMessageLiteratum.value;
        return errorMessageLiteratum;
    }

    getControllerName() {
        let controllerName = this.$elements.controllerName.value;
        return controllerName;
    }

    toggleDisabled($el, disabled) {
        if ($el) {
            $el.disabled = disabled;

            if (disabled) {
                $el.parentElement.classList.add('registration__field--disabled');
            } else {
                $el.parentElement.classList.remove('registration__field--disabled');
            }
        }
    }

    toggleRegistrationError() {
        let $activeStepErrors = Array.prototype.slice.call(document.querySelectorAll(this.selectors.activeStepErrors));
        let $registrationError = document.querySelector(this.selectors.registrationError);

        if ($activeStepErrors && $activeStepErrors.length > 0) {
            $registrationError.style.display = 'flex';
        } else {
            $registrationError.style.display = 'none';
        }
    }

    updateSpecialization() {
        const professionCode = this.$elements.professionField.value;
        const professionName =
            this.$elements.professionField.options[this.$elements.professionField.selectedIndex].textContent;

        const action = '/pb/widgets/UX3RegistrationFormController/loadSpecialization';

        $.ajax({
            method: 'GET',
            url: action,
            dataType: 'json',
            data: {
                professionName,
                professionCode,
            },
        }).then(
            (data, textStatus, jqXHR) => {
                if (data && data.dynamicSpecializationList) {
                    let specOptions = '<option value="" hidden></option>';

                    data.dynamicSpecializationList.forEach(spec => {
                        specOptions += `<option value="${spec.uri}">${spec.name}</option>`;
                    });
                    this.$elements.specializationField.innerHTML = specOptions;

                    let evt;
                    if (typeof Event === 'function') {
                        evt = new Event('blur');
                    } else {
                        evt = document.createEvent('Event');
                        evt.initEvent('blur', true, true);
                    }
                    this.$elements.specializationField.dispatchEvent(evt);

                    if (data.dynamicSpecializationList.length === 0) {
                        this.$elements.specializationField.parentElement.classList.add('registration__field--ignore');
                    } else {
                        this.$elements.specializationField.parentElement.classList.remove(
                            'registration__field--ignore'
                        );
                    }
                    this.toggleDisabled(
                        this.$elements.specializationField,
                        data.dynamicSpecializationList.length === 0
                    );
                    this.$elements.specializationField.parentElement.classList.remove('registration__field--error');
                }
            },
            (jqXHR, textStatus, errorThrown) => {
                this.toggleDisabled(this.$elements.specializationField, true);
                this.$elements.specializationField.parentElement.querySelector(
                    this.selectors.registrationFieldError
                ).textContent = 'Server error: Unable to load list of specializations.';
                this.$elements.specializationField.parentElement.classList.add('registration__field--error');
                console.log('Server error:', errorThrown); // eslint-disable-line no-console
            }
        );
    }

    getQueryParameters(location) {
        let params = {};
        let searchStr = location.search.substr(1);
        searchStr.split('&').map(param => {
            let keyValue = param.split('=');
            params[keyValue[0]] = decodeURIComponent(keyValue[1]);
        });

        return params;
    }

    validateEmail() {
        const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let $emailParentElement = this.$elements.emailField.parentElement;
        let $emailFieldError = $emailParentElement.querySelector(this.selectors.registrationFieldError);

        this.$elements.emailField.addEventListener('blur', e => {
            let lastValue = this.$elements.emailField.dataset.lastValue;
            let emailValue = this.$elements.emailField.value;

            if (lastValue && lastValue === emailValue) {
                return;
            }
            this.$elements.emailField.dataset.lastValue = emailValue;

            let emailValidation = emailRegex.test(emailValue);

            if (emailValidation) {
                $emailParentElement.classList.remove('registration__field--error');
                $emailParentElement.classList.remove('registration__field--client-side-validation-error');
            } else {
                $emailFieldError.textContent = 'Please enter a valid email address.';
                $emailParentElement.classList.add('registration__field--error');
                $emailParentElement.classList.add('registration__field--client-side-validation-error');
            }
            this.toggleRegistrationError();
        });

        this.$elements.emailField.addEventListener('change', e => {
            let lastValue = this.$elements.emailField.dataset.lastValue;
            let controllerName = this.getControllerName();
            let action = '/pb/widgets/' + controllerName + '/checkEmailAddress';
            let queryParams = this.getQueryParameters(window.location);
            let redirectUri = queryParams.redirectUri || '';
            let code = queryParams.code || '';

            if (this.$elements.emailField.value === '') {
                $emailFieldError.textContent = 'Please enter a valid email address.';
                $emailParentElement.classList.add('registration__field--error');
                $emailParentElement.classList.add('registration__field--client-side-validation-error');
            } else {
                let emailValue = this.$elements.emailField.value;

                if (lastValue && lastValue === emailValue) {
                    return;
                }
                this.$elements.emailField.dataset.lastValue = emailValue;

                let emailValidation = emailRegex.test(emailValue);

                if (emailValidation) {
                    this.$elements.emailField.parentElement.classList.remove('registration__field--error');
                    this.$elements.emailField.parentElement.classList.remove(
                        'registration__field--client-side-validation-error'
                    );
                    this.$elements.emailField.parentElement.classList.add('registration__field--validating');
                    $.ajax({
                        method: 'GET',
                        url: action,
                        dataType: 'json',
                        data: {
                            email: emailValue,
                            redirectUrl: redirectUri,
                            siteCode: code,
                        },
                    }).then(
                        (data, textStatus, jqXHR) => {
                            if (data) {
                                if (!data.isValidEmailAddress) {
                                    $emailFieldError.textContent = 'Please enter a valid email address.';
                                    $emailParentElement.classList.add('registration__field--error');
                                    $emailParentElement.classList.add(
                                        'registration__field--client-side-validation-error'
                                    );
                                } else if (data.duplicatedEmail) {
                                    let getErrorMessageChief = this.getErrorMessageChief();
                                    $emailFieldError.innerHTML = getErrorMessageChief;
                                    $emailParentElement.classList.add('registration__field--error');
                                    $emailParentElement.classList.add(
                                        'registration__field--client-side-validation-error'
                                    );
                                } else if (data.duplicatedEmailWithLiteratum) {
                                    let getErrorMessageLiteratum = this.getErrorMessageLiteratum();
                                    $emailFieldError.innerHTML = getErrorMessageLiteratum;
                                    $emailParentElement.classList.add('registration__field--error');
                                    $emailParentElement.classList.add(
                                        'registration__field--client-side-validation-error'
                                    );
                                } else if (data.isEmailEmpty) {
                                    $emailFieldError.textContent = 'Please enter an email address.';
                                    $emailParentElement.classList.add('registration__field--error');
                                    $emailParentElement.classList.add(
                                        'registration__field--client-side-validation-error'
                                    );
                                } else {
                                    $emailParentElement.classList.remove('registration__field--error');
                                    $emailParentElement.classList.remove(
                                        'registration__field--client-side-validation-error'
                                    );
                                }
                                this.$elements.emailField.parentElement.classList.remove(
                                    'registration__field--validating'
                                );
                            }
                            this.toggleRegistrationError();
                        },
                        (jqXHR, textStatus, errorThrown) => {
                            $emailFieldError.textContent = 'Server error. Unable to verify email address.';
                            $emailParentElement.classList.add('registration__field--error');
                            this.$elements.emailField.parentElement.classList.remove('registration__field--validating');
                            this.toggleRegistrationError();
                        }
                    );
                } else {
                    $emailFieldError.textContent = 'Please enter a valid email address.';
                    $emailParentElement.classList.add('registration__field--error');
                    $emailParentElement.classList.add('registration__field--client-side-validation-error');
                }
            }
        });
    }

    validatePassword() {
        let $passwordParentElement = this.$elements.passwordField.parentElement;

        this.$elements.passwordField.addEventListener('blur', e => {
            let passwordValue = this.$elements.passwordField.value;
            let passwordValidation = /^^\S{5,20}$/.test(passwordValue);

            if (!passwordValidation) {
                $passwordParentElement.classList.add('registration__field--error');
                $passwordParentElement.classList.add('registration__field--client-side-validation-error');
            } else {
                $passwordParentElement.classList.remove('registration__field--error');
                $passwordParentElement.classList.remove('registration__field--client-side-validation-error');
                this.$elements.passwordVerifyField.value = passwordValue;
            }
            this.toggleRegistrationError();
        });
    }

    loadCaptcha() {
        const action = '/pb/widgets/CaptchaResponseHandler/';

        $.ajax({
            method: 'GET',
            url: action,
            dataType: 'html',
        }).then(
            (data, textStatus, jqXHR) => {
                this.renderCaptcha(data);
            },
            (jqXHR, textStatus, errorThrown) => {
                let data =
                    '<script src="//www.google.com/recaptcha/api.js" async defer></script><div class="g-recaptcha" data-sitekey="6Lc4HR8TAAAAAPFSxfchztMruqn2dTwPIQ9vaX9b">';
                this.renderCaptcha(data);
            }
        );
    }

    renderCaptcha(captcha) {
        let $wrapperEl = document.createElement('div');
        $wrapperEl.innerHTML = captcha;
        let $scriptEl = $wrapperEl.querySelector('script[src]');
        let scriptUrl = $scriptEl.src;
        let $scriptEl2 = $wrapperEl.querySelector('script:not([src])');

        if (this.$elements.recaptchaPlaceholder.innerHTML.trim() === '') {
            this.$elements.recaptchaPlaceholder.appendChild($wrapperEl);
        }

        $.ajax({
            url: scriptUrl,
            dataType: 'script',
        }).then(
            (data, textStatus, jqXHR) => {
                if ($scriptEl2) {
                    let scriptText = $scriptEl2.textContent;
                    eval(scriptText);
                }
            },
            (jqXHR, textStatus, errorThrown) => {}
        );
    }
}
