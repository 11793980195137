export default class BrowseCollections {
    selectors = {
        browseCollections: '.browse-collections',
        collectionsTopLinks: '.accordion-tabbed__control--link a',
    };

    $elements = {};

    constructor() {
        if (document.querySelector(this.selectors.browseCollections)) {
            this.init();
        }
    }

    init() {
        this.getElements();
        this.addListeners();
    }

    getElements() {
        this.$elements.collectionsTopLinks = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.collectionsTopLinks)
        );
    }

    addListeners() {
        this.$elements.collectionsTopLinks.forEach(collectionLink => {
            collectionLink.addEventListener('click', e => {
                let collectionUrl = collectionLink.href;
                if (collectionUrl) {
                    window.location.href = collectionUrl;
                }
            });
        });
    }
}
