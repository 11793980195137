(function () {
    // open page links in new tab when video PIP is active
    window.setTimeout(function () {
        const $video = $('video');

        if ($video.length > 0) {
            const $pageLinks = $('a[href]:not([target])');

            $video.each(function () {
                $(this).on('enterpictureinpicture', e => {
                    $pageLinks.attr('target', '_blank');
                });

                $(this).on('leavepictureinpicture', e => {
                    $pageLinks.removeAttr('target');
                });
            });
        }
    }, 3000);
})();
