import MegaMenu from '../../components/mega-menu/js/mega-menu';
import SessionReminder from '../../components/session-reminder/js/session-reminder';
import PlumX from '../../components/plum-x/js/plum-x';
import ThreeDModal from '../../widgets/3d-modal/js/3d-modal';
import GetAccess from '../../widgets/get-access/js/get-access';
import InfoPopup from '../../components/info-popup/js/info-popup';
import RelatedArticles from '../../widgets/atypon-temp-related-articles/js/related-articles';
import Jifa from '../../widgets/jifa/js/jifa';
import EditorialBoard from '../../widgets/editorial-board/js/editorial-board';

(function () {
    $(document).ready(function () {
        const axelArticle = $('article[data-design]').length;

        UX.controller.init();

        if ($('main.content').length || $('.legacy-pb-page-content').length || axelArticle) {
            UX.pageBody.init();
        }

        if ($('.w-slide__btn').length) {
            UX.slide.init();
        }

        if ($('.mediaPlayer').length || $('.cloudflare-stream-container').length) {
            UX.mediaPlayer.init();
        }

        if ($('[data-db-target-for]').length && !axelArticle) {
            UX.dropBlock.init();
        }

        if ($('.main-nav').length) {
            UX.drawer.init();
        }

        if ($('[data-toggle="modal"]').length) {
            UX.modal.init();
        }

        if ($('.facet').length) {
            UX.toggle.init();
        }

        if ($('.accordion').length) {
            UX.accordion.init();
        }

        try {
            if (axelArticle) {
                jQuery.getScript('/specs/products/marlin/ha/releasedAssets/js/build.article.bundle.js', function () {
                    console.log('HA - Article Page Bundle has been loaded, self initiated for now.'); // eslint-disable-line no-console
                    if ($('[data-db-target-for]').length) {
                        UX.dropBlock.init();
                    }
                    if ($('figure').length) {
                        UX.figureViewer.init();
                    }
                });
            } else {
                if ($('article').length) {
                    UX.article.init();
                }

                if ($('figure').length) {
                    UX.figureViewer.init();
                }
            }
        } catch (e) {
            console.error(e);
        }

        if ($('.advanced-search').length) {
            UX.searchFieldsCtrl.init();
        }

        if ($('.tab').length) {
            UX.tab.init();
        }

        if ($('.article-tools').length) {
            UX.articleTools.init();
        }

        if ($('.owl-carousel').length) {
            UX.slider.init();
        }

        if ($('.quick-search').length) {
            UX.quickSearch.init();
        }

        if ($('.js__toggleAdForm').length || $('.js__adToHide').length) {
            UX.adplaceholder.init();
        }

        if ($('.article-header')) {
            UX.colorOverlay.init();
        }

        if ($('[data-toggle="transplant"]').length) {
            UX.transplant.init();
        }

        UX.searchResult.init();

        if ($('article table').length) {
            UX.complexTable.list();
        }

        if (!$('.source_example').length && $('.complexTable').length) {
            UX.complexTable.init();
        }

        if (!$('.accrodion--textbox').length) {
            UX.textbox.init();
        }

        if ($('.b-lazy').length) {
            UX.lazyload.init();
        }

        if (
            $('.table-of-content').length ||
            $('.article-header__download-full-issue').length ||
            $('.current-issue__download-full-issue').length ||
            $('.cover-image__download-full-issue').length
        ) {
            UX.tableOfContent.init();
        }

        if ($('.cookiePolicy-popup').length) {
            UX.cookiePolicyPopupWidget.init();
        }

        UX.equation.init();

        let isIE = UX.test.isIE();
        let isSafari = UX.test.isSafari();
        if ((!isIE || (isIE && isIE > 11)) && !isSafari) {
            UX.outlinejs.init();
        }

        if ($('.echeckout').length) {
            UX.checkout.init();
        }

        if ($('.esubscriptions').length) {
            UX.subscriptions.init();
        }

        if ($('.ec-help').length) {
            UX.ecHelp.init();
        }

        if ($('.search-in').length) {
            UX.searchInCollection.init();
        }

        UX.megaMenu = new MegaMenu();
        UX.sessionReminder = new SessionReminder();
        UX.plumX = new PlumX();

        const threeDModal = document.querySelector('.three-d-images-section');
        if (threeDModal) {
            UX.threeDModal = new ThreeDModal(threeDModal);
            UX.threeDModal.initialize();
        }

        const axelRelatedArticles = document.querySelector('.axel-related-articles');
        if (axelRelatedArticles) {
            UX.relatedArticles = new RelatedArticles(axelRelatedArticles);
            UX.relatedArticles.initialize();
        }

        const getAccessSection = document.querySelector('.get-access');
        if (getAccessSection) {
            UX.getAccess = new GetAccess(getAccessSection);
            UX.getAccess.initialize();
        }

        const infoPopupSection = document.querySelector('.info-popup');
        if (infoPopupSection) {
            UX.infoPopup = new InfoPopup(infoPopupSection);
            UX.infoPopup.initialize();
        }

        const jifaSection = document.querySelector('.jifa');
        if (jifaSection) {
            UX.jifa = new Jifa(jifaSection);
            UX.jifa.initialize();
        }

        const editorialBoardSection = document.querySelector('.editorial-board');
        if (editorialBoardSection) {
            UX.editorialBoard = new EditorialBoard(editorialBoardSection);
            UX.editorialBoard.initialize();
        }

        UX.enquireIt.init(); // important: keep it always last
    });
})();
