(function () {
    UX.accordion.additionalControls = function () {
        $(document.body).on('keyup', '.accordion-tabbed__control', function (e) {
            const ENTER_KEY = 13;

            if ((e.keyCode || e.which) === ENTER_KEY) {
                let $this = $(this);
                UX.accordion.on.toggle.tabbed($this);
            }
        });

        const accordionControls = document.querySelectorAll('.accordion__control');
        const lazyImagesTrigger = e => {
            const content = e.currentTarget.nextElementSibling;
            if (content && content.classList.contains('accordion__content')) {
                const lazyImage = content.querySelector('.b-lazy');
                if (lazyImage && !lazyImage.classList.contains('js--loaded')) {
                    UX.lazyload.init();
                    lazyImage.classList.add('js--loaded');
                }
            }
        };
        accordionControls.forEach(item => item.addEventListener('click', lazyImagesTrigger));
    };

    UX.accordion.ToggleTitle = {
        accordionControl: null,
        init: function () {
            let $this = this;
            $this.accordionControl = $('figure .accordion__control');
            $this.accordionControl.attr('title', ' ');
            $this.accordionControl.each(function () {
                if ($(this).hasClass('js--open')) {
                    $(this).addClass('expanded');
                    $this.titleFunction($(this));
                } else {
                    $this.titleFunction($(this));
                }
            });
            UX.accordion.ToggleTitle.toggleTitleClick();
        },
        toggleTitleClick: function () {
            $('figure .accordion__control').on('click', function () {
                $(this).toggleClass('expanded');
                UX.accordion.ToggleTitle.titleFunction($(this));
            });
        },
        titleFunction: function (accordion) {
            if (accordion.hasClass('expanded')) {
                accordion.attr('title', 'Collapse');
            } else {
                accordion.attr('title', 'Expand');
            }
        },
    };
    UX.accordion.ToggleTitle.init();
    UX.accordion.on.toggle.single = function ($this) {
        const isAxel = document.querySelector('article[data-design]');
        // we only need to make sure that in the axel-publication-content widget,
        // the accordion content is targeted properly
        // the rest is the same as in ui-core/../accordion.js
        let state;
        let $accordionContent = $this.next('.accordion__content');
        if (isAxel && !$accordionContent.length) {
            $accordionContent = $this.parent().next('.accordion__content');
        }
        if (typeof $this.attr('data-content-target') !== 'undefined') {
            const contentTarget = $this.attr('data-content-target');
            $accordionContent = $(contentTarget);
        }
        $accordionContent.slideToggle(200, function () {
            if ($this.parent().hasClass('article-accordion')) {
                $('.article-row-left').height('auto');
                UX.accordion.isStickyBody = state;
            }
        });
        $this.toggleClass('js--open');

        const $wrapper = $this.closest('.expandable-accordion');

        state = !($this.attr('aria-expanded') === 'true');
        $this.attr('aria-expanded', state);
        const accordionExpandedSingle = $.Event('accordion:expandedSingle');
        $(document).trigger(accordionExpandedSingle, [$this, $accordionContent]);
        if ($wrapper.length) UX.accordion.on.checkExpandAll($this, state, $wrapper);
    };
    UX.accordion.on.checkExpandAll = ($this, state, $wrapper) => {
        let $expandElem;
        if ($wrapper.length) {
            $expandElem = $wrapper.find('.expand-all');
        }

        if ($expandElem.length && $expandElem.attr('aria-expanded') === 'true') {
            $expandElem.attr('aria-expanded', state);
            if (state) {
                if ($expandElem.data('toggle-text')) {
                    $expandElem.find('.toggle-text').html($expandElem.data('collapse-text'));
                } else {
                    $expandElem.text('Hide All');
                }
            } else {
                if ($expandElem.data('toggle-text')) {
                    $expandElem.find('.toggle-text').html($expandElem.data('expand-text'));
                } else {
                    $expandElem.text('Expand All');
                }
            }
        }
    };
    UX.accordion.on.toggle.all = $this => {
        const $wrapper = $this.closest('.expandable-accordion');
        let state = !($this.attr('aria-expanded') === 'true');

        $this.attr('aria-expanded', state);
        if (state) {
            $wrapper.find('.accordion-tabbed__tab').addClass('js--open');
            $wrapper
                .find('.accordion__control, .accordion-tabbed__control')
                .addClass('js--open')
                .attr('aria-expanded', state);
            $wrapper.find('.accordion__content, .accordion-tabbed__content').slideDown(200);

            if ($this.data('toggle-text')) {
                $this.find('.toggle-text').html($this.data('collapse-text'));
            } else {
                $this.text('Hide All');
            }

            const $lazy = $wrapper.find('[data-ajaxLoaded]');
            $lazy.each(function () {
                if ($lazy.attr('[data-ajaxLoaded]') !== 'true' && $lazy.find('.accordion-lazy').length) {
                    UX.accordion.getAccordionContent($lazy.find('.accordion-lazy'));
                }
            });
        } else {
            $wrapper.find('.accordion-tabbed__tab').removeClass('js--open');
            $wrapper
                .find('.accordion__control, .accordion-tabbed__control')
                .removeClass('js--open')
                .attr('aria-expanded', state);
            $wrapper.find('.accordion__content, .accordion-tabbed__content').slideUp(200);

            if ($this.data('toggle-text')) {
                $this.find('.toggle-text').html($this.data('expand-text'));
            } else {
                $this.text('Expand All');
            }
        }
    };
})();
