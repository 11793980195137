class ListOfIssuesAjax {
    constructor($loiEl, pbContext) {
        this.$loiEl = $loiEl;
        this.widgetId = $loiEl.dataset.widgetid;
        this.groupBy = $loiEl.dataset.groupby;
        this.issueDisplayType = $loiEl.dataset.issuedisplaytype;
        this.issueDisplayFilter = $loiEl.dataset.issuedisplayfilter;
        this.displaySupplementsTab = $loiEl.dataset.displaysupplementstab;
        this.openAccessLabelDisplayPosition = $loiEl.dataset.openaccesslabeldisplayposition;
        this.displayIssueTitle = $loiEl.dataset.displayissuetitle;
        this.isHAWebsite = !!$loiEl.dataset.ishawebsite;
        this.pbContext = pbContext;
        this.IIPDescription = $loiEl.dataset.iipdescription;
        this.removeSpecialIssues = $loiEl.dataset.removespecialissues;

        this.addHandlers();
        this.init();
    }

    init() {
        setTimeout(() => this.expandFirstGroup(), 250);
        this.openTargetTab();
    }

    expandFirstGroup() {
        const $firstIssuesGroup = this.$loiEl.querySelector(
            '.accordion__tab[data-tab="issues"]:first-of-type .accordion__control'
        );
        const $firstSupplementsGroup = this.$loiEl.querySelector(
            '.accordion__tab[data-tab="supplements"]:first-of-type .accordion__control'
        );

        if ($firstIssuesGroup) {
            $firstIssuesGroup.click();
        }

        if ($firstSupplementsGroup) {
            $firstSupplementsGroup.click();
        }
    }

    openTargetTab() {
        const {hash} = window.location;
        if (!hash) return;
        const targetTabBtn = this.$loiEl.querySelector(`.tab__nav__item a[href="${hash}"]`);
        const targetTabPanel = this.$loiEl.querySelector(`.tab__pane[id="${hash.replace(/^.*#/, '')}"]`);
        if (targetTabBtn && targetTabPanel.childNodes.length) targetTabBtn.click();
    }

    addHandlers() {
        this.$loiEl.querySelectorAll('.js--load').forEach(control => {
            control.addEventListener('click', e => {
                e.preventDefault();

                if (!control.classList.contains('js--loaded')) {
                    control.classList.add('js--loaded');
                    const id = control.dataset.groupid;
                    this.loadGroup(id, control.closest('.accordion__tab'));
                }
            });
        });

        this.$loiEl.querySelectorAll('.js--toggle').forEach(toggle => {
            toggle.addEventListener('click', e => {
                e.preventDefault();

                const id = toggle.dataset.groupid;
                let $targetEl = toggle
                    .closest('.accordion__tab')
                    .querySelector(`.list-of-issues__group[data-groupid="${id}"]`);
                $targetEl.classList.toggle('js--open');
            });
        });

        this.$loiEl.querySelectorAll('.accordion__control').forEach(control => {
            control.addEventListener('click', e => {
                if (!control.classList.contains('js--open')) {
                    let $firstGroup = control.closest('.accordion__tab').querySelector('.list-of-issues__group');
                    let $firstGroupToggle = control
                        .closest('.accordion__tab')
                        .querySelector('.list-of-issues__group-expand.js--toggle');
                    if ($firstGroup && !$firstGroup.classList.contains('js--open') && $firstGroupToggle) {
                        $firstGroupToggle.click();
                    }
                }
            });
        });
    }

    loadGroup(id, $wrapper) {
        let $targetEl = $wrapper.querySelector(`.list-of-issues__group[data-groupid="${id}"]`);
        const params = {
            widgetId: this.widgetId,
            pbContext: encodeURIComponent(this.pbContext),
            id: id,
        };
        const ajaxUrl =
            '/pb/widgets/loi/content?' +
            Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');

        $targetEl.innerHTML = '<div class="loading"></div>';

        fetch(ajaxUrl)
            .then(response => response.json())
            .then(data => this.prepareData(id, data))
            .catch(error => {
                console.error('Error:', error);
            });
    }

    prepareData(id, data) {
        let group = data.data.journal.issueGroup;
        let issues = group.issues;
        let suplements = [];

        if (this.displaySupplementsTab) {
            suplements = group.issues.filter(issue => issue.isSupplement);
        }

        this.renderGroup(id, issues, suplements);
    }

    renderGroup(id, issues, suplements) {
        let $targetElIssues = this.$loiEl.querySelector(`.list-of-issues__group--issues[data-groupid="${id}"]`);
        let $targetElSupplements = this.$loiEl.querySelector(
            `.list-of-issues__group--supplements[data-groupid="${id}"]`
        );
        let $targetElIssuesWrapper = this.$loiEl.querySelector(
            `.list-of-issues__group__wrapper--issues[data-groupid="${id}"]`
        );
        let $targetElSupplementsWrapper = this.$loiEl.querySelector(
            `.list-of-issues__group__wrapper--supplements[data-groupid="${id}"]`
        );

        let issuesMarkup = '';
        let supplementsMarkup = '';
        let oaBoxRendered = false;

        if (issues && issues.length > 0) {
            oaBoxRendered = this.oaRendered($targetElIssuesWrapper);
            if (issues[0].accessIcon === 'oa' && issues[0].openAccessLabel === 'Open Archive' && !oaBoxRendered) {
                let $oaEl = document.createElement('div');
                let $firstChild = $targetElIssuesWrapper.querySelector('*:first-child');
                $oaEl.innerHTML = this.renderOpenArchive();
                $targetElIssuesWrapper.insertBefore($oaEl, $firstChild);
                oaBoxRendered = true;
            }
            issuesMarkup = '<ul class="rlist list-of-issues__list">';
            issuesMarkup += issues
                .map(issue => {
                    let itemMarkup = '';
                    if (issue.accessIcon === 'oa' && issue.openAccessLabel === 'Open Archive' && !oaBoxRendered) {
                        itemMarkup += `<li>${this.renderOpenArchive()}</li>`;
                        oaBoxRendered = true;
                    }
                    itemMarkup += this.renderItem(issue, 'issues');
                    return itemMarkup;
                })
                .join('');
            issuesMarkup += '</ul>';
            $targetElIssues.innerHTML = issuesMarkup;
            if (this.groupBy === 'volume') {
                Array.prototype.slice
                    .call(this.$loiEl.querySelectorAll(`.js--load[data-groupid="${id}"]`))
                    .forEach(control => {
                        control.classList.add('js--loaded');
                    });
            } else {
                $targetElIssuesWrapper.querySelector('.js--toggle').classList.add('js--loaded');
            }
        }

        if (suplements && suplements.length > 0) {
            oaBoxRendered = this.oaRendered($targetElSupplementsWrapper);
            if (
                suplements[0].accessIcon === 'oa' &&
                suplements[0].openAccessLabel === 'Open Archive' &&
                !oaBoxRendered
            ) {
                let $oaEl = document.createElement('div');
                let $firstChild = $targetElSupplementsWrapper.querySelector('*:first-child');
                $oaEl.innerHTML = this.renderOpenArchive();
                $targetElSupplementsWrapper.insertBefore($oaEl, $firstChild);
                oaBoxRendered = true;
            }
            supplementsMarkup = '<ul class="rlist list-of-issues__list">';
            supplementsMarkup += suplements
                .map(issue => {
                    let itemMarkup = '';
                    if (issue.accessIcon === 'oa' && issue.openAccessLabel === 'Open Archive' && !oaBoxRendered) {
                        itemMarkup += `<li>${this.renderOpenArchive()}</li>`;
                        oaBoxRendered = true;
                    }
                    itemMarkup += this.renderItem(issue, 'supplements');
                    return itemMarkup;
                })
                .join('');
            supplementsMarkup += '</ul>';
            $targetElSupplements.innerHTML = supplementsMarkup;

            if (this.groupBy === 'volume') {
                Array.prototype.slice
                    .call(this.$loiEl.querySelectorAll(`.js--load[data-groupid="${id}"]`))
                    .forEach(control => {
                        control.classList.add('js--loaded');
                    });
            } else {
                $targetElSupplementsWrapper.querySelector('.js--toggle').classList.add('js--loaded');
            }
        }
    }

    renderItem(item, type) {
        if (type === 'issues' && this.removeSpecialIssues && item.isSupplement) {
            return '';
        } else {
            switch (this.issueDisplayType) {
                case 'issue-date-pages':
                    return this.renderItemIssueDatePages(item);

                case 'issue-pages-date':
                    return this.renderItemIssuePagesDate(item);

                case 'lancet-style':
                    return this.renderItemLancetStyle(item);
            }
        }
    }

    renderItemIssueDatePages(item) {
        let itemMarkup = `<li><a href="${item.link}">`;

        if (item.issue && item.issueLabel) {
            itemMarkup += `<span>Issue ${item.issue}</span><span>${item.issueLabel}</span>`;
        } else if (item.issue) {
            itemMarkup += `<span>Issue ${item.issue}</span>`;
        }

        if (this.isHAWebsite && item.HAcoverDate) {
            itemMarkup += `<span>${item.HAcoverDate}</span>`;
        } else if (item.coverDate) {
            itemMarkup += `<span>${item.coverDate}</span>`;
        }

        if (item.pages) {
            itemMarkup += `<span>${item.pages}</span>`;
        }

        itemMarkup += this.renderAccess(item);

        itemMarkup += `</a>`;

        itemMarkup += this.renderOpenAccess(item);

        if (this.displayIssueTitle && item.title) {
            itemMarkup += `<br><a href="${item.link}"><div>${item.title}</div></a>`;
        }

        itemMarkup += `</li>`;

        return itemMarkup;
    }

    renderItemIssuePagesDate(item) {
        let itemMarkup = `<li><a href="${item.link}">`;

        if (item.issue && item.issueLabel) {
            itemMarkup += `<span>Issue ${item.issue}</span><span>${item.issueLabel}</span>`;
        } else if (item.issue) {
            itemMarkup += `<span>Issue ${item.issue}</span>`;
        }

        if (item.pages) {
            itemMarkup += `<span>${item.pages}</span>`;
        }

        if (this.isHAWebsite && item.HAcoverDate) {
            itemMarkup += `<span>${item.HAcoverDate}</span>`;
        } else if (item.coverDate) {
            itemMarkup += `<span>${item.coverDate}</span>`;
        }

        itemMarkup += this.renderAccess(item);

        itemMarkup += `</a>`;

        itemMarkup += this.renderOpenAccess(item);

        if (this.displayIssueTitle && item.title) {
            itemMarkup += `<br><a href="${item.link}"><div>${item.title}</div></a>`;
        }

        itemMarkup += `</li>`;

        return itemMarkup;
    }

    renderItemLancetStyle(item) {
        let itemMarkup = `<li><a href="${item.link}">`;

        if (item.issue && item.issueLabel) {
            itemMarkup += `<span>Issue ${item.issue}</span><span>${item.issueLabel}</span>`;
        } else if (item.issue) {
            itemMarkup += `<span>Issue ${item.issue}</span>`;
        }

        if (this.isHAWebsite && item.HAcoverDate) {
            itemMarkup += `<span>${item.HAcoverDate}</span>`;
        } else if (item.coverDate) {
            itemMarkup += `<span>${item.coverDate}</span>`;
        }

        if (item.pages) {
            itemMarkup += `<span>${item.pages}</span>`;
        }

        itemMarkup += this.renderAccess(item);

        itemMarkup += `</a>`;

        itemMarkup += this.renderOpenAccess(item);

        if (this.displayIssueTitle && item.title) {
            itemMarkup += `<br><a href="${item.link}"><div>${item.title}</div></a>`;
        }

        itemMarkup += `</li>`;

        return itemMarkup;
    }

    renderAccess(item) {
        let accessMarkup = '';

        if (item.isInProgress) {
            if (this.IIPDescription) {
                let rand = Math.round(Math.random() * 100000);
                accessMarkup += ` - <strong class="list-of-issues__list__state tooltip" data-slide-target="#tooltip${rand}">IN PROGRESS<div class="tooltip__body" id="tooltip${rand}">${unescape(
                    this.IIPDescription
                )}</div></strong>`;
            } else {
                accessMarkup += ` - <strong class="list-of-issues__list__state">IN PROGRESS</strong>`;
            }
        }

        if (item.freeAccessIcon && this.isHAWebsite) {
            accessMarkup += ` - <strong class="list-of-issues__list__state">FREE</strong>`;
        }

        return accessMarkup;
    }

    renderOpenAccess(item) {
        let accessMarkup = '';

        if (
            this.openAccessLabelDisplayPosition === 'nextToIssue' &&
            item.accessIcon === 'oa' &&
            item.openAccessLabel &&
            item.openAccessLabel.indexOf('Open Access') > -1
        ) {
            accessMarkup += ` - <strong class="OALabel">${unescape(item.openAccessLabel)}</strong>`;
        }

        return accessMarkup;
    }

    renderOpenArchive() {
        return `<div class="list-of-issues__open-archive"><h3>Open Archive</h3><p>Free access to archived material</p></div>`;
    }

    oaRendered(group) {
        if (this.groupBy === 'volume') {
            return false;
        }

        let rendered = false;
        let prevGroup = group.previousSibling;

        while (prevGroup && !rendered) {
            rendered = !!prevGroup.querySelector('.list-of-issues__open-archive');
            prevGroup = prevGroup.previousSibling;
        }
        return rendered;
    }
}

class ListOfIssuesFull {
    constructor($loiEl) {
        this.$loiEl = $loiEl;

        this.init();
    }

    init() {
        setTimeout(() => this.expandFirstGroup(), 250);
        this.openTargetTab();
    }

    expandFirstGroup() {
        const $firstIssuesGroup = this.$loiEl.querySelector(
            '.accordion__tab[data-tab="issues"]:first-of-type .accordion__control'
        );
        const $firstSupplementsGroup = this.$loiEl.querySelector(
            '.accordion__tab[data-tab="supplements"]:first-of-type .accordion__control'
        );

        if ($firstIssuesGroup) {
            $firstIssuesGroup.click();
        }

        if ($firstSupplementsGroup) {
            $firstSupplementsGroup.click();
        }
    }

    openTargetTab() {
        const {hash} = window.location;
        if (!hash) return;
        const targetTabBtn = this.$loiEl.querySelector(`.tab__nav__item a[href="${hash}"]`);
        const targetTabPanel = this.$loiEl.querySelector(`.tab__pane[id="${hash.replace(/^.*#/, '')}"]`);
        if (targetTabBtn && targetTabPanel.childNodes.length) targetTabBtn.click();
    }
}

// get pbContext and initialize ajax-style LOIs
const $pbContextEl = document.querySelector('[name="pbContext"]');
const $loiElAjax = document.querySelectorAll('.list-of-issues[data-loadtype="ajax"]');
const $loiElFull = document.querySelectorAll('.list-of-issues[data-loadtype="full"]');

if ($loiElAjax.length > 0 && $pbContextEl) {
    const pbContext = $pbContextEl.getAttribute('content');
    [...$loiElAjax].map($loi => {
        new ListOfIssuesAjax($loi, pbContext);
    });
}

if ($loiElFull.length > 0) {
    [...$loiElFull].map($loi => {
        new ListOfIssuesFull($loi);
    });
}
