(function ($) {
    let methods;

    function ellipsis(isLink) {
        return $(
            '<' +
                (isLink ? 'a href="#"' : 'span') +
                ' class="ellipsis"> ' +
                this.settings.ellipsisChar +
                ' </' +
                (isLink ? 'a' : 'span') +
                '>'
        );
    }

    function seeMoreLink() {
        if (this.settings.isMobile) {
            return $(
                '<li class="loa__item"><div class = "show-all-author"><a href="#" class="loa__item__name faded' +
                    ' read-more"' +
                    ' data-slide-target="' +
                    this.settings.mobileTarget +
                    '" style="white-space: nowrap;"> ... ' +
                    this.settings.seeMoreText +
                    '</a></div></li>'
            );
        } else {
            return $(
                '<li class="loa__item"><div class = "show-all-author"><a href="#" class="loa__item__name faded read-more"' +
                    ' style="white-space: nowrap;"> ... ' +
                    this.settings.seeMoreText +
                    '</a></div></li>'
            );
        }
    }

    function removeElements(containerEl) {
        let that = this;
        let $this = $(this);
        let height = $this.height();
        let maxHeight = this.settings.lines * Math.ceil(parseFloat($this.css('line-height')));
        let $lastRemoved;
        let $parent;

        let $seeMore;

        if (this.settings.seeMoreLink) {
            $seeMore = seeMoreLink.call(this);
        } else {
            $seeMore = ellipsis.call(this);
        }

        if (containerEl.get(0).nodeType === Node.ELEMENT_NODE) {
            while (height > maxHeight && containerEl.contents().length) {
                $lastRemoved = containerEl.contents().last().remove();
                height = $this.height();
            }

            if (height <= maxHeight && $lastRemoved) {
                if ($.trim($lastRemoved.text()) !== '') {
                    containerEl.append($lastRemoved);
                    height = $this.height();
                    if (height > maxHeight) {
                        removeElements.call(this, $lastRemoved);
                    }
                } else {
                    $this.append($seeMore);
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
            } else if ($.trim(containerEl.text()) === '') {
                $parent = containerEl.parent();
                containerEl.remove();
                height = $this.height();
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                } else if (height <= maxHeight) {
                    if ($this.children().length) {
                        let $lastChild = $this.children().last();
                        if ($lastChild.is('br')) {
                            $lastChild.after($seeMore);
                        } else {
                            $lastChild.parent().append($seeMore);
                        }
                    } else {
                        $this.append($seeMore);
                    }
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
            }
        } else {
            let textContent = containerEl.text();
            let min = 0;
            let max = textContent.length - 1;
            let mid = Math.floor((min + max) / 2);
            $parent = containerEl.parent();

            while (min < max) {
                if (height > maxHeight) {
                    //max = mid - 1;
                    max = mid + parseInt(((max - mid) * maxHeight) / height, 10);
                } else {
                    min = mid + 1;
                }
                $seeMore.remove();
                mid = Math.floor((min + max) / 2);
                containerEl.get(0).textContent = textContent.substr(
                    0,
                    this.settings.midWordEnabled ? mid : Math.min(mid, textContent.lastIndexOf(' ', mid))
                );
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                    return;
                }
                containerEl.parent().append($seeMore);
                height = $this.height();
            }

            while (height > maxHeight && mid > 0) {
                $seeMore.remove();
                mid = mid - 1;
                containerEl.get(0).textContent = textContent.substr(
                    0,
                    this.settings.midWordEnabled ? mid : Math.min(mid, textContent.lastIndexOf(' ', mid))
                );
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                    return;
                }
                containerEl.parent().append($seeMore);
                height = $this.height();
            }
            if (mid === 0) {
                $seeMore.remove();
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                }
            }

            if (height <= maxHeight) {
                if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
                $this.data('truncationDone', true);
                that.settings.onComplete();
            }
        }
    }

    function truncateText() {
        let that = this;
        let $this = $(this);
        let height = $this.height();
        let maxHeight = this.settings.lines * parseFloat($this.css('line-height'));
        let visible = !$this.closest(':not(:visible)').length;

        if (visible && height === 0) {
            setTimeout(function () {
                truncateText.call(that);
            }, 30);
            return;
        }

        if (height > maxHeight) {
            removeElements.call(this, $this);
        } else {
            setTimeout(function () {
                $this.addClass(that.settings.addClass);
            }, 0);
            $this.data('truncationDone', true);
            that.settings.onComplete();
        }
    }

    function removeListItemsEnd() {
        let that = this;
        let $this = $(this);
        let height;
        let lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        let maxHeight = this.settings.lines * lineHeight;
        let $seeMore;

        if (this.settings.seeMoreLink) {
            $seeMore = seeMoreLink.call(this);
        } else {
            $seeMore = ellipsis.call(this);
        }

        let removeItems = function () {
            height = $this.height();
            if (height > maxHeight) {
                while (height > maxHeight && $this.contents().length > 1) {
                    if ($this.find($seeMore).length) {
                        $($this.contents().get($this.contents().length - 2)).remove();
                    } else {
                        $this.contents().last().remove();
                        $this.append($seeMore);
                    }
                    height = $this.height();
                }
                setTimeout(removeItems, 1000);
            } else {
                if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
            }
        };

        removeItems();
    }

    function removeListItemsMiddle() {
        let $this = $(this);
        let $items = $this.children();

        if ($items.length > 1) {
            $this.html('');
            $this.append($items.first(), ellipsis.call(this, true), $items.last());
        }
    }

    function seeMoreMiddleLink() {
        if (this.settings.isMobile) {
            return $(
                '<li class="loa__item"><div class = "show-all-author"><a href="#" class="loa__item__name show-hide__authors faded' +
                    ' read-more w-slide__btn" data-slide-target="' +
                    this.settings.mobileTarget +
                    '" style="white-space: nowrap;"> ' +
                    this.settings.seeMoreText +
                    '</a></div></li>'
            );
        } else {
            return $(
                '<li class="loa__item"><div class = "show-all-author"><a href="#" class="loa__item__name show-hide__authors faded' +
                    ' read-more" style="white-space: nowrap;">' +
                    this.settings.seeMoreText +
                    '</a></div></li>'
            );
        }
    }

    function removeListItemsBeforeLast() {
        let that = this;
        let $this = $(this);
        let height = $this.height();
        let lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        let maxHeight = this.settings.lines * lineHeight;
        let $seeMoreLink = seeMoreMiddleLink.call(this);
        let $last1, $last2, $last3, $last4;
        let numberOfAuthors = $this.find('.author').length;
        let authors = $this.find('.author');
        let authorPlaceHolderShowDotDot = '<li class = "loa__item loa__item__remove-after dot-dot-dot"></li>';
        let authorPlaceHolderShowEtal =
            '<li class = "loa__item loa__item__remove-after"><span class = "etal-word">et' + ' al.</span></li>';
        let authorAttributeLength = parseInt($this.attr('data-number-of-author'));
        let counterFromStart =
            $this.attr('data-number-of-author') === undefined
                ? this.settings.ItemEllipsis / 2
                : authorAttributeLength / 2;
        let counterFromEnd = authors.length - Math.ceil(counterFromStart);
        counterFromStart = counterFromStart - 1;
        let counterFromStartEtal =
            $this.attr('data-number-of-author') === undefined ? this.settings.ItemNotEllipsis : authorAttributeLength;
        let ellipsisDefaultValue = this.settings.ItemEllipsis;
        let notEllipsisDefaultValue = this.settings.ItemNotEllipsis;
        let truncateType =
            this.settings.truncateType === null || this.settings.truncateType === undefined ? 'lines' : 'number';
        counterFromStartEtal = counterFromStartEtal - 1;
        let counterElementHide = 0;
        let counterElementDisplay = 0;
        let newItems = null;
        let flagRunEllipsis = false;
        let flagRunNotEllipsis = false;
        let removeItems = function () {
            height = $this.height();
            if (truncateType === 'number') {
                if ($this.hasClass('ellipsis-dot')) {
                    if (authors.length < authorAttributeLength && authors.length < ellipsisDefaultValue) {
                        flagRunEllipsis = false;
                    } else if (isNaN(authorAttributeLength) && authors.length < ellipsisDefaultValue) {
                        flagRunEllipsis = false;
                    } else {
                        flagRunEllipsis = true;
                    }
                    if (authors.length <= authorAttributeLength || authorAttributeLength < 1) {
                        if (authors.length < ellipsisDefaultValue) {
                            flagRunEllipsis = false;
                        } else if (authors.length === 6) {
                            flagRunEllipsis = false;
                        } else {
                            counterFromStart = ellipsisDefaultValue / 2 - 1;
                            counterFromEnd = authors.length - ellipsisDefaultValue / 2;
                        }
                    }
                    if (flagRunEllipsis) {
                        authors.each(function (index) {
                            if (index > counterFromStart && index < counterFromEnd) {
                                $(this).addClass('hide-author');
                                counterElementHide++;
                            }
                        });
                        counterElementDisplay = authors.length - counterElementHide;
                        newItems = $('.article-header__middle .author:visible');
                        newItems.eq(Math.floor(counterElementDisplay / 2)).before(authorPlaceHolderShowDotDot); // to set ... position
                        authors.last().after($seeMoreLink);
                    }
                } else {
                    if (authors.length <= authorAttributeLength || authorAttributeLength < 1) {
                        if (authors.length < notEllipsisDefaultValue) {
                            flagRunNotEllipsis = false;
                        } else if (authors.length === 6) {
                            flagRunNotEllipsis = false;
                        } else {
                            flagRunNotEllipsis = true;
                            counterFromStartEtal = notEllipsisDefaultValue - 1;
                        }
                    } else {
                        flagRunNotEllipsis = true;
                    }
                    if (flagRunNotEllipsis) {
                        authors.each(function (index) {
                            if (index > counterFromStartEtal) {
                                $(this).addClass('hide-author');
                            }
                        });
                        authors.eq(counterFromStartEtal).after(authorPlaceHolderShowEtal);
                        authors.last().after($seeMoreLink);
                    }
                }
            } else if (truncateType === 'lines') {
                if (height > maxHeight + 1) {
                    while (numberOfAuthors > 3 && height > maxHeight + 1 && $this.children().length > 2) {
                        $seeMoreLink.remove();
                        if ($('.article-header__footnotes').length) {
                            $last1 = $this.children().last().remove();
                            $last2 = $this.children().last().remove();
                            $last3 = $this.children().last().remove();
                            $last4 = $this.children().last().remove().addClass('dot-dot-dot');
                            $this.contents().last().remove();
                            $this.append($last4, $last3, $last2, $seeMoreLink, $last1);
                        } else {
                            $last1 = $this.children().last().remove();
                            $last2 = $this.children().last().remove();
                            $last3 = $this.children().last().remove().addClass('dot-dot-dot');
                            $this.contents().last().remove();
                            $this.append($last3, $last2, $last1, $seeMoreLink);
                        }
                        numberOfAuthors = $this.find('.author').length;
                        height = $this.height();
                    }
                    setTimeout(removeItems, 1000);
                } else {
                    if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                }
            }
        };
        removeItems();
    }

    function truncateList() {
        let that = this;
        let $this = $(this);
        let height = $this.height();
        let lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        let maxHeight = this.settings.lines * lineHeight;

        if (height === 0 || maxHeight === 0) {
            setTimeout(function () {
                truncateList.call(that);
            }, 10);
            return;
        }

        if (this.settings.position === 'end') {
            if (height > maxHeight) {
                removeListItemsEnd.call(this, $this);
            } else if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                setTimeout(function () {
                    $this.addClass(that.settings.addClass);
                }, 0);
            }
        } else if (this.settings.position === 'middle') {
            removeListItemsMiddle.call(this, $this);
        } else if (this.settings.position === 'beforeLast') {
            removeListItemsBeforeLast.call(this, $this);
        }
    }

    function seeLessLink() {
        return $(
            '<li class="loa__item"><div class = "show-all-author"><a href="#" class="loa__item__name faded show-hide__authors read-less"' +
                ' style="white-space: nowrap;">' +
                this.settings.seeLessText +
                '</i></a></div></li>'
        );
    }

    function addEventHandlers() {
        let that = this;
        let $this = $(this);

        $this.on('click', function (evt) {
            if ($(evt.target).hasClass('ellipsis') && $(evt.target).is('a')) {
                evt.preventDefault();
                $this.data('truncatedContent', $this.html());
                $this.html($this.data('originalContent'));
                let lessLink = seeLessLink.call(that);
                $this.append(lessLink);
            }

            if (
                ($(evt.target).hasClass('read-more') && !$(evt.target).hasClass('w-slide__btn')) ||
                ($(evt.target).parent().hasClass('read-more') && !$(evt.target).parent().hasClass('w-slide__btn'))
            ) {
                evt.preventDefault();
                $('ul[data-number-of-author] .loa__item .js--open').removeClass('js--open');
                $this.data('truncatedContent', $this.html());
                $this.html($this.data('originalContent'));
                let lessLink = seeLessLink.call(that);
                // $this.append(lessLink);
                if ($('.article-header__footnotes').length) {
                    lessLink.insertBefore($this.children().last());
                } else {
                    lessLink.insertAfter($this.children().last());
                }
            }

            if ($(evt.target).hasClass('read-less') || $(evt.target).parent().hasClass('read-less')) {
                evt.preventDefault();
                $this.html($this.data('truncatedContent'));
                UX.LSN.scroll($(this), '#articleHeader');
                //let moreLink = seeMoreLink.call(that);
                //$(evt.target).replaceWith(moreLink);
            }
        });
    }

    methods = {
        init: function (options) {
            return this.each(function () {
                let $this = $(this);
                let firstInit = true;
                if ($this.data('originalContent')) {
                    $this.html($this.data('originalContent'));
                    firstInit = false;
                }
                $this.data('originalContent', $this.html());
                if (options) {
                    this.settings = $.extend({}, $.fn.truncate.defaults, options);
                }
                if (this.settings.type === 'text') {
                    truncateText.call(this);
                } else if (this.settings.type === 'list') {
                    truncateList.call(this);
                }
                if (firstInit) {
                    addEventHandlers.call(this);
                }
            });
        },
        destroy: function () {
            return this.each(function () {
                let $this = $(this);
                if ($this.data('originalContent')) {
                    $this.html($this.data('originalContent'));
                    if (this.settings.addClass) {
                        $this.removeClass(this.settings.addClass);
                    }
                }
            });
        },
    };

    $.fn.truncate = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist on jQuery.truncate');
        }
    };

    /* Default truncate settings. */
    $.fn.truncate.defaults = {
        type: 'text', // text, list
        position: 'end', // end, middle, beforeLast
        lines: 1,
        seeMoreLink: false,
        ItemNotEllipsis: 6,
        ItemEllipsis: 6,
        seeMoreText: 'See More',
        seeLessText: 'See Less',
        ellipsisChar: '&hellip;',
        isMobile: false,
        mobileTarget: '',
        addClass: '',
        onComplete: function () {},
    };
})(jQuery);
