(function (UX) {
    /**
     * UX Namespace for tests
     * @namespace
     * @type {*|{}}
     */
    UX.test = UX.test || {};

    /**
     * detect Safari
     */
    UX.test.isSafari = function isIE() {
        let chromeAgent = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        let safariAgent = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

        return safariAgent && !chromeAgent;
    };
})(UX);
