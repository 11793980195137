(function () {
    let mediaPlayer = {
        players: 'null',
        init: function () {
            if (
                !(
                    $('article .mediaPlayer').length ||
                    $('article .cloudflare-stream-container').length ||
                    $('.mediaPlayer').length
                )
            ) {
                return false;
            }
            mediaPlayer.players = $('.mediaPlayer, .cloudflare-stream-player');
            mediaPlayer.players.each(function (i) {
                let _this = this;
                return (function (_this, i) {
                    let $id = $(_this).find('.mediaPlayer__player-holder').attr('id');
                    let cfId = $(_this).attr('id');
                    if (cfId !== undefined && cfId.startsWith('video_stream')) {
                        $id = $(_this).attr('id');
                    }
                    if (!$id) {
                        $id = 'mediaPlayer__player' + i;
                        $(_this).find('.mediaPlayer__player-holder').attr('id', $id);
                    }

                    let $file = $(_this).find('.html5__media').text().trim();
                    let $storyUrl = $(_this).find('.jwplayer__storyMediaUrl').text().trim();
                    let thumbnail = $(_this).attr('data-thumbnail-image');
                    let $preload = $(_this).attr('data-preload') ? $(_this).attr('data-preload') : 'metadata';

                    if (!$(_this).hasClass('ready')) {
                        let mediaWidth =
                            typeof $(_this).data('width') !== 'undefined' ? $(_this).data('width') : 'auto';
                        let mediaWidthUnits =
                            typeof $(_this).data('widthunits') !== 'undefined' &&
                            $(_this).data('widthunits') === 'percentage'
                                ? '%'
                                : 'px';
                        let mediaHeight =
                            typeof $(_this).data('height') !== 'undefined' ? $(_this).data('height') : 'auto';
                        let mediaHeightUnits =
                            typeof $(_this).data('heightunits') !== 'undefined' &&
                            $(_this).data('heightunits') === 'percentage'
                                ? '%'
                                : 'px';

                        if ($(_this).hasClass('audio')) {
                            const maxAudioHeight = 57;
                            const defaultAudioWidth = 300;
                            mediaHeight =
                                mediaHeightUnits === 'px' && mediaHeight !== 'auto' && mediaHeight < maxAudioHeight
                                    ? mediaHeight
                                    : maxAudioHeight;
                            mediaHeightUnits = 'px';
                            let config = {
                                src: $file,
                                width: mediaWidth !== 'auto' ? mediaWidth + mediaWidthUnits : defaultAudioWidth,
                                height: mediaHeight + mediaHeightUnits,
                                controls: true,
                                id: $id,
                                preload: $preload,
                            };

                            if (thumbnail) {
                                config.poster = thumbnail;
                            }

                            UX.Html5Player.setup($(_this).find('.mediaPlayer__player-holder'), config);
                            $(_this).addClass('ready');
                        } else {
                            let config = {
                                src: $file,
                                width: mediaWidth !== 'auto' ? mediaWidth + mediaWidthUnits : mediaWidth,
                                height: mediaHeight !== 'auto' ? mediaHeight + mediaHeightUnits : mediaHeight,
                                controls: true,
                                id: $id,
                                shareUrl: $storyUrl,
                                preload: thumbnail ? $preload : 'auto',
                            };

                            if (thumbnail) {
                                config.poster = thumbnail;
                            }

                            UX.Html5Player.setup($(_this).find('.mediaPlayer__player-holder'), config);
                        }
                        $(_this).addClass('ready');
                    }

                    UX.Html5Player.onReady($id, function () {
                        UX.mediaAdobeAnalatics.attach($(_this), this);
                    });
                })(_this, i);
            });
            UX.mediaAdobeAnalatics.init();
        },
        pauseMedia: function (playingMediaId) {
            /*$('body').find('.jwplayer, object').each(function(){
                currentMediaId = $(this).attr('id');
                if( jwplayer(this).getState() == "playing" || jwplayer(this).getState() == "buffering" ) {
                    if(currentMediaId !== playingMediaId){
                        jwplayer(this).play(false);
                    }
                }
            });*/
        },
    };

    UX.mediaPlayer = mediaPlayer; // add to global namespace

    if (window.PB && window.PB.$) {
        window.PB.$(document.documentElement).on('WidgetReinit.UX3Media', () => {
            UX.mediaPlayer.init();
        });
    }
})();
