(function () {
    if (typeof $('.content-navigation__body__title').truncate !== 'undefined') {
        $('.content-navigation__body__title').truncate({lines: 2});
    }

    let adjustFeedbackButtonPosition = () => {
        let $usabillaLiveButton = $('.usabilla_live_button_container');
        if ($usabillaLiveButton.length && $('.content-navigation.js--sticky').length) {
            $usabillaLiveButton.addClass('move-it');
        } else {
            $usabillaLiveButton.removeClass('move-it');
        }
    };

    $(window).on('load scroll', adjustFeedbackButtonPosition);
})();
