window.observeDOM = (function () {
    let MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
        eventListenerSupported = window.addEventListener;

    return function (obj, withMutations, observerOptions, callback) {
        if (MutationObserver) {
            // define a new observer
            let obs = new MutationObserver(function (mutations) {
                if (withMutations) {
                    callback(mutations);
                } else {
                    if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) callback();
                }
            });
            let options = observerOptions || {childList: true, subtree: true};
            if (obj) {
                obs.observe(obj, options);
            }
        } else if (eventListenerSupported) {
            obj.addEventListener('DOMNodeInserted', callback, false);
            obj.addEventListener('DOMNodeRemoved', callback, false);
        }
    };
})();
