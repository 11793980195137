(function () {
    let $window = $(window),
        header = '.header.fixed';
    let topAds = document.querySelector('header .gpt-ad');
    let pageBody = {
        contentSelectors: [],
        contentPaddingTop: 0,
        header: null,
        windowWidth: $window.width(),
        init: function () {
            this.header = header;
            this.contentSelectors = ['main.content', '.header ~ .content'];
            if (topAds) {
                window.observeDOM(topAds, true, {attributes: true}, function () {
                    pageBody.on.setContentTopPadding();
                });
            }
            pageBody.on.setContentTopPadding();

            pageBody.control();
            pageBody.extraControls();
        },
        skipToContent: function (e) {
            e.preventDefault();
            const {target} = e;
            const href = target.href.substring(target.href.indexOf('#'));
            if (href.indexOf('#') < 0) return;
            const targetEl = document.querySelector(href);
            if (targetEl) {
                targetEl.tabIndex = -1;
                targetEl.focus();
                setTimeout(() => {
                    window.scrollTo(0, targetEl.offsetTop - pageBody.contentPaddingTop);
                    targetEl.removeAttribute('tabindex');
                }, 25);
            }
        },
        control: function () {
            $(document).on('eventSetContentPadding', pageBody.on.setContentTopPadding);
            $window.on('resize', function () {
                if ($window.width() !== pageBody.windowWidth) {
                    pageBody.windowWidth = $window.width();
                    pageBody.on.setContentTopPadding();
                }
            });

            // Resize Observer
            // TODO: If you want to use this functionality in your product, you have to import resizeObserverPolyfill from plugins in all.js
            if (typeof ResizeObserver === 'function' && $(pageBody.header).length > 0) {
                let ro = new ResizeObserver(function () {
                    $(document).trigger('eventSetContentPadding');
                });
                ro.observe(document.querySelector(pageBody.header));
            }

            const skipLinks = document.querySelectorAll('.skipToContent');
            skipLinks.forEach(link => link.addEventListener('click', pageBody.skipToContent));
        },
        extraControls: function () {},
        on: {
            setContentTopPadding: function () {
                pageBody.contentPaddingTop = 0;
                const headerElements = $(`${header}, .header.auto-hide-bar`);
                if (headerElements.length) {
                    pageBody.contentPaddingTop = headerElements.outerHeight();
                }
                if ($('.scrollThenFix.locked').length) {
                    pageBody.contentPaddingTop += $('.scrollThenFix.locked').outerHeight();
                }
                if ($('.fixed-element').length) {
                    $.each($('.fixed-element:not(.header)'), function (index, value) {
                        if ($(value).outerHeight()) {
                            pageBody.contentPaddingTop += $(value).outerHeight();
                        }
                    });
                }
                pageBody.setTopPadding(pageBody.contentPaddingTop);
            },
        },
        setTopPadding: function (value) {
            if ($('.pb-edit-view').length) return;
            let topPaddingHandler = $('.top-padding-handler');
            if (topPaddingHandler.length) {
                topPaddingHandler.css('padding-top', value);
            } else {
                let mainContent = $('main.content');
                if (mainContent.closest('.pb-edit-view').length > 0) {
                    mainContent.css('padding-top', 0);
                } else {
                    mainContent.css('padding-top', value);
                }
                $('.legacy-pb-page-content').css('padding-top', value);
            }
        },
    };

    UX.pageBody = pageBody; // add to global namespace
})();
