(function () {
    const articleTools = {
        elm: null,
        articleNav: null,
        elmWrapper: null,

        init: function () {
            this.elm = document.querySelector('.article-tools');
            this.articleNav = document.querySelector('.content-navigation');
            this.elmWrapper = document.querySelector('.article-tools__wrapper');
            articleTools.control();
        },
        closeParentPopup: e => {
            const {currentTarget} = e;
            if (currentTarget.closest('#navigation_menu_pdf_block')) {
                const parent = currentTarget.closest('.article-tools__dropblock');
                const button = parent.querySelector('button');
                button?.click();
            } else if (currentTarget.closest('.article-tools__dropblock__holder')) {
                UX.dropBlock.on.hide();
            }
        },
        control: function () {
            $('.article-tools__header').append($('h1').html());

            const notAxel = document.querySelector('article[data-design]');
            if (!notAxel) {
                $(window).on('scroll', articleTools.on.scroll);
            }

            $('.back-to-top').click(function (e) {
                e.preventDefault();
                $('html,body').animate(
                    {
                        scrollTop: 0,
                    },
                    700
                );
            });

            $('a.mendeley').on('click', function () {
                document.body
                    .appendChild(document.createElement('script'))
                    .setAttribute('src', 'https://www.mendeley.com/minified/bookmarklet.js');
            });

            const axelContentWrapper = document.querySelector('[data-core-wrapper="content"]');
            if (axelContentWrapper) {
                const downloadPdfButtons = axelContentWrapper.querySelectorAll('.article-tools__sub-item a');
                downloadPdfButtons.forEach(item => item.addEventListener('click', articleTools.closeParentPopup));
            }
        },
        on: {
            scroll: function (e) {
                let scrollTop = $(window).scrollTop();
                let headerHeight = $('header').outerHeight() || 0;
                let topAds = $('.adplaceholder--header');
                let adHeight =
                    topAds.hasClass('mobile-hidden') || topAds.css('display') === 'none'
                        ? 0
                        : topAds.outerHeight() - 1 + 'px';
                let articleHeaderHeight = $('.article-header').outerHeight() || 0;
                let stickypoint = articleHeaderHeight;

                if (scrollTop > stickypoint) {
                    if (articleTools.elm) {
                        articleTools.elm.classList.add('sticky');
                    }
                    if (articleTools.elmWrapper) {
                        if ($(window).width() >= UX.grid.screenSm) {
                            articleTools.elmWrapper.style.top = headerHeight - 1 + 'px';
                        } else {
                            articleTools.elmWrapper.style.top = adHeight;
                        }
                    }
                    if (articleTools.articleNav) {
                        articleTools.articleNav.classList.add('js--sticky');
                    }
                } else {
                    if (articleTools.elm) {
                        articleTools.elm.classList.remove('sticky');
                        if (UX.dropBlock && $('.article-tools__holder').find('.js--open').length) {
                            UX.dropBlock.on.hide();
                        }
                    }
                    if (articleTools.elmWrapper) {
                        articleTools.elmWrapper.style.top = 0;
                    }
                    if (articleTools.articleNav) {
                        articleTools.articleNav.classList.remove('js--sticky');
                    }
                }
            },
        },
    };

    UX.articleTools = articleTools;
})();
