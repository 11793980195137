(function () {
    UX.toggle.additionalControls = function () {
        let $dateRange = UX.toggle.$toggle.find('.date-range');
        let $dateFrom = $dateRange.find('[name="date-range-from"]');
        let $dateTo = $dateRange.find('[name="date-range-to"]');
        let $error = $dateRange.find('.error');
        let minDate = parseInt($dateRange.data('mindate'), 10);
        let maxDate = parseInt($dateRange.data('maxdate'), 10);

        $dateRange.find('input').on('keydown', function (e) {
            const ENTER_KEY = 13;
            if ((e.keyCode || e.which) === ENTER_KEY) {
                let dateFrom = parseInt($dateFrom.val(), 10);
                let dateTo = parseInt($dateTo.val(), 10);

                if (
                    !isNaN(dateFrom) &&
                    !isNaN(dateTo) &&
                    dateFrom <= dateTo &&
                    dateFrom >= minDate &&
                    dateTo <= maxDate
                ) {
                    let pageUrl = new URL(window.location.href);
                    let searchParams = pageUrl.searchParams;
                    searchParams.set('AfterYear', dateFrom);
                    searchParams.set('BeforeYear', dateTo);
                    window.location.href = pageUrl;
                } else {
                    $error.removeClass('hidden');
                }
            }
        });

        $dateRange.find('input').on('focus', function (e) {
            $error.addClass('hidden');
        });
    };

    UX.toggle.on.build = function () {
        UX.toggle.$toggle.each(function () {
            var $target = $(this).find('ul.facet__list');
            let $select = $(this).find('.facet__select ');
            let moreCount = 0;
            let isToggle;
            let $hiddenItemsEl = $target.next('[name="facet-hidden-items"]');

            if ($hiddenItemsEl.length) {
                moreCount = $hiddenItemsEl.val();
                if (moreCount > 0) {
                    isToggle = true;
                }
            }

            $target.closest('.facet').attr('data-more-count', moreCount);

            if (isToggle) {
                $target.after(UX.toggle.get.showMore(moreCount));
            }

            $select.each(function () {
                var mis = $(this).magicSuggest({
                    hideTrigger: 'true',
                    allowFreeEntries: 'false',
                    expandOnFocus: true,
                    maxSelection: 1,
                    placeholder: $(this).data('placeholder'),
                });
                $(mis).on('selectionchange', function (e, m, selection) {
                    if (selection !== undefined) {
                        window.location.href = selection[0].id;
                    }
                });
            });
        });
    };

    UX.toggle.get.showMore = function (count) {
        return `<a href="#" class="show-more" aria-expanded="false"> ${UX.toggle.moreLabel} <i class="icon-section_arrow_d" aria-hidden="true"></i></a>`;
    };

    UX.toggle.on.toggle = function (elem) {
        var $toggle = elem.closest('.facet');
        var $target = $toggle.find('ul.facet__list');

        $target.find('.js--toggle').slideToggle();
        $toggle.find('.facet__select--hidden').toggle();

        elem.toggleClass('js--open');
        if (elem.hasClass('js--open')) {
            elem.html(UX.toggle.lessLabel + '<i class="icon-section_arrow_u" aria-hidden="true"></i>');
            elem.closest('.accordion__content').find('li:nth-child(6) a').focus();
            elem.attr('aria-expanded', true);
        } else {
            elem.html(UX.toggle.moreLabel + ' <i class="icon-section_arrow_d" aria-hidden="true"></i>');
            elem.closest('.accordion__content').find('li:first-child a').blur();
            elem.attr('aria-expanded', false);
        }
    };
})();
