window.loadRecaptcha = function () {
    try {
        if (typeof grecaptcha == 'undefined') return;
        $('.g-recaptcha')
            .filter(function () {
                return !$(this).hasClass('explicit');
            })
            .each(function () {
                grecaptcha.render($(this)[0], $(this).data());
            });
        $('.g-recaptcha iframe').attr('title', 'reCaptcha');
    } catch (e) {
        console.error(e);
    }
};
window.clearCapcha = function () {
    if (typeof grecaptcha != 'undefined') grecaptcha.reset(0);
};
window.captchaChallengeSubmit = function captchaChallengeSubmit(response) {
    $('textarea#g-recaptcha-response').each(function () {
        if ($(this).val() === response) {
            $(this).closest('form').submit();
        }
    });
};
