var lazyLoad = {
    lazyLoadSelector: '.b-lazy',
    init: function () {
        lazyLoad.blazy = new Blazy({
            selector: lazyLoad.lazyLoadSelector,
            success: function (element) {
                element.classList.add('ux-lazyloaded');
                lazyLoad.hiddenImage('.ux-lazyloaded');
                lazyLoad.loadImageFigure('.ux-lazyloaded');
                if ($('.cross-mark-lazyLoad').length > 0) {
                    $('.cross-mark-lazyLoad[data-src]').each(function () {
                        $(this).attr('src', $(this).attr('data-src'));
                        $(this).removeAttr('data-src');
                    });
                }
            },
        });
        lazyLoad.control();
    },
    hiddenImage: function (className) {
        var imagesParent = $(className).siblings();
        var imagesHidden = imagesParent.find('img[data-src]');
        if (imagesHidden.length > 0) {
            imagesHidden.each(function () {
                $(this).attr('src', $(this).attr('data-src'));
                $(this).removeAttr('data-src');
            });
        }
    },
    loadImageFigure: function (className) {
        if ($(className).closest('figure').length > 0) {
            // UX.figureViewer.firstLazyload = false;
            if ($(className).closest('.thumbnail__image--img-ratio').length > 0) {
                $(className).each(function () {
                    if (!$(this).hasClass('img-aspect-ratio-checked')) {
                        var imgAspectRatio = parseFloat(($(this).width() / $(this).height()).toPrecision(3));
                        if (imgAspectRatio > 1.45) {
                            $(this).addClass('wide');
                        } else if (imgAspectRatio <= 1.45) {
                            $(this).addClass('tall');
                        }
                        $(this).addClass('img-aspect-ratio-checked');
                    }
                });
            }
            var framesLazy = $('.frames img[data-src]');
            if (framesLazy.length) {
                framesLazy.each(function (index) {
                    $(this).attr('src', $(this).attr('data-src'));
                    $(this).removeAttr('data-src');
                });
            }
        }
    },
    control: function () {
        $(document).on('blazy-loadAll', function () {
            lazyLoad.loadAll();
        });
    },
    loadAll: function () {
        UX.lazyload.blazy.load($('.b-lazy:not(.b-loaded)'));
    },
};
UX.lazyload = lazyLoad;
