// import 'ui-core/widgets/raa/js/loginForm';
import '../../../widgets/raa/js/loginPopup';
import 'ui-core/widgets/raa/js/requestResetPasswordForm';
import '../../../widgets/raa/js/requestResetPasswordPopup';

import '../../../widgets/quick-search/js/quick-search';
import '../../../widgets/article-tools/js/article-tools';
import '../../../widgets/publication-content/js/publication-content';
import '../../../widgets/menu/js/menu';
import '../../../widgets/ad-placeholder/js/ad-placeholder';
import '../../../widgets/content-navigation/js/content-navigation';
import '../../../widgets/toc-header/js/toc-header.js';
import '../../../widgets/table-of-content/js/table-of-content.js';
import '../ad-placeholder/js/ad-placeholder';
// import '../../../widgets/share/js/share';
import '../../../widgets/checkout/js/checkout';
import '../../../widgets/subscriptions/js/subscriptions';
import '../../../widgets/raa/js/loginForm';

import 'ui-core/widgets/slideshow/js/slideshow';

// search
// import 'ui-core/widgets/advanced-search/js/advanced-search';
import '../../../widgets/advanced-search/js/advanced-search-core';
import '../../../widgets/advanced-search/js/advanced-search';
import 'ui-core/widgets/search-result/js/search-result.js';
import '../../../widgets/search-result/js/search-result.js';
import '../../../widgets/search-in-collection/js/search-in-collection.js';

// LOI
import '../../../widgets/list-of-issues/js/list-of-issues';

import Registration from '../../../widgets/registration/js/registration';
UX.registration = new Registration();

// collections
import CollectionAlerts from '../../../widgets/collection-alerts/js/collection-alerts';
UX.collectionAlerts = new CollectionAlerts();

import BrowseCollections from '../../../widgets/browse-collections/js/browse-collections';
UX.browseCollections = new BrowseCollections();

// home
import '../../../widgets/cover-image/js/cover-image';

// publication list
import MostCitedArticles from '../../../widgets/most-cited-articles/js/most-cited-articles';
UX.mostCitedArticles = new MostCitedArticles();

// audio / video
import '../../../widgets/audio-video/js/audio-video';

// multi search
import '../../../widgets/multi-search/js/multi-search';
