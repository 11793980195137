export default class MostCitedArticles {
    selectors = {
        accordionControls: '.publication-list .accordion-tabbed__control',
        previewLinks: '.publication-list .preview_link',
        articlesLoaderWrapper: '.cited-articles-wrapper',
        articlesLoader: '.articles-loader',
        loader: '.js--loading',
    };

    html = {
        loader: '<div class="js--loading" aria-label="loading articles" role="alert"></div>',
    };

    $elements = {};

    constructor() {
        const accordionControls = document.querySelector(this.selectors.accordionControls);
        const previewLinks = document.querySelector(this.selectors.previewLinks);
        const articlesLoaderWrapper = document.querySelector(this.selectors.articlesLoaderWrapper);

        if (accordionControls || previewLinks) {
            this.init();
        } else if (articlesLoaderWrapper) {
            this.articlesLoader();
        }
    }

    init() {
        this.getElements();
        this.setInitialState();
        this.addListeners();
    }

    getLazyLoadElements() {
        this.$elements.articlesLoaderWrapper = document.querySelector(this.selectors.articlesLoaderWrapper);
        this.$elements.articlesLoader = document.querySelector(this.selectors.articlesLoader);
    }

    getElements() {
        this.$elements.accordionControls = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.accordionControls)
        );
        this.$elements.previewLinks = Array.prototype.slice.call(
            document.querySelectorAll(this.selectors.previewLinks)
        );
    }

    setInitialState() {
        if (this.$elements.accordionControls)
            this.$elements.accordionControls.forEach(controlEl => controlEl.setAttribute('title', 'Expand'));

        if (this.$elements.previewLinks)
            this.$elements.previewLinks.forEach($previewLink => $previewLink.setAttribute('title', 'Expand'));
    }

    previewLinkClickHandler = e => {
        e.preventDefault();
        // @toDo: this jQuery code should be removed and replaced with pure js code that imitating the slide functionality.
        $(e.target)
            .toggleClass('js--open')
            .closest('.search__item')
            .find('.preview_content')
            .stop(true)
            .slideToggle(200);
        let currentTitle = e.target.getAttribute('title');
        e.target.setAttribute('title', currentTitle === 'Expand' ? 'Collapse' : 'Expand');
    };

    addListeners() {
        if (this.$elements.accordionControls) {
            this.$elements.accordionControls.forEach(controlEl => {
                controlEl.addEventListener('click', e => {
                    let currentTitle = controlEl.getAttribute('title');
                    controlEl.setAttribute('title', currentTitle === 'Expand' ? 'Collapse' : 'Expand');
                });
            });
        }

        if (this.$elements.previewLinks) {
            this.$elements.previewLinks.forEach(item => {
                item.addEventListener('click', this.previewLinkClickHandler);
            });
        }

        if (window.PB && window.PB.$) {
            window.PB.$(document.documentElement).on('WidgetReinit.UX3MostCited', () => {
                UX.accordion.init();
            });
        }
    }

    articlesLoader() {
        this.getLazyLoadElements();
        if (!this.$elements.articlesLoader) return;
        const observer = new IntersectionObserver(this.intersectionHandler.bind(this));
        observer.observe(this.$elements.articlesLoader);
    }

    additionalOnFetchSuccess() {
        UX.accordion.init();
        this.init();
        UX.UX3AdobeAnalytics.init();
    }

    async intersectionHandler(entries) {
        if (!entries.find(entry => entry.isIntersecting)) return;
        const articlesLoaderData = this.$elements.articlesLoader.dataset.ajaxLoad;
        this.loading('show');
        const res = await fetch(articlesLoaderData);
        const data = await res.json();
        this.$elements.articlesLoader.insertAdjacentHTML('afterend', data.html);
        this.loading('hide');
        this.$elements.articlesLoader.remove();
        this.additionalOnFetchSuccess();
    }

    loading(status) {
        const loadingEL = this.$elements.articlesLoaderWrapper.querySelector(this.selectors.loader);
        switch (status) {
            case 'show':
                if (!loadingEL) this.$elements.articlesLoaderWrapper.insertAdjacentHTML('beforeend', this.html.loader);
                break;
            case 'hide':
                if (loadingEL) loadingEL.remove();
                break;
        }
    }
}
