(function () {
    const LSN = {
        $html: null,
        $upperWrapper: null,
        $bottomWrapper: null,
        $topSections: null,
        $bottomSections: null,
        $lnfMobileCtrl: null,
        lnfAnchors: $(),
        articleTos: null,
        adPlaceholders: null,
        space: 20,
        adHeight: null,
        articleToolsHeight: null,
        headerHeight: null,
        $header: $(),
        isMobile: $(window).width() <= UX.grid.screenSm,
        lnfOpenedOnMobile: false,
        isPageLocked: false,
        vPort: 'screen-md',
        lockBodyScrollTop: 0,
        preselectSectionInterval: null,
        isClicked: false,

        init: function () {
            this.$html = $('html');
            this.$header = $('.header');
            this.articleTos = document.querySelector('.left-side-nav');
            this.$upperWrapper = $('.left-side-nav__upper'); // get top section wrapper
            this.$bottomWrapper = $('.left-side-nav__bottom'); // get bottom section wrapper
            this.$topSections = $('section > h2.top, section > .do-article-page > h2.top:visible'); // list all top section headers
            this.$bottomSections = $('section > h2.bottom'); // list all bottom section headers
            this.$lnfMobileCtrl = $('.left-side-nav__ctrl'); // Mobile drawer
            this.adPlaceholders = document.querySelector('.adplaceholders:not(.not-sticky)');
            this.$scrollIntoLinks = $('.scroll-into-link'); // list all scroll to links with in the page
            this.headerHeight = this.$header.length ? LSN.get.headerHeight() : 0; // get Header height to set LSN top postion
            this.articleToolsHeight = $('.article-tools__wrapper').length ? LSN.get.articleToolsHeight() : 0; // get article tools height to set LSN top position

            LSN.fill.top(); // fill top side bar nav
            LSN.fill.bottom(); // fill bottom side bar nav
            LSN.control(); // attach related events
            LSN.set.lnfHeight(); // set table of sections (left side bar nav bar ) height
            LSN.set.scrollIntoLinks();
            LSN.on.load();
            LSN.check();
        },
        control: function () {
            $(document).on(LSN.vPort + '-on', function () {
                LSN.isMobile = true;
            });
            $(document).on(LSN.vPort + '-off', function () {
                LSN.isMobile = false;
            });
            $(document).on('LNS-refill', function () {
                // refill side bar navs after ajax response
                LSN.fill.top(); // fill top side bar nav
                LSN.fill.bottom(); // fill bottom side bar nav
            });
            $(window).on('scroll', LSN.on.scroll);
            if (LSN.lnfAnchors !== null) {
                $(document).on('click', '.left-side-nav a', function (e) {
                    if ($(this).attr('href').replace(/^.*#/, '') === 'relatedArticles') LSN.isClicked = true;
                    clearInterval(LSN.preselectSectionInterval);
                    e.preventDefault();
                    if (LSN.isMobile) {
                        LSN.lnf.close(); // close left side bar nav on mobile before scrolling in to section
                    }
                    LSN.scroll($(this), this.hash); // scroll with animation to hash
                    e.stopPropagation();
                });
            }

            LSN.$lnfMobileCtrl.on('click', function () {
                LSN.lnf.toggle();
            });
        },
        fill: {
            top: function () {
                // got through all top sections header and add them to left side bar nav top sections nav list
                LSN.$topSections = $(
                    'section > h2.top:not(:empty), section > .do-article-page > h2.top:visible:not(:empty)'
                ); // update all top sections before start filling, needed in case of ajax.
                LSN.$upperWrapper = $('.left-side-nav__upper');
                if (LSN.$upperWrapper.length && LSN.$topSections.length) {
                    // check if top wrapper exist and we have top sections
                    $('.left-side-nav__upper').empty(); // empty left side navigation top list before start filling
                    let isHASite = $('.table-of-content').data('is-ha-site');
                    LSN.$topSections.each(function () {
                        const $this = $(this);
                        $this.attr('tabindex', '-1');
                        let thisText;
                        if ($this.attr('data-left-hand-nav') !== undefined) {
                            thisText = $this.attr('data-left-hand-nav');
                        } else {
                            thisText = isHASite ? $this.html() : $this.text();
                        }
                        const href = $this.attr('id') !== undefined ? `#${$this.attr('id')}` : '';
                        const li = $('<li/>').attr('role', 'menuitem').appendTo(LSN.$upperWrapper);
                        const link = $('<a class="w-slide__hide"/>').attr('href', href).appendTo(li);
                        const span = $('<span/>');

                        span.html(thisText).appendTo(link);
                    });
                    LSN.$upperWrapper.removeClass('hidden'); // Show upper section list after fill
                    LSN.lnfAnchors = LSN.lnfAnchors.add($('.left-side-nav a'));
                } else {
                    LSN.$upperWrapper.addClass('hidden'); // hide upper section list
                }
            },
            bottom: function () {
                // got through all bottom sections header and add them to left side bar nav top sections nav list
                LSN.$bottomSections = $('section > h2.bottom:not(:empty)'); // update all bottom sections before start filling, needed in case of ajax.
                LSN.$bottomWrapper = $('.left-side-nav__bottom');
                if (LSN.$bottomWrapper.length && LSN.$bottomSections.length) {
                    // check if bottom wrapper exist and we have bottom sections
                    $('.left-side-nav__bottom').empty();
                    LSN.$bottomSections.each(function () {
                        const thisText = $(this).find('.bottom_text').length
                            ? $(this).find('.bottom_text').text()
                            : $(this).text(); // get section text without index in case if exist
                        const $this = $(this);
                        const href = $this.attr('id') !== undefined ? `#${$this.attr('id')}` : '';
                        const li = $('<li/>').attr('role', 'menuitem').appendTo(LSN.$bottomWrapper);
                        const link = $('<a class="w-slide__hide"/>').attr('href', href).appendTo(li);
                        const span = $('<span/>');

                        span.text(thisText).appendTo(link);
                    });
                    LSN.$bottomWrapper.removeClass('hidden'); // Show upper section list after fill
                    LSN.lnfAnchors = LSN.lnfAnchors.add($('.left-side-nav a'));
                } else {
                    LSN.$bottomWrapper.addClass('hidden'); // hide upper section list
                }
            },
        },
        check: function () {
            const dScrollTop = parseInt($(document).scrollTop());
            if (LSN.lnfAnchors !== null) {
                LSN.lnfAnchors.each(function (index) {
                    const currLink = $(this);
                    const currLinkId = LSN.escapeDotsFromId(currLink.attr('href'));

                    if (currLinkId !== '') {
                        const $refElement = $(currLinkId);
                        const refElementTop = parseInt($refElement.offset().top);
                        const refElementHeight = parseInt($refElement.parent().outerHeight(true));
                        const refElementBtm = refElementTop + refElementHeight;
                        const additionalHeight = LSN.get.headerHeight() + LSN.articleToolsHeight + 3 * LSN.space;

                        if (typeof refElementTop !== 'undefined') {
                            if (
                                dScrollTop + additionalHeight >= refElementTop &&
                                dScrollTop + additionalHeight < refElementBtm
                            ) {
                                currLink.addClass('js--active');
                            } else {
                                currLink.removeClass('js--active');
                            }
                        }
                    }
                });
            }
        },
        scroll: function ($this, hash) {
            hash = LSN.escapeDotsFromId(hash);
            const pbEditView = document.querySelector('.pb-edit-view');
            if (pbEditView) return;
            const userAgent = navigator.userAgent;
            const isFirefox = /Firefox/.test(userAgent);
            let $delay = $(window).width() < UX.grid.screenSm ? 150 : 0;
            setTimeout(function () {
                $(document).off('scroll');
                LSN.articleToolsHeight = $('.article-tools__wrapper').length ? LSN.get.articleToolsHeight() : 0;
                // if target is inside an accordion, and it's closed, open it then scroll
                if (
                    $(hash).closest('.accordion__content') &&
                    !$(hash).closest('.accordion__content').siblings('.accordion__control').hasClass('js--open')
                ) {
                    $(hash).closest('.accordion__content').siblings('.accordion__control').trigger('click');
                }
                let target = hash,
                    $target = $(target),
                    position;

                if (!$target.length) return;
                if ($(window).width() < UX.grid.screenSm) {
                    if ($this.find('dropBlock.article-header__info')) {
                        position = $target.offset().top - (LSN.get.headerHeight() + LSN.articleToolsHeight + 20) + 2;
                    } else {
                        position = $target.offset().top - (LSN.articleToolsHeight + 28) + 2;
                    }
                    $('html, body')
                        .stop()
                        .animate(
                            {
                                scrollTop: position,
                            },
                            100,
                            'swing',
                            function () {
                                if (target !== '#articleHeader') {
                                    LSN.addHashToUrl(target);
                                }
                            }
                        );
                } else {
                    if ($('.adplaceholder--header').outerHeight() > 0 && isFirefox && !LSN.isMobile) {
                        position = $target.offset().top - (LSN.get.headerHeight() + LSN.articleToolsHeight + 20) + 2; // +2 is correction of possible rounding
                    } else if ($('.adplaceholder--header').outerHeight() > 0) {
                        position = $target.offset().top - (LSN.get.headerHeight() + LSN.articleToolsHeight + 20) + 2; // +2 is correction of possible rounding
                    } else {
                        position = $target.offset().top - (LSN.get.headerHeight() + LSN.articleToolsHeight + 20) + 2; // +2 is correction of possible rounding
                    }
                    $('html, body')
                        .stop()
                        .animate(
                            {
                                scrollTop: position,
                            },
                            100,
                            'swing',
                            function () {
                                if (target !== '#articleHeader') {
                                    LSN.addHashToUrl(target);
                                }
                                let targetPosition = $target.offset().top - (LSN.articleToolsHeight + 20) + 2;
                                if (targetPosition !== position) {
                                    $target.scrollTop();
                                }

                                if ($target.is('input, select, a, [tabindex]')) {
                                    $target.focus();
                                } else {
                                    $this.trigger('focus');
                                }
                            }
                        );
                }
                $(document).trigger('LSN-scrolling'); // triggering scrolling event toi be used by external js components
            }, $delay);
        },
        on: {
            scroll: function () {
                LSN.check();
                LSN.headerHeight = LSN.$header.length ? LSN.$header.outerHeight() : 0;
                let scrollTop = $(window).scrollTop(),
                    toolsHeight = $('.article-tools.sticky .article-tools__wrapper').length
                        ? $('.article-tools.sticky .article-tools__wrapper').outerHeight()
                        : 0,
                    articleHeaderTop =
                        $('.article-header').length && $('.article-header').position()
                            ? $('.article-header').position().top
                            : 0,
                    tocHeaderTop =
                        $('.toc-header').length && $('.toc-header').position() ? $('.toc-header').position().top : 0,
                    articleHeaderHeight = $('.article-header').length ? $('.article-header').outerHeight(true) : 0,
                    tocHeaderHeight = $('.toc-header').length ? $('.toc-header').outerHeight(true) : 0,
                    stickypoint =
                        articleHeaderTop +
                        tocHeaderTop +
                        articleHeaderHeight +
                        tocHeaderHeight -
                        LSN.headerHeight -
                        toolsHeight,
                    windowHeight = $(window).height(),
                    windowBottom = scrollTop + windowHeight,
                    footerTop = $('.footer').length === 1 ? $('.footer').offset().top : $(document).height();

                if (scrollTop > stickypoint) {
                    if (LSN.articleTos) {
                        LSN.articleTos.classList.add('js--sticky');
                        if (windowBottom < footerTop) {
                            if (!LSN.isMobile) {
                                LSN.articleTos.style.position = 'fixed';
                                LSN.articleTos.style.top = LSN.headerHeight + LSN.articleToolsHeight + LSN.space + 'px';
                                LSN.articleTos.style.bottom = 'auto';
                                LSN.set.lnfHeight();
                            }
                        } else {
                            if (!LSN.isMobile) {
                                LSN.articleTos.style.position = 'absolute';
                                LSN.articleTos.style.top = 'auto';
                                LSN.articleTos.style.bottom = LSN.space + 'px';
                                LSN.set.lnfHeight();
                            }
                        }
                    }
                    if (LSN.adPlaceholders) {
                        LSN.adPlaceholders.classList.add('js--sticky');
                        if (windowBottom < footerTop) {
                            LSN.adPlaceholders.style.position = 'fixed';
                            LSN.adPlaceholders.style.top = LSN.headerHeight + LSN.articleToolsHeight + 'px';
                            LSN.adPlaceholders.style.bottom = 'auto';
                        } else {
                            LSN.adPlaceholders.style.position = 'absolute';
                            LSN.adPlaceholders.style.top = 'auto';
                            LSN.adPlaceholders.style.bottom = LSN.space + 'px';
                        }
                    }
                } else {
                    if (LSN.articleTos && !LSN.isMobile) {
                        LSN.articleTos.classList.remove('js--sticky');
                        LSN.articleTos.style.position = 'absolute';
                        LSN.articleTos.style.top = '20px';
                        LSN.articleTos.style.botom = 'auto';
                        LSN.set.lnfHeight();
                    }
                    if (LSN.adPlaceholders) {
                        LSN.adPlaceholders.classList.remove('js--sticky');
                        LSN.adPlaceholders.style.position = 'absolute';
                        LSN.adPlaceholders.style.top = 0;
                        LSN.adPlaceholders.style.bottom = 'auto';
                    }
                }
            },
            load: function () {
                let sectionId = window.location.hash;
                if (sectionId) {
                    let $selectedEl = LSN.lnfAnchors.filter(`[href="${sectionId}"]`);
                    if ($selectedEl.length) {
                        LSN.preselectSectionInterval = setInterval(() => {
                            let $currentSelectedEl = LSN.lnfAnchors.filter('.js--active');
                            if (!$currentSelectedEl.is($selectedEl)) {
                                LSN.scroll($selectedEl, sectionId); // scroll with animation to hash
                            }
                        }, 2000);
                        setTimeout(() => {
                            clearInterval(LSN.preselectSectionInterval);
                        }, 5000);
                    }
                }
            },
        },
        get: {
            articleToolsHeight: function () {
                let $el = $('.article-tools__wrapper').clone();
                $el.css('position', 'absolute');
                $el.css('left', '-99999px');
                $el.css('padding', '13px');
                $('.article-tools').append($el);
                let height = $el.outerHeight();
                $el.remove();
                return height;
            },
            headerHeight: function () {
                if (LSN.$header.length) {
                    return parseInt(LSN.$header.outerHeight());
                } else {
                    return 0;
                }
            },
        },
        set: {
            lnfHeight: function () {
                if (!LSN.isMobile) {
                    $(LSN.articleTos).css(
                        'height',
                        $(window).height() - LSN.get.headerHeight() - LSN.articleToolsHeight - 2 * LSN.space
                    );
                }
            },
            scrollIntoLinks: function () {
                LSN.$scrollIntoLinks.each(function () {
                    $(this).on('click', function () {
                        LSN.scroll($(this), this.hash);
                    });
                });
            },
        },
        lnf: {
            open: function () {
                LSN.$lnfMobileCtrl.addClass('js--opened');
                $(LSN.articleTos).addClass('js--opened');
                $(LSN.articleTos).scrollTop(0);
                LSN.$lnfMobileCtrl.find('i').removeClass('icon-sub-menu').addClass('icon-close_thin');
                LSN.lnfOpenedOnMobile = true;
                LSN.lock();
                LSN.$html.addClass('page--locked');
            },
            close: function () {
                $(LSN.articleTos).removeClass('js--opened');
                LSN.$lnfMobileCtrl.find('i').removeClass('icon-close_thin').addClass('icon-sub-menu');
                LSN.$lnfMobileCtrl.removeClass('js--opened');
                LSN.lnfOpenedOnMobile = false;
                LSN.$html.removeClass('page--locked');
                LSN.unlock();
            },
            toggle: function () {
                if (LSN.lnfOpenedOnMobile) {
                    LSN.lnf.close();
                } else {
                    LSN.lnf.open();
                }
            },
        },
        addHashToUrl: function (url) {
            // add hash to URL
            if (url === '' || url === undefined) url = '_'; // it is empty hash because if put empty string here then browser will scroll to top of page
            const hash = url.replace(/^.*#/, '');
            const node = document.getElementById(hash);
            if (node) {
                node.removeAttribute('id');
                document.location.hash = hash;
                const scrollFixEl = document.createElement('div');
                scrollFixEl.id = hash;
                scrollFixEl.style.cssText = `position: absolute; visibility: hidden; top: ${window.scrollY}px`;
                document.body.insertAdjacentElement('beforeend', scrollFixEl);
                scrollFixEl.remove();
                node.setAttribute('id', hash);
            }
        },
        lock: function () {
            if (!LSN.isPageLocked) {
                LSN.lockBodyScrollTop = window.pageYOffset;
                document.getElementById('pb-page-content').style.top = LSN.lockBodyScrollTop * -1 + 'px';
                const vpH = window.innerHeight.toString();
                document.documentElement.style.height = vpH.toString() + 'px';
                document.body.style.height = vpH.toString() + 'px';
                LSN.isPageLocked = true;
            }
        },
        unlock: function () {
            if (LSN.isPageLocked) {
                document.getElementById('pb-page-content').style.top = '';
                document.documentElement.style.height = '';
                document.body.style.height = '';
                window.scrollTo(0, LSN.lockBodyScrollTop);
                setTimeout(function () {
                    window.scrollTo(0, LSN.lockBodyScrollTop);
                    LSN.lockBodyScrollTop = 0;
                }, 1);
                LSN.isPageLocked = false;
            }
        },
        escapeDotsFromId: function (id) {
            return id.replace(/\./g, '\\.');
        },
    };
    if ($('.left-side-nav').length) {
        LSN.init();
    }
    UX.LSN = LSN; // add to global namespace
})();
