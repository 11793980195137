(function () {
    var equation = {
        init: function () {
            setTimeout(function () {
                $('.MathJax').each(function () {
                    var width = $(this).width();
                    var parentWidth = $(this).parent().width();
                    if (parentWidth < width) {
                        $(this).css({overflow: 'auto', 'max-width': '100%', display: 'block'});
                    }
                });
            }, 15000);
        },
    };
    UX.equation = equation;
})();
