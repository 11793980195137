(function () {
    const scroller = {
        elements: [],
        add: function (selector) {
            if (scroller.elements.indexOf(selector) === -1) {
                scroller.elements.push(selector);
                scroller.watch(selector);
            }
            return 'reached-' + selector;
        },
        watch: function (selector) {
            $(window).scroll(function () {
                let $selector = $(`.${selector}`);
                if ($selector.length > 0) {
                    let top = $selector.position();
                    let stickyPoint = top.top + $selector.innerHeight();
                    if ($(window).scrollTop() > stickyPoint) {
                        $(document).trigger(`reached-${selector}`);
                    } else {
                        $(document).trigger(`not-reached-${selector}`);
                    }
                }
            });
        },
    };

    UX.scroller = scroller;
})();
