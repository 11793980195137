const FontFaceObserver = require('fontfaceobserver');

export default class FontLoader {

    constructor(typeFace = 'Open Sans', fontWeights = [300, 400, 600, 700, 800], timeout = 10000) {
        this.typeFace = typeFace;
        this.fontWeights = fontWeights;
        this.subset = new FontFaceObserver(typeFace);
        this.timeout = timeout;
        this._init();
    }


    _init() {
        this._addListeners();
    }

    _addListeners() {
        window.addEventListener('load', this._loadFonts.bind(this));
    }

    _loadFonts() {
        this.subset.load(null, this.timeout).then(() => {
            const fonts = this.fontWeights.map(weight => new FontFaceObserver(this.typeFace, {weight}).load(null, this.timeout));
            Promise.all(fonts).then(function () {
                var $ux3Elements = $(".ux3 > *");
                var $fontsLoadedClass = "fonts-loaded";
                document.documentElement.className += ' ' + $fontsLoadedClass;
                if ($ux3Elements.length > 0) {
                    $ux3Elements.addClass($fontsLoadedClass);
                }
            }, function () {
                console.warn('Font is not available after waiting 10 seconds');
            }).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });
    }
}

new FontLoader('Nexus Sans Pro', [400, 700]);
new FontLoader('elsevierWordmarkRegular', [400]);
