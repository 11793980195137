(function ($) {
    if (!$) return;
    function load($widget) {
        $widget.html('Loading...');
        $widget.pbAjax({
            type: 'GET',
            url: '/pb/widgets/optimizer',
            dataType: 'html',
            success: function (data) {
                if ($widget.closest('.pbOptimizerWidget').length !== 0) {
                    $widget.closest('.pbOptimizerWidget').replaceWith(data);
                } else {
                    $widget.html(data);
                }
                $('html').trigger('WidgetReinit');
            },
            error: function () {
                $widget.html('');
            },
        });
    }

    let observer = null;
    if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let $widget = $(entry.target);
                    observer.unobserve(entry.target);
                    load($widget);
                }
            });
        });
    }

    function init() {
        $('[data-optimizer]').each(function () {
            let $widget = $(this);

            if ($widget.is('[data-observer]') && observer) {
                observer.observe(this);
            } else {
                load($widget);
            }
        });
    }

    if (window.PB && window.PB.$) {
        // Make this module reinitialize in design mode.
        window.PB.$(document.documentElement).on('WidgetReinit.*', init);
    }

    $(init);
})(jQuery);
