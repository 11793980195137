import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    UIComponent,
} from '@atypon/ui-utils';

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    active = 'js--active';
    hidden = 'js--hidden';
    tabButton = 'tab-btn';
    noScroll = 'js--no-scroll';
}

class Selectors extends ComponentSelectors {
    getAccessTabs: string = '.get-access--tabs';
    tabList: string = '.get-access--tabs [role="tab"]';
    modal: string = '.get-access--modal';
    modalBox: string = '.get-access--modal__overlay__box';
    errorMessage: string = '.get-access--error-message';
    getAccessBtn: string = '.denial-block__upsell button, .article-tools__item__purchase';
    focusFlag: string = '[data-focus-flag]';
    modalPanel: string = '.get-access--modal .tab-panel';
    modalActivePanel: string = '.tab-panel.js--active';
    viewPurchaseBtn: string = '.view-purchase__link';
    oneTrustWrapper: string = '[id="onetrust-consent-sdk"]';
    loginInputWrapper: string = '.login-input--wrapper';
    dialogClose = new ComponentSelector('.dialog-close');
    freeContentBody = new ComponentSelector('.body-section__free-content');
    subscribeBox = new ComponentSelector('.subscribe-box__description');
}

class Elements extends ComponentElements<Selectors> {
    dialogClose: HTMLElement;
    freeContentBody: HTMLElement;
    subscribeBox: HTMLElement;
}

interface GetAccess extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class GetAccess implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
        this.additionalInit();
    }

    additionalInit(): void {
        this.truncateText(this.elements.subscribeBox, 180);
        this.checkFormErrors();
    }

    addEventListeners(): void {
        const getAccessBtnList = this.domUtils.getElements(this.selectors.getAccessBtn, document.body);
        getAccessBtnList.forEach((btn: HTMLElement) => this.domUtils.addEventListener(btn, 'click', this.handleGetAccessBtn.bind(this)));

        const modalPanelList = this.domUtils.getElements(this.selectors.modalPanel);
        modalPanelList.forEach((panel: HTMLElement) => this.domUtils.addEventListener(panel, 'focusout', this.ModalFocusOut.bind(this)));

        const viewPurchaseBtn = this.domUtils.getElements(this.selectors.viewPurchaseBtn);
        viewPurchaseBtn.forEach((btn: HTMLElement) => this.domUtils.addEventListener(btn, 'click', this.openPurchaseTab.bind(this)));

        this.domUtils.addEventListener(document, 'click', this.handleTabClick.bind(this));
        this.domUtils.addEventListener(document, 'keydown', this.handleTabKeyDown.bind(this));
        this.domUtils.addEventListener(document, 'click', this.clickOutsideModal.bind(this));
        this.domUtils.addEventListener(document, 'keydown', this.handleEscape.bind(this));
        this.domUtils.addEventListener(this.elements.dialogClose, 'click', this.closeDialog.bind(this));
        this.domUtils.addEventListener(this.elements.dialogClose, 'keydown', this.closeDialogKeyDown.bind(this));
        this.domUtils.addEventListener(this.elements.freeContentBody, 'focusout', this.ModalFocusOut.bind(this));
    }

    protected truncateText(element: HTMLElement, maxChars: number): void {
        if (!element) return;
        let totalChars = 0;

        const truncateNode = (node: ChildNode): void => {
            switch (node.nodeType) {
                case Node.TEXT_NODE:
                    const textLength = node.textContent.trim().length;
                    if (totalChars + textLength > maxChars) {
                        const remainingChars = maxChars - totalChars;
                        let truncatedText = node.textContent.substring(0, remainingChars);
                        if (truncatedText[truncatedText.length - 1] !== ' ' && remainingChars < textLength) {
                            truncatedText = `${truncatedText.split(' ').slice(0, -1).join(' ')} ...`;
                        }
                        node.textContent = truncatedText;
                        totalChars = maxChars;
                    } else {
                        totalChars += textLength;
                    }
                    break;
                case Node.ELEMENT_NODE:
                    const children = Array.from(node.childNodes);
                    children.map(child => {
                        if (totalChars >= maxChars) {
                            node.removeChild(child);
                        } else {
                            truncateNode(child);
                        }
                    });
                    break;
            }
        };

        truncateNode(element);
    }

    protected closeDialogKeyDown(e: any): void {
        const { key, shiftKey } = e;
        if (key !== 'Tab' || !shiftKey) return;
        e.preventDefault();
        if (this.elements.freeContentBody) {
            const bodyFocusableElements = this.domUtils.getFocusableElements(this.elements.freeContentBody);
            bodyFocusableElements[bodyFocusableElements.length - 1].focus();
        } else {
            const activePanel = this.domUtils.getElement(this.selectors.modalActivePanel, this.wrapper);
            if (activePanel) {
                let focusableElements = this.domUtils.getFocusableElements(activePanel);
                const target = focusableElements.length
                    ? focusableElements[focusableElements.length - 1]
                    : activePanel;
                target.focus();
            }
        }
    }

    protected ModalFocusOut(e: any): void {
        if (e.relatedTarget && !this.domUtils.closest(this.selectors.modalBox, e.relatedTarget)) {
            this.elements.dialogClose.focus();
        }
    }

    protected handleEscape(e: any): void {
        if (e.key !== 'Escape') return;
        const activeModal = this.domUtils.getElement(this.selectors.modal);
        activeModal && this.toggleModal('hide', true);
    }

    protected handleGetAccessBtn(e: any): void {
        e.preventDefault();
        this.toggleModal('show', true, e.currentTarget);
    }

    protected checkFormErrors(): void {
        const errorMessageEl = this.domUtils.getElement(this.selectors.errorMessage, this.wrapper);
        const btn = this.domUtils.getElement(this.selectors.getAccessBtn);
        if (errorMessageEl?.textContent) {
            this.toggleModal('show', false, btn);
            setTimeout(() => errorMessageEl.focus(), 250);
        }
    }

    protected openPurchaseTab(e: any): void {
        const { label } = e.currentTarget.dataset;
        const targetTab = this.domUtils.getElement(`.tab-btn[data-type="${label}"]`);
        targetTab.click();
        setTimeout(() => targetTab.focus(), 200);
    }

    protected clickOutsideModal(e: any): void {
        if (
            !this.domUtils.closest(this.selectors.modalBox, e.target)
            && !this.domUtils.closest(this.selectors.getAccessBtn, e.target)
            && !this.domUtils.closest(this.selectors.oneTrustWrapper, e.target)
            && !this.domUtils.closest(this.selectors.loginInputWrapper, e.target)
        ) this.toggleModal('hide', true);
    }

    protected closeDialog(e: any): void {
        this.toggleModal('hide', true);
    }

    protected clearErrorMessage(): void {
        const errorMessageEl = this.domUtils.getElement(this.selectors.errorMessage, this.wrapper);
        if (errorMessageEl?.textContent) errorMessageEl.textContent = '';
    }

    protected preventScroll(e: TouchEvent): void {
        return e.preventDefault();
    }

    protected enableScroll(e: TouchEvent): void {
        return e.stopPropagation();
    }

    protected toggleModal(status: string, focusCloseDialog: boolean, el: HTMLElement = null): void {
        const btn = el || this.domUtils.getElement(this.selectors.focusFlag, document.body);
        if (!btn) return;
        btn.setAttribute('data-focus-flag', 'true');
        const modal = this.domUtils.getElement(this.selectors.modal, document.body);
        switch (status) {
            case 'show' :
                modal.classList.remove(this.classList.hidden);
                focusCloseDialog && this.clearErrorMessage();
                document.body.classList.add(this.classList.noScroll);
                document.body.addEventListener('touchmove', this.preventScroll, { passive: false });
                this.domUtils.addEventListener(this.wrapper, 'touchmove', this.enableScroll.bind(this));
                if (focusCloseDialog) setTimeout(() => this.elements.dialogClose.focus(), 250);
                break;
            case 'hide':
                modal.classList.add(this.classList.hidden);
                btn.removeAttribute('data-focus-flag');
                document.body.classList.remove(this.classList.noScroll);
                document.body.removeEventListener('touchmove', this.preventScroll);
                setTimeout(() => btn.focus(), 250);
        }
    }

    protected toggleTab(btn: HTMLElement, panel: HTMLElement, status: string): void {
        switch (status) {
            case 'show':
                btn.classList.add(this.classList.active);
                btn.setAttribute('aria-selected', 'true');
                btn.setAttribute('tabindex', '0');
                panel.classList.add(this.classList.active);
                break;
            case 'hide':
                btn.classList.remove(this.classList.active);
                btn.setAttribute('aria-selected', 'false');
                btn.setAttribute('tabindex', '-1');
                panel.classList.remove(this.classList.active);
                break;
        }
    }

    protected tabClick(item: HTMLElement): void {
        const { tabBtn } = item.dataset;
        const currentBtn = this.domUtils.getElement(`${this.selectors.getAccessTabs} .tab-btn.${this.classList.active}`);
        const currentPanel = this.domUtils.getElement(`${this.selectors.getAccessTabs} .tab-panel.${this.classList.active}`);
        const targetPanel = this.domUtils.getElement(`${this.selectors.getAccessTabs} .tab-panel[data-tab-target="${tabBtn}"]`);

        this.toggleTab(currentBtn, currentPanel, 'hide');
        this.toggleTab(item, targetPanel, 'show');
    }

    protected handleTabClick(e: any): void {
        if (!e.target.classList.contains(this.classList.tabButton)) return;
        this.tabClick(e.target);
    }

    protected handleTabKeyDown(e: any): void {
        if (!e.target.classList.contains(this.classList.tabButton)) return;
        const { target: item, key } = e;
        if (!key.includes('Arrow')) return;
        const tabOrder = parseInt(item.dataset.tabBtn);
        const tabList = this.domUtils.getElements(this.selectors.tabList);
        let index = tabOrder;
        switch (key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                index--;
                index = index < 1 ? tabList.length : index;
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                index++;
                index = index > tabList.length ? 1 : index;
                break;
            case 'Enter':
            case ' ': // space
                this.tabClick(item);
                break;
        }
        tabList[index - 1].focus();
    }
}

export default GetAccess;

export {
    Elements as GetAccessElements,
    Selectors as GetAccessSelectors,
    ClassList as GetAccessClassList,
    Texts as GetAccessTexts
};