$(document).ready(function ($) {
    function isWidgetInView(widget) {
        let $thisWidget = widget;

        if (typeof jQuery === 'function' && widget instanceof jQuery) {
            $thisWidget = widget[0];
        }

        let rect = $thisWidget.getBoundingClientRect();

        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= $(window).height() && rect.right <= $(window).width();
    }

    function addArticlesNumber(data, widget) {
        const section = widget.closest('.axel-related-articles');
        if (!section) return;

        const title = section.querySelector('.axel-related-articles__title');
        let itemsCount = 0;
        const itemsList = widget.querySelectorAll('.axel-related-articles__items');
        itemsList.forEach(list => (itemsCount += list.children.length));

        if (title) {
            const {title: titleData} = title.dataset;
            title.innerHTML = `${titleData} (${itemsCount})`;
        }
    }

    function loadWidget(widget) {
        let pbContext = $('[name="pbContext"]').attr('content');
        let $thisWidget = $(widget);
        let widgetType = $thisWidget.data('pb-widget-type');
        let widgetId = $thisWidget.data('widget-id');
        let articlePii = $thisWidget.data('article-pii');
        let dataAttributes = $thisWidget.data();
        let url = '/pb/widgets/ux3/' + widgetType;
        let $sectionTitle = $thisWidget.prev('h2');
        let sectionHash;
        let $lsnLink;

        if ($sectionTitle.length) {
            sectionHash = $sectionTitle.attr('id');
            $lsnLink = $('.left-side-nav a[href="#' + sectionHash + '"]');
        }

        if (articlePii) {
            url += '/' + articlePii;
        }

        dataAttributes['pbContext'] = pbContext;

        $thisWidget.addClass('ux3DeferredLoaded');
        $.ajax({
            type: 'GET',
            url: url,
            data: dataAttributes,
            success: function (data) {
                if (data) {
                    $thisWidget.html(data);
                    if (widget.dataset.showMore) {
                        addArticlesNumber(data, widget);
                    }
                    if ($thisWidget.find('.mediaPlayer').length) {
                        $thisWidget.find('.mediaPlayer__player-holder').each(function (index) {
                            $thisWidget.attr('id', widgetId + index);
                        });
                        UX.mediaPlayer.init();
                        UX.mediaAdobeAnalatics.init();
                    }
                } else {
                    $thisWidget.text("We haven't found any related articles.");
                }

                $(document).trigger('LSN-refill');
                if ($lsnLink && UX.LSN.isClicked) {
                    UX.LSN.scroll($lsnLink, '#' + sectionHash);
                    UX.LSN.isClicked = false;
                }
            },
            error: function (xhr, status, error) {
                $thisWidget.text("We haven't found any related articles.");
                $(document).trigger('LSN-refill');
                if ($lsnLink && UX.LSN.isClicked) {
                    UX.LSN.scroll($lsnLink, '#' + sectionHash);
                    UX.LSN.isClicked = false;
                }
            },
        });
    }

    let $deferredWidgets = $('.ux3DeferredOnScrollWidget:not(.ux3DeferredLoaded)');

    function checkDeferredWidgets() {
        $deferredWidgets.each(function () {
            if (isWidgetInView(this)) {
                loadWidget(this);
            }

            if ($('.ux3DeferredOnScrollWidget:not(.ux3DeferredLoaded)').length === 0) {
                $(window).off('DOMContentLoaded load resize scroll', checkDeferredWidgets);
            }
        });
    }

    if ($deferredWidgets.length) {
        $(window).on('DOMContentLoaded load resize scroll', checkDeferredWidgets);
    }
});

$(document).on('LSN-refill', function () {
    // refill side bar navs after ajax response
    UX.LSN.fill.top(); // fill top side bar nav
    UX.LSN.fill.bottom(); // fill bottom side bar nav
});
