(function () {
    UX.quickSearch.additionalController = function () {
        $('.quick-search').each(function (index) {
            let $searchInput = $(this).find('.searchText');
            let $searchbar = $(this).find('.quick-search__toggle').length
                ? $(this).find('.quick-search__toggle')
                : $(this).find('.quick-search__searchbar');

            let confNumOfPublicationTitles = $searchInput.data('publication-titles-conf');
            let confNumOfContributors = $searchInput.data('contributors-conf');
            let confNumOfTopics = $searchInput.data('topics-conf');
            let confNumOfHistoryItems = $searchInput.data('history-items-conf');
            let numOfGroupTitles = $searchInput.data('group-titles-conf');
            let numOfPublicationItems = $searchInput.data('publication-items-conf');
            let autoCompeteSearchUrl = `/action/doSuggest?target=auto-complete&hs=${confNumOfHistoryItems}&pts=${confNumOfPublicationTitles}&ts=${confNumOfTopics}&cs=${confNumOfContributors}&fl=PubID&ptgs=${numOfGroupTitles}&ptfs=${numOfPublicationItems}`;

            if ($searchInput.hasClass('searchText-autocomplete')) {
                $searchInput.autocomplete({
                    appendTo: $searchbar,
                    source: function (request, response) {
                        $.ajax({
                            url: autoCompeteSearchUrl,
                            dataType: 'json',
                            data: {
                                query: request.term,
                            },
                            success: function (data) {
                                let uniqueItemsData = {};
                                for (let i in data) {
                                    let itemLabel = data[i]['label'];
                                    itemLabel = itemLabel.toLowerCase();
                                    uniqueItemsData[itemLabel] = data[i];
                                }
                                response(
                                    $.map(uniqueItemsData, function (el) {
                                        return {
                                            label: el.label,
                                            value: el.label,
                                            id: el.value,
                                            type: el.param,
                                            url: el.url ? el.url : el.value,
                                        };
                                    })
                                );
                            },
                        });
                    },
                });
                $searchInput.on('autocompleteselect', function (event, ui) {
                    if (ui.item.type === 'DOI') {
                        $.ajax({
                            url: `/pb/article?articleId=${ui.item.id}`,
                            success: function (data) {
                                let newData = typeof data === 'string' ? JSON.parse(data) : data;
                                window.location.replace(newData.link);
                            },
                        });
                    } else {
                        window.location.replace(ui.item.url);
                    }
                });
            }
        });
    };

    $(document).on('change', '.ck-quick-search .quick-search__dropdown', function () {
        $('input[type="hidden"][name="occurrences"]').val($(this).find('option:selected').val());
    });

    $(document).on('click', '.quick-search__close', function (e) {
        e.preventDefault();
        UX.dropBlock.on.hide();
    });

    $(document).on('click', '.search-drawer', function (e) {
        e.preventDefault();
        if ($('.menu-drawer .menu-drawer__ctrl').hasClass('js--open')) {
            UX.drawer.on.hide();
        }
    });

    $('.quick-search input[name="SeriesKey"]').on('click', function (e) {
        if ($(this).prop('checked')) {
            $(this).closest('.quick-search__scope-options').find('input[name="journalCode"]').val($(this).val());
        }
    });

    $('.quick-search form').on('submit', function (e) {
        if ($(this).find('input[name="text1"]').val().trim() === '') {
            e.preventDefault();
            $(this).find('.quick-search__alert').removeClass('hidden');
            $(this).find('.quick-search__alert--close').trigger('focus');

            return false;
        }

        let $checkedSeriesKeyEl = $(this).find('input[name="SeriesKey"]:checked');
        if ($checkedSeriesKeyEl.length > 0 && $checkedSeriesKeyEl.val() === '') {
            $(this).find('input[name="SeriesKey"], input[name="journalCode"]').prop('disabled', true);
        }
    });

    $('.quick-search__alert--close').on('click', function (e) {
        e.preventDefault();

        $(this).closest('.quick-search__alert').addClass('hidden');
        $(this).closest('form').find('input[name="text1"]').trigger('focus');
    });

    $('.search-result__quick-search [data-db-target-for="quickSearch"]').removeAttr('data-db-target-for');
    $('.search-result__quick-search [data-db-target-of="quickSearch"]').removeAttr('data-db-target-of');
})();
