(function () {
    let $body = $('body');
    let drawer = {
        $controller: $('.menu-drawer__ctrl a'),
        offesty: '',
        isMobile: false,
        headerTop: 0,
        suffix: '',
        escape: 27,
        vPort: 'screen-sm',
        $section: $('.menu-drawer__nav'),
        init: function () {
            drawer.offesty = window.pageYOffset;
            drawer.vPort = $('.menu-drawer__nav').attr('data-ctrl-res'); // get responsive switching break point
            drawer.suffix = UX.utils.capitalizeFirstLetter(drawer.vPort.split('-', 2)[1]);

            if ($(window).width() < UX.grid['screen' + drawer.suffix]) {
                this.isMobile = true;
                $('.header__nav').addClass('menu--res');
                $('.header__nav').removeClass('menu--desk');
            } else {
                $('.header__nav').removeClass('menu--res');
                $('.header__nav').addClass('menu--desk');
            }
            $(document).on(this.vPort + '-on', function () {
                // Waiting for custom event that will be triggered by controller.js to activate responsive effects
                this.isMobile = true;
                $('.header__nav').addClass('menu--res');
                $('.header__nav').removeClass('menu--desk');
            });
            $(document).on(this.vPort + '-off', function () {
                // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
                this.isMobile = false;
                $('.header__nav').removeClass('menu--res');
                $('.header__nav').addClass('menu--desk');
            });
            drawer.control();
            drawer.cloneLinks();
        },
        control: function () {
            $body.on('click', '.menu-drawer__ctrl a', function (e) {
                e.preventDefault();
                let $this = $(this).closest('.menu-drawer__ctrl');
                if (!$this.hasClass('js--open')) {
                    drawer.offesty = window.pageYOffset;
                    $this.siblings('.menu-drawer__nav').attr('aria-hidden', 'false');
                    $this.children('a').attr('aria-expanded', 'true');
                }
                if ($(window).width() >= UX.grid['screen' + drawer.suffix]) {
                    drawer.open($('.menu-drawer .dropdown__toggle').first());
                }
                if ($this.hasClass('js--open')) {
                    $('body').css({overflow: 'auto', position: 'static', height: 'auto', top: 'auto'});
                    $(window).scrollTop(drawer.offesty);
                    drawer.closeAll();
                    $this.children('a').attr('aria-expanded', 'false');
                    //aria-expanded="false"
                    $this.siblings('.menu-drawer__nav').attr('aria-hidden', 'true');
                }
                $this.toggleClass('js--open');
                drawer.update.top($this);
                $this.siblings('.menu-drawer__nav').toggleClass('js--open');
                if ($this.hasClass('js--open')) {
                    $('body').css({
                        overflow: 'hidden',
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        top: -drawer.offesty + 'px',
                    });
                }
                $('body').toggleClass('lock-screen');
            });
            $body.on('click', '.dropdown__toggle', function (e) {
                e.preventDefault();
                drawer.open($(this));
            });
            $body.on('click', '.menu-drawer__back__ctrl', function (e) {
                e.preventDefault();
                drawer.closeAll();
            });
            $(document).on('keydown', function (e) {
                // hide / close when esc key is pressed
                if ((e.keyCode || e.which) === drawer.escape) {
                    if ($('.menu-drawer__ctrl').hasClass('js--open')) {
                        $('body').css({overflow: 'auto', position: 'static', height: 'auto', top: 'auto'});
                        $(window).scrollTop(drawer.offesty);
                        drawer.closeAll();
                        $('.menu-drawer__ctrl').children('a').attr('aria-expanded', 'false');
                        $('.menu-drawer__ctrl').siblings('.menu-drawer__nav').attr('aria-hidden', 'true');
                        $('.menu-drawer__ctrl').removeClass('js--open');
                        drawer.update.top($('.menu-drawer__ctrl'));
                        $('.menu-drawer__ctrl').siblings('.menu-drawer__nav').removeClass('js--open');
                    }
                }
            });
        },
        open: function ($element) {
            if ($body.attr('data-active') !== undefined && $body.attr('data-active') !== 'drawer') {
                UX.dropBlock.on.hide();
                UX.tableOfContent.on.hide();
            }
            drawer.closeAll();
            drawer.fill($element);
            $element.toggleClass('js--open');
            $element.siblings('.dropdown__menu').addClass('js--open');
            $body.css({
                position: 'fixed',
                width: '100%',
            }); // because body is not in ux3 scope
            $('header').closest('.ux3').prepend('<div class="body__overlay"></div>');
            $body.attr('data-active', 'drawer');
        },
        closeAll: function () {
            drawer.empty();
            $('.menu-drawer .dropdown__menu').removeClass('js--open');
            $('.menu-drawer .dropdown__toggle').removeClass('js--open');
            if ($body.attr('data-active') === 'drawer') {
                $body.removeAttr('data-active');
            }
            $('.body__overlay').remove();
        },
        fill: function ($element) {
            $('.menu-drawer--holder').text($element.text());
        },
        on: {
            hide: function () {
                $('.menu-drawer .menu-drawer__ctrl').removeClass('js--open');
                $('.menu-drawer .menu-drawer__nav').removeClass('js--open');
                $('.menu-drawer__ctrl')
                    .children('a')
                    .attr('aria-expanded', 'false')
                    .siblings('.menu-drawer__nav')
                    .attr('aria-hidden', 'true');
                if ($body.attr('data-active') === 'drawer') {
                    $body.removeAttr('data-active');
                }
                $body.css({
                    position: 'static',
                    overflow: 'auto',
                });
                $(window).scrollTop(drawer.offesty);
                $('.body__overlay').remove();
            },
        },
        empty: function () {
            $('.menu-drawer--holder').text('');
        },
        update: {
            top: function (element) {
                let headerHeight = $('header').outerHeight();
                let headerOffset = $('.header__wrapper').offset();
                if ($(window).width() < UX.grid['screen' + drawer.suffix]) {
                    drawer.isMobile = true;
                } else {
                    drawer.isMobile = false;
                }
                if (headerOffset.top - $(window).scrollTop() >= 0) {
                    drawer.headerTop = headerOffset.top - $(window).scrollTop();
                } else {
                    drawer.headerTop = 0;
                }
                let top = drawer.isMobile ? drawer.headerTop : headerHeight;
                element.siblings('.menu-drawer__nav').css('top', top);
            },
        },
        cloneLinks: function () {
            if ($('.profile-links__register').length) {
                let li1 = $(
                    '<li role="menuitem" aria-label="Home" id="menu-item-main-menu-reg" class="menu-item hidden-lg"/>'
                ).appendTo($('.menubar'));
                $('.profile-links__register a').clone().appendTo(li1);
            }
            if ($('.profile-links__subscribe').length) {
                let li2 = $(
                    '<li role="menuitem" aria-label="Home" id="menu-item-main-menu-sub" class="menu-item hidden-lg"/>'
                ).appendTo($('.menubar'));
                $('.profile-links__subscribe a').clone().appendTo(li2);
            }
            if ($('.profile-links__claim').length) {
                let li3 = $(
                    '<li role="menuitem" aria-label="Home" id="menu-item-main-menu-cla" class="menu-item hidden-lg"/>'
                ).appendTo($('.menubar'));
                $('.profile-links__claim a').clone().appendTo(li3);
            }
            if ($('.profile-links__mda').length) {
                let li4 = $(
                    '<li role="menuitem" aria-label="Home" id="menu-item-main-menu-mda" class="menu-item hidden-lg"/>'
                ).appendTo($('.menubar'));
                $('.profile-links__mda a').clone().appendTo(li4);
            }
        },
    };
    UX.drawer = drawer; // add to global namespace
})();
