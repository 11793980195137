UX.grid = {
    // Deprecated `$screen-xs` as of v3.0.1
    screenXs: '532',
    // Deprecated `$screen-sm` as of v3.0.1
    screenSm: '768',
    // Deprecated `$screen-md` as of v3.0.1
    screenMd: '1140',
    // Deprecated `$screen-md` as of v3.0.1
    screenLg: '1500',
    // Deprecated `$screen-md` as of v3.0.1
    screenXlg: '1920',
    // Small screen / phone
    containerMobile: '500',
    // For `$screen-sm-min` and up
    containerXs: '',
    // Small screen / tablet
    containerTablet: '736',
    // For `$screen-sm-min` and up
    containerSm: '',
    // Medium screen / desktop
    containerDesktop: '1080',
    // For `$screen-md-min` and up
    containerMd: '',
    // Large screen / wide desktop
    containerLargeDesktop: '1472',
    // For `$screen-lg-min` and up
    containerLg: '',
};
