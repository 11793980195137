(function () {
    const tocHeader = {
        full: $('.caption__trimmed').html(),
        ctrl: $('.caption__ctrl'),
        tocNav: null,
        scrollToTop: false,
        isMobile: $(window).width() <= UX.grid.screenSm,
        trimmed: '',
        init: function () {
            tocHeader.trimmed = tocHeader.excludeTextTags(tocHeader.full)?.substring(0, 161);
            if ($('.caption__dots').length) {
                // check if text has been truncated in jade template ( if char exceeded 160 )
                tocHeader.trimmed = tocHeader.trimmed.substr(
                    0,
                    Math.min(tocHeader.trimmed.length, tocHeader.trimmed.lastIndexOf(' '))
                );
            }
            tocHeader.tocNav = document.querySelector('.toc-header .content-navigation');
            tocHeader.control();
        },
        control: function () {
            $('.caption__trimmed').html(tocHeader.trimmed);
            $('.caption__ctrl').on('click', function () {
                tocHeader.switchText(tocHeader.ctrl);
                if (tocHeader.scrollToTop && tocHeader.isMobile) {
                    $('html, body').stop().animate(
                        {
                            scrollTop: 60,
                        },
                        500,
                        'swing'
                    );
                }
            });
            $(document).on('keydown', function (e) {
                if (e.keyCode === 27) {
                    // ESC
                    window.location.replace('#');
                    tocHeader.switchText(tocHeader.ctrl);
                }
            });
            /*
                this function to hide lightbox `image full screen` when the user click ESC
                and replace text showLess with show more in caption__ctrl
            */
            $(document).on('LSN-scrolling db-hide', function () {
                tocHeader.switchText(tocHeader.ctrl);
            });
            $(window).on('scroll', tocHeader.scroll);
        },
        excludeTextTags: function (string) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = string;
            const tempDivNodes = Array.from(tempDiv.childNodes);
            tempDivNodes.forEach((item, i) => {
                if (item.nodeType === 1 && item.tagName.toLowerCase() === 'em') {
                    tempDivNodes[i] = item.outerHTML;
                } else {
                    tempDivNodes[i] = item.textContent;
                }
            });
            tempDiv.remove();
            return tempDivNodes.length && tempDivNodes.join('');
        },
        switchText: function (element) {
            if (element.hasClass('js--open')) {
                element.text('Show less').attr('title', 'Hide full caption');
            } else {
                element.text('Show more').attr('title', 'Show full caption');
            }
        },
        scroll: function () {
            const scrollTop = $(window).scrollTop();
            let tocHeaderHeight = $('.toc-header').outerHeight() || 0;
            if (scrollTop > tocHeaderHeight) {
                if (tocHeader.tocNav) {
                    tocHeader.tocNav.classList.add('js--sticky');
                }
            } else {
                if (tocHeader.tocNav) {
                    tocHeader.tocNav.classList.remove('js--sticky');
                }
            } // when the user scroll make the content-navigation `next and prev` sticky
            if ($(window).scrollTop() > 162) {
                tocHeader.scrollToTop = true;
            } else if ($(window).scrollTop() < 162) {
                tocHeader.scrollToTop = false;
            }
        },
    };
    if ($('.toc-header').length) {
        tocHeader.init();
    }
    UX.tocHeader = tocHeader;
})();
