import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    Injectable,
    UIComponent,
} from '@atypon/ui-utils';

class Texts extends ComponentObject {
}

class ClassList extends ComponentObject {
    active = 'js--active';
    hidden = 'js--hidden';
    open = 'js--open';
}

@Injectable()

class Selectors extends ComponentSelectors {
    infoPopup: string = '.info-popup';
    infoPopupBtn: string = '.info-popup__btn';
    infoPopupContent: string = '.info-popup__content';
    openedInfoPopupContent: string = '.info-popup__content.js--open';
}

class Elements extends ComponentElements<Selectors> {
}

interface InfoPopup extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)

class InfoPopup implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
    }

    addEventListeners(): void {
        const infoPopupBtnList = this.domUtils.getElements(this.selectors.infoPopupBtn, this.wrapper);

        infoPopupBtnList.forEach(btn => this.domUtils.addEventListener(btn, 'click', this.handleInfoPopup.bind(this)));
        this.domUtils.addEventListener(document, 'click', this.clickOutSide.bind(this));
        this.domUtils.addEventListener(document, 'keydown', this.escapeHandler.bind(this));
    }

    protected clickOutSide(e: any): void {
        const openedInfoPopupContent = this.domUtils.getElement(this.selectors.openedInfoPopupContent);
        if (openedInfoPopupContent && !this.domUtils.closest(this.selectors.infoPopup, e.target))
            this.toggleModal(openedInfoPopupContent, 'hide');
    }

    protected toggleModal(el: HTMLElement, status: string): void {
        switch (status) {
            case 'show':
                el.classList.remove(this.classList.hidden);
                el.classList.add(this.classList.open);
                break;
            case 'hide':
                el.classList.add(this.classList.hidden);
                el.classList.remove(this.classList.open);
                break;
        }
    }

    protected escapeHandler(e: any): void {
        if (e.key !=='Escape') return;
        const openedInfoPopupContent = this.domUtils.getElement(this.selectors.openedInfoPopupContent);
        if (openedInfoPopupContent) this.toggleModal(openedInfoPopupContent, 'hide');
    }

    protected handleInfoPopup(e: any): void {
        const {currentTarget: btn} = e;
        const content = this.domUtils.nextAll(btn, this.selectors.infoPopupContent)[0];
        this.toggleModal(content, content.classList.contains(this.classList.hidden) ? 'show' : 'hide');
    }
}

export default InfoPopup;

export {
    Elements as InfoPopupElements,
    Selectors as InfoPopupSelectors,
    ClassList as InfoPopupClassList,
    Texts as InfoPopupTexts,
}