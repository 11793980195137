export default class MegaMenu {
    // TODO:this code need to bee cleaned up (add the duplicated code in functions)
    constructor() {
        if (document.documentElement.id === 'pb-editor') {
            const interval = setInterval(() => {
                if (document.querySelector(this.selectors.pbView)) {
                    clearInterval(interval);
                    this.init();
                }
            }, 1000);
        } else {
            this.init();
        }
    }

    classList = {
        open: 'js--open',
        megaMenuLink: 'mega-menu__item-link',
        seeAllLink: 'seeAllLink',
        menuLink: 'menu-link',
    };

    selectors = {
        fixedHeader: 'header.fixed',
        pbView: '.pb-widget-toolbar',
        section: '.mega-menu-nav',
        megaMenu: '.mega-menu',
        megaMenuLink: '.mega-menu__item-link',
        menuLink: '.menu-link',
        megaMenuWrapper: '.mega-menu__item-wrapper',
        megaMenuToggle: '.mega-menu__toggle',
        quickSearchNav: '.mega-menu-nav__quick-search',
        quickSearchToggleInput: '.quick-search__toggle input[type="text"]',
        quickSearchBox: '.quick-search__box',
        quickSearchBoxClose: '.quick-search__box-close',
        megaMenuQuickSearch: '.mega-menu-nav .quick-search',
        subMenu: '.sub-menu',
        nonHeading: '.non-heading',
        subMenuItem: '.sub-menu__item',
        headingList: '.heading-list',
        doubleWidth: '.double-width',
        subMenuHeading: '.mega-menu__sub-menu-heading',
        listDropZone: '.listDropZone',
        hiddenMultiSearch: '.multi-search--hidden',
        skipMenuLink: '.skip a[aria-label="Skip menu"]',
    };

    elements = {
        megaMenu: null,
        megaMenuLink: null,
        fixedHeader: null,
        menuLink: null,
        megaMenuWrapper: null,
        megaMenuToggle: null,
        quickSearchNav: null,
        quickSearchToggleInput: null,
        quickSearchBox: null,
        quickSearchBoxClose: null,
        megaMenuQuickSearch: null,
        listDropZone: null,
        hiddenMultiSearch: null,
        skipMenuLink: null,
    };

    setElements() {
        this.elements.megaMenuLink = this.elements.section.querySelectorAll(this.selectors.megaMenuLink);
        this.elements.fixedHeader = document.querySelector(this.selectors.fixedHeader);
        this.elements.menuLink = this.elements.section.querySelectorAll(this.selectors.menuLink);
        this.elements.megaMenuToggle = this.elements.section.querySelector(this.selectors.megaMenuToggle);
        this.elements.megaMenu = this.elements.section.querySelector(this.selectors.megaMenu);
        this.elements.quickSearchNav = this.elements.section.querySelector(this.selectors.quickSearchNav);
        this.elements.quickSearchToggleInput = this.elements.quickSearchNav?.querySelector(
            this.selectors.quickSearchToggleInput
        );
        this.elements.quickSearchBox = this.elements.quickSearchNav?.querySelector(this.selectors.quickSearchBox);
        this.elements.quickSearchBoxClose = this.elements.quickSearchNav?.querySelector(
            this.selectors.quickSearchBoxClose
        );
        this.elements.megaMenuQuickSearch = this.elements.section.querySelector(this.selectors.megaMenuQuickSearch);
        this.elements.megaMenuWrapper = this.elements.megaMenu.querySelectorAll(this.selectors.megaMenuWrapper);
        this.elements.listDropZone = this.elements.megaMenu.querySelectorAll(this.selectors.listDropZone);
        this.elements.hiddenMultiSearch = this.elements.section.querySelectorAll(this.selectors.hiddenMultiSearch);
        this.elements.skipMenuLink = this.elements.section.querySelector(this.selectors.skipMenuLink);
    }

    toggleElement = (el, status) => {
        if (status) {
            el.classList.add(this.classList.open);
            el.setAttribute('aria-expanded', 'true');
        } else {
            el.classList.remove(this.classList.open);
            el.setAttribute('aria-expanded', 'false');
        }
    };

    megaMenuLinkHandler = e => {
        if (this.elements.quickSearchBox && this.elements.quickSearchBox.classList.contains(this.classList.open))
            this.elements.quickSearchBox.classList.remove(this.classList.open);

        const targetEl = e.currentTarget;
        const itemDataLink = targetEl.dataset.itemLink;
        if (!itemDataLink) return;
        const targetWrapper = this.elements.section.querySelector(
            `${this.selectors.megaMenuWrapper}[data-item-wrapper="${itemDataLink}"]`
        );
        const prevItemDataLink = this.elements.section.querySelector(
            `${this.selectors.megaMenuLink}.${this.classList.open}`
        );
        const prevItemDataWrapper = this.elements.section.querySelector(
            `${this.selectors.megaMenuWrapper}.${this.classList.open}`
        );

        if (targetEl.classList.contains(this.classList.open) && targetWrapper.classList.contains(this.classList.open)) {
            this.toggleElement(targetEl, false);
            targetWrapper.classList.remove(this.classList.open);
        } else {
            prevItemDataLink && this.toggleElement(prevItemDataLink, false);
            prevItemDataWrapper?.classList.remove(this.classList.open);

            this.toggleElement(targetEl, true);
            targetWrapper.classList.add(this.classList.open);
        }
    };

    toggleHandler = e => {
        e.currentTarget.classList.toggle(this.classList.open);
        this.elements.megaMenu.classList.toggle(this.classList.open);
        if (!this.elements.megaMenu.classList.contains(this.classList.open)) {
            this.elements.megaMenu
                .querySelectorAll(`.${this.classList.open}`)
                .forEach(item => item.classList.remove(this.classList.open));
        }
    };

    moveFocusToMainLink = (currentLink, targetedIndex) => {
        const parentWrapper = currentLink.closest(this.selectors.megaMenuWrapper);
        const parentLink = parentWrapper.previousSibling;
        const parentLinkIndex = parseInt(parentLink.dataset.index);
        let newIndex = targetedIndex === 'next' ? parentLinkIndex + 1 : parentLinkIndex - 1;
        let targetedMenuLink = this.elements.megaMenuLink[targetedIndex === 'next' ? newIndex : parentLinkIndex];

        this.toggleElement(parentLink, false);
        parentWrapper.classList.remove(this.classList.open);

        if (targetedMenuLink) {
            this.toggleElement(targetedMenuLink, true);
            targetedMenuLink.nextSibling?.classList.add(this.classList.open);
            targetedMenuLink.focus();
        } else {
            this.elements.quickSearchToggleInput?.focus();
        }
    };

    onlyFocusable = nodeList => [...nodeList].filter(item => item.offsetParent);

    dropZoneChecker = (link, subMenu, dir) => {
        let targetedSubMenu = dir === 'next' ? subMenu.nextSibling : subMenu.previousSibling;
        if (targetedSubMenu) {
            const listDropZone = targetedSubMenu.querySelector(this.selectors.listDropZone);
            if (listDropZone) {
                if (listDropZone.childNodes.length > 0) {
                    const listDropZoneItems = this.onlyFocusable(
                        listDropZone.querySelectorAll(this.selectors.menuLink)
                    );
                    if (listDropZoneItems.length)
                        listDropZoneItems[dir === 'next' ? 0 : listDropZoneItems.length - 1].focus();
                } else {
                    this.dropZoneChecker(link, targetedSubMenu, dir);
                }
            } else {
                const focusableEl = this.onlyFocusable(targetedSubMenu.querySelectorAll(this.selectors.menuLink));
                focusableEl.length && focusableEl[0].focus();
            }
        } else {
            this.moveFocusToMainLink(link, dir);
        }
    };

    menuLinkKeyDownHandler = e => {
        const {target: targetEl} = e;
        const keyList = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Shift', 'Escape', ' '];
        const isKeyPressed = keyList.find(key => key.includes(e.key));
        if (!isKeyPressed) return;

        const isParent = targetEl.classList.contains(this.classList.megaMenuLink);
        const itemIndex = parseInt(targetEl.dataset.index);
        const itemDataLink = targetEl.dataset.itemLink;
        const targetWrapper = this.elements.section.querySelector(
            `${this.selectors.megaMenuWrapper}[data-item-wrapper="${itemDataLink}"]`
        );
        switch (e.key) {
            case ' ': // Space Key
                this.megaMenuLinkHandler(e);
                break;
            case 'ArrowLeft':
                if (isParent) {
                    const prevMenuItem = this.elements.megaMenuLink[itemIndex - 1];
                    if (prevMenuItem) {
                        prevMenuItem.focus();
                        if (targetEl.classList.contains(this.classList.open)) {
                            this.toggleElement(targetEl, false);
                            targetWrapper?.classList.remove(this.classList.open);
                            this.toggleElement(prevMenuItem, true);
                            prevMenuItem.nextSibling?.classList.add(this.classList.open);
                        }
                    }
                } else {
                    const parentWrapper = targetEl.closest(this.selectors.megaMenuWrapper);
                    const parentLink = parentWrapper.previousSibling;
                    parentLink?.focus();
                }
                break;
            case 'ArrowRight':
                if (isParent) {
                    const nextMenuItem = this.elements.megaMenuLink[itemIndex + 1];
                    // if we want to make the focus moving from the last to first one
                    // let nextMenuItem = this.elements.megaMenuLink[itemIndex + 1] ? this.elements.megaMenuLink[itemIndex + 1] : this.elements.megaMenuLink[0];
                    if (nextMenuItem) {
                        nextMenuItem.focus();
                        if (targetEl.classList.contains(this.classList.open)) {
                            this.toggleElement(targetEl, false);
                            targetWrapper?.classList.remove(this.classList.open);
                            this.toggleElement(nextMenuItem, true);
                            nextMenuItem.nextSibling?.classList.add(this.classList.open);
                        }
                    } else if (this.elements.megaMenuQuickSearch?.style.display !== 'none') {
                        this.elements.quickSearchToggleInput?.focus();
                    }
                } else {
                    const parentWrapper = targetEl.closest(this.selectors.megaMenuWrapper);
                    const parentLink = parentWrapper.previousSibling;
                    parentLink?.focus();
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (isParent) {
                    this.toggleElement(targetEl, false);
                    targetEl.nextSibling?.classList.remove(this.classList.open);

                    const targetedMenuLink = this.elements.megaMenuLink[itemIndex - 1];
                    if (targetedMenuLink) {
                        this.toggleElement(targetedMenuLink, true);
                        const targetedMenuWrapper = targetedMenuLink.nextSibling;
                        if (targetedMenuWrapper) {
                            targetedMenuWrapper.classList.add(this.classList.open);
                            const menuLinkList = this.onlyFocusable(
                                targetedMenuWrapper.querySelectorAll(this.selectors.menuLink)
                            );
                            menuLinkList.length && menuLinkList[menuLinkList.length - 1].focus();
                        } else {
                            targetedMenuLink.focus();
                        }
                    }
                } else {
                    if (targetEl.closest(this.selectors.nonHeading)) {
                        const targetParent = targetEl.closest(this.selectors.nonHeading);
                        const currentIndex = parseInt(targetEl.dataset.index);
                        const targetIndex = targetParent.querySelector(
                            `${this.selectors.menuLink}[data-index="${currentIndex - 1}"]`
                        );
                        if (targetIndex) {
                            targetIndex.focus();
                        } else {
                            this.moveFocusToMainLink(targetEl, 'prev');
                        }
                    } else if (targetEl.closest(this.selectors.doubleWidth)) {
                        const targetParent = targetEl.closest(this.selectors.doubleWidth);
                        const currentIndex = parseInt(targetEl.dataset.index);
                        const targetIndex = targetParent.querySelector(
                            `${this.selectors.menuLink}[data-index="${currentIndex - 1}"]`
                        );
                        if (targetIndex) {
                            targetIndex.focus();
                        } else {
                            const prevMenuList = targetEl.closest(this.selectors.subMenuItem).previousSibling;
                            if (prevMenuList) {
                                const listDropZone = prevMenuList.querySelector(this.selectors.listDropZone);
                                if (listDropZone) {
                                    if (listDropZone.childNodes.length > 0) {
                                        const listDropZoneItems = this.onlyFocusable(
                                            listDropZone.querySelectorAll(this.selectors.menuLink)
                                        );
                                        listDropZoneItems.length &&
                                            listDropZoneItems[listDropZoneItems.length - 1].focus();
                                    } else {
                                        this.dropZoneChecker(targetEl, prevMenuList, 'prev');
                                    }
                                } else {
                                    const prevMenuListItems = this.onlyFocusable(
                                        prevMenuList.querySelectorAll(this.selectors.menuLink)
                                    );
                                    prevMenuListItems.length && prevMenuListItems[prevMenuListItems.length - 1].focus();
                                }
                            } else {
                                this.moveFocusToMainLink(targetEl, 'prev');
                            }
                        }
                    }
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                e.stopImmediatePropagation();
                if (isParent) {
                    this.toggleElement(targetEl, true);
                    if (targetWrapper) {
                        targetWrapper.classList.add(this.classList.open);
                        const headingSubMenu = targetWrapper.querySelector(this.selectors.subMenuHeading);
                        if (headingSubMenu) {
                            const firstSubMenu = headingSubMenu.querySelector(this.selectors.doubleWidth);
                            if (firstSubMenu) {
                                const listDropZone = firstSubMenu.querySelector(this.selectors.listDropZone);
                                if (listDropZone) {
                                    if (listDropZone.childNodes.length > 0) {
                                        const listDropZoneItems = this.onlyFocusable(
                                            listDropZone.querySelectorAll(this.selectors.menuLink)
                                        );
                                        listDropZoneItems.length && listDropZoneItems[0].focus();
                                    } else {
                                        const nextMenuList = listDropZone.closest(
                                            this.selectors.doubleWidth
                                        ).nextSibling;
                                        this.dropZoneChecker(targetEl, nextMenuList, 'next');
                                    }
                                } else {
                                    const focusableList = this.onlyFocusable(
                                        targetWrapper.querySelectorAll(this.selectors.menuLink)
                                    );
                                    focusableList.length && focusableList[0].focus();
                                }
                            }
                        } else {
                            const focusableList = this.onlyFocusable(
                                targetWrapper.querySelectorAll(this.selectors.menuLink)
                            );
                            focusableList.length && focusableList[0].focus();
                        }
                    }
                } else {
                    if (targetEl.closest(this.selectors.nonHeading)) {
                        const targetParent = targetEl.closest(this.selectors.nonHeading);
                        const currentIndex = parseInt(targetEl.dataset.index);
                        const targetIndex = targetParent.querySelector(
                            `${this.selectors.menuLink}[data-index="${currentIndex + 1}"]`
                        );
                        if (targetIndex) {
                            targetIndex.focus();
                        } else {
                            this.moveFocusToMainLink(targetEl, 'next');
                        }
                    } else if (targetEl.closest(this.selectors.doubleWidth)) {
                        const targetParent = targetEl.closest(this.selectors.doubleWidth);
                        const currentIndex = parseInt(targetEl.dataset.index);
                        const targetIndex = targetParent.querySelector(
                            `${this.selectors.menuLink}[data-index="${currentIndex + 1}"]`
                        );
                        if (targetIndex) {
                            targetIndex.focus();
                        } else {
                            const nextMenuList = targetEl.closest(this.selectors.subMenuItem).nextSibling;
                            if (nextMenuList) {
                                const listDropZone = nextMenuList.querySelector(this.selectors.listDropZone);
                                if (listDropZone) {
                                    if (listDropZone.childNodes.length > 0) {
                                        const listDropZoneItems = this.onlyFocusable(
                                            listDropZone.querySelectorAll(this.selectors.menuLink)
                                        );
                                        listDropZoneItems.length && listDropZoneItems[0].focus();
                                    } else {
                                        this.dropZoneChecker(targetEl, nextMenuList, 'next');
                                    }
                                } else {
                                    const focusableList = this.onlyFocusable(
                                        nextMenuList.querySelectorAll(this.selectors.menuLink)
                                    );
                                    focusableList.length && focusableList[0].focus();
                                }
                            } else {
                                this.moveFocusToMainLink(targetEl, 'next');
                            }
                        }
                    }
                }
                break;
            case 'Tab':
                if (targetEl.classList.contains(this.classList.seeAllLink)) return;
                if (isParent) {
                    if (e.shiftKey) {
                        const prevMainLink = this.elements.megaMenuLink[itemIndex - 1];
                        if (!prevMainLink) return;
                        if (targetEl.classList.contains(this.classList.open)) {
                            this.toggleElement(prevMainLink, true);
                            prevMainLink.nextSibling?.classList.add(this.classList.open);
                        }
                        this.toggleElement(targetEl, false);
                        targetEl.nextSibling?.classList.remove(this.classList.open);
                    } else {
                        const targetWrapper = targetEl.nextSibling;
                        if (!targetWrapper) {
                            const nextMainLink = this.elements.megaMenuLink[itemIndex + 1];
                            if (nextMainLink) {
                                if (targetEl.classList.contains(this.classList.open)) {
                                    this.toggleElement(targetEl, false);
                                    if (nextMainLink.nextSibling) {
                                        this.toggleElement(nextMainLink, true);
                                        nextMainLink.nextSibling.classList.add(this.classList.open);
                                    } else {
                                        nextMainLink.classList.add(this.classList.open);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (!e.shiftKey) {
                        if (targetEl.closest(this.selectors.nonHeading)) {
                            const nextMenuItem = targetEl
                                .closest(this.selectors.nonHeading)
                                .querySelector(`${this.selectors.menuLink}[data-index="${itemIndex + 1}"]`);

                            if (!nextMenuItem) {
                                e.preventDefault();
                                this.moveFocusToMainLink(targetEl, 'next');
                            }
                        } else if (targetEl.closest(this.selectors.doubleWidth)) {
                            const nextMenuItem = targetEl
                                .closest(this.selectors.doubleWidth)
                                .querySelector(`${this.selectors.menuLink}[data-index="${itemIndex + 1}"]`);

                            if (!nextMenuItem) {
                                e.preventDefault();
                                const nextSubMenu = targetEl.closest(this.selectors.doubleWidth).nextSibling;
                                if (nextSubMenu) {
                                    const listDropZone = nextSubMenu.querySelector(this.selectors.listDropZone);
                                    if (listDropZone) {
                                        if (listDropZone.childNodes.length > 0) {
                                            const listDropZoneItems = this.onlyFocusable(
                                                listDropZone.querySelectorAll(this.selectors.menuLink)
                                            );
                                            listDropZoneItems.length && listDropZoneItems[0].focus();
                                        } else {
                                            this.dropZoneChecker(targetEl, nextSubMenu, 'next');
                                        }
                                    } else {
                                        const focusableList = this.onlyFocusable(
                                            nextSubMenu.querySelectorAll(this.selectors.menuLink)
                                        );
                                        focusableList.length && focusableList[0].focus();
                                    }
                                } else {
                                    this.moveFocusToMainLink(targetEl, 'next');
                                }
                            }
                        }
                    }
                }
                break;
            case 'Escape':
                if (isParent) {
                    const menuWrapper = targetEl.nextSibling;
                    if (menuWrapper && menuWrapper.classList.contains(this.classList.open)) {
                        this.toggleElement(targetEl, false);
                        targetWrapper.classList.remove(this.classList.open);
                    }
                } else {
                    const parentWrapper = targetEl.closest(this.selectors.megaMenuWrapper);
                    const parentMenu = parentWrapper.previousSibling;
                    parentMenu.focus();
                    this.toggleElement(parentMenu, false);
                    parentWrapper.classList.remove(this.classList.open);
                }
                break;
        }
    };

    expandQuickSearchBox = () => {
        if (this.elements.megaMenu.classList.contains(this.classList.open)) {
            this.elements.megaMenu.classList.remove(this.classList.open);
            this.elements.megaMenuToggle.classList.remove(this.classList.open);
        }
        this.elements.quickSearchBox.classList.add(this.classList.open);
    };

    collapseQuickSearchBox = e => {
        if (e.relatedTarget && !e.relatedTarget.closest(this.selectors.quickSearchNav))
            this.elements.quickSearchBox?.classList.remove(this.classList.open);
    };

    closeQuickSearchBox = () => {
        this.elements.quickSearchToggleInput.nextSibling.focus();
        this.elements.quickSearchBox?.classList.remove(this.classList.open);
    };

    closeMenuOnClick = e => {
        if (!e.target.closest(this.selectors.section)) {
            const openedMenuItems = this.elements.megaMenu.querySelectorAll(`.${this.classList.open}`);
            openedMenuItems.forEach(item => {
                if (item.classList.contains(this.classList.megaMenuLink)) {
                    this.toggleElement(item, false);
                } else {
                    item.classList.remove(this.classList.open);
                }
            });

            if (this.elements.quickSearchBox && this.elements.quickSearchBox.classList.contains(this.classList.open))
                this.elements.quickSearchBox.classList.remove(this.classList.open);
        }
    };

    closeMenuOnFocus = e => {
        if (e.relatedTarget && !e.relatedTarget.closest(this.selectors.megaMenu)) {
            const openedMenuItems = this.elements.megaMenu.querySelectorAll(`.${this.classList.open}`);
            openedMenuItems.forEach(item => {
                if (item.classList.contains(this.classList.megaMenuLink)) {
                    this.toggleElement(item, false);
                } else {
                    item.classList.remove(this.classList.open);
                }
            });
        }
    };

    filterBoxHideHandler = e => {
        if (e.relatedTarget && !e.relatedTarget.closest(this.selectors.quickSearchNav)) this.closeQuickSearchBox();
    };

    setFocusIndex = e => {
        if (!e.target.closest(this.selectors.listDropZone)) return;
        const listDropZone = e.target.closest(this.selectors.listDropZone);
        const focusableList = this.onlyFocusable(listDropZone.querySelectorAll(this.selectors.menuLink));
        focusableList.map((item, index) => {
            item.dataset.index = `${index}`;
            item.addEventListener('keydown', this.menuLinkKeyDownHandler);
        });
    };

    removeHiddenMultiSearch = () => {
        this.elements.hiddenMultiSearch.forEach(item => {
            const parentColumn = item.closest(this.selectors.listDropZone);
            if (parentColumn && document.documentElement.id !== 'pb-editor') parentColumn.textContent = '';
        });
    };

    skipMenuSection = e => {
        e.preventDefault();
        document.body.scrollTop = this.elements.quickSearchToggleInput.offsetTop;
        this.elements.quickSearchToggleInput.focus();
    };

    onReady = () => {
        this.removeHiddenMultiSearch();
    };

    eventListeners() {
        document.body.addEventListener('click', this.closeMenuOnClick);
        this.elements.megaMenuLink.forEach(item => item.addEventListener('click', this.megaMenuLinkHandler));
        this.elements.menuLink.forEach(item => item.addEventListener('keydown', this.menuLinkKeyDownHandler));
        this.elements.listDropZone.forEach(dropZone => {
            const focusableList = dropZone.querySelectorAll(
                'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
            );
            focusableList.forEach(item => {
                item.classList.add(this.classList.menuLink);
                item.addEventListener('keydown', this.menuLinkKeyDownHandler);
                item.addEventListener('focusin', this.setFocusIndex);
            });
        });
        this.elements.megaMenuToggle.addEventListener('click', this.toggleHandler);
        this.elements.quickSearchToggleInput?.addEventListener('focus', this.expandQuickSearchBox);
        this.elements.quickSearchToggleInput?.addEventListener('focusout', this.collapseQuickSearchBox);
        this.elements.quickSearchBoxClose?.addEventListener('click', this.closeQuickSearchBox);
        this.elements.megaMenu.addEventListener('focusout', this.closeMenuOnFocus);
        this.elements.quickSearchBox.addEventListener('focusout', this.filterBoxHideHandler);
        this.elements.skipMenuLink?.addEventListener('click', this.skipMenuSection);
    }

    init() {
        const megaMenuSection = document.querySelector(this.selectors.section);
        if (!megaMenuSection) return;
        this.elements.section = megaMenuSection;
        this.setElements();
        this.onReady();
        this.eventListeners();
    }
}
