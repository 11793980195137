// @TODO: this is old advanced search (core) from core-1.49.0 and will should be removed and replaced by the advanced search form core-1.50.0 file (LIT-)
(function () {
    var $body = $('body');

    var searchFieldsCtrl = {
        clonedInside: '.advanced-search--searchIn',
        $wrapper: null,
        $form: null,
        $terms: null,
        escape: 27,
        dateRangeVal: $('#staticRangeSelect').val(),
        vPort: 'screen-sm', // default responsive break point
        isMobile: false,
        $magicSuggest: null,
        magicSuggestObjects: [],
        $cloningInput: null,

        init: function () {
            searchFieldsCtrl.$form = $('.frmSearch');
            if (config.enableMagicSearchEnabledInAdvancedSearch) { /*eslint-disable-line*/
                searchFieldsCtrl.$magicSuggest = $('.magicsuggest');
                searchFieldsCtrl.initMagicSuggest();
            }
            searchFieldsCtrl.check.submitButton();
            searchFieldsCtrl.check.terms();
            searchFieldsCtrl.control();
            searchFieldsCtrl.addtionalControls();
            searchFieldsCtrl.$form.find('input[type=text]:first').focus();

            const $lastTerm = $('.advanced-search--searchIn').find('.searchIn--field:last-child');
            searchFieldsCtrl.check.lastClone($lastTerm);
        },
        control: function () {
            $body.on('click', '.advanced-search--searchIn [class*="-ctrl-field"]', function (e) {
                e.preventDefault();
                e.stopPropagation();
                searchFieldsCtrl.$wrapper = $(this).closest('.searchIn--field');

                if ($(this).is('.add-ctrl-field')) {
                    searchFieldsCtrl.$terms = $(this).closest('.advanced-search--searchIn').find('.searchIn--field');

                    if (searchFieldsCtrl.$terms.length < 7) {
                        searchFieldsCtrl.on.clone($(this));
                    }
                } else {
                    searchFieldsCtrl.on.remove($(this));
                }
            });

            $body.on('keyup input', '.searchIn--field input', function () {
                searchFieldsCtrl.$form = $(this).closest('form');
                searchFieldsCtrl.check.submitButton();
            });

            searchFieldsCtrl.click.toggleSearchBlock();

            $(document).on(searchFieldsCtrl.vPort + '-on', function () {
                // Waiting for custom event that will be triggered by controller.js to activate responsive effects
                searchFieldsCtrl.isMobile = true;
            });

            $(document).on(searchFieldsCtrl.vPort + '-off', function () {
                // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
                searchFieldsCtrl.isMobile = false;
            });

            $('.advanced-search--advancedFilters .radio--primary input[type="radio"]').change(function () {
                $('.advanced-search--advancedFilters .radio--primary input[type="radio"]').prop('checked', false);
                $(this).prop('checked', true);
            });

            $('body').on('change', '#earlyCite', function () {
                // this code added to solve LIT-190918

                var $earlyCite_hidden = $('#earlyCite_hidden');

                if ($earlyCite_hidden.val() === '') {
                    $earlyCite_hidden.val('off');
                    $earlyCite_hidden.prop('disabled', false);
                } else {
                    $earlyCite_hidden.val('');
                    $earlyCite_hidden.prop('disabled', true);
                }
            });

            $('body').on('change', '[type=radio][name=Ppub]', function () {
                $('#publicationDate .time-frame').find('select').prop('disabled', true);
                $(this).closest('.time-frame').find('select').prop('disabled', false);
            });
        },
        addtionalControls: function () {},
        hideTriggerOption: function ($elem) {
            var hideTrigger = true;
            if ($elem.hasClass('showDefaultData')) {
                hideTrigger = false;
            }
            return hideTrigger;
        },
        click: {
            toggleSearchBlock: function () {
                $body.on('click', '.search__result .advanced-search__ctrl', function (e) {
                    if (!searchFieldsCtrl.isMobile) {
                        e.preventDefault();
                        $(this).toggleClass('js--open');
                        $('.search-result__meta .advanced-search__tabs').toggle();
                    }
                });
            },
        },
        on: {
            clone: function ($this) {
                searchFieldsCtrl.$wrapper
                    .clone(true, true)
                    .find('input')
                    .val('')
                    .end()
                    .appendTo(searchFieldsCtrl.clonedInside);
                $this.addClass('hidden');
                $this.siblings('.remove-ctrl-field').removeClass('hidden');
                $this = $('.advanced-search--searchIn').children('.searchIn--field:last-child');
                searchFieldsCtrl.check.lastClone($this);
            },
            remove: function ($this) {
                searchFieldsCtrl.$form = $this.closest('form');
                searchFieldsCtrl.$wrapper.remove();
                searchFieldsCtrl.on.additionalRemove($this);
                searchFieldsCtrl.check.submitButton();

                $this = $('.advanced-search--searchIn').children('.searchIn--field:last-child');
                searchFieldsCtrl.check.lastClone($this);
            },
            additionalRemove: function ($elem) {},
        },
        check: {
            emptyInputs: function (form) {
                var textInputs = $(form).find('input[type=search]');
                if (textInputs.length === 0) {
                    textInputs = $(form).find('input[type=text]');
                }

                textInputs = $(textInputs).add($(form).find('.checkVal'));

                const $emptyFields = $(textInputs).filter(function () {
                    return this.value === '';
                });
                return $emptyFields.length === textInputs.length;
            },
            submitButton: function () {
                if (
                    searchFieldsCtrl.check.emptyInputs(searchFieldsCtrl.$form) &&
                    searchFieldsCtrl.check.emptySuggestions()
                ) {
                    $(searchFieldsCtrl.$form).find('button[type=submit]').attr('disabled', true);
                } else {
                    $(searchFieldsCtrl.$form).find('button[type=submit]').removeAttr('disabled', true);
                }
            },
            emptySuggestions() {
                const suggestions = searchFieldsCtrl.magicSuggestObjects;
                if (!suggestions.length) {
                    return true;
                }
                return suggestions.filter(suggestion => suggestion.getValue().length > 0).length === 0;
            },
            terms: function () {
                searchFieldsCtrl.$terms = $('.advanced-search--searchIn .searchIn--field');

                searchFieldsCtrl.$terms.each(function (index) {
                    var number = index + 1;
                    var $this = $(this);

                    searchFieldsCtrl.$cloningInput = searchFieldsCtrl.check.defineCloningInput($this);

                    $this.find('[for*="searchArea"]').attr({
                        for: 'searchArea' + number,
                    });

                    $this.find('[for*="text"]').attr({
                        for: 'text' + number,
                    });

                    $this.find('select').attr({
                        id: 'searchArea' + number,
                        name: 'field' + number,
                    });

                    searchFieldsCtrl.$cloningInput.attr({
                        id: 'text' + number,
                        name: 'text' + number,
                    });
                });
            },
            defineCloningInput: function ($this) {
                return $this.find('input');
            },
            lastClone: function ($this) {
                if ($this.index() === 6) {
                    $this.children('.add-ctrl-field').addClass('hidden');
                    $this.children('.remove-ctrl-field').removeClass('hidden');
                } else {
                    $this.children('.add-ctrl-field').removeClass('hidden');
                    $this.children('.remove-ctrl-field').addClass('hidden');
                }
                searchFieldsCtrl.check.terms();
            },
        },
    };
    if (config.enableMagicSearchEnabledInAdvancedSearch) { /*eslint-disable-line*/
        searchFieldsCtrl.initMagicSuggest = function () {
            searchFieldsCtrl.$magicSuggest.each(function () {
                searchFieldsCtrl.on.doSuggest($(this));
            });
        };
        searchFieldsCtrl.on.doSuggest = function ($elem) {
            let doSuggestTarget = $elem.data('auto-complete-target');
            const withinData = $elem.data('auto-complete-within');
            const maxWords = 200;
            const maxChars = 222;
            const name = $elem.attr('name');
            const id = $elem.attr('data-magic-id') || $elem.attr('id');
            const values = $elem.attr('data-values') ? JSON.parse($elem.attr('data-values')) : [];

            if (withinData) {
                doSuggestTarget = doSuggestTarget + '&within=' + withinData;
            }

            const magicSuggestCfg = searchFieldsCtrl.setMagicSuggestCfg(id, name, $elem);

            const $ms = $elem.magicSuggest({
                data: '/action/doSuggest?target=' + doSuggestTarget, // realData
                // data: '/specs/ux3/widgets/advanced-search/templates/demo/fakeAutoCompleteData.json', // fakeData
                method: 'GET',
                valueField: 'value',
                autoSelect: false,
                allowFreeEntries: false,
                displayField: 'label',
                cls: 'search-term',
                hideTrigger: searchFieldsCtrl.hideTriggerOption($elem),
                inputCfg: magicSuggestCfg,
                beforeSend: function (xhr, settings) {
                    var enteredTerm = settings.url.substr(settings.url.indexOf('query=') + 6).replace(/[​​+]/g, ' ');
                    if (
                        enteredTerm.split(' ').length > maxWords ||
                        enteredTerm.length > maxChars ||
                        !enteredTerm.replace(/\s/g, '').length
                    ) {
                        if ($elem.hasClass('showDefaultData')) {
                            //get all data
                            settings.url += '*:*';
                        } else {
                            return false;
                        }
                    }
                },
            });

            if (values.length) {
                $ms.setSelection(values);
            }
            $($ms).on('selectionchange', function () {
                searchFieldsCtrl.check.submitButton();
            });
            searchFieldsCtrl.magicSuggestObjects.push($ms);
            searchFieldsCtrl.check.submitButton();

            const didSuggest = $.Event('search:didSuggest');
            $(document).trigger(didSuggest, $ms);
        };

        searchFieldsCtrl.setMagicSuggestCfg = function (id, name, $elem) {
            $elem.attr('id', id + '-original');
            return {id: id, name: name};
        };
    }

    UX.searchFieldsCtrl = searchFieldsCtrl; // add to global namespace
})();
