(function ($) {
    function getCloudflareScript() {
        if ($('script[src="https://embed.cloudflarestream.com/embed/r4xu.fla9.latest.js"]').length === 0) {
            $.getScript('https://embed.cloudflarestream.com/embed/r4xu.fla9.latest.js');
        }
    }

    function addShareButton() {
        let wrapperContent = $('stream');
        let fbShareLink = 'https://www.facebook.com/sharer/sharer.php?u='; // + share_url, //facebook
        let twShareLink = 'https://twitter.com/share?url='; //+ $title + '&url=' + share_url, // twitter
        let emShareLink = 'mailto:?body='; // + share_url, //email
        let videoUri = window.location.protocol + '//' + window.location.hostname + window.location.pathname;

        let shareButton = $('<div>', {class: 'player-share-button', title: 'Share Video'});
        shareButton.append("<div class='share-player-icon'></div>");
        let shareContent = $('<div>', {class: 'player-share-container hidden'});

        let facebookHref = $('<li>', {
            class: 'html5-share-facebook-icon',
            'data-share-url': fbShareLink + videoUri,
            target: '_blank',
        });

        let twitterHref = $('<li>', {
            class: 'html5-share-twitter-icon',
            'data-share-url': twShareLink + videoUri,
            target: '_blank',
        });

        let mailkHref = $('<li>', {
            class: 'html5-share-mail-icon',
            'data-share-url': emShareLink + videoUri,
            target: '_blank',
        });

        let socialLinkContainer = $('<div>', {class: 'social-links-container'});
        let socialLinklist = $('<ul>', {class: 'social-links-list'});

        let closeShare = $('<span>', {class: 'player-close-share'});

        shareContent.append(closeShare);
        socialLinklist.append(facebookHref).append(twitterHref).append(mailkHref);
        socialLinkContainer.append(socialLinklist);

        let sharingTextbox = $('<input>', {
            type: 'text',
            value: videoUri,
            readonly: 'readonly',
            class: 'share-input',
        });
        socialLinkContainer.append(sharingTextbox);

        shareContent.append(socialLinkContainer);
        wrapperContent.append(shareButton).append(shareContent);

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            if (isMobile) {
                $('.player-share-button').attr('style', 'right: 4%; top: 16%;');
            }
        }

        $(socialLinkContainer)
            .find('li')
            .each(function () {
                $(this).click(function () {
                    let shareLink = $(this).attr('data-share-url');
                    window.open(shareLink);
                });
            });
    }

    function toggleOpenShare() {
        $('.player-share-button').on('click', function (e) {
            $(this).parent().find('.player-share-container').removeClass('hidden');
            // $('.player-share-container').toggleClass('hidden'); //
            $(this).addClass('hidden');
        });

        $('.player-close-share').on('click', function (e) {
            $(this).closest('.player-share-container').addClass('hidden');
            $(this).closest('.player-share-container').prev().removeClass('hidden');
        });

        $('.social-links-container')
            .find('li')
            .on('click', function () {
                let shareLink = $(this).attr('data-share-url');
                window.open(shareLink);
            });
    }

    $(document).ready(function () {
        if ($('.cloudflare-stream-container').length > 0) {
            getCloudflareScript();
            $('.cloudflare-stream-container').each(function (indx, element) {
                let videoSource = $(element).find('.video-stream-source').text().trim();
                let streamTag = $(element).find('.cloudflare-stream-player');
                $(streamTag).attr('src', videoSource);
            });

            addShareButton();
            toggleOpenShare();
        }
    });
})(jQuery);
