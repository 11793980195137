import {focusCycle} from 'ui-core/utility/js/utils.js';

export default class SessionReminder {
    constructor() {
        this.init();
    }

    flags: {
        timeNow: number;
        sessionEndTime: number;
        sessionStartValue: number;
        sessionStartText: string;
        sessionEnd: number;
        hide: string;
        show: string;
        sessionExpiredText: string;
        sessionCheckerDelay: number;
        countDownDelay: number;
        reminderDuration: number;
        loginSessionDuration: number;
        events: string[];
    } = {
        timeNow: 0,
        sessionEndTime: 0,
        sessionStartText: 'sessionStart',
        sessionStartValue: 0,
        sessionEnd: 30,
        hide: 'hide',
        show: 'show',
        sessionExpiredText: 'Your session has expired.',
        sessionCheckerDelay: 60000,
        countDownDelay: 1000,
        reminderDuration: 120000,
        loginSessionDuration: 1800000,
        events: ['click', 'keydown'],
    }

    classList: Record<string, string> = {
        hidden: 'hidden'
    }

    selectors: Record<string, string> = {
        sessionReminderDialog: '.session-reminder__dialog',
        sessionReset: '.session-reset',
        dialogTimer: '.dialog-timer',
        dialogTimerMinutes: '.dialog-timer__minutes',
        dialogTimerSeconds: '.dialog-timer__seconds',
        dialogClose: '.dialog-close',
        dialogDesc: '.dialog-desc',
    }

    elements: {
        section: HTMLElement | null,
        sessionReset: HTMLElement | null,
        dialogTimer: HTMLElement | null,
        dialogTimerMinutes: HTMLElement | null,
        dialogTimerSeconds: HTMLElement | null,
        dialogClose: HTMLElement | null,
        dialogDesc: HTMLElement | null,
    } = {
        section: null,
        sessionReset: null,
        dialogTimer: null,
        dialogTimerMinutes: null,
        dialogTimerSeconds: null,
        dialogClose: null,
        dialogDesc: null,
    }

    setElements() {
        this.elements.sessionReset = this.elements.section.querySelector(this.selectors.sessionReset) as HTMLElement;
        this.elements.dialogTimer = this.elements.section.querySelector(this.selectors.dialogTimer) as HTMLElement;
        this.elements.dialogTimerMinutes = this.elements.dialogTimer.querySelector(this.selectors.dialogTimerMinutes) as HTMLElement;
        this.elements.dialogTimerSeconds = this.elements.dialogTimer.querySelector(this.selectors.dialogTimerSeconds) as HTMLElement;
        this.elements.dialogClose = this.elements.section.querySelector(this.selectors.dialogClose) as HTMLElement;
        this.elements.dialogDesc = this.elements.section.querySelector(this.selectors.dialogDesc) as HTMLElement;
    }

    sessionReset = () => location.reload();

    updateSessionEndTime = () => {
        this.flags.sessionEndTime = this.flags.timeNow + this.flags.loginSessionDuration;
        window.localStorage.setItem(this.flags.sessionStartText, this.flags.timeNow.toString());
    }

    millisToMinutesAndSeconds = millis => {
        const minutes: number = Math.floor(millis / 60000);
        const seconds: string = ((millis % 60000) / 1000).toFixed(0);
        const time: string = `${minutes}:${(parseInt(seconds) < 10 ? '0' : '') + seconds}`;
        return time;
    }

    handleSessionExpired = interval => {
        clearInterval(interval);
        this.elements.dialogDesc.setAttribute('aria-live', 'polite');
        this.elements.dialogDesc.textContent = this.flags.sessionExpiredText;
    }

    countDownHandler = (remainingTime: number, delay: number) => {
        const countDownInterval = setInterval(() => {
            if (remainingTime > 0) {
                remainingTime -= 1000;
                this.elements.dialogTimer.textContent = `(0${this.millisToMinutesAndSeconds(remainingTime)})`;
            } else {
                this.handleSessionExpired(countDownInterval);
            }
        }, delay);
    }

    sessionReminder = {
        toggle: (status: string) => {
            if (status === this.flags.hide) {
                this.elements.section.classList.add(this.classList.hidden);
            } else {
                this.elements.section.classList.remove(this.classList.hidden);
                this.countDownHandler(this.flags.reminderDuration, this.flags.countDownDelay);
                setTimeout(() => this.elements.dialogClose.focus(), 150);
            }
        }
    }

    clickOutsideDialogHandler = e => {
        const {type, target, key} = e;
        switch (type) {
            case 'click':
                if (!target.closest(this.selectors.sessionReminderDialog))
                    this.sessionReminder.toggle(this.flags.hide);
                break;
            case 'keydown':
                if (key !== 'Escape' && !this.elements.section.classList.contains(this.classList.hidden)) return;
                this.sessionReminder.toggle(this.flags.hide);
        }
    }

    sessionInit = (delay: number) => {
        focusCycle(this.elements.section);
        this.flags.timeNow = Date.now();
        this.updateSessionEndTime();
        const sessionCheckerInterval = setInterval(() => {
            let currentSessionValue: number = parseInt(window.localStorage.getItem(this.flags.sessionStartText));
            if (currentSessionValue > this.flags.timeNow) {
                this.flags.timeNow = currentSessionValue;
                this.updateSessionEndTime();
            }
            this.flags.sessionEndTime -= delay;
            if (this.flags.sessionEndTime - this.flags.timeNow === this.flags.reminderDuration) {
                this.sessionReminder.toggle(this.flags.show);
                clearInterval(sessionCheckerInterval);
            }
        }, delay);
    }

    onReady = () => {
        this.sessionInit(this.flags.sessionCheckerDelay);
    }

    eventHandler = () => {
        this.elements.sessionReset?.addEventListener('click', this.sessionReset);
        this.elements.dialogClose?.addEventListener('click', () => this.sessionReminder.toggle(this.flags.hide));
        this.flags.events.map(ev => document.addEventListener(ev, this.clickOutsideDialogHandler));
    }

    init() {
        const sessionReminder = document.querySelector('.session-reminder') as HTMLElement;
        if (!sessionReminder) return;
        this.elements.section = sessionReminder;
        this.setElements();
        this.eventHandler();
        this.onReady();
    }
}
