(function () {
    var textbox = {
        isMobile: false,
        vPort: 'screen-sm',
        init: function () {
            $(document).on(textbox.vPort + '-on', function () {
                // Waiting for custom event that will be triggered by controller.js to activate responsive effects
                textbox.isMobile = true;
            });

            $(document).on(textbox.vPort + '-off', function () {
                // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects
                textbox.isMobile = false;
            });

            if ($(window).width() < UX.grid.screenMd) {
                textbox.isMobile = true;
            }

            if (!textbox.isMobile) {
                $('.accordion--textbox').each(function () {
                    var $this = $(this).find('.accordion__control');
                    UX.accordion.on.toggle.single($this);
                });
            }
            textbox.ToggleTitle.init();
            textbox.ToggleTitle.toggleTitleClick();
        },
        ToggleTitle: {
            accordionControl: null,
            init: function () {
                var $this = this;
                $this.accordionControl = $('.accordion--textbox .accordion__control');
                $this.accordionControl.attr('title', ' ');
                $this.accordionControl.each(function () {
                    if ($(this).hasClass('js--open')) {
                        $(this).addClass('expanded');
                        $this.titleFunction($(this));
                    } else {
                        $this.titleFunction($(this));
                    }
                });
            },
            toggleTitleClick: function () {
                $('.accordion--textbox .accordion__control').on('click', function () {
                    $(this).toggleClass('expanded');
                    textbox.ToggleTitle.titleFunction($(this));
                });
            },
            titleFunction: function (accordion) {
                if (accordion.hasClass('expanded')) {
                    accordion.attr('title', 'Collapse');
                } else {
                    accordion.attr('title', 'Expand');
                }
            },
        },
    };

    UX.textbox = textbox; // add to global namespace
})();
