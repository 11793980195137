(function () {
    const checkout = {
        $htmlAndBody: $('html, body'),
        $pageHeader: $('header.header'),
        $thisWidget: $('.echeckout'),
        $sameAsBillingContainer: $('.echeckout_sameAsBilling'),
        $sameAsBillingCheckBox: $('.echeckout_sameAsBillingCB'),
        $shippingForm: $('.echeckout_form-shipping'),
        $submitBtn: $('.echeckout_btn input'),
        $allRequiredFields: $('.echeckout_required'),
        $billingFormRequiredFields: $('.echeckout_form-billing .echeckout_required'),
        $shippingFormRequiredFields: $('.echeckout_form-shipping .echeckout_required'),
        $countryCodeSelect: $('.echeckout_countryCode'),
        $shippingCountryCodeSelect: $('.echeckout_sh_countryCode'),
        $stateSelectUsa: $('select.echeckout_state__usa'),
        $stateSelectCA: $('select.echeckout_state__ca'),
        $stateInput: $('input.echeckout_state'),
        $shippingStateSelectUsa: $('select.echeckout_sh_state__usa'),
        $shippingStateSelectCa: $('select.echeckout_sh_state__ca'),
        $shippingStateInput: $('input.echeckout_sh_state'),
        $valSummary: $('.echeckout_val-summary'),
        $errorsMesage: $('.echeckout_errors'),
        $valSummaryListItems: $('.echeckout_val-summary li'),
        $valSummaryLinks: $('.echeckout_val-summary li a'),
        $errorClass: 'echeckout_error',
        $addressStateLabel: $('.echeckout_form label[for="state"]'),
        $billingStateLabel: $('.echeckout_form label[for="shipping_state"]'),
        init: function () {
            checkout.control();
            checkout.$countryCodeSelect.trigger('change');
            checkout.$shippingCountryCodeSelect.trigger('change');
        },
        control: function () {
            // Same As Billing CheckBox
            checkout.$sameAsBillingContainer.show();
            checkout.$sameAsBillingCheckBox.change(function () {
                checkout.$shippingForm.slideToggle();
                checkout.$shippingFormRequiredFields.removeClass(checkout.$errorClass);
            });
            // Submit Btn
            checkout.$submitBtn.prop('disabled', false);
            checkout.$submitBtn.click(function (e) {
                if (checkout.runFormValidation()) {
                    checkout.hideValSummary();
                    checkout.newPageCheckout();
                } else {
                    e.preventDefault();
                    checkout.showValSummary();
                    checkout.scrollTopElement(checkout.$valSummary);
                }
            });
            // Country Code Select
            checkout.$countryCodeSelect.change(function () {
                checkout.CountrySelectHandler(
                    checkout.$countryCodeSelect.val(),
                    checkout.$stateSelectUsa,
                    checkout.$stateSelectCA,
                    checkout.$stateInput,
                    checkout.$addressStateLabel
                );
            });
            // Shipping Country Code Select
            checkout.$shippingCountryCodeSelect.change(function () {
                checkout.CountrySelectHandler(
                    checkout.$shippingCountryCodeSelect.val(),
                    checkout.$shippingStateSelectUsa,
                    checkout.$shippingStateSelectCa,
                    checkout.$shippingStateInput,
                    checkout.$billingStateLabel
                );
            });
            // All Required Fields
            checkout.$allRequiredFields.focus(function () {
                $(this).removeClass(checkout.$errorClass);
            });
            // Validation Summary Links
            checkout.$valSummaryLinks.click(function (e) {
                e.preventDefault();
                let $formInput = checkout.$thisWidget.find("[name='" + $(this).attr('data-for') + "']:not(:disabled)");
                $formInput.focus();
                checkout.scrollTopElement($formInput);
            });
        },
        CountrySelectHandler: function (countryCode, selectElUsa, selectElCa, textInput, labelEl) {
            if (countryCode === 'US' || countryCode === 'CA') {
                selectElUsa.prop('disabled', countryCode === 'CA');
                selectElCa.prop('disabled', countryCode === 'US');
                textInput.prop('disabled', true);
                labelEl.html(
                    'State / Province / Region <span class="sr-only">required</span><span aria-hidden="true">*</span>'
                );
            } else {
                selectElUsa.prop('disabled', true);
                selectElCa.prop('disabled', true);
                textInput.prop('disabled', false);
                labelEl.html('State / Province / Region');
            }
        },
        showValSummary: function () {
            checkout.$valSummary.show();
            checkout.$errorsMesage.hide();
        },
        hideValSummary: function () {
            checkout.$valSummary.hide();
            checkout.$errorsMesage.hide();
        },
        scrollTopElement: function ($element) {
            checkout.$htmlAndBody.animate(
                {
                    scrollTop: $element.offset().top - checkout.$pageHeader.height() - 10,
                },
                1000
            );
        },
        runFormValidation: function () {
            let $isValid = true;
            checkout.$valSummaryListItems.hide();
            // Billing Form
            checkout.$billingFormRequiredFields.each(function () {
                if (!$(this).prop('disabled') && !$.trim($(this).val())) {
                    if ($(this).hasClass('echeckout_state')) {
                        if (checkout.$countryCodeSelect.val() === 'US' || checkout.$countryCodeSelect.val() === 'CA') {
                            $(this).addClass(checkout.$errorClass);
                            $isValid = false;
                            checkout.$valSummaryListItems.filter("[data-for='" + $(this).attr('name') + "']").show();
                        } else {
                            $(this).removeClass(checkout.$errorClass);
                        }
                    } else {
                        $(this).addClass(checkout.$errorClass);
                        checkout.$valSummaryListItems.filter("[data-for='" + $(this).attr('name') + "']").show();
                        $isValid = false;
                    }
                } else {
                    $(this).removeClass(checkout.$errorClass);
                }
            });

            // Shipping Form
            if (!checkout.$sameAsBillingCheckBox.is(':checked')) {
                checkout.$shippingFormRequiredFields.each(function () {
                    if (!$(this).prop('disabled') && !$.trim($(this).val())) {
                        if ($(this).hasClass('echeckout_sh_state')) {
                            if (
                                checkout.$shippingCountryCodeSelect.val() === 'US' ||
                                checkout.$shippingCountryCodeSelect.val() === 'CA'
                            ) {
                                $(this).addClass(checkout.$errorClass);
                                $isValid = false;
                                checkout.$valSummaryListItems
                                    .filter("[data-for='" + $(this).attr('name') + "']")
                                    .show();
                            } else {
                                $(this).removeClass(checkout.$errorClass);
                            }
                        } else {
                            $(this).addClass(checkout.$errorClass);
                            $isValid = false;
                            checkout.$valSummaryListItems.filter("[data-for='" + $(this).attr('name') + "']").show();
                        }
                    } else {
                        $(this).removeClass(checkout.$errorClass);
                    }
                });
            }
            return $isValid;
        },

        newPageCheckout: function () {
            let newPage = window.pageData;
            if (newPage['isLoaded'] !== undefined) delete newPage['isLoaded'];
            if (newPage['savedEvents'] !== undefined) delete newPage['savedEvents'];
            if (newPage['eventList'] !== undefined) delete newPage['eventList'];
            delete newPage.visitor.loginSuccess;

            newPage.page['purchaseStep'] = 'checkout';
            newPage.page.name = 'ecommerce:paymentinfo';
            newPage.page.type = 'EC-OD';
            checkout.logMessage('calling adobe newPage Checkout action: ' + JSON.stringify(newPage));
            if (newPage.journal) {
                newPage = {
                    content: newPage.content,
                    page: newPage.page,
                    visitor: newPage.visitor,
                    journal: newPage.journal,
                };
            } else {
                newPage = {
                    content: newPage.content,
                    page: newPage.page,
                    visitor: newPage.visitor,
                };
            }
            window.pageDataTracker.trackEvent('newPage', newPage);
        },

        logMessage: function (message) {
            if (window.console && window.console.log) {
                console.log(message); // eslint-disable-line no-console
            }
        },
    };
    UX.checkout = checkout; // add to global namespace
})();
