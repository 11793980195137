(function () {
    UX.tab.on.dropdownBuild = function (elem, index) {
        // rebuild tabs to be as drop down
        UX.dropBlock.init();
        elem.wrap('<div class="dropBlock" data-db-parent-of="dbTab-' + index + '"></div>');

        let $dropdownContainer = elem.closest('.dropBlock');
        let $activeTab = elem.find('.active a');

        $dropdownContainer.prepend(
            '<a href="#" data-db-target-for="dbTab-' +
                index +
                '"><span class="dropBlock__selected">' +
                $activeTab.text() +
                '</span><i class="dropBlock__selected icon-arrows pull-right" aria-hidden="true"></i></a>'
        );
        elem.attr('data-db-target-of', 'dbTab-' + index);
    };
    UX.dropBlock.on.show = function () {
        if (!UX.dropBlock.$controller.closest('.dropBlock__holder').length) {
            if (typeof UX.controller != 'undefined') {
                UX.controller.check();
            }
        }
        if (UX.dropBlock.$controller.hasClass('article-header__info__ctrl')) {
            UX.LSN.scroll($(this), '#articleHeader');
        }
        UX.dropBlock.$controller.attr('aria-expanded', 'true');
        UX.dropBlock.$target.addClass('js--open');
        UX.dropBlock.$controller.addClass('js--open');
        $(document).trigger('db-show');
        if (UX.dropBlock.$controller.closest('.figure-viewer').length) {
            UX.dropBlock.$controller
                .closest('.figure-viewer')
                .find('.figure-viewer__caption-body')
                .css('overflow-y', 'hidden');
        }
        $('body').attr('data-active', 'dropBlock');
        UX.dropBlock.$target.closest("[class*='sticko']").addClass('js--open');
        UX.dropBlock.switch.icon();

        let isLoi = UX.dropBlock.$target.find('.loi').length > 0;

        if (isLoi) {
            UX.dropBlock.$target.find('.loi__list').each(function () {
                UX.loi.on.recalculate($(this));
            });
        }
        let focusable = UX.dropBlock.$target.find(
            'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        if (focusable.length > 0) {
            let lastFocusable = focusable[focusable.length - 1];
            $(lastFocusable).on('focusout', function (event) {
                if (!$(event.relatedTarget).closest('.dropBlock__holder').length) UX.dropBlock.on.hide();
            });
        } else {
            UX.dropBlock.$controller.on('focusout', function () {
                UX.dropBlock.on.hide();
            });
        }

        $('body').on('click', '.dropBlock__ctrl--close', function (event) {
            event.preventDefault();
            UX.dropBlock.on.hide();
        });

        let searchbar = UX.dropBlock.$target.find('.quick-search__searchbar input');
        if (searchbar) {
            searchbar.focus();
        }
    };
    UX.dropBlock.on.hide = function (e) {
        if (UX.dropBlock.$controller && UX.dropBlock.$controller.hasClass('article-header__info__ctrl')) {
            window.location.hash = ' ';
        }
        if (UX.dropBlock.$targets) {
            let resetComponent = true;
            UX.dropBlock.$controller.attr('aria-expanded', 'false');
            UX.dropBlock.$targets.each(function () {
                let targetId = $(this).attr('data-db-target-of'); // get this dropblock holder controller
                let thisClicked = e && $(this).is($(e.target).closest('.accordion-tabbed__content'));

                if (!(UX.dropBlock.$controller.closest($(this)).length > 0) && $(this).hasClass('js--open')) {
                    //check if this is not nested drop-block
                    if (!e || !thisClicked) {
                        $(this).removeClass('js--open'); // close this dropblock holder
                        $("[data-db-target-for='" + targetId + "']").removeClass('js--open'); // remove class from this holder
                        $(document).trigger('db-hide');
                    } else {
                        resetComponent = false;
                    }
                } else {
                    return;
                }
            });
            if (resetComponent) {
                UX.dropBlock.$controllers = $('[data-db-target-for]');
                UX.dropBlock.$controllers.removeClass('js--open');
                if ($('body').attr('data-active') === 'dropBlock') {
                    $('body').removeAttr('data-active');
                }
                UX.dropBlock.$target.closest("[class*='sticko']").removeClass('js--open');
                if (UX.dropBlock.$controller.closest('.figure-viewer').length) {
                    UX.dropBlock.$controller
                        .closest('.figure-viewer')
                        .find('.figure-viewer__caption-body')
                        .css('overflow-y', 'auto');
                }
                UX.dropBlock.switch.back();
            }
            if (UX.dropBlock.$target) {
                UX.dropBlock.$target.find('input:focus').blur();
            }
        }
    };
    UX.dropBlock.find.target = function () {
        if ($('[data-db-target-of]').length) {
            UX.dropBlock.$targets = $('[data-db-target-of]');
            UX.dropBlock.$target = $(
                "[data-db-target-of='" + UX.dropBlock.$controller.attr('data-db-target-for') + "']"
            );
            UX.dropBlock.$section = UX.dropBlock.$target;
            let $nested = UX.dropBlock.$section.parents('.dropBlock__holder');
            if ($nested.length) {
                UX.dropBlock.$parentSection = $nested;
            } else {
                UX.dropBlock.$parentSection = null;
            }
            UX.dropBlock.find.parent();
        }
    };
})();
