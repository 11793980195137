$(function () {
    let $drawers = $('.top-drawer');
    let $drawer = $('.request-reset-password-drawer');
    let $loginPopup = $('.login-popup');

    let $content = $drawer.find('.content');
    let $form = $content.find('form');

    let $email = $form.find('.email');
    let $submit = $form.find('.submit');

    let $original = $content.find('.form');
    let $success = $content.find('.success-template');
    let $successMsg = $success.find('p');
    let $message = $content.find('.message');
    let $resetCancel = $drawer.find('.request-reset-cancel a');
    let $resetForm = $drawer.find('.request-reset-password-form');

    $successMsg.data('original', $successMsg.text());

    let items = $drawer.find('a, button, input');
    let lastItem,
        revers = false,
        tabKey = 9,
        shift = 16,
        spaceKey = 32,
        $close = $drawer.find('.close');

    function tabEvent() {
        lastItem.off('focusout');
        $close.off('focusout');
        $close.on('focusout', function (e) {
            $email.focus();
        });
        lastItem.on('focusout', function (e) {
            $close.focus();
        });
    }

    function tabRevers() {
        $close.off('focusout');
        lastItem.off('focusout');
        $close.on('focusout', function (e) {
            lastItem.focus();
        });
    }

    items.each(function (index) {
        if (index === items.length - 1) {
            lastItem = $(this);
        }
    });

    $('.show-request-reset-password', $loginPopup).click(function (event) {
        if ($drawer && $drawer.length > 0) {
            $drawers.addClass('hidden');
            $drawer.removeClass('hidden');
            $success.addClass('hidden');
            $resetCancel
                .text('Cancel')
                .attr('aria-label', 'Close reset password popup')
                .attr('title', 'Close reset password popup');
            $resetForm.removeClass('loading');
            $successMsg.text($successMsg.data('original'));

            $original.removeClass('hidden');
            $content.slideDown('fast');
            $content.find(':focusable').first().focus();
            event.preventDefault();
        }
    });

    $drawer.on('click', '.cancel, .close', function (event) {
        $content.slideUp('fast');
        $message.html('');
        $email.val('');
        $submit.attr('disabled', true);
        $drawer.addClass('hidden');
        event.preventDefault();
        $('.login-popup').find(':focusable').first().focus();
    });

    $drawer.on('keyup', function (e) {
        if (e.keyCode === 27) {
            // Escape is pressed
            $drawer.find('.cancel').trigger('click');
        }

        if (e.keyCode === shift) {
            revers = false;
        }
    });

    $drawer.on('keydown', function (e) {
        if (e.keyCode === shift) {
            revers = true;
        }

        if ((e.keyCode || e.which) === tabKey) {
            if (!revers) {
                tabEvent();
            } else {
                tabRevers();
            }
        }
    });

    $close.on('keyup', function (e) {
        if ((e.keyCode || e.which) === spaceKey) {
            $close.trigger('click');
        }
    });

    $form.submit(function (event) {
        event.preventDefault();
        if (!$email.val()) {
            return;
        }
        let url = $form.attr('action');
        let data = $form.serializeArray();

        /*format variable indicates the type of response that the server will give. If not indicated, then the server
        give a regular response.*/
        data.push({name: 'PopupView', value: 'true'});
        $resetForm.addClass('loading');
        $.ajax({method: 'post', url: url, data: data, dataType: 'json', xhrFields: {withCredentials: true}})
            .then(function (data) {
                if (typeof data.isEmailSent !== 'undefined' && data.isEmailSent === 'true') {
                    $successMsg.text($successMsg.text().replace('__email__', $email.val()));
                    $original.addClass('hidden');
                    $success.removeClass('hidden');
                    $resetCancel
                        .text('Close')
                        .attr('aria-label', 'Close confirmation message popup')
                        .attr('title', 'Close confirmation message popup');
                } else if (typeof data.isNonMigratedError !== 'undefined' && data.isNonMigratedError === 'true') {
                    window.location = '/action/showErrorAE?type=NON_MIGRATED_FORGOT_PASSWORD';
                } else {
                    $original.removeClass('hidden');
                    $success.addClass('hidden');
                    $message.html(data.errorMessage);
                    $resetCancel
                        .text('Cancel')
                        .attr('aria-label', 'Close reset password popup')
                        .attr('title', 'Close reset password popup');
                    $successMsg.text($successMsg.data('original'));
                    $message.focus();
                }
                $resetForm.removeClass('loading');
            })
            .fail(function () {
                $original.removeClass('hidden');
                $success.addClass('hidden');
                //in ROM registration link will have '#' href value, so you can use it to know if site is ROM or not.
                if ($('.show-registration').attr('href') === '#') {
                    $message.html(
                        'We apologize for the inconvenience, but this feature is temporarily unavailable while we are performing system maintenance.'
                    );
                } else {
                    $message.html('Unknown error');
                }
                $resetCancel
                    .text('Cancel')
                    .attr('aria-label', 'Close reset password popup')
                    .attr('title', 'Close reset password popup');
                $successMsg.text($successMsg.data('original'));
                $resetForm.removeClass('loading');
                $message.focus();
            });
    });
});
