(function () {
    // truncate do card description to 2 lines
    const $doCardDescription = $('.do-card .do-item-content .do-item-description');

    if ($doCardDescription.length) {
        const doTruncate = () => {
            $doCardDescription.truncate({
                lines: 2,
            });
        };

        $(document).on('smartResize', doTruncate);
        doTruncate();
    }

    // update search withing collection widget's placeholder text
    const $doSearchWithin = $('.search-in-do .search-in_input');

    if ($doSearchWithin.length) {
        $doSearchWithin.attr('placeholder', 'Search within');
    }

    const resizeIframe = $iframe => {
        try {
            if ($iframe.contents()) {
                $iframe.height($iframe.contents().height());
            }
        } catch (e) {
            console.error(e);
        }
    };

    let $detailIframe = $('.do-detail iframe:not(#surveyFrame)');
    if ($detailIframe.length) {
        $detailIframe.on('load', function () {
            let counter = 0;
            const resizeInterval = setInterval(() => {
                resizeIframe($detailIframe);
                counter += 1;
                if (counter > 20) {
                    clearInterval(resizeInterval);
                }
            }, 1000);
        });
    }

    const relatedInnerBox = document.querySelectorAll('.do-related-sidebar__inner-box');
    const relatedInnerBoxHandler = item => {
        const doRelatedList = item.querySelector('.do-related');
        if (doRelatedList && doRelatedList.children.length > 0) {
            item.classList.remove('hidden');
        }
    };
    relatedInnerBox.forEach(item => relatedInnerBoxHandler(item));
})();
